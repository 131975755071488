import { Machine, assign } from "xstate";
import axios from "axios";
import { alertService } from "@/main";
import { logger } from "@/utils";

export default Machine(
  {
    id: "ContactUsForm",
    initial: "idle",
    context: {
      error: {},
      contactInfo: {
        firstname: null,
        lastname: null,
        companyName: null,
        mobilePhone: null,
        companyEmail: null,
        enquiryType: null,
        enquiryMessage: null,
        termsAccepted: false
      },
      preQualification: {
        bondValue: null,
        isProfitable: null,
        netTangibleAsset: null,
        atoPaymentPlan: null
      }
    },
    states: {
      idle: {
        on: {
          CONTACT_INPUT: "contactInput"
        }
      },
      contactInput: {
        entry: "assignPrequalification",
        on: {
          SUBMIT: "submittingContact"
        }
      },
      submittingContact: {
        invoke: {
          id: "submitContact",
          src: "submitContact",
          onDone: [
            {
              target: "done"
            }
          ],
          onError: {
            target: "contactInput",
            actions: [
              "assignError",
              context =>
                alertService.send("ALERT", {
                  alert: {
                    type: "warning",
                    message: "Could not submit contact",
                    items: [context.error],
                    timer: 5
                  }
                })
            ]
          }
        }
      },
      done: {
        type: "final"
      }
    }
  },
  {
    actions: {
      assignError: assign({
        error: (_context, event) => {
          return event.data?.response?.data?.errorMessage || event.data?.message;
        }
      }),
      assignPrequalification: assign((context, event) => {
        return {
          preQualification: {
            ...context.preQualification,
            ...(event.bondValue && { bondValue: event.bondValue }),
            ...(event.isProfitable != null && { isProfitable: event.isProfitable }),
            ...(event.netTangibleAsset && { netTangibleAsset: event.netTangibleAsset }),
            ...(event.atoPaymentPlan != null && { atoPaymentPlan: event.atoPaymentPlan })
          }
        };
      })
    },
    services: {
      submitContact: async (context, event) => {
        const data = {
          type: event.submitType,
          email: context.contactInfo.companyEmail,
          phone: context.contactInfo.mobilePhone,
          data: {
            firstname: context.contactInfo.firstname,
            lastname: context.contactInfo.lastname,
            companyName: context.contactInfo.companyName,
            ...context.preQualification,
            enquiryType: context.contactInfo.enquiryType,
            enquiryMessage: context.contactInfo.enquiryMessage
          }
        };

        try {
          const response = await axios
            .post(`${process.env.VUE_APP_SERVICES_REST_ENDPOINT}/activity/submit`, data)
            .then(response => response.data);

          return response;
        } catch (e) {
          logger.error(e);
        }
      }
    }
  }
);
