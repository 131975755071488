<template>
  <div @keyup.enter="onSubmit">
    <!-- EMAIL -->
    <UiInput
      v-model.trim="form.email"
      name="email"
      label="Email"
      type="email"
      class="mb-4"
      :error="$v.form.email.$error"
    >
      <template v-if="$v.form.email.$error" slot="errors">
        <p v-if="!$v.form.email.required">
          Email is required.
        </p>

        <p v-if="!$v.form.email.email">
          Valid email address required.
        </p>
      </template>
    </UiInput>

    <!-- CODE -->
    <UiInput
      v-model.trim="form.code"
      name="code"
      label="Code"
      class="mb-4"
      :error="$v.form.code.$error"
    >
      <template v-if="$v.form.code.$error" slot="errors">
        <p v-if="!$v.form.code.required">
          A code is required.
        </p>
      </template>
    </UiInput>

    <div class="flex items-center">
      <UiInput is="UiButton" theme="primary" :disabled="resendingCode" @click="onSubmit"
        >Confirm Account</UiInput
      >

      <button
        type="button"
        :disabled="resendingCode || !form.email"
        :title="
          form.email ? 'Send another confirmation code' : 'Please enter an email address first'
        "
        class="resend-button"
        :class="{
          'animate__animated animate__pulse animate__infinite': resendingCode
        }"
        @click="resendCodeHandler"
      >
        {{ resendLabel }}
      </button>
    </div>
  </div>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";
import formMixin from "@/mixins/form";
import UiInput from "@/components/UI/UiInput/UiInput";
import UiButton from "@/components/UI/UiButton/UiButton";

export default {
  name: "ConfirmationForm",
  components: { UiButton, UiInput },
  mixins: [formMixin],
  props: {
    resendingCode: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: {
        code: "",
        email: this.$route?.query?.email
      }
    };
  },
  computed: {
    resendLabel() {
      return this.resendingCode ? "Sending new code..." : "Resend code?";
    }
  },
  methods: {
    onSubmit() {
      // validate using this.validate() from our formMixin...
      this.validate();

      if (!this.$v.$invalid) {
        const { email, code } = this.form;

        this.$emit("submit", { email, code });
      }
    },
    resendCodeHandler() {
      this.$emit("resend-code", this.form?.email);
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      code: {
        required
      }
    }
  }
};
</script>

<style scoped>
.resend-button {
  @apply ml-2 text-xs text-gray-500;

  &[disabled] {
    @apply opacity-50 cursor-not-allowed;
  }
}
</style>
