<template>
  <UiCard theme="secondary" class="message-card" data-testid="message-card">
    <div class="message-card-meta">
      <div class="flex items-center mr-2 md:mr-4">
        <img
          class="w-6 h-auto rounded"
          :src="profilePicPath"
          :alt="`${senderName} profile picture`"
        />
      </div>

      <div class="flex justify-between flex-grow">
        <div>
          <p v-if="senderOrganization" class="message-card-sender-organization">
            {{ senderOrganization | capitalize }}
            <span class="sender-badge" :class="senderRole">{{ senderRole | capitalize }}</span>
          </p>
          <p v-if="senderName" class="message-card-sender">
            {{ senderName | capitalize }}
          </p>
        </div>
        <p v-if="dateSent" class="message-card-date-sent" data-testid="date-sent">
          {{ dateSent | formatDateTimeFromString }}
        </p>
      </div>
    </div>

    <div class="message-card-content">
      <slot></slot>
    </div>
  </UiCard>
</template>

<script>
import UiCard from "@/components/UI/UiCard/UiCard";
import eguaranteeCheckIcon from "@/assets/images/logos/eguarantee-check.png";

export default {
  name: "MessageCard",
  components: { UiCard },
  props: {
    dateSent: {
      type: String,
      default: null
    },
    profilePic: {
      type: String,
      default: ""
    },
    senderOrganization: {
      type: String,
      default: ""
    },
    senderName: {
      type: String,
      default: ""
    },
    senderRole: {
      type: String,
      default: ""
    }
  },
  computed: {
    profilePicPath() {
      return this.profilePic ? this.profilePic : eguaranteeCheckIcon;
    }
  }
};
</script>

<style scoped>
/* Base Styles */
.message-card-sender {
  @apply text-xs text-cool-gray-500;

  @screen md {
    @apply text-xs;
  }
}

.message-card-sender-organization {
  @apply text-xs text-cool-gray-500;

  @screen md {
    @apply text-sm;
  }
}

.message-card-date-sent {
  @apply text-xs font-light text-cool-gray-500;
}

.message-card-meta {
  @apply flex flex-wrap w-full pb-2 mb-3 border-b text-xs;

  @screen md {
    @apply pb-4;
  }
}

.message-card-content {
  @apply w-full text-sm text-gray-700 font-light whitespace-pre-wrap;

  p {
    @apply mb-2;

    &:last-child {
      @apply mb-0;
    }
  }

  >>> strong {
    @apply font-bold;
  }

  >>> em,
  i {
    @apply italic;
  }

  >>> ul {
    @apply list-disc list-inside;
  }

  >>> ol {
    @apply list-decimal list-inside;
  }

  >>> blockquote {
    @apply border-l-4 border-solid pl-2;
  }

  >>> code {
    @apply font-mono bg-gray-100;
  }

  @screen md {
    @apply flex-1 leading-7;
  }
}

.sender-badge {
  @apply rounded bg-white shadow-sm text-xs text-gray-600 px-3 py-0.5 ml-1;

  &.guarantor,
  &.admin {
    @apply bg-primary text-white;
  }

  &.landlord {
    @apply bg-green-400 text-white;
  }

  &.tenant {
    @apply bg-blue-400 text-white;
  }
}
</style>
