<template>
  <UiField
    v-if="field"
    class="draft-guarantee-field"
    :class="{ highlight: field.proposedValue }"
    :label="field.label"
    full-width-meta
  >
    <div class="flex flex-wrap items-end justify-between lg:flex-no-wrap">
      <div
        class="flex items-center w-full mr-2 justify-between"
        :class="{ 'items-baseline': isEditing && error }"
      >
        <!-- RICHTEXT FIELD -->
        <RichTextFieldValue v-if="showValue && richTextField">
          <GuaranteeFieldValue
            v-if="field.excerptValue"
            slot="excerpt"
            :rich-text="field.excerptValue"
          />

          <GuaranteeFieldValue
            v-if="field.value"
            slot="value"
            :rich-text="field.proposedValue || field.value"
          />
        </RichTextFieldValue>

        <!-- INPUT FIELD -->
        <GuaranteeFieldValue
          v-if="showValue && inputField"
          :proposed-by="field.proposedBy"
          :proposed-value="field.proposedValue"
          :value="field.value"
          :leading-value="field.leadingValue"
          :trailing-value="field.trailingValue"
        />

        <!-- DATE FIELD -->
        <GuaranteeFieldValue
          v-if="showValue && dateField"
          :proposed-by="field.proposedBy"
          :proposed-value="formatDateField(field.proposedValue)"
          :value="formatDateField(field.value)"
          :leading-value="field.leadingValue"
          :trailing-value="field.trailingValue"
        />

        <!-- NUMBER FIELD -->
        <GuaranteeFieldValue
          v-if="showValue && numberField"
          :proposed-by="field.proposedBy"
          :proposed-value="formatNumberField(field.proposedValue, field.precision)"
          :leading-value="field.leadingValue"
          :trailing-value="field.trailingValue"
          :value="formatNumberField(field.value, field.precision)"
        />

        <!-- ORGANIZATION FIELD -->
        <OrganizationFieldValue v-if="organizationField" :organization-id="field.organizationId">
          <GuaranteeFieldValue
            :proposed-by="field.proposedBy"
            :proposed-value="field.proposedValue"
            :value="field.value"
          />
        </OrganizationFieldValue>

        <UiTable
          v-if="tableField"
          :id="fieldId"
          :headers="jsonValue.headers"
          :body="jsonValue.body"
        ></UiTable>

        <div v-if="contactField" class="w-full space-y-2 md:w-1/2">
          <p class="contact-detail">
            <span class="contact-icon">
              <UserIcon size="0.9x" />
            </span>
            {{ field.value }}
          </p>

          <a v-if="field.email" class="contact-detail contact-link" :href="`mailto:${field.email}`">
            <span class="contact-icon">
              <MailIcon size="0.9x" />
            </span>
            {{ field.email }}
          </a>
          <a v-if="field.phone" class="contact-detail contact-link" :href="`tel:${field.phone}`">
            <span class="contact-icon">
              <PhoneIcon size="0.9x" />
            </span>
            {{ field.phone }}
          </a>
        </div>

        <!-- Editable Fields -->
        <component
          :is="fieldComponent"
          v-if="field.isEditable && allowAmendments"
          v-show="isEditing"
          :name="fieldId"
          :placeholder="field.label"
          :type="fieldType"
          :value="field.proposedValue || value[fieldId] || originalValue"
          :error="error"
          :clear-button="false"
          :mime-types="mimeTypes"
          :class="{ 'w-full': fieldComponent === 'UiTextarea' }"
          @input="onFieldInput"
        >
          <template v-if="error" slot="errors">
            <p v-for="(e, i) in errors" :key="i">
              {{ e }}
            </p>
          </template>
        </component>

        <div class="flex flex-col items-center gap-2">
          <UiButton
            v-if="isEditing && amended"
            v-scroll-to="'#amendment-actions'"
            theme="primary"
            type="button"
            size="xs"
          >
            Submit
          </UiButton>
          <UiButton
            v-if="field.isEditable && allowAmendments && !field.isApprovable"
            theme="outline"
            type="button"
            size="xs"
            @click="editHandler"
          >
            {{ isEditing ? "Cancel" : "Edit" }}
          </UiButton>
        </div>
      </div>

      <!-- Approve/Undo Buttons -->
      <div v-if="field.proposedValue && canApprove" class="mt-3 lg:mt-0 field-actions">
        <UiButton
          v-if="field.isApprovable"
          theme="outline bg-green-50 border-green-400 text-green-400"
          size="xs"
          type="button"
          class="mr-2"
          @click="acceptHandler"
        >
          Accept
        </UiButton>
        <UiButton theme="outline bg-cool-gray-50" size="xs" type="button" @click="rejectHandler">
          Undo
        </UiButton>
      </div>
    </div>
  </UiField>
</template>

<script>
import { format } from "date-fns";
import { useService } from "@xstate/vue";
import { computed } from "@vue/composition-api";
import { organizationService } from "@/main";
import UiButton from "@/components/UI/UiButton/UiButton";
import UiDateInput from "@/components/UI/UiDateInput/UiDateInput";
import UiField from "@/components/UI/UiField/UiField";
import UiTextarea from "@/components/UI/UiTextarea/UiTextarea";
import UiInput from "@/components/UI/UiInput/UiInput";
import { getInputComponent, getFieldInputType, formatNumber } from "@/utils/index";
import OrganizationFieldValue from "@/components/Guarantee/fields/OrganizationFieldValue";
import RichTextFieldValue from "@/components/Guarantee/fields/RichTextFieldValue";
import GuaranteeFieldValue from "@/components/Guarantee/fields/GuaranteeFieldValue";
import { UserIcon, MailIcon, PhoneIcon } from "@vue-hero-icons/outline";
import UiTable from "@/components/UI/UiTable/UiTable.vue";

export default {
  name: "GuaranteeField",
  components: {
    GuaranteeFieldValue,
    OrganizationFieldValue,
    RichTextFieldValue,
    UiButton,
    UiDateInput,
    UiField,
    UiInput,
    UiTextarea,
    UserIcon,
    MailIcon,
    PhoneIcon,
    UiTable
  },
  props: {
    allowAmendments: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    },
    field: {
      type: Object,
      default: () => {}
    },
    guaranteeId: {
      type: String,
      default: ""
    },
    originalValue: {
      type: String,
      default: ""
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      isEditing: false
    };
  },
  setup() {
    // Setup our state machine...
    const { state: organizationState } = useService(organizationService);

    const canApprove = computed(() => {
      const approvers = ["tenant", "landlord"];
      const role = organizationState?.value?.context?.organization?.role;
      return approvers.includes(role);
    });

    return {
      canApprove
    };
  },
  computed: {
    amended() {
      return this.value[this.fieldId];
    },
    dateField() {
      return this.field?.__typename === "DateField";
    },
    fieldComponent() {
      return getInputComponent(this.field?.__typename);
    },
    fieldId() {
      return this.field?.id;
    },
    fieldType() {
      return getFieldInputType(this.field?.__typename);
    },
    inputField() {
      return ["InputField", "TextareaField"].includes(this.field?.__typename);
    },
    mimeTypes() {
      return this.field?.mimeTypes ?? "";
    },
    numberField() {
      return this.field?.__typename === "NumberField";
    },
    organizationField() {
      return this.field.__typename === "OrganizationField";
    },
    richTextField() {
      return this.field.__typename === "RichTextField";
    },
    contactField() {
      return this.field.__typename === "ContactField";
    },
    tableField() {
      return this.field.__typename === "TableField";
    },
    showValue() {
      return this.field.value && !this.isEditing;
    },
    jsonValue() {
      try {
        return JSON.parse(this.field.value);
      } catch (err) {
        return {};
      }
    }
  },
  methods: {
    acceptHandler() {
      const amendment = this.createAmendment(true);

      this.$emit("resolve-amendment", amendment);
    },
    createAmendment(acceptAmendment) {
      return {
        acceptAmendment,
        fieldId: this.fieldId,
        guaranteeId: this.guaranteeId
      };
    },
    editHandler() {
      // If they cancel then reset the fields value...
      if (this.isEditing) this.onFieldInput(this.originalValue);

      this.isEditing = !this.isEditing;
    },
    formatDateField(value) {
      if (!value) return null;

      return format(new Date(value), "d MMMM yyyy");
    },
    formatNumberField(value, precision) {
      if (!value) return null;

      return formatNumber(value, precision);
    },
    onFieldInput(data) {
      const { fieldId } = this;

      // Get the current value and add the additional input data...
      const value = {
        ...this.value
      };

      // If the data has changed from the original value then append the new value...
      if (data !== this.originalValue) value[fieldId] = data;

      // If it hasn't changed then remove the prop...
      if (data === this.originalValue) delete value[fieldId];

      this.$emit("input", value);
    },
    rejectHandler() {
      if (window.confirm("Are you sure?")) {
        const amendment = this.createAmendment(false);

        this.$emit("resolve-amendment", amendment);
      }
    }
  }
};
</script>

<style scoped>
.draft-guarantee-field {
  &.highlight {
    @apply bg-blue-50 rounded;
  }
}

.field-actions {
  @apply flex items-center mt-2;

  @screen sm {
    @apply justify-end;
  }
}

.contact-detail {
  @apply flex items-center;
  color: rgb(107, 114, 128);
}

.contact-link {
  @apply text-primary;
  font-size: 14px;
}

.contact-icon {
  @apply text-gray-500 mr-1 block;
}
</style>
