<template>
  <div class="flex items-center justify-center p-10 h-full">
    <div class="flex flex-col items-center justify-center max-w-md">
      <h1 :size="2" class="text-newsecondary font-bold text-4xl sm:text-5xl md:text-6xl">
        Welcome
      </h1>
      <p class="text-newwhite">
        We are thrilled to have you here
      </p>

      <div class="mt-16">
        <UiButton
          button-class="px-10"
          type="button"
          theme="primary"
          :disabled="state.matches('submittingLeadSource')"
          @click="onClickAccess"
          ><font-awesome-icon class="pr-4" icon="fa-solid fa-lock" inverse /><span
            class="font-normal"
            >Access your platform</span
          >
        </UiButton>
      </div>
    </div>
  </div>
</template>

<script>
import { useMachine } from "@xstate/vue";
import CreateAccountFormMachine from "./create-account-machine";
import UiButton from "@/components/UI/UiButton/UiButton";

export default {
  name: "WelcomeForm",
  components: {
    UiButton
  },
  setup() {
    const { state, send } = useMachine(CreateAccountFormMachine);
    return {
      state,
      send
    };
  },
  mounted() {
    this.send({ type: "WELCOME" });
  },
  methods: {
    onClickAccess() {
      this.send({ type: "ACCESS_PLATFORM" });
    }
  }
};
</script>
