<template>
  <div>
    <!-- LABEL -->
    <label v-if="label || $slots.label" :for="name" class="ui-base-input-label" :class="size"
      >{{ label }}
      <slot name="label" />
    </label>

    <div class="relative">
      <!-- INPUT SLOT -->
      <div
        class="ui-base-input-slot"
        :class="{
          'rounded-md shadow-sm': decorated
        }"
      >
        <div
          v-if="$slots.leadingAddon"
          class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"
        >
          <span class="text-gray-500 sm:text-sm sm:leading-5">
            <slot name="leadingAddon" />
          </span>
        </div>

        <div
          v-if="$slots.default"
          class="ui-base-input-addon-wrap"
          :class="[
            $slots.leadingAddon ? `leading-addon-${leadingWidth}` : '',
            $slots.trailingAddon ? 'trailing-addon' : ''
          ]"
        >
          <slot />
        </div>

        <div
          v-if="$slots.trailingAddon"
          class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
        >
          <span class="text-gray-500 sm:text-sm sm:leading-5">
            <slot name="trailingAddon" />
          </span>
        </div>

        <!-- ERROR ICON  -->
        <div
          v-if="error"
          class="ui-base-input-error-icon"
          :class="{
            'trailing-addon': $slots.trailingAddon
          }"
        >
          <svg class="w-3 h-3 text-red-500" fill="currentColor" viewBox="0 0 20 20">
            <path
              fill-rule="evenodd"
              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
              clip-rule="evenodd"
            />
          </svg>
        </div>
      </div>

      <!-- LOADER -->
      <div v-if="loading" class="ui-base-input-loader spin">
        <LoaderIcon size=".9x" />
      </div>
      <!-- .loading-indicator -->
    </div>
    <!-- .relative -->

    <!-- ERRORS -->
    <div v-if="$slots.errors" class="ui-base-input-errors">
      <slot name="errors" />
    </div>
    <!-- .errors -->
  </div>
  <!-- .relative -->
</template>

<script>
import { LoaderIcon } from "vue-feather-icons";

export default {
  name: "UiBaseInput",
  components: { LoaderIcon },
  inheritAttrs: false,
  props: {
    decorated: {
      type: Boolean,
      default: true
    },
    error: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    leadingWidth: {
      type: String,
      default: "sm"
    },
    loading: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ""
    },
    size: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped>
/* Base Styles */
.ui-base-input-label {
  @apply block text-xs font-medium leading-5 text-gray-700 mb-2 ml-1;

  @screen md {
    @apply text-sm;

    &.sm {
      @apply text-xs;
    }
  }
}

.ui-base-input-slot {
  @apply relative;
}

.ui-base-input-error-icon {
  @apply absolute flex items-baseline pointer-events-none;
  right: 2px;
  top: 2px;
}

.ui-base-input-errors {
  @apply text-red-500 text-xs;
  padding-left: 0.8rem;

  div,
  p {
    @apply mt-1;
  }
}

.ui-base-input-loader {
  @apply absolute text-gray-400;
  top: 11px;
  right: 11px;
}

.ui-base-input-addon-wrap {
  &.leading-addon-sm {
    input {
      @apply pl-7;
    }
  }

  &.leading-addon-md {
    input {
      @apply pl-10;
    }
  }

  &.trailing-addon {
    input {
      @apply pr-7;
    }
  }
}
</style>
