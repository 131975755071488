<template>
  <UiBaseInput :name="$attrs.name" :label="label" :error="error">
    <Multiselect
      :class="inputClass"
      :value="value"
      :placeholder="placeholder"
      :options="options"
      :mode="mode"
      searchable
      @clear="$emit('clear', $event)"
      @change="$emit('input', $event)"
    />

    <slot slot="errors" name="errors" />
  </UiBaseInput>
</template>

<script>
import UiBaseInput from "@/components/UI/UiBaseInput/UiBaseInput";
import Multiselect from "@vueform/multiselect/dist/multiselect.vue2.js";

export default {
  name: "UiMultiselect",
  components: { Multiselect, UiBaseInput },
  props: {
    label: {
      type: String,
      default: ""
    },
    info: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: "Select"
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Array],
      default: ""
    },
    error: {
      type: Boolean,
      default: false
    },
    single: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    mode() {
      return this.single ? "single" : "tags";
    },
    inputClass() {
      return this.error ? "error" : undefined;
    }
  }
};
</script>

<style>
.multiselect-placeholder,
.multiselect-option,
.multiselect-tag {
  font-size: 0.75rem !important;
}

.multiselect-tag {
  @apply bg-primary;

  i:before {
    color: white;
  }
}

.error {
  .multiselect-input {
    @apply border-red-300 text-red-900 pr-10;
  }
}

.multiselect-options {
  max-height: 300px !important;
}
</style>
