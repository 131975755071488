import { Machine, assign } from "xstate";
import { API, graphqlOperation } from "aws-amplify";
import {
  GenerateReport as GenerateReportMutation,
  GenerateUserReport as GenerateUserReportMutation
} from "@/graphql/mutations";
import { getGraphQlData, helperActions } from "@/utils/machine-helpers";

const initialState = {
  errors: []
};

export default Machine(
  {
    id: "reports-machine",

    initial: "idle",

    context: {
      ...initialState
    },

    states: {
      idle: {
        on: {
          GENERATE: "generating"
        }
      },
      generating: {
        entry: "resetState",
        invoke: {
          id: "generating",
          src: "generateReport",
          onDone: {
            target: "idle",
            actions: "navigateToDownloadUrl"
          },
          onError: {
            target: "error",
            actions: ["assignErrors", "trackErrors"]
          }
        }
      },
      error: {
        on: {
          RETRY: "generating"
        }
      }
    }
  },
  {
    actions: {
      ...helperActions,
      navigateToDownloadUrl: (context, event) => {
        const downloadUrl =
          getGraphQlData(event, "generateReport.url") ||
          getGraphQlData(event, "generateUserReport.url");

        window.document.location.href = downloadUrl;
      },
      resetState: assign({
        ...initialState
      })
    },
    // guards: {
    // },
    services: {
      generateReport: (context, event) => {
        if (event.reportType.startsWith("user")) {
          const input = {
            reportType: event.reportType
          };

          return API.graphql(graphqlOperation(GenerateUserReportMutation, { input }));
        }

        return API.graphql(graphqlOperation(GenerateReportMutation));
      }
    }
  }
);
