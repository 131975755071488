import { getUserAttributes } from "@/lib/auth";

export default {
  API: {
    graphql_headers: async () => {
      const orgId = await getUserAttributes("custom:organizationId");

      return {
        orgId
      };
    }
  },
  Auth: {
    identityPoolId: process.env.VUE_APP_COGNITO_IDENTITY_POOL_ID,
    region: process.env.VUE_APP_COGNITO_REGION,
    identityPoolRegion: process.env.VUE_APP_COGNITO_IDENTITY_POOL_REGION,
    userPoolId: process.env.VUE_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.VUE_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.VUE_APP_COGNITO_DOMAIN,
      scope: ["phone", "email", "profile", "openid"],
      redirectSignIn: process.env.VUE_APP_SIGN_IN_REDIRECT,
      redirectSignOut: process.env.VUE_APP_SIGN_OUT_REDIRECT,
      responseType: "code"
    }
  },
  aws_appsync_graphqlEndpoint: process.env.VUE_APP_APPSYNC_GRAPHQL_ENDPOINT,
  aws_appsync_region: process.env.VUE_APP_APPSYNC_REGION,
  aws_appsync_authenticationType: process.env.VUE_APP_APPSYNC_AUTHENTICATION_TYPE
};
