import { Machine, assign } from "xstate";

const initialState = {
  alert: {}
};

export default Machine(
  {
    id: "alert",

    initial: "idle",

    context: {
      ...initialState
    },

    states: {
      idle: {
        entry: "resetState",
        on: {
          ALERT: "active"
        }
      },
      active: {
        entry: "assignAlert",
        on: {
          CLEAR: "idle"
        }
      }
    }
  },
  {
    actions: {
      assignAlert: assign({
        alert: (context, event) => event?.alert
      }),
      resetState: assign({
        ...initialState
      })
    }
  }
);
