<template>
  <AbnSearchLogic :value="value" @input="$emit('input', $event)">
    <div slot-scope="{ abn, asicSearchState, companyName, errors, onAsicSearch, onContactInput }">
      <div>
        <UiInput
          type="number"
          name="abn"
          :loading="asicSearchState.matches('searching')"
          :placeholder="`Enter ${role} ABN/NZBN`"
          :disabled="disabled || asicSearchState.matches('searching')"
          :value="abn"
          :error="error || !!errors.length"
          @input="onAsicSearch"
        >
          <slot slot="errors" name="abnErrors">
            <p v-for="(e, i) in errors" :key="i">
              {{ e }}
            </p>
          </slot>
        </UiInput>

        <v-popover v-if="tooltip && tooltip.length && !companyName" slot="meta" offset="16">
          <UiButton theme="link" size="sm" button-class="p-0">Learn more&hellip;</UiButton>
          <template slot="popover">
            <a v-close-popover class="tooltip-close">x</a>
            <p>
              {{ tooltip }}
            </p>
          </template>
        </v-popover>
        <label v-if="companyName" class="w-full mt-2 text-xs font-light text-gray-700 pl-1.5">{{
          companyName
        }}</label>
      </div>

      <UiField
        v-if="asicSearchState.matches('noOrganization') && showContactDetails"
        theme=""
        class="-m-2 mt-2"
        :info="
          `This ${role} is currently not in our system. Please supply contact details and we will invite them to view the application once approved.`
        "
      >
        <UiInput
          v-if="!companyName || !companyName.length"
          type="text"
          name="companyName"
          :placeholder="`Enter the name of the business`"
          class="mb-4"
          :value="value.name"
          :error="companyNameError"
          @input="onContactInput('name', $event)"
        >
          <slot slot="errors" name="companyNameErrors" />
        </UiInput>
        <UiInput
          type="email"
          name="contactEmail"
          :placeholder="`Enter your ${role}'s email`"
          class="mb-4"
          :value="value.contactEmail"
          :error="contactEmailError"
          @input="onContactInput('contactEmail', $event)"
        >
          <slot slot="errors" name="contactEmailErrors" />
        </UiInput>

        <UiInput
          type="text"
          name="contactFirstname"
          :placeholder="`Enter your ${role}'s first name`"
          validation="required"
          class="mb-4"
          :value="value.contactFirstname"
          :error="contactFirstnameError"
          @input="onContactInput('contactFirstname', $event)"
        >
          <slot slot="errors" name="contactFirstnameErrors" />
        </UiInput>

        <UiInput
          type="text"
          name="contactLastname"
          :placeholder="`Enter your ${role}'s last name`"
          class="mb-4"
          :value="value.contactLastname"
          :error="contactLastnameError"
          @input="onContactInput('contactLastname', $event)"
        >
          <slot slot="errors" name="contactLastnameErrors" />
        </UiInput>
      </UiField>
    </div>
  </AbnSearchLogic>
</template>

<script>
import AbnSearchLogic from "@/components/AbnSearch/AbnSearchLogic";
import UiButton from "@/components/UI/UiButton/UiButton";
import UiField from "@/components/UI/UiField/UiField";
import UiInput from "@/components/UI/UiInput/UiInput";

export default {
  name: "AbnSearchApplicationInput",
  components: { AbnSearchLogic, UiButton, UiField, UiInput },
  props: {
    companyNameError: {
      type: Boolean,
      default: false
    },
    contactEmailError: {
      type: Boolean,
      default: false
    },
    contactFirstnameError: {
      type: Boolean,
      default: false
    },
    contactLastnameError: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    role: {
      type: String,
      default: ""
    },
    showContactDetails: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {}
    },
    tooltip: {
      type: String,
      default: undefined
    }
  }
};
</script>
