<template>
  <main>
    <div class="container ui-main">
      <slot />
    </div>
  </main>
</template>

<script>
export default {
  name: "UiMain"
};
</script>
