<template>
  <div @keyup.enter="enterPressed">
    <UiHeadline :size="4" class="mb-4">Sign In</UiHeadline>

    <UiInput
      v-model.trim="email"
      name="email"
      label="Email"
      placeholder="Enter your email"
      validation="required|email"
      class="mb-4"
      :error="$v.email.$error"
      data-cy="email"
      :disabled="showPass"
      :aria-disabled="showPass"
    >
      <template v-if="$v.email.$error" slot="errors">
        <p v-if="!$v.email.required">
          Email is required.
        </p>

        <p v-if="!$v.email.email">
          Valid email address required.
        </p>
      </template>
    </UiInput>
    <UiButton is="UiButton" v-if="!showPass" type="submit" theme="primary" @click="onContinue">
      Continue
    </UiButton>

    <div v-if="showPass">
      <UiInput
        v-model.trim="password"
        type="password"
        name="password"
        label="Password"
        placeholder="Enter your password"
        validation="required"
        class="mb-4"
        :error="$v.password.$error"
        data-cy="password"
      >
        <template v-if="$v.password.$error" slot="errors">
          <p v-if="!$v.password.required">
            Password is required.
          </p>
        </template>
      </UiInput>

      <div class="flex items-center justify-between">
        <div>
          <UiButton is="UiButton" type="submit" theme="primary" data-cy="signIn" @click="onSubmit">
            Sign in
          </UiButton>
          <button class="outline-none text-xs text-gray-500 ml-4" @click="goBack">
            Back
          </button>
        </div>

        <p class="ml-2 text-xs text-gray-500">
          <button class="outline-none" @click="$emit('forgot-password', { email })">
            Forgot password?
          </button>
        </p>
      </div>
    </div>

    <div class="mt-6 md:mt-12 text-center">
      <p class="text-xs text-gray-500">
        Not registered yet?
        <router-link to="/get-started" class="">
          Create an account
        </router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
import formMixin from "@/mixins/form";
import UiButton from "@/components/UI/UiButton/UiButton";
import UiHeadline from "@/components/UI/UiHeadline/UiHeadline";
import UiInput from "@/components/UI/UiInput/UiInput";
import { enforceSSO } from "@/utils/machine-helpers";

export default {
  name: "SignInForm",
  components: { UiButton, UiHeadline, UiInput },
  mixins: [formMixin],
  data() {
    return {
      email: "",
      password: "",
      showPass: false
    };
  },
  methods: {
    goBack() {
      this.$v.password.$reset();
      this.showPass = false;
    },
    enterPressed() {
      this.showPass ? this.onSubmit() : this.onContinue();
    },
    onContinue() {
      this.$v.email.$touch();
      if (this.$v.email.$invalid || this.$v.email.$error) {
        return;
      }

      const { email } = this;
      if (enforceSSO(email)) {
        this.$emit("sso-sign-in", { email });
        return;
      }

      this.showPass = true;
    },
    onSubmit() {
      this.validate();

      const { email, password } = this;

      if (!this.$v.$invalid) this.$emit("submit", { email, password });
    }
  },
  validations: {
    email: {
      required,
      email
    },
    password: {
      required
    }
  }
};
</script>
