<template>
  <UiCard theme="secondary" size="sm" class="meta-card" data-testid="meta-card">
    <div class="flex">
      <div class="meta-card-content">
        <div class="flex items-center mb-2">
          <slot name="icon">
            <div v-if="iconType" class="meta-card-icon-wrap" data-testid="icon-type">
              <CheckCircleIcon v-if="iconType === 'check'" class="text-green-500 meta-card-icon" />
              <PaperClipIcon
                v-if="iconType === 'paperclip'"
                class="meta-card-icon paperclip-icon"
              />
              <ExclamationCircleIcon
                v-if="iconType === 'exclamationCircle'"
                class="text-orange-400 meta-card-icon"
              />
              <ExclamationIcon
                v-if="iconType === 'exclamationTriangle'"
                class="text-yellow-400 meta-card-icon"
              />
            </div>
          </slot>

          <p v-if="title" class="meta-card-title">{{ formattedTitle }}</p>
          <slot name="title" />
        </div>
        <!-- .flex items-center -->

        <p v-if="info" class="meta-card-info">{{ info }}</p>
        <slot name="info" />
        <slot></slot>
        <div
          v-if="hiddenContentActive"
          v-dompurify-html="hiddenContent"
          class="meta-card-hidden-content"
        />

        <UiButton
          v-if="hiddenContent"
          theme="link"
          remove-padding
          size="xs"
          @click="onToggleHiddenContent"
          >{{ hiddenContentButtonLabel }}</UiButton
        >

        <div class="meta-card-footer">
          <div v-if="$slots['action']" class="flex-auto">
            <slot name="action"></slot>
          </div>

          <div v-if="$slots['footer']" class="flex-auto">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </UiCard>
</template>

<script>
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  ExclamationIcon,
  PaperClipIcon
} from "@vue-hero-icons/outline";
import UiCard from "@/components/UI/UiCard/UiCard";
import UiButton from "@/components/UI/UiButton/UiButton";

export default {
  name: "MetaCard",
  components: {
    CheckCircleIcon,
    ExclamationCircleIcon,
    ExclamationIcon,
    PaperClipIcon,
    UiButton,
    UiCard
  },
  props: {
    iconType: {
      type: String,
      default: ""
    },
    hiddenContent: {
      type: String,
      default: ""
    },
    info: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    footer: {
      type: String,
      default: ""
    },
    showHiddenContent: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hiddenContentActive: this.showHiddenContent
    };
  },
  computed: {
    formattedTitle() {
      const LINE_LENGTH = 32;
      return this.title
        ?.split(" ")
        .map(part => {
          if (part.length < LINE_LENGTH) return part;

          let formattedPart = part;
          for (let i = LINE_LENGTH; i <= part.length; i += LINE_LENGTH) {
            formattedPart = formattedPart.slice(0, i) + "-" + formattedPart.slice(i);
          }
          return formattedPart;
        })
        .join(" ");
    },
    hiddenContentButtonLabel() {
      return this.hiddenContentActive ? "Hide Details" : "Show Details";
    }
  },
  methods: {
    onToggleHiddenContent() {
      this.hiddenContentActive = !this.hiddenContentActive;
    }
  }
};
</script>

<style scoped>
/* Base Styles */
.meta-card {
  @apply w-full;
}

.meta-card-icon-wrap {
  @apply mr-2;
}

.meta-card-icon {
  @apply h-5 w-5;
  margin-top: 1px;

  &.paperclip-icon {
    @apply h-4 w-4 text-gray-600;
    margin-top: 3px;
  }
}

.meta-card-content {
  @apply w-full;
  >>> a,
  >>> button {
    @apply text-xs text-primary;
  }
}

.meta-card-hidden-content {
  @apply text-xs text-cool-gray-500 mt-2;
}

.meta-card-title {
  @apply text-xs font-semibold text-gray-900 max-w-full;
}

.meta-card-info {
  @apply text-gray-500 text-xs font-light leading-relaxed;
}

.meta-card-footer {
  @apply mt-3 flex justify-between items-end text-gray-400 text-xs;
}
</style>
