<template>
  <div>
    <div v-if="0 === value.envelopes.length">
      Digital signature is initializing, please wait and return later
    </div>
    <div class="grid grid-cols-2">
      <template v-for="(recipient, i) in recipients">
        <UiDocusignStatusBox
          :key="i"
          :value="recipient.isCompleted"
          :label="recipient.name == null ? 'N/A' : recipient.name"
        />
        <UiDocusignStatusBox
          v-if="recipient.witness"
          :key="i + '100'"
          :value="recipient.witness.isCompleted"
          label="Witness"
        />
      </template>
    </div>
  </div>
</template>

<script>
import UiDocusignStatusBox from "@/components/UI/UiDocusign/UiDocusignStatusBox";

export default {
  name: "UiDocusignStatusPersonal",
  components: { UiDocusignStatusBox },
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ""
    },
    value: {
      type: Object,
      default: () => ({ isCompleted: false, envelopes: [] })
    }
  },
  computed: {
    inputClassObject() {
      return {
        error: this.error,
        [this.size]: this.size
      };
    },
    recipients() {
      const recipients = [];
      const filter = !!this.value.data;
      const set = new Set(this.value.data?.map(d => d.email));
      this.value.envelopes.forEach(envelope => {
        const _recipients = {};
        const _witnesses = [];
        envelope.recipients.forEach(recipient => {
          if ("witnessFor" in recipient) _witnesses.push(recipient);
          else if (!filter || (recipient.email !== null && set.has(recipient.email))) {
            _recipients[recipient.recipientId] = { ...recipient };
          }
        });
        _witnesses.forEach(witness => {
          if (witness.witnessFor in _recipients) {
            _recipients[witness.witnessFor].witness = witness;
          }
        });
        recipients.push(...Object.values(_recipients));
      });
      return recipients;
    }
  }
};
</script>
