import { Guarantees as GuaranteesQuery } from "@/graphql/queries";
import {
  PerformGuaranteeAction as PerformGuaranteeActionMutation,
  CreateDraftApplication,
  DeleteApplication
} from "@/graphql/mutations";
import { getGraphQlData, helperActions } from "@/utils/machine-helpers";
import { API, graphqlOperation } from "aws-amplify";
import pickBy from "lodash.pickby";
import { assign, Machine } from "xstate";
import { router } from "@/router";

const initialState = {
  errors: [],
  filters: {},
  guarantees: [],
  paginationKey: "",
  searchFilter: {}
};

export default Machine(
  {
    id: "guaranteesMachine",

    initial: "loading",

    context: {
      ...initialState
    },

    states: {
      error: {
        on: {
          RETRY: "loading"
        }
      },
      loading: {
        invoke: {
          id: "guarantees",
          src: "guaranteesQuery",
          onDone: {
            target: "loaded.hist",
            actions: ["assignGuarantees", "assignPaginationKey"]
          },
          onError: {
            target: "error",
            actions: ["assignErrors", "trackErrors"]
          }
        }
      },
      loaded: {
        on: {
          FILTER: ".filtering",
          SEARCH: ".searching",
          DELETE: "deleting",
          CREATE_DRAFT: "creatingDraft",
          FETCH_MORE: "#guaranteesMachine.loading"
        },
        initial: "idle",
        states: {
          idle: {},
          filtering: {
            entry: ["assignFilters", "resetGuarantees"],
            invoke: {
              id: "filtering",
              src: "guaranteesQuery",
              onDone: {
                target: "filtered",
                actions: ["assignGuarantees", "assignPaginationKey"]
              },
              onError: {
                target: "..error",
                actions: ["assignErrors", "trackErrors"]
              }
            }
          },
          searching: {
            entry: ["assignSearchFilter", "resetGuarantees"],
            invoke: {
              id: "searching",
              src: "guaranteesQuery",
              onDone: {
                target: "filtered",
                actions: ["assignGuarantees", "assignPaginationKey"]
              },
              onError: {
                target: "..error",
                actions: ["assignErrors", "trackErrors"]
              }
            }
          },
          filtered: {},
          hist: {
            type: "history"
          }
        }
      },
      deleting: {
        exit: ["resetGuarantees"],
        invoke: {
          id: "deleting",
          src: "deleteApplication",
          onDone: {
            target: "loading",
            actions: ["scrollToTop"]
          },
          onError: {
            target: "error",
            actions: ["assignErrors", "trackErrors"]
          }
        }
      },
      creatingDraft: {
        invoke: {
          id: "creatingDraft",
          src: "createDraftApplication",
          onDone: {
            actions: ["navigateToGuaranteeRoute"]
          },
          onError: {
            target: "error",
            actions: ["assignErrors", "trackErrors"]
          }
        }
      }
    }
  },
  {
    actions: {
      ...helperActions,
      assignGuarantees: assign({
        guarantees: (context, event) => {
          return [...context.guarantees, ...getGraphQlData(event, "guarantees.guarantees")];
        }
      }),
      assignFilters: assign({
        filters: (context, event) => {
          // Format the new filters...
          const formattedFilters = event.filters?.map(filter => ({
            [filter.type]: filter.value
          }));

          // If we're resetting the filters then return an empty object...
          // Otherwise add the existing filters from the context...
          const existingFilters = event.resetFilters ? {} : context.filters;

          const filters = Object.assign(
            {},
            existingFilters,
            // Merge the formatted filters with the current filters...
            ...formattedFilters
          );

          // Only return filters that have a value...

          const newFilters = pickBy(filters, value => value);
          router.patchQuery(newFilters);
          return newFilters;
        }
      }),
      assignPaginationKey: assign({
        paginationKey: (context, event) => getGraphQlData(event, "guarantees.paginationKey")
      }),
      assignSearchFilter: assign({
        searchFilter: (context, { filter }) => {
          let searchFilter = {};

          if (filter.value) {
            searchFilter = {
              [filter.type]: filter.value
            };
          }

          return searchFilter;
        }
      }),
      resetGuarantees: assign({
        guarantees: []
      }),
      scrollToTop: () => {
        window.scrollTo(0, 0);
      },

      navigateToGuaranteeRoute: (_, event) => {
        const { guaranteeId } = event.data.data.createDraftApplication;
        return router.push({
          name: "Guarantee",
          params: { id: guaranteeId }
        });
      }
    },
    services: {
      createDraftApplication: (_, event) => {
        const { guaranteeType } = event;

        return API.graphql(
          graphqlOperation(CreateDraftApplication, {
            input: {
              guaranteeType: guaranteeType.toUpperCase()
            }
          })
        );
      },
      deleteApplication: (_, event) => {
        const { guaranteeId } = event;
        return API.graphql(
          graphqlOperation(DeleteApplication, {
            input: {
              guaranteeId
            }
          })
        );
      },

      guaranteesQuery: (context, event) => {
        const { filters, searchFilter } = context;
        const paginationKey = event.type === "FETCH_MORE" ? context.paginationKey : null;

        const input = {
          filters: {
            ...filters,
            ...searchFilter
          },
          paginationKey
        };

        return API.graphql(graphqlOperation(GuaranteesQuery, { input }));
      }
    }
  }
);
