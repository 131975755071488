<template>
  <!-- Q1 -->
  <div
    v-if="
      state.matches('Q1') || state.matches('searchingASIC') || state.matches('creatingOrganization')
    "
    class="flex flex-col justify-between p-10 h-full"
  >
    <div class="flex flex-col items-center sm:items-end w-full">
      <h1 :size="3" class="text-newsecondary font-bold text-3xl sm:text-4xl md:text-5xl">
        Congratulations!
      </h1>
      <p class="font-bold text-xl text-newwhite">
        You pre-qualify for our Lease Bond
      </p>
      <p class="mt-2 text-newwhite">Let's quickly set you up</p>
    </div>
    <div class="w-full sm:w-1/2 p-0 md:px-10 lg:px-24 mt-4">
      <div class="flex flex-auto gap-2 flex-col sm:flex-row">
        <UiInput
          v-model.trim="state.context.organizationInfo.contactFirstName"
          class="flex-grow"
          type="text"
          name="Firstname"
          placeholder="First name"
          :on-enter-key="() => onSubmitOrganization()"
          :error="$v.state.context.organizationInfo.contactFirstName.$error"
        >
          <template v-if="$v.state.context.organizationInfo.contactFirstName.$error" slot="errors">
            <p v-if="!$v.state.context.organizationInfo.contactFirstName.required">
              First Name is required.
            </p>
          </template>
          <template slot="label">
            <span class="text-newwhite">First name</span>
            <span class="text-red-500 text-xl"> *</span>
          </template>
        </UiInput>

        <UiInput
          v-model.trim="state.context.organizationInfo.contactLastName"
          class="flex-grow"
          type="text"
          name="Lastname"
          placeholder="Last name"
          :on-enter-key="() => onSubmitOrganization()"
          :error="$v.state.context.organizationInfo.contactLastName.$error"
        >
          <template v-if="$v.state.context.organizationInfo.contactLastName.$error" slot="errors">
            <p v-if="!$v.state.context.organizationInfo.contactLastName.required">
              Last Name is required.
            </p>
          </template>
          <template slot="label">
            <span class="text-newwhite">Last name</span>
            <span class="text-red-500 text-xl"> *</span>
          </template>
        </UiInput>
      </div>
      <UiInput
        v-model.trim="state.context.organizationInfo.contactEmail"
        class="mt-4 flex-grow"
        type="text"
        name="CompanyEmail"
        placeholder="you@company.com"
        :on-enter-key="() => onSubmitOrganization()"
        :error="$v.state.context.organizationInfo.contactEmail.$error"
      >
        <template v-if="$v.state.context.organizationInfo.contactEmail.$error" slot="errors">
          <p
            v-if="
              !$v.state.context.organizationInfo.contactEmail.required ||
                !$v.state.context.organizationInfo.contactEmail.emailValidator
            "
          >
            A valid email is required
          </p>
        </template>
        <template slot="label">
          <span class="text-newwhite">Email</span>
          <span class="text-red-500 text-xl"> *</span>
        </template>
      </UiInput>
      <UiInput
        v-model.trim="state.context.accountInfo.contactPhone"
        class="mt-4 flex-grow"
        type="text"
        name="contactPhone"
        leading-width="md"
        placeholder="0400 000 000"
        :on-enter-key="() => onSubmitOrganization()"
        :error="$v.state.context.accountInfo.contactPhone.$error"
      >
        <template slot="leadingAddon"> +{{ state.context.accountInfo.countryPhoneCode }} </template>
        <template v-if="$v.state.context.accountInfo.contactPhone.$error" slot="errors">
          <p>
            Please provide a valid mobile number.
          </p>
        </template>
        <template slot="label">
          <span class="text-newwhite">Phone</span>
          <span class="text-red-500 text-xl"> *</span>
        </template>
      </UiInput>
      <UiInput
        v-model.trim="state.context.organizationInfo.organizationAbn"
        class="mt-4 flex-grow"
        type="text"
        name="OrganizationAbn"
        placeholder="43 616 322 484"
        :disabled="state.matches('searchingASIC')"
        :on-enter-key="() => onSubmitOrganization()"
        :error="
          $v.state.context.organizationInfo.organizationAbn.$error ||
            $v.state.context.organizationInfo.organizationName.$error
        "
        @input="onAbnInput"
      >
        <template
          v-if="
            $v.state.context.organizationInfo.organizationAbn.$error ||
              $v.state.context.organizationInfo.organizationName.$error
          "
          slot="errors"
        >
          <p v-if="!$v.state.context.organizationInfo.organizationAbn.required">
            Please
            <span class="cursor-pointer underline font-bold" @click="onContactUsClicked"
              >contact us</span
            >
            should you not have an ABN
          </p>
          <p v-else>
            A valid ABN is required.
          </p>
        </template>
        <template slot="label">
          <span class="text-newwhite">Company ABN</span>
          <span class="text-red-500 text-xl"> *</span>
        </template>
        <template v-if="state.matches('searchingASIC')" slot="trailingAddon">
          <LoaderIcon size="1.3x" class="spin text-cool-500" />
        </template>
      </UiInput>
      <div class="mt-16">
        <UiButton
          button-class="px-10"
          type="button"
          theme="primary"
          :disabled="!state.matches('Q1')"
          @click="onSubmitOrganization()"
        >
          <LoaderIcon
            v-if="state.matches('creatingOrganization')"
            size="1.3x"
            class="spin text-cool-500"
          />
          <span v-else class="font-normal">
            Next<font-awesome-icon class="px-1" icon="fa-solid fa-arrow-right" inverse />
          </span>
        </UiButton>
      </div>
    </div>
    <div class="flex justify-center sm:justify-end w-full gap-2 mt-4">
      <div class="bg-newsecondary rounded-full h-4 w-4"></div>
      <div class="bg-newgrey rounded-full h-4 w-4"></div>
      <div class="bg-newgrey rounded-full h-4 w-4"></div>
      <div class="bg-newgrey rounded-full h-4 w-4"></div>
    </div>
  </div>

  <!-- Q2 -->
  <div
    v-else-if="state.matches('Q2') || state.matches('creatingUser')"
    class="flex flex-col justify-between p-10 h-full"
  >
    <div class="flex flex-col items-center sm:items-end w-full">
      <h1 :size="3" class="text-newsecondary font-bold text-3xl sm:text-4xl md:text-5xl">
        Congratulations!
      </h1>
      <p class="font-bold text-xl text-newwhite">
        You pre-qualify for our Lease Bond
      </p>
      <p class="mt-2 text-newwhite">Let's quickly set you up</p>
    </div>
    <div class="w-full sm:w-1/2 p-0 md:px-10 lg:px-24 mt-4">
      <UiInput
        v-model.trim="state.context.accountInfo.password"
        class="mt-4 flex-grow"
        type="password"
        name="Password"
        :on-enter-key="() => onSubmitPassword()"
        :error="$v.state.context.accountInfo.password.$error"
      >
        <template v-if="$v.state.context.accountInfo.password.$error" slot="errors">
          <p v-if="!$v.state.context.accountInfo.password.required">
            Password is required
          </p>
          <p v-if="!$v.state.context.accountInfo.password.hasLowerCase">
            Password must contain atleast 1 lower case character.
          </p>
          <p v-if="!$v.state.context.accountInfo.password.hasUpperCase">
            Password must contain atleast 1 upper case character.
          </p>
          <p v-if="!$v.state.context.accountInfo.password.hasNumber">
            Password must contain atleast 1 number.
          </p>
          <p v-if="!$v.state.context.accountInfo.password.minLength">
            Password must be atleast
            {{ $v.state.context.accountInfo.password.$params.minLength.min }} characters long.
          </p>
        </template>
        <template slot="label"><span class="text-newwhite">Password</span></template>
      </UiInput>
      <UiInput
        v-model.trim="state.context.accountInfo.confirmPassword"
        class="mt-4 flex-grow"
        type="password"
        name="ConfirmPassword"
        :on-enter-key="() => onSubmitPassword()"
        :error="$v.state.context.accountInfo.confirmPassword.$error"
      >
        <template v-if="$v.state.context.accountInfo.confirmPassword.$error" slot="errors">
          <p>
            Must be the same as password
          </p>
        </template>
        <template slot="label"><span class="text-newwhite">Confirm Password</span></template>
      </UiInput>
      <div class="mt-4">
        <UiCheckbox
          v-model="state.context.accountInfo.termsAccepted"
          checkbox-type="tickbox"
          size="sm"
          :error="$v.state.context.accountInfo.termsAccepted.$error"
          ><template slot="label"
            ><p class="text-newwhite text-sm">
              I agree with eGuarantee's
              <span class="cursor-pointer font-bold underline" @click="openTermsWindow"
                >terms of use</span
              >
              and
              <span class="cursor-pointer font-bold underline" @click="openPrivacyPolicyWindow"
                >privacy policy</span
              >
            </p></template
          >
          <template v-if="$v.state.context.accountInfo.termsAccepted.$error" slot="errors">
            <p>
              Please accept and agree to the Terms of Use and Privacy Policy.
            </p>
          </template>
        </UiCheckbox>
      </div>
      <div class="mt-16">
        <UiButton
          button-class="px-10"
          type="button"
          theme="primary"
          :disabled="!state.matches('Q2')"
          @click="onSubmitPassword()"
        >
          <LoaderIcon v-if="state.matches('creatingUser')" size="1.3x" class="spin text-cool-500" />
          <span v-else class="font-normal">
            <font-awesome-icon class="px-1" icon="fa-solid fa-lock" inverse />Create account
          </span>
        </UiButton>
      </div>
    </div>
    <div class="flex justify-center sm:justify-end w-full gap-2 mt-4">
      <div class="bg-newsecondary rounded-full h-4 w-4"></div>
      <div class="bg-newsecondary rounded-full h-4 w-4"></div>
      <div class="bg-newgrey rounded-full h-4 w-4"></div>
      <div class="bg-newgrey rounded-full h-4 w-4"></div>
    </div>
  </div>

  <!-- Q3 -->
  <div
    v-else-if="
      state.matches('Q3') ||
        state.matches('resendingSignup') ||
        state.matches('submittingConfirmSignup')
    "
    class="flex flex-col justify-between p-10 h-full"
  >
    <div class="flex flex-col items-center sm:items-end w-full">
      <h1 :size="3" class="text-newsecondary font-bold text-3xl sm:text-4xl md:text-5xl">
        Congratulations!
      </h1>
      <p class="font-bold text-xl text-newwhite">
        You pre-qualify for our Lease Bond
      </p>
      <p class="mt-2 text-newwhite">Let's quickly set you up</p>
    </div>
    <div class="w-full p-0 md:px-10 lg:px-24 mt-4">
      <div class="mt-16">
        <p class="text-newwhite">
          Enter the verification code we sent to
          <span class="text-newsecondary">{{ state.context.registeredOrganization.email }}</span>
        </p>
      </div>
      <div class="flex gap-2 w-full sm:w-1/2 mt-4">
        <UiInput
          v-for="(_code, index) in Array.from({ length: 6 })"
          :key="index"
          :ref="`confirmCodeEmail${index}`"
          v-model.trim="confirmCodeEmail[index]"
          type="text"
          :disabled="state.matches('submittingConfirmSignup')"
          :input-ref="`confirmCodeEmail${index}`"
          :input-style="'text-center'"
          :name="`confirmCodeEmail${index}`"
          :on-enter-key="() => onSubmitConfirmCode('confirmCodeEmail')"
          :error="$v.state.context.accountInfo.confirmCodeEmail.$dirty && !confirmCodeEmail[index]"
          @input="trimConfirmCode('confirmCodeEmail', $event, index)"
          @click="moveCursor('confirmCodeEmail', index)"
        >
        </UiInput>
      </div>
      <div class="mt-16">
        <p class="text-newgrey text-xs">
          Didn't get the code?
          <span class="cursor-pointer font-bold" @click="resendConfirmation('confirmCodeEmail')"
            >Resend</span
          >
          or
          <span class="cursor-pointer font-bold" @click="callEguarantee">call us</span>
        </p>
      </div>
      <div class="mt-4">
        <UiButton
          button-class="px-10"
          type="button"
          theme="primary"
          :disabled="state.matches('submittingConfirmSignup')"
          @click="onSubmitConfirmCode('confirmCodeEmail')"
        >
          <LoaderIcon
            v-if="state.matches('submittingConfirmSignup')"
            size="1.3x"
            class="spin text-cool-500"
          />
          <div v-else>
            <font-awesome-icon class="px-1" icon="fa-solid fa-envelope" inverse />
            <span class="font-normal pl-3">Verify email</span>
          </div>
        </UiButton>
      </div>
    </div>
    <div class="flex justify-center sm:justify-end w-full gap-2 mt-4">
      <div class="bg-newsecondary rounded-full h-4 w-4"></div>
      <div class="bg-newsecondary rounded-full h-4 w-4"></div>
      <div class="bg-newsecondary rounded-full h-4 w-4"></div>
      <div class="bg-newgrey rounded-full h-4 w-4"></div>
    </div>
  </div>

  <!-- Q4 -->
  <div
    v-else-if="
      state.matches('Q4') || state.matches('signingIn') || state.matches('submittingConfirmSignIn')
    "
    class="flex flex-col justify-between p-10 h-full"
  >
    <div class="flex flex-col items-center sm:items-end w-full">
      <h1 :size="3" class="text-newsecondary font-bold text-3xl sm:text-4xl md:text-5xl">
        Congratulations!
      </h1>
      <p class="font-bold text-xl text-newwhite">
        You pre-qualify for our Lease Bond
      </p>
      <p class="mt-2 text-newwhite">Let's quickly set you up</p>
    </div>
    <div class="w-full p-0 md:px-10 lg:px-24 mt-4">
      <div class="mt-16">
        <p class="text-newwhite">
          Enter the verification code we sent to
          <span class="text-newsecondary"
            >+{{ state.context.accountInfo.countryPhoneCode
            }}{{ state.context.accountInfo.contactPhone.replace(/^0+/, "") }}</span
          >
        </p>
      </div>
      <div class="flex gap-2 w-full sm:w-1/2 mt-4">
        <UiInput
          v-for="(_code, index) in Array.from({ length: 6 })"
          :key="index"
          :ref="`confirmCodeMobile${index}`"
          v-model.trim="confirmCodeMobile[index]"
          class="flex-grow-0"
          type="text"
          :disabled="state.matches('signingIn') || state.matches('submittingConfirmSignIn')"
          :input-ref="`confirmCodeMobile${index}`"
          :input-style="'text-center'"
          :name="`confirmMobileCode${index}`"
          :on-enter-key="() => onSubmitConfirmCode('confirmCodeMobile')"
          :error="
            $v.state.context.accountInfo.confirmCodeMobile.$dirty && !confirmCodeMobile[index]
          "
          @input="trimConfirmCode('confirmCodeMobile', $event, index)"
          @click="moveCursor('confirmCodeMobile', index)"
        >
        </UiInput>
      </div>
      <div class="mt-16">
        <p class="text-newgrey text-xs">
          Didn't get the code?
          <span
            class="cursor-pointer font-bold"
            @click="() => resendConfirmation('confirmCodeMobile')"
            >Resend</span
          >
          or
          <span class="cursor-pointer font-bold" @click="callEguarantee">call us</span>
        </p>
      </div>
      <div class="mt-4">
        <UiButton
          button-class="px-10"
          type="button"
          theme="primary"
          :disabled="state.matches('signingIn') || state.matches('submittingConfirmSignIn')"
          @click="onSubmitConfirmCode('confirmCodeMobile')"
        >
          <LoaderIcon
            v-if="state.matches('signingIn') || state.matches('submittingConfirmSignIn')"
            size="1.3x"
            class="spin text-cool-500"
          />
          <div v-else>
            <font-awesome-icon class="px-1" icon="fa-solid fa-phone" inverse />
            <span class="font-normal pl-3">Verify Phone</span>
          </div>
        </UiButton>
      </div>
    </div>
    <div class="flex justify-center sm:justify-end w-full gap-2 mt-4">
      <div class="bg-newsecondary rounded-full h-4 w-4"></div>
      <div class="bg-newsecondary rounded-full h-4 w-4"></div>
      <div class="bg-newsecondary rounded-full h-4 w-4"></div>
      <div class="bg-newsecondary rounded-full h-4 w-4"></div>
    </div>
  </div>
  <!-- end form -->
</template>

<script>
import { LoaderIcon } from "vue-feather-icons";
import { useMachine } from "@xstate/vue";
import {
  required,
  email as emailValidator,
  numeric,
  minLength,
  maxLength,
  sameAs
} from "vuelidate/lib/validators";
import { hasLowerCase, hasUpperCase, hasNumber, validMobileNumber } from "@/utils/validations";

import formMixin from "@/mixins/form";
import CreateAccountFormMachine from "./create-account-machine";
import phoneCodes from "@/utils/international-phone";

import UiButton from "@/components/UI/UiButton/UiButton";
import UiCheckbox from "@/components/UI/UiCheckbox/UiCheckbox";
import UiInput from "@/components/UI/UiInput/UiInput";
import router from "@/router";

export default {
  name: "CreateAccountForm",
  components: {
    LoaderIcon,
    UiButton,
    UiCheckbox,
    UiInput
  },
  mixins: [formMixin],
  setup() {
    const { state, send } = useMachine(CreateAccountFormMachine);

    const confirmCodeEmail = [];
    const confirmCodeMobile = [];
    const preQualification = {};

    return {
      confirmCodeEmail,
      confirmCodeMobile,
      phoneCodes,
      preQualification,
      state,
      send
    };
  },
  mounted() {
    this.state.context.organizationInfo.contactEmail = this.$route.query?.email;
    this.state.context.organizationInfo.contactFirstName = this.$route.query?.firstname;
    this.state.context.organizationInfo.contactLastName = this.$route.query?.lastname;
    this.state.context.accountInfo.contactPhone = this.$route.query?.phone?.replace(/,/g, "");

    this.preQualification.bondValue = this.$route.params?.bondValue;
    this.preQualification.isProfitable = this.$route.params?.isProfitable;
    this.preQualification.atoPaymentPlan = this.$route.params?.atoPaymentPlan;

    this.$router.push({ query: null });
  },
  methods: {
    removeWhiteSpace(field, value) {
      this.state.context.form[field] = value.replace(/\s/g, "");
    },
    trimConfirmCode(propName, value, index) {
      const inputVal = value;
      const numericIndex = this[propName][index].search(/(\d)[^\d]*$/);
      this[propName][index] = inputVal[numericIndex];

      this.state.context.accountInfo[propName] = this[propName].join("");

      // focus next if has value
      if (this[propName][index]) {
        if (index === 5) {
          this.onSubmitConfirmCode(propName);
        } else {
          this.$refs[`${propName}${index + 1}`][0].$refs[`${propName}${index + 1}`].focus();
        }
        // focus prev if no value. assume that user deleted from the input
      } else if (!inputVal && index !== 0) {
        this.$refs[`${propName}${index - 1}`][0].$refs[`${propName}${index - 1}`].focus();
      }
    },
    moveCursor(refPrefix, index) {
      const inputEl = this.$refs[`${refPrefix}${index}`][0].$refs[`${refPrefix}${index}`];

      const end = inputEl.value.length;

      setTimeout(function() {
        inputEl.setSelectionRange(end, end);
        inputEl.focus();
      }, 0);
    },
    onContactUsClicked() {
      router.push({
        name: "ContactUs",
        params: {
          ...this.preQualification
        }
      });
    },
    onSubmitConfirmCode(type) {
      this.validate();
      switch (type) {
        case "confirmCodeEmail":
          if (!this.$v.state.context.accountInfo.confirmCodeEmail.$invalid) {
            this.send({ type: "SUBMIT_CONFIRM_SIGNUP" });
          }
          this.$v.state.context.accountInfo.confirmCodeMobile.$reset();
          break;
        case "confirmCodeMobile":
          if (!this.$v.state.context.accountInfo.confirmCodeMobile.$invalid) {
            this.send({ type: "SUBMIT_CONFIRM_SIGNIN" });
          }
          break;
      }
    },
    onAbnInput(value) {
      this.state.context.organizationInfo.organizationAbn = value.replace(/\s/g, "");

      if (!this.$v.state.context.organizationInfo.organizationAbn.$invalid) {
        this.send({ type: "SEARCH_ABN", data: { abn: value } });
      } else {
        this.send("RESET_COMPANY");
      }
    },
    onSubmitOrganization() {
      this.validate();

      if (
        !this.$v.state.context.organizationInfo.$invalid &&
        !this.$v.state.context.accountInfo.contactPhone.$invalid
      ) {
        this.$v.state.context.accountInfo.password.$reset();
        this.$v.state.context.accountInfo.confirmPassword.$reset();
        this.$v.state.context.accountInfo.termsAccepted.$reset();
        this.send({ type: "CREATING_ORGANIZATION" });
      }
    },
    openTermsWindow() {
      window.open("https://www.eguarantee.com.au/terms", "_blank");
    },
    openPrivacyPolicyWindow() {
      window.open("https://www.eguarantee.com.au/privacy", "_blank");
    },
    onSubmitPassword() {
      this.validate();

      if (
        !this.$v.state.context.accountInfo.password.$invalid &&
        !this.$v.state.context.accountInfo.confirmPassword.$invalid &&
        !this.$v.state.context.accountInfo.termsAccepted.$invalid
      ) {
        this.$v.state.context.accountInfo.confirmCodeEmail.$reset();
        this.send({ type: "CREATING_USER" });
      }
    },
    resendConfirmation(type) {
      switch (type) {
        case "confirmCodeEmail":
          this.send({ type: "RESEND_SIGNUP" });
          break;
        case "confirmCodeMobile":
          this.send({ type: "RESEND_SIGNIN" });
          break;
      }
    },
    callEguarantee() {
      window.open("tel:1300561804");
    }
  },
  validations() {
    return {
      state: {
        context: {
          organizationInfo: {
            contactEmail: {
              required,
              email: emailValidator
            },
            contactFirstName: { required },
            contactLastName: { required },
            organizationAbn: {
              required,
              numeric,
              maxLength: maxLength(13),
              minLength: minLength(11)
            },
            organizationName: { required }
          },
          accountInfo: {
            contactPhone: validMobileNumber,
            countryPhoneCode: { required },
            password: {
              required,
              minLength: minLength(8),
              hasLowerCase,
              hasUpperCase,
              hasNumber
            },
            confirmPassword: {
              sameAsPassword: sameAs("password")
            },
            termsAccepted: { checked: value => value === true },
            confirmCodeEmail: { required, minLength: minLength(6) },
            confirmCodeMobile: { required, minLength: minLength(6) }
          }
        }
      }
    };
  }
};
</script>
