import { createMachine, assign } from "xstate";
import { Auth } from "aws-amplify";
import { router } from "@/router";

const initialState = {
  email: "",
  error: "",
  message: "",
  notice: {
    title: "",
    message: ""
  },
  user: null
};

export default createMachine(
  {
    id: "reset-password",

    initial: "idle",

    context: {
      ...initialState
    },

    states: {
      idle: {
        on: {
          RESEND: "resending",
          UPDATE: "updatingPassword"
        }
      },
      resending: {
        entry: ["resetState", "setEmail"],
        invoke: {
          id: "resending",
          src: (context, event) => Auth.forgotPassword(event.data.email.toLowerCase()),
          onDone: {
            target: "resentLink",
            actions: assign({
              message: () => "Success. A new reset link has been sent to your email"
            })
          },
          onError: {
            target: "idle",
            actions: "assignError"
          }
        }
      },
      resentLink: {
        type: "final"
      },
      updatingPassword: {
        entry: ["resetState", "setEmail"],
        invoke: {
          id: "updatingPassword",
          src: async (context, event) => {
            const { email, code, password } = event.data;

            await Auth.forgotPasswordSubmit(email.toLowerCase(), code, password);
            const signInResp = await Auth.signIn(email.toLowerCase(), password);
            return signInResp;
          },
          onDone: [
            {
              target: "confirm",
              cond: "isMfaAuth",
              actions: assign((context, event) => ({
                user: event.data,
                message: "Updated your password successfully"
              }))
            },
            {
              target: "authenticated",
              cond: "isAuthenticated"
            },
            {
              // If the transition above fails we transition to idle
              target: "idle",
              actions: assign({
                error: "Could not sign you in"
              })
            }
          ],
          onError: [
            {
              target: "idle",
              cond: "isCodeExpired",
              actions: assign({
                notice: () => ({
                  title: "Reset link expired",
                  message:
                    "Please select the 'Request New Link' button to generate a new reset link."
                })
              })
            },
            {
              target: "idle",
              actions: "assignError"
            }
          ]
        }
      },
      confirm: {
        on: {
          CONFIRM: "confirming"
        }
      },
      confirming: {
        entry: "resetState",
        invoke: {
          id: "confirming",
          src: (context, event) => {
            const { code, challengeName } = event.data;

            return Auth.confirmSignIn(context.user, code, challengeName);
          },
          onDone: [
            {
              target: "authenticated",
              // Only transition to 'authenticated' if the guard (cond) evaluates to true
              cond: "isAuthenticated"
            },
            {
              // If the transition above fails we transition to idle
              target: "idle",
              actions: assign({
                error: "Could not confirm your code"
              })
            }
          ],
          onError: {
            target: "confirm",
            actions: "assignError"
          }
        }
      },
      authenticated: {
        entry: (context, event) => router.push({ name: "Home" }),
        type: "final"
      }
    }
  },
  {
    actions: {
      assignError: assign({
        error: (context, event) => event?.data?.response?.data?.errorMessage || event?.data?.message
      }),
      resetState: assign({
        error: "",
        message: "",
        notice: {
          title: "",
          message: ""
        }
      }),
      setEmail: assign((context, event) => ({
        email: event.data?.email?.toLowerCase() ?? ""
      }))
    },
    guards: {
      isMfaAuth: (context, event) => {
        return (
          event?.data?.challengeName === "SMS_MFA" ||
          event?.data?.challengeName === "SOFTWARE_TOKEN_MFA"
        );
      },
      isAuthenticated: (context, event) => {
        return event?.data?.username ? true : false;
      },
      isCodeExpired: (context, event) => {
        return event?.data?.code === "ExpiredCodeException";
      }
    }
  }
);
