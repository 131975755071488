<template>
  <Auth>
    <Confirmation />
  </Auth>
</template>

<script>
import Auth from "@/views/auth/Auth";
import Confirmation from "@/components/Auth/Confirmation/Confirmation";

export default {
  name: "Confirm",
  components: { Auth, Confirmation },
  metaInfo: {
    title: "Confirm"
  }
};
</script>
