<template>
  <div
    class="flex items-center justify-between w-full mb-4 text-xs text-gray-600 form-input md:text-sm"
  >
    <button class="flex items-center" type="button" @click="$emit('download', file)">
      <UiLoader v-if="downloading" label="" />
      <FileTextIcon v-if="!downloading" size="1.2x" class="mr-1.5 text-cool-gray-500" />
      {{ fileName }}
    </button>

    <button
      v-if="!uploading"
      class="z-10 pl-1 text-xs outline-none text-primary"
      @click="$emit('remove', file)"
    >
      (remove)
    </button>

    <p
      v-if="uploading"
      class="leading-none text-green-500 text-xxs animate__animated animate__pulse animate__infinite"
    >
      Uploading&hellip;
    </p>
  </div>
</template>

<script>
import { FileTextIcon } from "vue-feather-icons";
import UiLoader from "@/components/UI/UiLoader/UiLoader";

export default {
  name: "FileItem",
  components: { FileTextIcon, UiLoader },
  props: {
    file: {
      type: [File, Object, String],
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    uploading: {
      type: Boolean,
      default: false
    },
    downloading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    fileName() {
      const fileName = this.$get(this.file, "name");

      if (fileName) {
        return fileName;
      }

      if (this.file.fileName) {
        return this.file.fileName;
      }

      if (typeof this.file === "string") {
        return this.file.split("/").pop();
      }

      return "Unknown file";
    }
  }
};
</script>

<style scoped></style>
