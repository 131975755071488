<template>
  <!-- overlay -->
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
        >&#8203;</span
      >

      <div
        class="inline-block align-bottom bg-cool-gray-50 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
      >
        <!-- close button -->
        <div v-if="closable" class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
          <button
            type="button"
            class="bg-white rounded-md text-gray-400 hover:text-red-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="sr-only">Cancel and Close</span>
            <XIcon @click="$emit('close')" />
          </button>
        </div>
        <!-- header -->
        <div class="text-center">
          <slot name="header">
            <div
              class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"
            >
              <DocumentAddIcon class="text-green-600" />
            </div>
          </slot>

          <!-- body -->
          <div class="mt-3 text-center sm:mt-5">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              Upload and Attach a Document
            </h3>
            <AttachmentsUploader
              v-if="allowAttaching"
              :disable-private="disablePrivate"
              :recipient-options="recipientOptions"
              :loading="attachmentState.matches('attaching') || attachmentState.matches('removing')"
              :role="role"
              class="w-full"
              @attach-document="attachDocument"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DocumentAddIcon, XIcon } from "@vue-hero-icons/outline";
import AttachmentsUploader from "@/components/Attachments/AttachmentsUploader";

export default {
  name: "UploaderModal",
  components: {
    AttachmentsUploader,
    DocumentAddIcon,
    XIcon
  },
  props: {
    closable: {
      type: Boolean,
      default: false
    },
    allowAttaching: {
      type: Boolean,
      default: true
    },
    sendToAttachmentMachine: {
      type: Function,
      required: true
    },
    attachmentState: {
      type: Object,
      required: true
    },
    recipientOptions: {
      type: Array,
      default: () => []
    },
    disablePrivate: {
      type: Boolean,
      default: false
    },
    role: {
      type: String,
      default: ""
    }
  },
  methods: {
    attachDocument(customName, attachment, recipients, isPrivate) {
      this.sendToAttachmentMachine("ATTACH", {
        customName,
        attachment,
        recipients,
        isPrivate
      });
      this.$emit("close");
    }
  }
};
</script>
