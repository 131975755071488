import { render, staticRenderFns } from "./UiInput.vue?vue&type=template&id=12c206ce&scoped=true"
import script from "./UiInput.vue?vue&type=script&lang=js"
export * from "./UiInput.vue?vue&type=script&lang=js"
import style0 from "./UiInput.vue?vue&type=style&index=0&id=12c206ce&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12c206ce",
  null
  
)

export default component.exports