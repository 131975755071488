import { email, maxLength, minLength, numeric, required } from "vuelidate/lib/validators";
import { phone } from "phone";

export const isMimeTypeValid = (fileType, mimeTypes) => {
  // Split the mimeTypes string into an array...
  const mimeTypesArray = mimeTypes.split(",");

  // Check the files mime type is in the array...
  return mimeTypesArray.includes(fileType);
};

export const defaultAbnValidations = (role, form, includeContact = true) => {
  const organizationId = form[role]?.organizationId;

  const name = !organizationId ? { required } : {};

  const contactEmail = !organizationId
    ? {
        required,
        email
      }
    : {};

  const contactFirstname = !organizationId
    ? {
        required
      }
    : {};

  const contactLastname = !organizationId
    ? {
        required
      }
    : {};

  return {
    abn: {
      required,
      numeric,
      minLength: minLength(9),
      maxLength: maxLength(13)
    },
    name: includeContact && name,
    contactEmail: includeContact && contactEmail,
    contactFirstname: includeContact && contactFirstname,
    contactLastname: includeContact && contactLastname
  };
};

// Custom validator to check the value starts with 0 4 for Aus Mobile numbers
const startsWithZeroFour = value => {
  if (typeof value === "undefined" || value === null || value === "") {
    return true;
  }
  return /^04[0-9].*$/g.test(value);
};

const isValidMobileNumber = (phoneNumber, form) => {
  return phone(`+${form.countryPhoneCode}${phoneNumber}`).isValid;
};

const isValidPhoneNumber = (phoneNumber, form) => {
  return phone(`+${form.countryPhoneCode}${phoneNumber}`, { validateMobilePrefix: false }).isValid;
};

export const validMobileNumber = {
  required,
  numeric,
  isValidMobileNumber
};

export const validPhoneNumber = {
  required,
  numeric,
  isValidPhoneNumber
};

export const australianMobileNumber = {
  required,
  numeric,
  minLength: minLength(10),
  maxLength: maxLength(10),
  startsWithZeroFour
};

export const optionalAustralianPhoneNumber = {
  numeric,
  minLength: minLength(10),
  maxLength: maxLength(13)
};

export const australianGenericPhoneNumber = {
  required,
  numeric,
  minLength: minLength(10),
  maxLength: maxLength(13)
};

export const hasLowerCase = val => /[a-z]+/.test(val);
export const hasUpperCase = val => /[A-Z]+/.test(val);
export const hasNumber = val => /[0-9]+/.test(val);
