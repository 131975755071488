import { Machine, assign } from "xstate";
import { API, graphqlOperation } from "aws-amplify";
import { AsicABNSearch } from "@/graphql/queries";
import { helperActions, getGraphQlData } from "@/utils/machine-helpers";

const initialState = {
  abn: "",
  company: {},
  name: "",
  contactEmail: "",
  contactFirstname: "",
  contactLastname: "",
  contactPhone: "",
  errors: [],
  skipOrganizationCheck: false
};

export default Machine(
  {
    id: "asicSearch",

    initial: "idle",

    context: {
      ...initialState
    },

    on: {
      SEARCH: [
        {
          target: "searching",
          cond: "isCorrectLength"
        },
        {
          target: "empty",
          actions: ["assignAbn", "assignCompany"]
        }
      ],
      RESET: "empty"
    },

    states: {
      idle: {},
      empty: {
        entry: ["emitInputData", "resetContactData"]
      },
      searching: {
        entry: ["assignAbn", "emitSearchStart"],
        exit: ["emitSearchFinish"],
        invoke: {
          id: "search",
          src: "asicSearchQuery",
          onDone: [
            {
              target: "hasOrganization",
              cond: "skipOrganizationCheck",
              actions: "assignCompany"
            },
            {
              target: "hasOrganization",
              cond: "hasOrganization",
              actions: "assignCompany"
            },
            {
              target: "noOrganization",
              actions: "assignCompany"
            },
            {
              target: "invalidAbn"
            }
          ],
          onError: {
            target: "empty",
            actions: ["assignErrors", "trackErrors"]
          }
        }
      },
      hasOrganization: {
        entry: ["emitInputData", "resetContactData"]
      },
      noOrganization: {
        entry: "emitInputData",
        on: {
          UPDATE_CONTACT: {
            actions: [
              "emitInputData",
              "assignCompanyName",
              "assignContactEmail",
              "assignContactFirstname",
              "assignContactLastname",
              "assignContactPhone"
            ]
          }
        }
      },
      invalidAbn: {
        entry: ["emitInputData", "resetContactData", "assignCompany"]
      }
    }
  },
  {
    actions: {
      assignAbn: assign({
        abn: (context, event) => event.abn
      }),
      assignCompany: assign({
        company: (context, event) => ({
          ...(event?.data?.data?.asicABNSearch ?? {})
        })
      }),
      assignCompanyName: assign({
        name: (context, event) => event.name ?? context.name
      }),
      assignContactEmail: assign({
        contactEmail: (context, event) => event.contactEmail ?? context.contactEmail
      }),
      assignContactFirstname: assign({
        contactFirstname: (context, event) => event.contactFirstname ?? context.contactFirstname
      }),
      assignContactLastname: assign({
        contactLastname: (context, event) => event.contactLastname ?? context.contactLastname
      }),
      assignContactPhone: assign({
        contactPhone: (context, event) => event.contactPhone ?? context.contactPhone
      }),
      ...helperActions,
      emitInputData: (context, event) => {
        const abn = context.abn;
        const contactEmail = context.contactEmail;
        const contactFirstname = context.contactFirstname;
        const contactLastname = context.contactLastname;
        const contactPhone = context.contactPhone;
        const name = context.company?.name || context.name;
        const organizationId = context.company?.organizationId;

        context.emit("input", {
          abn,
          contactEmail,
          contactFirstname,
          contactLastname,
          contactPhone,
          name,
          organizationId
        });
      },
      emitSearchFinish: (context, event) => {
        context.emit("search-finish");
      },
      emitSearchStart: (context, event) => {
        context.emit("search-start");
      },
      resetContactData: assign({
        name: "",
        contactEmail: "",
        contactFirstname: "",
        contactLastname: "",
        contactPhone: ""
      }),
      resetState: assign({
        ...initialState
      })
    },
    guards: {
      hasOrganization: (context, event) => {
        // Check the response has an organizationId...
        return getGraphQlData(event, "asicABNSearch.organizationId");
      },
      isCorrectLength: (context, event) => {
        // Check the ABN has the correct length of 11...
        return event.abn?.length >= 9 && event.abn?.length < 14;
      },
      isValidAbn: (context, event) => {
        // Check the ABN is valid if we get company details back...
        return getGraphQlData(event, "asicABNSearch.name") !== undefined;
      },
      skipOrganizationCheck: (context, event) => {
        return context.skipOrganizationCheck;
      }
    },
    services: {
      asicSearchQuery: (context, event) => {
        const input = {
          abn: event.abn
        };

        return API.graphql(graphqlOperation(AsicABNSearch, { input }));
      }
    }
  }
);
