<template>
  <UiBaseInput :name="$attrs.name" :label="label" :error="error">
    <VueEditor
      id="ui-vue-editor"
      v-model="content"
      :custom-modules="customModules"
      :editor-options="editorOptions"
      :editor-toolbar="toolbarOptions"
      :placeholder="placeholder"
      @text-change="onTextChange"
    />

    <slot slot="errors" name="errors" />
  </UiBaseInput>
</template>

<script>
import { VueEditor } from "vue2-editor";
import UiBaseInput from "@/components/UI/UiBaseInput/UiBaseInput";
import sanitizeHtml from "sanitize-html";

export default {
  name: "UiRichText",
  components: { VueEditor, UiBaseInput },
  props: {
    label: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data(props) {
    return {
      content: props.value,
      customModules: [],
      editorOptions: {
        modules: {}
      },
      toolbarOptions: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        ["blockquote"]
      ]
    };
  },
  methods: {
    onTextChange() {
      this.content = sanitizeHtml(this.content, {
        allowedTags: ["strong", "em", "u", "ul", "ol", "li", "blockquote", "p", "br"]
      });
      this.$emit("input", this.content);
    }
  }
};
</script>

<style>
#ui-vue-editor {
  height: 180px;
}
</style>
