<template>
  <div class="flex items-center gap-2 mb-2">
    <UiTextAvatar v-if="!isSender" :name="senderName" :role="senderRole" />
    <div
      :class="
        `text-sm w-full rounded-md p-3 pb-2 ${
          isSender
            ? 'bg-gray-200 ml-auto text-gray-700 rounded-br-none'
            : 'bg-white text-gray-700 rounded-bl-none'
        }`
      "
    >
      <div class="flex items-center mb-1">
        <span v-if="!isSender" class="text-xs font-semibold mr-4">{{ senderName }} </span>
        <span v-if="!isSender" class="text-xs font-medium mr-4">
          {{ senderOrganization | capitalize }}
        </span>
        <span class="text-xs text-gray-500 ml-auto"> {{ sentDateTime }}</span>
        <UiButton
          v-if="fetchedOrganization.role === 'admin'"
          theme="link"
          button-class="text-red-500"
          style="padding-left: 5px;"
          remove-padding
          :loading="messagesState.matches('removing')"
          @click="showModal"
          >Remove</UiButton
        >
      </div>

      <span v-dompurify-html="message" class="message-content"></span>
      <div v-if="!isSender" class="text-right">
        <span :class="`px-1 rounded-md text-xs italic text-gray-500 ${senderRole}`">{{
          senderRole || capitalize
        }}</span>
      </div>
    </div>
    <UiModal
      v-if="modalVisible"
      title="Delete message"
      primary-action-label="Delete"
      :message="`Are you sure you want to delete this message?`"
      :closable="false"
      @primaryActionClicked="onRemoveClick(deletingMessage.messageId)"
      @secondaryActionClicked="closeModal"
    >
    </UiModal>
  </div>
</template>

<script>
import eguaranteeCheckIcon from "@/assets/images/logos/eguarantee-check.png";
import { useService, useMachine } from "@xstate/vue";
import { organizationService as profileService } from "@/main";
import { computed, ref } from "@vue/composition-api";
import messagesMachine from "@/components/Messages/messages-machine";
import { parseISO, format } from "date-fns";
import UiTextAvatar from "@/components/UI/UiAvatar/UiTextAvatar.vue";
import UiButton from "../UI/UiButton/UiButton.vue";
import UiModal from "../UI/UiModal/UiModal.vue";

export default {
  name: "ChatMessageItem",
  components: {
    UiTextAvatar,
    UiButton,
    UiModal
  },
  props: {
    messageId: {
      type: String,
      default: () => ""
    },
    message: {
      type: String,
      default: () => ""
    },
    isSender: {
      type: Boolean,
      default: () => false
    },
    senderRole: {
      type: String,
      default: () => ""
    },
    senderName: {
      type: String,
      default: () => ""
    },
    senderOrganization: {
      type: String,
      default: () => ""
    },
    sentDate: {
      type: String,
      default: () => ""
    },
    profilePic: {
      type: String,
      default: () => ""
    },
    sendToGuaranteeMachine: {
      type: Function,
      default: () => {}
    },
    guaranteeId: {
      type: String,
      required: true
    }
  },
  data() {
    return {};
  },
  setup(props) {
    const { guaranteeId, sendToGuaranteeMachine } = props;
    const machineOptions = {
      context: { guaranteeId },
      actions: {
        reloadGuarantee: () => sendToGuaranteeMachine("RELOAD_GUARANTEE", { guaranteeId })
      }
    };

    const { state: messagesState, send: sendToMessagesMachine } = useMachine(
      messagesMachine,
      machineOptions
    );
    const errors = computed(() => messagesState.value?.context?.errors);

    const { state: profileState } = useService(profileService);

    const fetchedOrganization = computed(() => {
      return profileState?.value?.context?.organization ?? {};
    });

    const modalVisible = ref(false);
    const deletingMessage = ref({});

    const closeModal = () => {
      deletingMessage.value = {};
      modalVisible.value = false;
    };
    const showModal = () => {
      deletingMessage.value = { messageId: props.messageId };
      modalVisible.value = true;
    };

    // Return what we want to expose to the template...
    return {
      fetchedOrganization,
      modalVisible,
      deletingMessage,
      closeModal,
      showModal,
      errors,
      messagesState,
      sendToMessagesMachine
    };
  },
  computed: {
    profilePicPath() {
      return this.profilePic ? this.profilePic : eguaranteeCheckIcon;
    },
    sentDateTime() {
      return format(parseISO(this.sentDate), "HH:MM aaa").toUpperCase();
    }
  },

  mounted() {},

  methods: {
    onRemoveClick(messageId) {
      this.closeModal();
      this.sendToMessagesMachine("REMOVE", { messageId });
    }
  }
};
</script>

<style scoped>
.guarantor,
.admin {
  @apply bg-blue-100 text-white;
}

.tenant {
  @apply bg-green-100 text-white;
}

.landlord {
  @apply bg-red-100 text-white;
}

.message-content {
  @apply w-full text-sm text-gray-700 font-light whitespace-pre-wrap;

  p {
    @apply mb-2;

    &:last-child {
      @apply mb-0;
    }
  }

  >>> strong {
    @apply font-bold;
  }

  >>> em,
  i {
    @apply italic;
  }

  >>> ul {
    @apply list-disc list-inside;
  }

  >>> ol {
    @apply list-decimal list-inside;
  }

  >>> blockquote {
    @apply border-l-4 border-solid pl-2;
  }

  >>> code {
    @apply font-mono bg-gray-100;
  }

  @screen md {
    @apply flex-1 leading-7;
  }
}
</style>
