import gql from "graphql-tag";

export const GuaranteeParts = gql`
  fragment GuaranteeParts on Guarantee {
    guaranteeId
    guaranteeNumber
    guaranteeType
    permissions {
      messaging
      attachment
    }
    context {
      statusId
      type
      __typename
      user {
        firstname
        lastname
      }
      organization {
        name
        profilePic
        role
      }
      created
      ... on AttachmentContextItem {
        name
        attachmentId
      }
      ... on MessageContextItem {
        content
      }
    }
    status {
      allowAmendments
      statusId
      label
      isDraft
      primaryStatus
      negotiable
      dealStage
    }
    actions {
      actionId
      allowSubmit
      allowSave
      sections {
        description
        title
        collapsible
        inputs {
          __typename
          description
          inputId
          isRequired
          label
          value
          visibilityConditions {
            property
            condition
            value {
              booleanValue
              stringValue
              intValue
              floatValue
            }
          }
          ... on AddressInputType {
            inputId
            label
            description
            isRequired
            addressValue {
              street
              suburb
              postcode
              state
              country
            }
          }
          ... on OrganizationInputType {
            inputId
            label
            description
            isRequired
            organizationValue {
              organizationId
              abn
              name
              contactFirstname
              contactLastname
              contactEmail
              contactPhone
            }
          }
          ... on FileInputType {
            inputId
            label
            description
            notification
            isRequired
            value
            fileValue {
              key
              name
            }
            allowMultiple
            mimeTypes
          }
          ... on DocusignInputType {
            lockArray
            type
          }
          ... on DocusignStatusInputType {
            type
          }
          ... on NumberInputType {
            inputId
            label
            leading
            trailing
            step
            description
            isRequired
            value
            minValue
            maxValue
          }
          ... on TextInputType {
            inputId
            label
            leading
            trailing
            description
            isRequired
            inputType
            value
            minLength
            maxLength
          }
          ... on TextAreaInputType {
            inputId
            label
            description
            isRequired
            value
            minLength
            maxLength
          }
          ... on StaticInputType {
            inputId
            label
            description
            isRequired
            value
          }
          ... on OptionsInputType {
            inputId
            label
            description
            isRequired
            allowMultiple
            value
            autoselect
            options {
              label
              value
            }
          }
          ... on BooleanInputType {
            disabled
          }
        }
      }
      infoPanels {
        attachmentUrl
        contentDetails
        hiddenContent
        title
      }
      name
      priority
      title
      next
    }
    events {
      title
      dateActioned
      message
      details
      type
      organizationName
      userName
    }
    attachments {
      attachmentId
      dateUploaded
      name
      url
      access
      ownerName
      ownerOrganizationName
    }
    messages {
      id
      senderName
      senderRole
      isSender
      organizationName
      organizationProfilePic
      sentDate
      messageContent
      recipients
    }
    fields {
      id
      label
      isEditable
      isApprovable
      value
      displayOnSummary
      proposedBy
      proposedValue
      __typename
      ... on DateField {
        leadingValue
        trailingValue
      }
      ... on NumberField {
        minValue
        maxValue
        leadingValue
        trailingValue
        precision
      }
      ... on OrganizationField {
        organizationId
      }
      ... on ContactField {
        email
        phone
      }
      ... on RichTextField {
        excerptValue
        minLength
        maxLength
      }
    }
  }
`;

export const OrganizationParts = gql`
  fragment OrganizationParts on Organization {
    organizationId
    permissions {
      manageProfile
      manageUsers
    }
    facilities {
      id
      created
      updated
      activated
      expiry
      status
      issuer {
        name
      }
      guaranteesCount
      approvedAmount
      allocatedAmount
      pendingAmount
      remainingAmount
    }
    role
    name
    abn
    attachments {
      attachmentId
      dateUploaded
      name
    }
    phone
    profilePic
    address {
      street
      suburb
      postcode
      state
      country
    }
    notifications {
      messageId
      description
      guaranteeId
      isUnread
      notificationDate
      notificationId
      profilePic
      subtitle
      title
    }
    users {
      id
      email
      firstname
      lastname
      status
      userRoles {
        id
        name
      }
      userGroupIds
    }
    userGroups {
      id
      name
      addresses {
        street
        suburb
        postcode
        state
        country
      }
      userEmails
    }
    subsidiaries {
      abn
      name
    }
    userRoles {
      id
      name
      description
      permissions
      isDefault
    }
  }
`;
