import { Machine, assign } from "xstate";
import axios from "axios";
import { alertService } from "@/main";
import { logger } from "@/utils";

export default Machine(
  {
    id: "ReferralForm",
    initial: "intermediaryInput",
    context: {
      error: {},
      tenantInfo: {
        firstname: null,
        lastname: null,
        companyName: null,
        email: null,
        name: null,
        phone: null
      },
      intermediaryInfo: {
        firstname: null,
        lastname: null,
        email: null,
        name: null,
        phone: null
      },
      landlordInfo: {
        name: null,
        leaseAddress: null,
        leaseValue: null,
        termsAccepted: null
      }
    },
    states: {
      intermediaryInput: {
        on: {
          TENANT_INPUT: "tenantInput"
        }
      },
      tenantInput: {
        on: {
          LANDLORD_INPUT: "landlordInput"
        }
      },
      landlordInput: {
        on: {
          SUBMITTING_REFERRAL: "submittingReferral"
        }
      },
      submittingReferral: {
        invoke: {
          id: "submittingReferral",
          src: "submittingReferral",
          onDone: {
            target: "done"
          },
          onError: {
            target: "landlordInput",
            actions: [
              "assignError",
              context =>
                alertService.send("ALERT", {
                  alert: {
                    type: "warning",
                    message: "Error occurred",
                    items: [context.error],
                    timer: 5
                  }
                })
            ]
          }
        }
      },
      done: {
        type: "final"
      }
    }
  },
  {
    actions: {
      assignError: assign({
        error: (_context, event) => {
          return event.data?.response?.data?.errorMessage || event.data?.message;
        }
      })
    },
    services: {
      submittingReferral: async (context, _event) => {
        const data = {
          type: "referralSubmit",
          phone: context.tenantInfo.phone,
          email: context.tenantInfo.email,
          data: {
            intermediaryEmail: context.intermediaryInfo.email,
            intermediaryFirstName: context.intermediaryInfo.firstname,
            intermediaryLastName: context.intermediaryInfo.lastname,
            intermediaryPhone: context.intermediaryInfo.phone,
            landlordName: context.landlordInfo.name,
            leaseAddress: context.landlordInfo.leaseAddress,
            leaseValue: context.landlordInfo.leaseValue,
            tenantEmail: context.tenantInfo.email,
            tenantFirstName: context.tenantInfo.firstname,
            tenantLastName: context.tenantInfo.lastname,
            tenantCompany: context.tenantInfo.companyName,
            tenantPhone: context.tenantInfo.phone
          }
        };

        try {
          const response = await axios
            .post(`${process.env.VUE_APP_SERVICES_REST_ENDPOINT}/activity/submit`, data)
            .then(response => response.data);

          return response;
        } catch (e) {
          logger.error(e);
        }
      }
    }
  }
);
