import Vue from "vue";
import VueRouter from "vue-router";
import qs from "qs";
import routes from "@/router/routes";
import { isAuthenticated } from "@/lib/auth";
import { logger } from "@/utils/index";
import NProgress from "nprogress";

Vue.use(VueRouter);

export const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  stringifyQuery: query => {
    let result = qs.stringify(query, { format: "RFC1738" });
    return result ? "?" + result : "";
  },
  routes
});

const signInRedirect = (to, next) =>
  next({
    name: "SignIn",
    query: { redirect: to.fullPath }
  });

// Setup Auth checks
router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // This route requires auth, so check whether the user is authenticated
    try {
      const authenticated = await isAuthenticated();

      if (!authenticated) {
        // if not authenticated, redirect to sign-in page.
        signInRedirect(to, next);
      } else {
        // Otherwise continue
        next();
      }
    } catch (error) {
      logger.error(error);
      signInRedirect(to, next);
    }
  } else {
    // This route doesn't require auth, make sure to always call next()!
    next();
  }
});

// Setup route loader animation...
router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start();
  }
  next();
});

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

router.patchQuery = newQuery => {
  const currentQuery = router.currentRoute.query;
  if (JSON.stringify(newQuery) !== JSON.stringify(currentQuery)) {
    router.push({
      query: { ...currentQuery, ...newQuery }
    });
  }
};

export default router;
