<template>
  <div>
    <div v-if="$slots.errors" class="container">
      <slot name="errors" />
    </div>
    <!-- .container -->

    <slot name="loader" />

    <UiHeader>
      <slot name="steps" />
      <div class="grid-wrap">
        <div class="flex flex-wrap items-center justify-between grid-main">
          <slot name="header" />
        </div>
        <!-- .w-gr-large -->
        <div class="invisible grid-sidebar"></div>
        <!-- .w-gr-small -->
      </div>
    </UiHeader>

    <UiMain>
      <div class="grid-wrap">
        <div class="grid-main">
          <slot name="contextual" />
          <slot name="main" />
        </div>
        <!-- .w-gr-large -->

        <div class="grid-sidebar">
          <slot name="sidebar" />
        </div>
        <!-- .w-gr-small -->
      </div>
    </UiMain>

    <slot name="meta" />
  </div>
</template>

<script>
import UiHeader from "@/components/UI/UiHeader/UiHeader";
import UiMain from "@/components/UI/UiMain/UiMain";

export default {
  name: "GuaranteeLayout",
  components: { UiHeader, UiMain }
};
</script>

<style scoped>
.grid-wrap {
  @apply flex flex-wrap;

  @screen lg {
    @apply -mx-6;
  }
}

.grid-main {
  @apply w-full;

  @screen lg {
    @apply w-3/4 px-6;
  }
}

.grid-sidebar {
  @apply w-full;

  @screen lg {
    @apply w-1/4 px-6;
  }
}
</style>
