<template>
  <footer class="ui-footer-wrap">
    <div class="container ui-footer">
      <div class="row">
        <ul>
          <li><a href="https://eguarantee.com.au/terms" target="_blank">Terms of Use</a></li>
          <li><a href="https://eguarantee.com.au/privacy" target="_blank">Privacy Policy</a></li>
        </ul>
        <p class="text-right">Telephone: <a href="tel:1300561804">1300 561 804</a></p>
      </div>
      <p class="leading-6 text-center text-gray-400">
        &copy; {{ year }} eGuarantee. All rights reserved.
      </p>
    </div>
  </footer>
</template>

<script>
export default {
  name: "UiFooter",
  data() {
    const year = new Date().getFullYear();

    return {
      year
    };
  }
};
</script>

<style scoped>
.ui-footer-wrap {
  @apply bg-white border-t border-gray-200 relative;
  z-index: 2;
}

.ui-footer {
  @apply py-4 text-xs relative;
  z-index: 2;
}

.row {
  @apply flex justify-between;
}
</style>
