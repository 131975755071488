import { phone } from "phone";

export default [
  {
    countryISOCode: "AF",
    label: "Afghanistan (+93)",
    value: "93"
  },
  {
    countryISOCode: "AL",
    label: "Albania (+355)",
    value: "355"
  },
  {
    countryISOCode: "DZ",
    label: "Algeria (+213)",
    value: "213"
  },
  {
    countryISOCode: "AS",
    label: "American Samoa (+1-684)",
    value: "1684"
  },
  {
    countryISOCode: "AD",
    label: "Andorra (+376)",
    value: "376"
  },
  {
    countryISOCode: "AO",
    label: "Angola (+244)",
    value: "244"
  },
  {
    countryISOCode: "AI",
    label: "Anguilla (+1-264)",
    value: "1264"
  },
  {
    countryISOCode: "AQ",
    label: "Antarctica (+672)",
    value: "672"
  },
  {
    countryISOCode: "AG",
    label: "Antigua and Barbuda (+1-268)",
    value: "1268"
  },
  {
    countryISOCode: "AR",
    label: "Argentina (+54)",
    value: "54"
  },
  {
    countryISOCode: "AM",
    label: "Armenia (+374)",
    value: "374"
  },
  {
    countryISOCode: "AW",
    label: "Aruba (+297)",
    value: "297"
  },
  {
    countryISOCode: "AU",
    label: "Australia (+61)",
    value: "61"
  },
  {
    countryISOCode: "AT",
    label: "Austria (+43)",
    value: "43"
  },
  {
    countryISOCode: "AZ",
    label: "Azerbaijan (+994)",
    value: "994"
  },
  {
    countryISOCode: "BS",
    label: "Bahamas (+1-242)",
    value: "1242"
  },
  {
    countryISOCode: "BH",
    label: "Bahrain (+973)",
    value: "973"
  },
  {
    countryISOCode: "BD",
    label: "Bangladesh (+880)",
    value: "880"
  },
  {
    countryISOCode: "BB",
    label: "Barbados (+1-246)",
    value: "1246"
  },
  {
    countryISOCode: "BY",
    label: "Belarus (+375)",
    value: "375"
  },
  {
    countryISOCode: "BE",
    label: "Belgium (+32)",
    value: "32"
  },
  {
    countryISOCode: "BZ",
    label: "Belize (+501)",
    value: "501"
  },
  {
    countryISOCode: "BJ",
    label: "Benin (+229)",
    value: "229"
  },
  {
    countryISOCode: "BM",
    label: "Bermuda (+1-441)",
    value: "1441"
  },
  {
    countryISOCode: "BT",
    label: "Bhutan (+975)",
    value: "975"
  },
  {
    countryISOCode: "BO",
    label: "Bolivia (+591)",
    value: "591"
  },
  {
    countryISOCode: "BA",
    label: "Bosnia and Herzegovina (+387)",
    value: "387"
  },
  {
    countryISOCode: "BW",
    label: "Botswana (+267)",
    value: "267"
  },
  {
    countryISOCode: "BR",
    label: "Brazil (+55)",
    value: "55"
  },
  {
    countryISOCode: "IO",
    label: "British Indian Ocean Territory (+246)",
    value: "246"
  },
  {
    countryISOCode: "VG",
    label: "British Virgin Islands (+1-284)",
    value: "1284"
  },
  {
    countryISOCode: "BN",
    label: "Brunei (+673)",
    value: "673"
  },
  {
    countryISOCode: "BG",
    label: "Bulgaria (+359)",
    value: "359"
  },
  {
    countryISOCode: "BF",
    label: "Burkina Faso (+226)",
    value: "226"
  },
  {
    countryISOCode: "BI",
    label: "Burundi (+257)",
    value: "257"
  },
  {
    countryISOCode: "KH",
    label: "Cambodia (+855)",
    value: "855"
  },
  {
    countryISOCode: "CM",
    label: "Cameroon (+237)",
    value: "237"
  },
  {
    countryISOCode: "CA",
    label: "Canada (+1)",
    value: "1"
  },
  {
    countryISOCode: "CV",
    label: "Cape Verde (+238)",
    value: "238"
  },
  {
    countryISOCode: "KY",
    label: "Cayman Islands (+1-345)",
    value: "1345"
  },
  {
    countryISOCode: "CF",
    label: "Central African Republic (+236)",
    value: "236"
  },
  {
    countryISOCode: "TD",
    label: "Chad (+235)",
    value: "235"
  },
  {
    countryISOCode: "CL",
    label: "Chile (+56)",
    value: "56"
  },
  {
    countryISOCode: "CN",
    label: "China (+86)",
    value: "86"
  },
  {
    countryISOCode: "CX",
    label: "Christmas Island (+61)",
    value: "61"
  },
  {
    countryISOCode: "CC",
    label: "Cocos Islands (+61)",
    value: "61"
  },
  {
    countryISOCode: "CO",
    label: "Colombia (+57)",
    value: "57"
  },
  {
    countryISOCode: "KM",
    label: "Comoros (+269)",
    value: "269"
  },
  {
    countryISOCode: "CK",
    label: "Cook Islands (+682)",
    value: "682"
  },
  {
    countryISOCode: "CR",
    label: "Costa Rica (+506)",
    value: "506"
  },
  {
    countryISOCode: "HR",
    label: "Croatia (+385)",
    value: "385"
  },
  {
    countryISOCode: "CU",
    label: "Cuba (+53)",
    value: "53"
  },
  {
    countryISOCode: "CW",
    label: "Curacao (+599)",
    value: "599"
  },
  {
    countryISOCode: "CY",
    label: "Cyprus (+357)",
    value: "357"
  },
  {
    countryISOCode: "CZ",
    label: "Czech Republic (+420)",
    value: "420"
  },
  {
    countryISOCode: "CD",
    label: "Democratic Republic of the Congo (+243)",
    value: "243"
  },
  {
    countryISOCode: "DK",
    label: "Denmark (+45)",
    value: "45"
  },
  {
    countryISOCode: "DJ",
    label: "Djibouti (+253)",
    value: "253"
  },
  {
    countryISOCode: "DM",
    label: "Dominica (+1-767)",
    value: "1767"
  },
  {
    countryISOCode: "DO",
    label: "Dominican Republic (+1-809)",
    value: "1809"
  },
  {
    countryISOCode: "DO",
    label: "Dominican Republic (+1-829)",
    value: "1829"
  },
  {
    countryISOCode: "DO",
    label: "Dominican Republic (+1-849)",
    value: "1849"
  },
  {
    countryISOCode: "TL",
    label: "East Timor (+670)",
    value: "670"
  },
  {
    countryISOCode: "EC",
    label: "Ecuador (+593)",
    value: "593"
  },
  {
    countryISOCode: "EG",
    label: "Egypt (+20)",
    value: "20"
  },
  {
    countryISOCode: "SV",
    label: "El Salvador (+503)",
    value: "503"
  },
  {
    countryISOCode: "GQ",
    label: "Equatorial Guinea (+240)",
    value: "240"
  },
  {
    countryISOCode: "ER",
    label: "Eritrea (+291)",
    value: "291"
  },
  {
    countryISOCode: "EE",
    label: "Estonia (+372)",
    value: "372"
  },
  {
    countryISOCode: "ET",
    label: "Ethiopia (+251)",
    value: "251"
  },
  {
    countryISOCode: "FK",
    label: "Falkland Islands (+500)",
    value: "500"
  },
  {
    countryISOCode: "FO",
    label: "Faroe Islands (+298)",
    value: "298"
  },
  {
    countryISOCode: "FJ",
    label: "Fiji (+679)",
    value: "679"
  },
  {
    countryISOCode: "FI",
    label: "Finland (+358)",
    value: "358"
  },
  {
    countryISOCode: "FR",
    label: "France (+33)",
    value: "33"
  },
  {
    countryISOCode: "PF",
    label: "French Polynesia (+689)",
    value: "689"
  },
  {
    countryISOCode: "GA",
    label: "Gabon (+241)",
    value: "241"
  },
  {
    countryISOCode: "GM",
    label: "Gambia (+220)",
    value: "220"
  },
  {
    countryISOCode: "GE",
    label: "Georgia (+995)",
    value: "995"
  },
  {
    countryISOCode: "DE",
    label: "Germany (+49)",
    value: "49"
  },
  {
    countryISOCode: "GH",
    label: "Ghana (+233)",
    value: "233"
  },
  {
    countryISOCode: "GI",
    label: "Gibraltar (+350)",
    value: "350"
  },
  {
    countryISOCode: "GR",
    label: "Greece (+30)",
    value: "30"
  },
  {
    countryISOCode: "GL",
    label: "Greenland (+299)",
    value: "299"
  },
  {
    countryISOCode: "GD",
    label: "Grenada (+1-473)",
    value: "1473"
  },
  {
    countryISOCode: "GU",
    label: "Guam (+1-671)",
    value: "1671"
  },
  {
    countryISOCode: "GT",
    label: "Guatemala (+502)",
    value: "502"
  },
  {
    countryISOCode: "GG",
    label: "Guernsey (+44-1481)",
    value: "441481"
  },
  {
    countryISOCode: "GN",
    label: "Guinea (+224)",
    value: "224"
  },
  {
    countryISOCode: "GW",
    label: "Guinea-Bissau (+245)",
    value: "245"
  },
  {
    countryISOCode: "GY",
    label: "Guyana (+592)",
    value: "592"
  },
  {
    countryISOCode: "HT",
    label: "Haiti (+509)",
    value: "509"
  },
  {
    countryISOCode: "HN",
    label: "Honduras (+504)",
    value: "504"
  },
  {
    countryISOCode: "HK",
    label: "Hong Kong (+852)",
    value: "852"
  },
  {
    countryISOCode: "HU",
    label: "Hungary (+36)",
    value: "36"
  },
  {
    countryISOCode: "IS",
    label: "Iceland (+354)",
    value: "354"
  },
  {
    countryISOCode: "IN",
    label: "India (+91)",
    value: "91"
  },
  {
    countryISOCode: "ID",
    label: "Indonesia (+62)",
    value: "62"
  },
  {
    countryISOCode: "IR",
    label: "Iran (+98)",
    value: "98"
  },
  {
    countryISOCode: "IQ",
    label: "Iraq (+964)",
    value: "964"
  },
  {
    countryISOCode: "IE",
    label: "Ireland (+353)",
    value: "353"
  },
  {
    countryISOCode: "IM",
    label: "Isle of Man (+44-1624)",
    value: "441624"
  },
  {
    countryISOCode: "IL",
    label: "Israel (+972)",
    value: "972"
  },
  {
    countryISOCode: "IT",
    label: "Italy (+39)",
    value: "39"
  },
  {
    countryISOCode: "CI",
    label: "Ivory Coast (+225)",
    value: "225"
  },
  {
    countryISOCode: "JM",
    label: "Jamaica (+1-876)",
    value: "1876"
  },
  {
    countryISOCode: "JP",
    label: "Japan (+81)",
    value: "81"
  },
  {
    countryISOCode: "JE",
    label: "Jersey (+44-1534)",
    value: "441534"
  },
  {
    countryISOCode: "JO",
    label: "Jordan (+962)",
    value: "962"
  },
  {
    countryISOCode: "KZ",
    label: "Kazakhstan (+7)",
    value: "7"
  },
  {
    countryISOCode: "KE",
    label: "Kenya (+254)",
    value: "254"
  },
  {
    countryISOCode: "KI",
    label: "Kiribati (+686)",
    value: "686"
  },
  {
    countryISOCode: "XK",
    label: "Kosovo (+383)",
    value: "383"
  },
  {
    countryISOCode: "KW",
    label: "Kuwait (+965)",
    value: "965"
  },
  {
    countryISOCode: "KG",
    label: "Kyrgyzstan (+996)",
    value: "996"
  },
  {
    countryISOCode: "LA",
    label: "Laos (+856)",
    value: "856"
  },
  {
    countryISOCode: "LV",
    label: "Latvia (+371)",
    value: "371"
  },
  {
    countryISOCode: "LB",
    label: "Lebanon (+961)",
    value: "961"
  },
  {
    countryISOCode: "LS",
    label: "Lesotho (+266)",
    value: "266"
  },
  {
    countryISOCode: "LR",
    label: "Liberia (+231)",
    value: "231"
  },
  {
    countryISOCode: "LY",
    label: "Libya (+218)",
    value: "218"
  },
  {
    countryISOCode: "LI",
    label: "Liechtenstein (+423)",
    value: "423"
  },
  {
    countryISOCode: "LT",
    label: "Lithuania (+370)",
    value: "370"
  },
  {
    countryISOCode: "LU",
    label: "Luxembourg (+352)",
    value: "352"
  },
  {
    countryISOCode: "MO",
    label: "Macao (+853)",
    value: "853"
  },
  {
    countryISOCode: "MK",
    label: "Macedonia (+389)",
    value: "389"
  },
  {
    countryISOCode: "MG",
    label: "Madagascar (+261)",
    value: "261"
  },
  {
    countryISOCode: "MW",
    label: "Malawi (+265)",
    value: "265"
  },
  {
    countryISOCode: "MY",
    label: "Malaysia (+60)",
    value: "60"
  },
  {
    countryISOCode: "MV",
    label: "Maldives (+960)",
    value: "960"
  },
  {
    countryISOCode: "ML",
    label: "Mali (+223)",
    value: "223"
  },
  {
    countryISOCode: "MT",
    label: "Malta (+356)",
    value: "356"
  },
  {
    countryISOCode: "MH",
    label: "Marshall Islands (+692)",
    value: "692"
  },
  {
    countryISOCode: "MR",
    label: "Mauritania (+222)",
    value: "222"
  },
  {
    countryISOCode: "MU",
    label: "Mauritius (+230)",
    value: "230"
  },
  {
    countryISOCode: "YT",
    label: "Mayotte (+262)",
    value: "262"
  },
  {
    countryISOCode: "MX",
    label: "Mexico (+52)",
    value: "52"
  },
  {
    countryISOCode: "FM",
    label: "Micronesia (+691)",
    value: "691"
  },
  {
    countryISOCode: "MD",
    label: "Moldova (+373)",
    value: "373"
  },
  {
    countryISOCode: "MC",
    label: "Monaco (+377)",
    value: "377"
  },
  {
    countryISOCode: "MN",
    label: "Mongolia (+976)",
    value: "976"
  },
  {
    countryISOCode: "ME",
    label: "Montenegro (+382)",
    value: "382"
  },
  {
    countryISOCode: "MS",
    label: "Montserrat (+1-664)",
    value: "1664"
  },
  {
    countryISOCode: "MA",
    label: "Morocco (+212)",
    value: "212"
  },
  {
    countryISOCode: "MZ",
    label: "Mozambique (+258)",
    value: "258"
  },
  {
    countryISOCode: "MM",
    label: "Myanmar (+95)",
    value: "95"
  },
  {
    countryISOCode: "NA",
    label: "Namibia (+264)",
    value: "264"
  },
  {
    countryISOCode: "NR",
    label: "Nauru (+674)",
    value: "674"
  },
  {
    countryISOCode: "NP",
    label: "Nepal (+977)",
    value: "977"
  },
  {
    countryISOCode: "NL",
    label: "Netherlands (+31)",
    value: "31"
  },
  {
    countryISOCode: "AN",
    label: "Netherlands Antilles (+599)",
    value: "599"
  },
  {
    countryISOCode: "NC",
    label: "New Caledonia (+687)",
    value: "687"
  },
  {
    countryISOCode: "NZ",
    label: "New Zealand (+64)",
    value: "64"
  },
  {
    countryISOCode: "NI",
    label: "Nicaragua (+505)",
    value: "505"
  },
  {
    countryISOCode: "NE",
    label: "Niger (+227)",
    value: "227"
  },
  {
    countryISOCode: "NG",
    label: "Nigeria (+234)",
    value: "234"
  },
  {
    countryISOCode: "NU",
    label: "Niue (+683)",
    value: "683"
  },
  {
    countryISOCode: "KP",
    label: "North Korea (+850)",
    value: "850"
  },
  {
    countryISOCode: "MP",
    label: "Northern Mariana Islands (+1-670)",
    value: "1670"
  },
  {
    countryISOCode: "NO",
    label: "Norway (+47)",
    value: "47"
  },
  {
    countryISOCode: "OM",
    label: "Oman (+968)",
    value: "968"
  },
  {
    countryISOCode: "PK",
    label: "Pakistan (+92)",
    value: "92"
  },
  {
    countryISOCode: "PW",
    label: "Palau (+680)",
    value: "680"
  },
  {
    countryISOCode: "PS",
    label: "Palestine (+970)",
    value: "970"
  },
  {
    countryISOCode: "PA",
    label: "Panama (+507)",
    value: "507"
  },
  {
    countryISOCode: "PG",
    label: "Papua New Guinea (+675)",
    value: "675"
  },
  {
    countryISOCode: "PY",
    label: "Paraguay (+595)",
    value: "595"
  },
  {
    countryISOCode: "PE",
    label: "Peru (+51)",
    value: "51"
  },
  {
    countryISOCode: "PH",
    label: "Philippines (+63)",
    value: "63"
  },
  {
    countryISOCode: "PN",
    label: "Pitcairn (+64)",
    value: "64"
  },
  {
    countryISOCode: "PL",
    label: "Poland (+48)",
    value: "48"
  },
  {
    countryISOCode: "PT",
    label: "Portugal (+351)",
    value: "351"
  },
  {
    countryISOCode: "PR",
    label: "Puerto Rico (+1-787)",
    value: "1787"
  },
  {
    countryISOCode: "PR",
    label: "Puerto Rico (+1-939)",
    value: "1939"
  },
  {
    countryISOCode: "QA",
    label: "Qatar (+974)",
    value: "974"
  },
  {
    countryISOCode: "CG",
    label: "Republic of the Congo (+242)",
    value: "242"
  },
  {
    countryISOCode: "RE",
    label: "Reunion (+262)",
    value: "262"
  },
  {
    countryISOCode: "RO",
    label: "Romania (+40)",
    value: "40"
  },
  {
    countryISOCode: "RU",
    label: "Russia (+7)",
    value: "7"
  },
  {
    countryISOCode: "RW",
    label: "Rwanda (+250)",
    value: "250"
  },
  {
    countryISOCode: "BL",
    label: "Saint Barthelemy (+590)",
    value: "590"
  },
  {
    countryISOCode: "SH",
    label: "Saint Helena (+290)",
    value: "290"
  },
  {
    countryISOCode: "KN",
    label: "Saint Kitts and Nevis (+1-869)",
    value: "1869"
  },
  {
    countryISOCode: "LC",
    label: "Saint Lucia (+1-758)",
    value: "1758"
  },
  {
    countryISOCode: "MF",
    label: "Saint Martin (+590)",
    value: "590"
  },
  {
    countryISOCode: "PM",
    label: "Saint Pierre and Miquelon (+508)",
    value: "508"
  },
  {
    countryISOCode: "VC",
    label: "Saint Vincent and the Grenadines (+1-784)",
    value: "1784"
  },
  {
    countryISOCode: "WS",
    label: "Samoa (+685)",
    value: "685"
  },
  {
    countryISOCode: "SM",
    label: "San Marino (+378)",
    value: "378"
  },
  {
    countryISOCode: "ST",
    label: "Sao Tome and Principe (+239)",
    value: "239"
  },
  {
    countryISOCode: "SA",
    label: "Saudi Arabia (+966)",
    value: "966"
  },
  {
    countryISOCode: "SN",
    label: "Senegal (+221)",
    value: "221"
  },
  {
    countryISOCode: "RS",
    label: "Serbia (+381)",
    value: "381"
  },
  {
    countryISOCode: "SC",
    label: "Seychelles (+248)",
    value: "248"
  },
  {
    countryISOCode: "SL",
    label: "Sierra Leone (+232)",
    value: "232"
  },
  {
    countryISOCode: "SG",
    label: "Singapore (+65)",
    value: "65"
  },
  {
    countryISOCode: "SX",
    label: "Sint Maarten (+1-721)",
    value: "1721"
  },
  {
    countryISOCode: "SK",
    label: "Slovakia (+421)",
    value: "421"
  },
  {
    countryISOCode: "SI",
    label: "Slovenia (+386)",
    value: "386"
  },
  {
    countryISOCode: "SB",
    label: "Solomon Islands (+677)",
    value: "677"
  },
  {
    countryISOCode: "SO",
    label: "Somalia (+252)",
    value: "252"
  },
  {
    countryISOCode: "ZA",
    label: "South Africa (+27)",
    value: "27"
  },
  {
    countryISOCode: "KR",
    label: "South Korea (+82)",
    value: "82"
  },
  {
    countryISOCode: "SS",
    label: "South Sudan (+211)",
    value: "211"
  },
  {
    countryISOCode: "ES",
    label: "Spain (+34)",
    value: "34"
  },
  {
    countryISOCode: "LK",
    label: "Sri Lanka (+94)",
    value: "94"
  },
  {
    countryISOCode: "SD",
    label: "Sudan (+249)",
    value: "249"
  },
  {
    countryISOCode: "SR",
    label: "Suriname (+597)",
    value: "597"
  },
  {
    countryISOCode: "SJ",
    label: "Svalbard and Jan Mayen (+47)",
    value: "47"
  },
  {
    countryISOCode: "SZ",
    label: "Swaziland (+268)",
    value: "268"
  },
  {
    countryISOCode: "SE",
    label: "Sweden (+46)",
    value: "46"
  },
  {
    countryISOCode: "CH",
    label: "Switzerland (+41)",
    value: "41"
  },
  {
    countryISOCode: "SY",
    label: "Syria (+963)",
    value: "963"
  },
  {
    countryISOCode: "TW",
    label: "Taiwan (+886)",
    value: "886"
  },
  {
    countryISOCode: "TJ",
    label: "Tajikistan (+992)",
    value: "992"
  },
  {
    countryISOCode: "TZ",
    label: "Tanzania (+255)",
    value: "255"
  },
  {
    countryISOCode: "TH",
    label: "Thailand (+66)",
    value: "66"
  },
  {
    countryISOCode: "TG",
    label: "Togo (+228)",
    value: "228"
  },
  {
    countryISOCode: "TK",
    label: "Tokelau (+690)",
    value: "690"
  },
  {
    countryISOCode: "TO",
    label: "Tonga (+676)",
    value: "676"
  },
  {
    countryISOCode: "TT",
    label: "Trinidad and Tobago (+1-868)",
    value: "1868"
  },
  {
    countryISOCode: "TN",
    label: "Tunisia (+216)",
    value: "216"
  },
  {
    countryISOCode: "TR",
    label: "Turkey (+90)",
    value: "90"
  },
  {
    countryISOCode: "TM",
    label: "Turkmenistan (+993)",
    value: "993"
  },
  {
    countryISOCode: "TC",
    label: "Turks and Caicos Islands (+1-649)",
    value: "1649"
  },
  {
    countryISOCode: "TV",
    label: "Tuvalu (+688)",
    value: "688"
  },
  {
    countryISOCode: "VI",
    label: "U.S. Virgin Islands (+1-340)",
    value: "1340"
  },
  {
    countryISOCode: "UG",
    label: "Uganda (+256)",
    value: "256"
  },
  {
    countryISOCode: "UA",
    label: "Ukraine (+380)",
    value: "380"
  },
  {
    countryISOCode: "AE",
    label: "United Arab Emirates (+971)",
    value: "971"
  },
  {
    countryISOCode: "GB",
    label: "United Kingdom (+44)",
    value: "44"
  },
  {
    countryISOCode: "US",
    label: "United States (+1)",
    value: "1"
  },
  {
    countryISOCode: "UY",
    label: "Uruguay (+598)",
    value: "598"
  },
  {
    countryISOCode: "UZ",
    label: "Uzbekistan (+998)",
    value: "998"
  },
  {
    countryISOCode: "VU",
    label: "Vanuatu (+678)",
    value: "678"
  },
  {
    countryISOCode: "VA",
    label: "Vatican (+379)",
    value: "379"
  },
  {
    countryISOCode: "VE",
    label: "Venezuela (+58)",
    value: "58"
  },
  {
    countryISOCode: "VN",
    label: "Vietnam (+84)",
    value: "84"
  },
  {
    countryISOCode: "WF",
    label: "Wallis and Futuna (+681)",
    value: "681"
  },
  {
    countryISOCode: "EH",
    label: "Western Sahara (+212)",
    value: "212"
  },
  {
    countryISOCode: "YE",
    label: "Yemen (+967)",
    value: "967"
  },
  {
    countryISOCode: "ZM",
    label: "Zambia (+260)",
    value: "260"
  },
  {
    countryISOCode: "ZW",
    label: "Zimbabwe (+263)",
    value: "263"
  }
];

export const checkPhoneNumber = number => {
  // Provide phone number, country code and whether to allow landlines
  // Return the first item in the array which is a normalized phone number in E.164 format...
  return phone(number);
};
