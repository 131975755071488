<template>
  <Auth>
    <SignIn />
  </Auth>
</template>

<script>
import Auth from "@/views/auth/Auth";
import SignIn from "@/components/Auth/SignIn/SignIn";

export default {
  name: "SignInView",
  components: { Auth, SignIn },
  metaInfo: {
    title: "Sign In"
  }
};
</script>
