<template>
  <UiField
    :label="input.label"
    theme="secondary"
    :no-border="noBorder"
    :info="inputInfo"
    :name="input.inputId"
    :class="{ 'items-start': alignItemsStart }"
    full-width-meta
  >
    <component
      :is="fieldComponent"
      :name="input.inputId"
      :label="inputLabel"
      :error="error"
      :type="fieldType"
      :sub-type="fieldSubType"
      :lock-array="lockArray"
      :child-error="childError"
      :value="valueFor(input)"
      :disabled="input.disabled"
      :options="input.options"
      :mime-types="input.mimeTypes"
      :step="input.step"
      :leading="input.leading"
      :trailing="input.trailing"
      :min="input.minValue"
      :max="input.maxValue"
      :allow-multiple="input.allowMultiple"
      :single="!input.allowMultiple"
      :notification="input.notification"
      :role="role"
      :form-dirty="formDirty"
      :report-status="reportStatus"
      :data-cy="input.inputId"
      :checkbox-type="input.inputType"
      @input="onActionInput($event)"
    >
      <template v-if="error" slot="errors">
        <div v-for="({ type, message }, i) in errors" :key="i">
          <p v-if="hasValidationError(type)">
            {{ message }}
          </p>
        </div>
      </template>
      <template v-if="input.leading" slot="leadingAddon">
        {{ input.leading }}
      </template>
      <template v-if="input.trailing" slot="trailingAddon">
        {{ input.trailing }}
      </template>
    </component>
  </UiField>
</template>

<script>
import UiField from "@/components/UI/UiField/UiField";
import UiDateInput from "@/components/UI/UiDateInput/UiDateInput";
import UiDocusignInput from "@/components/UI/UiDocusign/UiDocusignInput.vue";
import UiDocusignStatusInput from "@/components/UI/UiDocusign/UiDocusignStatusInput.vue";
import UiFileInput from "@/components/UI/UiFileInput/UiFileInput";
import UiTextarea from "@/components/UI/UiTextarea/UiTextarea";
import UiInput from "@/components/UI/UiInput/UiInput";
import UiCheckbox from "@/components/UI/UiCheckbox/UiCheckbox";
import UiMultiselect from "@/components/UI/UiMultiselect/UiMultiselect";
import UiRichText from "@/components/UI/UiRichText/UiRichText";
import UiOrganizationInput from "@/components/UI/UiOrganizationInput/UiOrganizationInput";
import UiAddressInput from "@/components/UI/UiAddressInput/UiAddressInput";
import { getInputComponent, getFieldInputType, parseBoolean } from "@/utils/index";
import { isArray, some, map, get } from "lodash";
import { inject } from "@vue/composition-api";

export default {
  name: "ActionField",
  components: {
    UiCheckbox,
    UiDateInput,
    UiDocusignInput,
    UiDocusignStatusInput,
    UiField,
    UiFileInput,
    UiInput,
    UiTextarea,
    UiMultiselect,
    UiRichText,
    UiOrganizationInput,
    UiAddressInput
  },
  props: {
    error: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    },
    input: {
      type: Object,
      required: true
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {}
    },
    fieldValidationStatus: {
      type: Object,
      default: () => {}
    },
    formDirty: {
      type: Boolean,
      default: false
    },
    reportStatus: {
      type: Function,
      default: () => {}
    }
  },
  computed: {
    childError() {
      const eachIter = get(this.fieldValidationStatus, "$each.$iter");
      if (eachIter === undefined) return [];
      return map(eachIter, v => {
        const result = Object.create(null);
        Object.keys(v.$params).forEach(k => {
          // k -> Personal: name, email. Entity: name, director, secretary.
          result[k] = {};
          Object.keys(v[k].$params).forEach(l => {
            if (undefined === v[k][l].$error) return;
            // l -> Personal: N/A. Entity: name, email under director, secretary.
            // Assign error value to each child
            result[k][l] ||= v[k][l].$error;
          });
          // No child, use its own error
          if (Object.keys(result[k]).length === 0) result[k] = v[k].$error;
        });
        return result;
      });
    },
    alignItemsStart() {
      return ["UiFileInput", "UiCheckbox"].includes(this.fieldComponent);
    },
    inputId() {
      return this.input?.inputId;
    },
    inputInfo() {
      return this.fieldComponent === "UiCheckbox" ? "" : this.input?.description;
    },
    inputLabel() {
      return this.fieldComponent === "UiCheckbox" || this.fieldComponent === "p"
        ? this.input?.description
        : "";
    },
    fieldType() {
      return this.input.inputType || getFieldInputType(this.input?.__typename);
    },
    fieldSubType() {
      return this.input?.type;
    },
    lockArray() {
      return this.input?.lockArray;
    },
    fieldComponent() {
      return getInputComponent(this.input?.__typename);
    },
    role() {
      return inject("userRole")?.value;
    }
  },
  methods: {
    hasValidationError(type, fieldValidationStatus = this.fieldValidationStatus) {
      const validationStatus = get(fieldValidationStatus, type);
      if (validationStatus !== undefined) return !validationStatus;
      if (type.startsWith("$each.")) {
        const eachIter = get(fieldValidationStatus, "$each.$iter");
        if (eachIter === undefined) return false;
        return some(eachIter, v => this.hasValidationError(type.substr(6), v));
      }
      return false;
    },
    onActionInput(data) {
      const { inputId } = this;

      // Get the current value and add the additional input data...
      const value = {
        ...this.value,
        [inputId]: data
      };

      this.$emit("input", value);
    },
    valueFor(input) {
      if (this.fieldComponent === "UiMultiselect") {
        if (!this.value[input.inputId] && input.autoselect && input.options[0]) {
          this.value[input.inputId] = [input.options[0].value];
        }

        return this.value[input.inputId] || "";
      }
      if (this.input?.__typename === "BooleanInputType") {
        return parseBoolean(this.value[input.inputId]);
      }
      return this.value[input.inputId];
    }
  }
};
</script>
