<template>
  <li class="relative overflow-hidden lg:flex-1">
    <div class="overflow-hidden border-t-0 rounded-b-md lg:border-0">
      <a class="group">
        <span
          class="absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-gray-200 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
          aria-hidden="true"
        ></span>
        <span class="px-3 md:4 py-3 flex items-center justify-center text-sm font-medium lg:pl-6">
          <span class="flex-shrink-0">
            <span
              class="w-4 h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 flex items-center justify-center border-2 border-gray-300 rounded-full"
            >
              <span class="text-gray-500">{{ order }}</span>
            </span>
          </span>
          <span class="mt-0.5 ml-2 md:ml-1 min-w-0 flex flex-col">
            <span class="text-xxs lg:text-xs font-semibold text-gray-500 tracking-wide">{{
              name
            }}</span>
          </span>
        </span>
      </a>
      <UiStepsSeparator />
    </div>
  </li>
</template>

<script>
import UiStepsSeparator from "./UiStepsSeparator.vue";
export default {
  name: "UiStepsUpcoming",
  components: {
    UiStepsSeparator
  },
  props: {
    name: {
      type: String,
      default: () => "",
      required: true
    },
    order: {
      type: Number,
      default: () => 0,
      required: true
    }
  }
};
</script>
