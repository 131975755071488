<template>
  <div @keyup.enter="onSubmit">
    <UiHeadline :size="4" class="mb-4">Set New Password</UiHeadline>

    <!-- PASSWORD -->
    <UiInput
      v-model.trim="form.password"
      label="New Password"
      type="password"
      placeholder="Enter new password"
      :error="$v.form.password.$error"
      class="mb-4"
    >
      <template v-if="$v.form.password.$error" slot="errors">
        <p v-if="!$v.form.password.required">
          New password is required.
        </p>

        <p v-if="!$v.form.password.minLength">
          Password must be at least
          {{ $v.form.password.$params.minLength.min }} characters.
        </p>

        <p v-if="!$v.form.password.hasLowerCase">
          Password must contain at least 1 lower case character.
        </p>

        <p v-if="!$v.form.password.hasUpperCase">
          Password must contain at least 1 upper case character.
        </p>

        <p v-if="!$v.form.password.hasNumber">
          Password must contain at least 1 number.
        </p>
      </template>
    </UiInput>

    <!-- CONFIRM PASSWORD -->
    <UiInput
      v-model.trim="form.passwordConfirm"
      placeholder="Confirm new password"
      label="Confirm Password"
      type="password"
      class="mb-4"
      :error="$v.form.passwordConfirm.$error"
    >
      <template v-if="$v.form.passwordConfirm.$error" #errors>
        <p v-if="!$v.form.passwordConfirm.required">
          Confirm password is required.
        </p>
        <p v-if="!$v.form.passwordConfirm.sameAsPassword">
          Passwords must be identical.
        </p>
      </template>
    </UiInput>

    <div class="flex flex-wrap items-center">
      <UiInput is="UiButton" theme="primary" @click="onSubmit">Update Password</UiInput>
    </div>
    <!-- .flex items-center -->
  </div>
</template>

<script>
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { hasLowerCase, hasUpperCase, hasNumber } from "@/utils/validations";
import formMixin from "@/mixins/form";
import UiButton from "@/components/UI/UiButton/UiButton";
import UiHeadline from "@/components/UI/UiHeadline/UiHeadline";
import UiInput from "@/components/UI/UiInput/UiInput";

export default {
  name: "SetNewPassword",
  components: { UiButton, UiHeadline, UiInput },
  mixins: [formMixin],
  data() {
    return {
      form: {
        code: "",
        email: "",
        password: "",
        passwordConfirm: ""
      }
    };
  },
  methods: {
    onSubmit() {
      // validate using this.validate() from our formMixin...
      this.validate();

      if (!this.$v.$invalid) {
        const { email, code, password } = this.form;

        this.$emit("submit", { email, code, password });
      }
    }
  },
  validations: {
    form: {
      password: {
        required,
        minLength: minLength(8),
        hasLowerCase,
        hasUpperCase,
        hasNumber
      },
      passwordConfirm: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  }
};
</script>
