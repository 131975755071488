import { Auth } from "aws-amplify";
import { logger } from "@/utils/index";
import { router } from "@/router";
import { AmplifyAuthCookieStorage } from "@/lib/amplify-auth-cookie-storage";

Auth.configure({
  storage: AmplifyAuthCookieStorage
});

export const isAuthenticated = async () => {
  return Auth.currentAuthenticatedUser()
    .then(user => {
      logger.info(
        "isAuthenticated:",
        user?.attributes?.email || user?.signInUserSession?.idToken?.payload?.email
      );
      return true;
    })
    .catch(error => {
      if (!error.includes("not authenticated")) logger.error(error, "isAuthenticated");
    });
};

export const getCurrentUser = async () => {
  return Auth.currentAuthenticatedUser()
    .then(user => {
      return user;
    })
    .catch(error => {
      logger.error(error, "getCurrentUser");
    });
};

export const signout = async error => {
  try {
    await Auth.signOut();

    router.push({ name: "SignIn", params: { error } });
  } catch (error) {
    logger.error(error, "signout");
  }
};

export const getUserAttributes = async prop => {
  const user = await Auth.currentAuthenticatedUser();

  if ("attributes" in user) {
    return prop ? user.attributes[prop] : user.attributes;
  }

  return prop
    ? user?.signInUserSession?.idToken?.payload[prop]
    : user?.signInUserSession?.idToken?.payload;
};

export const getAuthenticationToken = async () => {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
};
