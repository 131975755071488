var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.label || _vm.$slots.label)?_c('label',{staticClass:"ui-base-input-label",class:_vm.size,attrs:{"for":_vm.name}},[_vm._v(_vm._s(_vm.label)+" "),_vm._t("label")],2):_vm._e(),_c('div',{staticClass:"relative"},[_c('div',{staticClass:"ui-base-input-slot",class:{
        'rounded-md shadow-sm': _vm.decorated
      }},[(_vm.$slots.leadingAddon)?_c('div',{staticClass:"absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none"},[_c('span',{staticClass:"text-gray-500 sm:text-sm sm:leading-5"},[_vm._t("leadingAddon")],2)]):_vm._e(),(_vm.$slots.default)?_c('div',{staticClass:"ui-base-input-addon-wrap",class:[
          _vm.$slots.leadingAddon ? ("leading-addon-" + _vm.leadingWidth) : '',
          _vm.$slots.trailingAddon ? 'trailing-addon' : ''
        ]},[_vm._t("default")],2):_vm._e(),(_vm.$slots.trailingAddon)?_c('div',{staticClass:"absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"},[_c('span',{staticClass:"text-gray-500 sm:text-sm sm:leading-5"},[_vm._t("trailingAddon")],2)]):_vm._e(),(_vm.error)?_c('div',{staticClass:"ui-base-input-error-icon",class:{
          'trailing-addon': _vm.$slots.trailingAddon
        }},[_c('svg',{staticClass:"w-3 h-3 text-red-500",attrs:{"fill":"currentColor","viewBox":"0 0 20 20"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z","clip-rule":"evenodd"}})])]):_vm._e()]),(_vm.loading)?_c('div',{staticClass:"ui-base-input-loader spin"},[_c('LoaderIcon',{attrs:{"size":".9x"}})],1):_vm._e()]),(_vm.$slots.errors)?_c('div',{staticClass:"ui-base-input-errors"},[_vm._t("errors")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }