<template>
  <div>
    <div v-if="!hideTitle" class="flex items-center justify-between mb-4">
      <UiHeadline :level="3" :size="5">Documents</UiHeadline>
    </div>

    <div class="flex justify-start gap-3 mb-4">
      <UiButton v-if="allowAttaching" theme="primary" size="sm" @click="showUploadModal">
        Upload New
      </UiButton>
      <UiButton
        v-if="attachments.length > 1"
        slot="action"
        theme="outline"
        size="sm"
        :loading="
          attachmentState.matches('downloading') ||
            attachmentState.matches('downloadingAll') ||
            attachmentState.matches('waitingToDownload')
        "
        @click="onDownloadAllClick"
        >Download All</UiButton
      >
    </div>

    <div class="flex flex-col items-center space-y-4">
      <UiAlert
        v-if="attachmentState.matches('error')"
        theme="warning"
        title="There was an error"
        size="sm"
        :items="errors"
      />

      <MetaCard
        v-for="attachment in visibleItems"
        :key="attachment.attachmentId"
        icon-type="paperclip"
        :info="formatAttachmentTitle(attachment.dateUploaded)"
        :title="attachment.name"
      >
        <div v-if="attachment.access && attachment.access.length > 0" class="flex gap-1 mt-2 mb-1">
          <div
            v-for="role in attachment.access"
            :id="role"
            :key="role"
            class="bg-cool-gray-600 text-white px-1 py-0.5 rounded text-xxs font-semibold "
          >
            {{ role | capitalize }}
          </div>
        </div>
        <template #action>
          <UiButton
            theme="link"
            remove-padding
            :loading="attachmentState.matches('downloading')"
            @click="onDownloadClick(attachment.attachmentId)"
          >
            Download
          </UiButton>
          <UiButton
            v-if="fetchedOrganization.role === 'admin'"
            theme="link"
            button-class="text-red-500"
            style="padding-left: 5px;"
            remove-padding
            :loading="attachmentState.matches('removing')"
            @click="showModal(attachment.name, attachment.attachmentId)"
          >
            Remove
          </UiButton>
        </template>

        <template #footer>
          <p class="text-right">
            {{ attachment.ownerName }}
          </p>
          <p class="text-right">
            {{ attachment.ownerOrganizationName | capitalize }}
          </p>
        </template>
      </MetaCard>

      <UiButton
        v-if="attachments.length > itemsToShow"
        theme="link"
        size="sm"
        @click="viewMore = !viewMore"
      >
        {{ viewMore ? "View Less" : "View More" }}
      </UiButton>

      <div v-if="!attachments.length">
        <p class="text-xs text-gray-500">
          No documents have been added yet.
        </p>
      </div>
    </div>

    <UiModal
      v-if="modalVisible"
      title="Delete attachment"
      primary-action-label="Delete"
      :message="`Are you sure you want to delete '${deletingAttachment.name}'?`"
      :closable="false"
      @primaryActionClicked="onRemoveClick(deletingAttachment.attachmentId)"
      @secondaryActionClicked="closeModal"
    >
    </UiModal>

    <UploaderModal
      v-if="uploaderModalVisible"
      :recipient-options="recipientOptions"
      :closable="true"
      :disable-private="disablePrivate"
      :attachment-state="attachmentState"
      :send-to-attachment-machine="sendToAttachmentMachine"
      :role="fetchedOrganization.role"
      @close="closeUploadModal"
    >
    </UploaderModal>
  </div>
</template>

<script>
import { format } from "date-fns";
import { useMachine, useService } from "@xstate/vue";
import { computed, ref } from "@vue/composition-api";
import { organizationService as profileService } from "@/main";
import attachmentMachine from "./attachment-machine";
import { watchState } from "@/utils/machine-helpers";
import UiAlert from "@/components/UI/UiAlert/UiAlert";
import UiButton from "@/components/UI/UiButton/UiButton";
import UiHeadline from "@/components/UI/UiHeadline/UiHeadline";
import UiModal from "@/components/UI/UiModal/UiModal.vue";
import MetaCard from "@/components/Cards/MetaCard/MetaCard";
import UploaderModal from "@/components/Attachments/UploaderModal";

export default {
  setup(props) {
    const { parentId, parentType, reloadParent, allowedRecipients } = props;
    const machineOptions = {
      context: { parentId, parentType },
      services: {
        reloadParent
      }
    };

    // Setup our state machine...
    const {
      service: attachmentService,
      state: attachmentState,
      send: sendToAttachmentMachine
    } = useMachine(attachmentMachine, machineOptions);

    const errors = computed(() => attachmentState?.value?.context?.errors ?? []);

    const { state: profileState } = useService(profileService);

    const fetchedOrganization = computed(() => {
      return profileState?.value?.context?.organization ?? {};
    });

    // Remove Alert modal
    const modalVisible = ref(false);
    const uploaderModalVisible = ref(false);
    const deletingAttachment = ref({});

    const showUploadModal = () => {
      uploaderModalVisible.value = true;
    };

    const closeUploadModal = () => {
      uploaderModalVisible.value = false;
    };

    const closeModal = () => {
      deletingAttachment.value = {};
      modalVisible.value = false;
    };

    const showModal = (name, attachmentId) => {
      deletingAttachment.value = { attachmentId, name };
      modalVisible.value = true;
    };

    watchState(attachmentState, "Attachment");

    // Return what we want to expose to the template...
    return {
      fetchedOrganization,
      modalVisible,
      uploaderModalVisible,
      deletingAttachment,
      closeModal,
      closeUploadModal,
      showUploadModal,
      showModal,
      attachmentState,
      errors,
      sendToAttachmentMachine,
      recipientOptions:
        "guarantee" === parentType && allowedRecipients.length === 0 ? [] : allowedRecipients
    };
  },
  name: "Attachments",
  components: {
    MetaCard,
    UploaderModal,
    UiAlert,
    UiButton,
    UiHeadline,
    UiModal
  },
  props: {
    attachments: {
      type: Array,
      default: () => []
    },
    parentId: {
      type: String,
      default: ""
    },
    parentType: {
      type: String,
      default: "guarantee"
    },
    allowAttaching: {
      type: Boolean,
      default: true
    },
    reloadParent: {
      type: Function,
      required: true
    },
    disablePrivate: {
      type: Boolean,
      default: false
    },
    allowedRecipients: {
      type: Array,
      default: () => []
    },
    hideTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      itemsToShow: 3,
      viewMore: false
    };
  },
  computed: {
    visibleItems() {
      return this.viewMore
        ? this.attachments
        : this.attachments.filter((item, index) => index < this.itemsToShow);
    }
  },
  methods: {
    formatAttachmentTitle(dateUploaded) {
      // Uploaded 15 Jan 2020 at 7:32pm
      const date = format(new Date(dateUploaded), "d LLL yyyy");
      const time = format(new Date(dateUploaded), "hh:mma").toLowerCase();
      return `Uploaded - ${date} at ${time}`;
    },
    onDownloadClick(attachmentId) {
      this.sendToAttachmentMachine("DOWNLOAD", { attachmentId });
    },
    onRemoveClick(attachmentId) {
      this.closeModal();
      this.sendToAttachmentMachine("REMOVE", { attachmentId });
    },
    onDownloadAllClick() {
      this.sendToAttachmentMachine("DOWNLOAD_ALL");
    }
  }
};
</script>
