<template>
  <UiCard theme="primary" :padding="false" :enable-css-overflow="true">
    <div
      class="action-section-meta"
      :title="`Click to ${contentActive ? 'close' : 'open'}`"
      @click="onToggleClick"
    >
      <div>
        <UiHeadline v-if="title" :size="5">{{ title }}</UiHeadline>
        <p v-if="description" v-dompurify-html="description" class="action-section-description"></p>
      </div>

      <div>
        <ChevronUpIcon v-if="contentActive" size="1.4x" class="text-tertiary" />
        <ChevronDownIcon v-else size="1.4x" class="text-gray-400" />
      </div>
    </div>
    <!-- .action-section-meta -->

    <div class="action-section-content" :class="{ active: contentActive }">
      <slot />
    </div>
    <!-- .action-section-content -->
  </UiCard>
</template>

<script>
import { ChevronDownIcon, ChevronUpIcon } from "vue-feather-icons";
import UiCard from "@/components/UI/UiCard/UiCard";
import UiHeadline from "@/components/UI/UiHeadline/UiHeadline";

export default {
  name: "ActionSection",
  components: { ChevronDownIcon, ChevronUpIcon, UiCard, UiHeadline },
  props: {
    description: {
      type: String,
      default: ""
    },
    error: {
      type: Boolean,
      default: false
    },
    index: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ""
    },
    collapsible: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      contentActive: true
    };
  },
  methods: {
    onToggleClick() {
      if (this.collapsible) {
        this.contentActive = !this.contentActive;
      }
    }
  }
};
</script>

<style scoped>
.action-section-meta {
  @apply p-4 cursor-pointer flex items-center justify-between;

  @screen md {
    @apply p-6;
  }
}

.action-section-description {
  @apply font-normal text-xs text-gray-500 mt-2;

  @screen md {
    @apply mt-3 text-sm;
  }
}

.action-section-content {
  @apply hidden;

  &.active {
    @apply block border-t border-gray-200;
  }
}
</style>
