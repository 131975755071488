const PERMISSIONS = {
  READ: "READ",
  CREATE: "CREATE",
  UPDATE: "UPDATE",
  AMEND: "AMEND",
  MESSAGE: "MESSAGE",
  ATTACH: "ATTACH",
  APPROVE: "APPROVE",
  MANAGE_USER: "MANAGE_USER",
  MANAGE_ORGANIZATION: "MANAGE_ORGANIZATION",
  DELETE_APPLICATION: "DELETE_APPLICATION",
  CANCEL: "CANCEL",
  APPROVE_CANCEL: "APPROVE_CANCEL",
  CLAIM: "CLAIM",
  APPROVE_CLAIM: "APPROVE_CLAIM"
};

const PERMISSION_LABELS = {
  READ: "Read",
  CREATE: "Create",
  UPDATE: "Input",
  AMEND: "Amend Draft",
  MESSAGE: "Messaging",
  ATTACH: "Attach Documents",
  APPROVE: "Approve",
  MANAGE_USER: "Manage Users",
  MANAGE_ORGANIZATION: "Manage Organisation",
  DELETE_APPLICATION: "Delete Application",
  CANCEL: "Cancel",
  APPROVE_CANCEL: "Approve Cancellation",
  CLAIM: "Claim",
  APPROVE_CLAIM: "Approve Claim"
};

const PERMISSIONS_DESCRIPTIONS = {
  READ: {
    default:
      "View the registry of guarantees (regardless of state), all guarantee electronic records, messages, documents only."
  },
  CREATE: {
    default: "Create new bond applications",
    tenant: "Create new bond applications",
    agent: "Create a new bond application on behalf of a tenant",
    landlord: "Upload an existing bank guarantee to create an electronic record"
  },
  UPDATE: {
    default: "Upload, amend and save but not submit",
    landlord: "Can accept bond terms"
  },
  AMEND: {
    default: "Amend draft guarantee in the Review and Agree Guarantee"
  },
  APPROVE: {
    default:
      "Approve is the ability to progress from one state to the next, i.e. Submit for Credit Check, Accept Pricing and Charges, Accept Term Sheet, etc",
    tenant:
      "Approve is the ability to progress from one state to the next, i.e. Submit for Credit Check, Accept Pricing and Charges, Accept Term Sheet, etc",
    landlord:
      "Approve is the ability to progress from one state to the next, i.e. Confirm Record (in relation to a digitised BG), Accept Lease Bond, Submit Claim, Submit Cancellation, etc",
    guarantor:
      " Approve is the ability to progress from one state to the next, (i.e. Request for Tenant Indemnifiers, Approve/Reject Credit Check, Confirm Pricing & Fees, Rollback to Under Review, Send to Credit Check, Upload Term Sheet, Upload Security Documents, Issue Bond, etc"
  },
  ATTACH: {
    default: "Attach documents to the document tray"
  },
  MESSAGE: {
    default: "Send messages in the messages tray"
  },
  MANAGE_USER: {
    default: "Invite users to organisation and assign user roles"
  },
  MANAGE_ORGANIZATION: {
    default: "Update organisation details, setup subsidiaries, and user roles"
  },
  DELETE_APPLICATION: {
    default: "Delete draft application"
  },
  CANCEL: {
    default: "Can submit cancellation request"
  },
  APPROVE_CANCEL: {
    default:
      "Can approve cancellation requests. If the user also has permission to cancel, the platfrom will skip the approval step."
  },
  CLAIM: {
    default: "Can submit claim on bonds"
  },
  APPROVE_CLAIM: {
    default:
      "Can approve bond claim requests. If the user also has permission to submit claim, the platform will skip the approval step."
  }
};

export { PERMISSIONS, PERMISSION_LABELS, PERMISSIONS_DESCRIPTIONS };
