<template>
  <component :is="this.$route.meta.layout || 'div'">
    <UserRole :role="userRole" />

    <router-view />
  </component>
</template>

<script>
import { Hub } from "aws-amplify";
import { useService } from "@xstate/vue";
import { computed, provide } from "@vue/composition-api";
import { getUserAttributes, isAuthenticated, signout } from "@/lib/auth";
import { organizationService } from "@/main";

import UserRole from "@/components/Utils/UserRole";

export default {
  setup() {
    // Setup our state machine...
    const { state: organizationState, send: sendToOrganizationService } = useService(
      organizationService
    );

    // watchState(organizationState, "Organization");

    // Add a user role label in development
    const userRole = computed(() => organizationState?.value?.context?.organization?.role);

    // Provide the organisation to the rest of the app
    const organization = computed(() => organizationState.value?.context?.organization);

    provide("organization", organization);
    provide("userRole", userRole);

    // Return what we want to expose to the template...
    return {
      organizationState,
      sendToOrganizationService,
      userRole
    };
  },
  components: { UserRole },
  metaInfo: {
    title: "Home",
    // all titles will be injected into this template
    titleTemplate: "%s | eGuarantee"
  },
  mounted() {
    this.setupAuthEvents();

    this.handleLoggedInOnLoad();
  },
  methods: {
    async handleLoggedInOnLoad() {
      const errroMessage =
        "It looks like there was an error trying to re-authenticate. Please try again.";

      try {
        const authenticated = await isAuthenticated();

        if (authenticated) this.onSignIn();
      } catch (error) {
        this.$logger.error(error);
        signout(errroMessage);
      }
    },
    onSignIn() {
      this.setupOrganization();

      this.setupTracking();
    },
    setupAuthEvents() {
      const authListener = data => {
        switch (data.payload.event) {
          case "signIn":
            this.$logger.info("User signed in");
            this.onSignIn();
            break;
          case "signUp":
            this.$logger.info("User signed up");
            break;
          case "signOut":
            this.sendToOrganizationService("RESET");
            this.$logger.info("User signed out");
            break;
          case "signIn_failure":
            this.$logger.warn("User sign in failed");
            break;
          case "configured":
            this.$logger.info("The Auth module is configured");
        }
      };

      Hub.listen("auth", authListener);
    },
    setupOrganization() {
      this.sendToOrganizationService("FETCH_ORGANIZATION");
    },
    async setupTracking() {
      const user = await getUserAttributes();
      const { email, name } = user;
      const orgId = user["custom:organizationId"];

      window.Sentry?.configureScope(scope => {
        scope.setUser({ email });
        scope.setTag("orgId", orgId);
      });

      window.LogRocket?.identify(email, {
        name,
        email,
        orgId
      });
    }
  }
};
</script>
