<template>
  <div>
    <div class="flex flex-wrap items-center justify-between mb-4">
      <UiHeadline :level="3" :size="5" class="mb-2">Status Updates</UiHeadline>

      <StatusUpdate
        v-if="status"
        :status="status.primaryStatus"
        :label="status.label"
        class="mb-2"
      />
    </div>
    <!-- .flex items-center -->

    <div class="flex flex-col items-center space-y-4">
      <MetaCard
        v-for="(event, i) in visibleItems"
        :key="i"
        :icon-type="getEventIconType(event.type)"
        :info="event.message"
        :hidden-content="event.details"
        :title="formatEventTitle(event)"
        show-hidden-content
      >
        <template #footer>
          <p class="text-xs text-right text-gray-400">
            {{ isSystemEvent(event.type) ? "(Automatic by system)" : event.userName }}
          </p>
          <p v-if="!isSystemEvent(event.type)" class="text-xs text-right text-gray-400">
            {{ event.organizationName | capitalize }}
          </p>
        </template>
      </MetaCard>

      <UiButton
        v-if="events.length > itemsToShow"
        theme="link"
        size="sm"
        @click="viewMore = !viewMore"
        >{{ viewMore ? "View Less" : "View More" }}</UiButton
      >
    </div>
    <!-- .space-y-4 -->
  </div>
</template>

<script>
import { format } from "date-fns";
import UiButton from "@/components/UI/UiButton/UiButton";
import UiHeadline from "@/components/UI/UiHeadline/UiHeadline";
import StatusUpdate from "@/components/StatusUpdate/StatusUpdate";
import MetaCard from "@/components/Cards/MetaCard/MetaCard";

export default {
  name: "Events",
  components: { MetaCard, StatusUpdate, UiButton, UiHeadline },
  props: {
    events: {
      type: Array,
      default: () => []
    },
    status: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      itemsToShow: 3,
      viewMore: false
    };
  },
  computed: {
    visibleItems() {
      return this.viewMore
        ? this.events
        : this.events.filter((item, index) => index < this.itemsToShow);
    }
  },
  methods: {
    formatEventTitle(event) {
      const date = format(new Date(event.dateActioned), "d LLL yyyy");
      return `${event.title} - ${date}`;
    },
    getEventIconType(type) {
      const types = {
        action: "check",
        amendment: "exclamationCircle",
        system: "exclamationTriangle"
      };

      return types[type];
    },
    isSystemEvent(eventType) {
      return eventType === "system";
    }
  }
};
</script>
