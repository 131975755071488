import { organizationService } from "@/main";
import { isProduction, isDev, isStaging } from "@/utils/constants";
import { getAuthenticationToken } from "@/lib/auth";
import axios from "axios";
import isError from "lodash.iserror";

const fieldTypes = {
  DateField: "date",
  NumberField: "number",
  NumberInputType: "number",
  OrganizationField: "text",
  TextInputType: "text"
};

export const getFieldInputType = typename => {
  // Return the input field type or default to text...
  return fieldTypes[typename] ?? null;
};

const inputComponents = {
  DateField: "UiDateInput",
  NumberField: "UiInput",
  OrganizationField: "UiInput",
  TextareaField: "UiTextarea",
  BooleanField: "UiCheckbox",

  BooleanInputType: "UiCheckbox",
  DateInputType: "UiDateInput",
  NumberInputType: "UiInput",
  TextInputType: "UiInput",
  FileInputType: "UiFileInput",
  DocusignInputType: "UiDocusignInput",
  DocusignStatusInputType: "UiDocusignStatusInput",
  TextAreaInputType: "UiTextarea",
  RichTextInputType: "UiRichText",

  OptionsInputType: "UiMultiselect",
  OrganizationInputType: "UiOrganizationInput",
  AddressInputType: "UiAddressInput",

  StaticInputType: "p"
};

export const getInputComponent = typename => {
  // Return the input field type or default to text...
  return inputComponents[typename] ?? "UiInput";
};

export const getPresignedUploadUrl = async (file, uploadType) => {
  // Get orgId from organizationService...
  const { organizationId } = organizationService?.state?.context?.organization;

  const fileName = encodeURIComponent(file.name);
  const fileType = encodeURIComponent(file.type);
  const uploadTypeQuery = uploadType ? `&uploadType=${encodeURIComponent(uploadType)}` : "";

  const authToken = await getAuthenticationToken();
  return axios.get(
    `${process.env.VUE_APP_SERVICES_REST_ENDPOINT}/attachment/upload?organizationId=${organizationId}&filename=${fileName}&contentType=${fileType}${uploadTypeQuery}&type=upload`,
    {
      headers: {
        Authorization: authToken
      }
    }
  );
};

export const getPresignedDeleteUrl = async key => {
  const { organizationId } = organizationService?.state?.context?.organization;

  const keyURI = encodeURIComponent(key);

  const authToken = await getAuthenticationToken();
  return axios.get(
    `${process.env.VUE_APP_SERVICES_REST_ENDPOINT}/attachment/upload?organizationId=${organizationId}&key=${keyURI}&type=delete`,
    {
      headers: {
        Authorization: authToken
      }
    }
  );
};

export const getPresignedDownloadUrl = async (key, name) => {
  const { organizationId } = organizationService?.state?.context?.organization;

  const keyURI = encodeURIComponent(key);
  const nameURI = encodeURIComponent(name);

  const authToken = await getAuthenticationToken();
  return axios.get(
    `${process.env.VUE_APP_SERVICES_REST_ENDPOINT}/attachment/upload?organizationId=${organizationId}&key=${keyURI}&name=${nameURI}&type=download`,
    {
      headers: {
        Authorization: authToken
      }
    }
  );
};

export const uploadFile = async (url, file) => {
  const config = {
    // `url` is the server URL that will be used for the request
    url,
    method: "put",
    data: file,
    headers: {
      "Content-Type": file.type
    }
  };

  return axios.request(config);
};

export const deleteFile = async url => {
  const config = {
    url,
    method: "delete"
  };

  return axios.request(config);
};

export const formatNumber = (stringValue, precision = 2) => {
  const number = parseFloat(stringValue).toFixed(precision);
  const currency = new Intl.NumberFormat("en-AU", {
    style: "decimal",
    minimumFractionDigits: precision
  }).format(number);

  return currency;
};

export const clearFormFromLocalStorage = (formId = "", form) => {
  const url = location.href;
  const formIdentifier = `${url}-${formId}`;

  window?.localStorage.removeItem(formIdentifier);
};

export const saveFormToLocalStorage = (formId = "", form) => {
  const url = location.href;
  const formIdentifier = `${url}-${formId}`; // Identifier used to identify the form

  window?.localStorage.setItem(formIdentifier, JSON.stringify(form));
};

export const restoreFormFromLocalStorage = (formId = "") => {
  const url = location.href;
  const formIdentifier = `${url}-${formId}`;

  return JSON.parse(localStorage.getItem(formIdentifier));
};

export const parseBoolean = value => {
  return value === true || value === "true" || value === "t" || value === 1;
};

export const typeCastByInputType = (inputType, value) => {
  switch (inputType) {
    case "BooleanInputType":
      return parseBoolean(value);
    case "NumberInputType":
      return Number(value);
    case "DateInputType":
      return value;
    case "DocusignInputType":
    case "DocusignStatusInputType":
      return JSON.parse(value);
    default:
      return value;
  }
};

export const logger = isProduction
  ? // If we're in production send errors to LogRocket and Sentry...
    {
      error: (err, label = "", sendToErrorMonitor = true) => {
        // Convert the err argument to an actual Error if it isn't one already...
        const error = isError(err) ? err : Error(err);

        // Send to LogRocket
        sendToErrorMonitor &&
          window.LogRocket?.captureException(error, {
            label
          });

        // Send to Sentry
        sendToErrorMonitor && window.Sentry?.captureException(error);
      },
      info: () => {},
      warn: () => {},
      log: () => {}
    }
  : // Or use just the console and Sentry in development/local...
    {
      ...window.console,
      error: (err, label, sendToErrorMonitor = true) => {
        // Convert the err argument to an actual Error if it isn't one already...
        const error = isError(err) ? err : Error(err);

        // Only send the error to Sentry in development and not local...
        if ((isStaging || isDev) && sendToErrorMonitor) window.Sentry?.captureException(error);

        label ? console.error(label, error) : console.error(error);
      }
    };
