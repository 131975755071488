<template>
  <UiBaseInput
    :name="$attrs.name"
    :label="label"
    :error="error"
    :size="size"
    :loading="loading"
    class="ui-date-input-base"
  >
    <flatPickr
      :value="value"
      :config="config"
      :class="inputClassObject"
      :placeholder="placeholder"
      :data-cy="$attrs.name"
      @input="onInput"
    />
    <slot slot="label" name="label" />
    <slot slot="errors" name="errors" />
  </UiBaseInput>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import UiBaseInput from "@/components/UI/UiBaseInput/UiBaseInput";

export default {
  name: "UiDateInput",
  components: { flatPickr, UiBaseInput },
  inheritAttrs: false,
  props: {
    error: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: "DD/MM/YYYY"
    },
    size: {
      type: String,
      default: "base"
    },
    value: {
      type: [Date, String],
      default: ""
    },
    config: {
      type: Object,
      default: () => ({
        allowInput: true,
        altFormat: "d/m/Y",
        altInput: true,
        dateFormat: "Y-m-d"
      })
    }
  },
  computed: {
    inputClassObject() {
      return {
        "form-input ui-date-input": true,
        error: this.error,
        [this.size]: this.size
      };
    }
  },
  methods: {
    onInput(event) {
      this.$emit("input", event ? new Date(event).toISOString() : null);
    }
  }
};
</script>

<style scoped>
/* purgecss start ignore */
/* Base Styles */
>>> .ui-date-input {
  @apply block w-full text-xs tracking-wide;

  &::placeholder {
    @apply text-xs tracking-normal;

    @screen md {
      @apply text-sm;
    }
  }

  &[disabled] {
    @apply text-gray-500;
  }

  &.error {
    @apply border-red-300 text-red-900 pr-2;

    &::placeholder {
      @apply text-red-900;
    }

    &:focus {
      @apply border-red-300 shadow-outline-red;
    }
  }

  @screen md {
    @apply text-sm leading-5;
  }

  &.sm {
    @screen md {
      @apply text-xs;

      &::placeholder {
        @apply text-xs;
      }
    }
  }
}

>>> .ui-date-input-calendar {
  @apply rounded shadow-md overflow-hidden text-xs p-1 top-11;

  @screen md {
    @apply text-sm top-11 p-1.5;
  }

  header .prev:not(.disabled):hover,
  header .next:not(.disabled):hover,
  header .up:not(.disabled):hover {
    @apply bg-cool-gray-200 rounded;
  }

  header .prev:after {
    @apply rounded-sm;
    border-right: 10px solid #153d65;
  }

  header .next:after {
    @apply rounded-sm;
    border-left: 10px solid #153d65;
  }

  header span:not(.next),
  header span:not(.prev) {
    @apply text-cool-gray-600 text-sm;
  }

  .cell:not(.blank):not(.disabled).day:hover,
  .cell:not(.blank):not(.disabled).month:hover,
  .cell:not(.blank):not(.disabled).year:hover {
    @apply border-tertiary bg-cool-gray-100;
  }

  .cell {
    @apply rounded text-cool-gray-600;
    height: 34px;
    line-height: 34px;

    &.selected {
      @apply bg-primary text-white !important;

      &:hover {
        @apply bg-primary;
      }
    }
  }
}

>>> .vdp-datepicker__clear-button {
  @apply absolute right-2 top-1.5 text-cool-gray-500;
}
/* purgecss end ignore */
</style>
