import { Machine, assign } from "xstate";
import { Auth } from "aws-amplify";
import { router } from "@/router";

export default Machine(
  {
    id: "confirmation",

    initial: "idle",

    context: {
      error: "",
      message: ""
    },

    states: {
      idle: {
        on: {
          CONFIRM: "confirming",
          RESEND_CODE: "resendingCode"
        }
      },
      confirming: {
        entry: [
          "resetState",
          assign((context, event) => {
            return {
              ...event.data
            };
          })
        ],
        invoke: {
          id: "confirming",
          src: (context, event) => {
            return Auth.confirmSignUp(event.data.email, event.data.code);
          },
          onDone: [
            {
              target: "confirmed",
              // Only transition to 'verified' if the guard (cond) evaluates to true
              cond: "isConfirmed"
            },
            {
              // If the transition above fails we transition to unverified
              target: "idle",
              actions: assign({
                error: "Could not confirm your account"
              })
            }
          ],
          onError: {
            target: "idle",
            actions: assign({
              error: (context, event) =>
                event?.data?.response?.data?.errorMessage || event?.data?.message
            })
          }
        }
      },
      confirmed: {
        invoke: {
          id: "confirmed",
          src: (context, event) => {
            router.push({ name: "SignIn", query: { createdAccount: "success" } });
          },
          onDone: [
            {
              target: "idle",
              actions: assign({
                error:
                  "Could not sign you in, please contact our support team (contact@eguarantee.com.au)"
              })
            }
          ]
        }
      },
      resendingCode: {
        entry: "resetState",
        invoke: {
          id: "resendingCode",
          src: (context, event) => {
            return Auth.resendSignUp(event.data?.email);
          },
          onDone: [
            {
              target: "idle",
              actions: assign({
                message: "New code sent"
              })
            }
          ],
          onError: {
            target: "confirming",
            actions: assign({
              error: (context, event) =>
                event?.data?.response?.data?.errorMessage || event?.data?.message
            })
          }
        }
      }
    }
  },
  {
    actions: {
      resetState: assign({
        error: "",
        message: ""
      })
    },
    guards: {
      isConfirmed: (context, event) => {
        return event.data === "SUCCESS";
      }
    }
  }
);
