<template>
  <div>
    <UiButton
      v-if="!state.matches('error')"
      theme="link"
      :loading="state.matches('generating')"
      button-class="report-button"
      :remove-padding="true"
      @click="onGenerateClick(reportType)"
      >{{ buttonLabel }}<FileTextIcon v-if="!state.matches('generating')" size="1x" class="ml-1" />
    </UiButton>

    <div v-if="state.matches('error')" class="flex items-center">
      <p class="mr-2 text-xs text-red-500">Failed to generate&hellip;</p>

      <UiButton theme="warning" @click="send({ type: 'RETRY', reportType })">Retry?</UiButton>
    </div>
    <!-- .flex items-center -->
  </div>
</template>

<script>
import { FileTextIcon } from "vue-feather-icons";
import { useMachine } from "@xstate/vue";
import reportsMachine from "./reports-machine";
import UiButton from "@/components/UI/UiButton/UiButton";

export default {
  name: "GenerateReport",
  components: { FileTextIcon, UiButton },
  props: {
    reportType: {
      type: String,
      default: "guarantee"
    }
  },
  setup() {
    // Setup our state machine...
    const { state, send } = useMachine(reportsMachine);

    // watchState(state, "Reports");

    return {
      send,
      state
    };
  },
  computed: {
    buttonLabel() {
      return this.state.matches("generating") ? "Generating Report" : "Generate Report";
    }
  },
  methods: {
    onGenerateClick(reportType) {
      return this.send({
        type: "GENERATE",
        reportType
      });
    }
  }
};
</script>

<style scoped>
/* Base Styles */
>>> .report-button {
  @apply pl-0;
}
</style>
