<template>
  <div>
    <div class="space-y-4">
      <div v-for="(entity, index) in value" :key="entity.id" class="flex flex-col">
        <div class="flex flex-row">
          <UiInput
            type="text"
            :name="`${name}[${index}].name`"
            placeholder="Entity Name"
            :disabled="lockArray"
            :value="entity.name"
            :error="childError[index].name"
            @input="updateCurrentModelValue(entity, 'name', $event)"
          />
          <Trash2 v-if="!lockArray" class="button-delete" @click="remove(entity.id)" />
        </div>
        <div class="grid grid-cols-2">
          <UiInput
            type="text"
            :name="`${name}[${index}].director.name`"
            placeholder="Director/Secretary Full Name"
            :value="entity.director.name"
            :error="childError[index].director.name"
            @input="updateCurrentModelValue(entity.director, 'name', $event)"
          />
          <UiInput
            type="email"
            class="input-email"
            :name="`${name}[${index}].director.email`"
            placeholder="Director/Secretary Email"
            :value="entity.director.email"
            :error="childError[index].director.email"
            @input="updateCurrentModelValue(entity.director, 'email', $event)"
          />
          <UiInput
            type="text"
            :name="`${name}[${index}].secretary.name`"
            placeholder="Secretary Full Name"
            :value="entity.secretary.name"
            :error="childError[index].secretary"
            @input="updateCurrentModelValue(entity.secretary, 'name', $event)"
          />
          <UiInput
            type="email"
            class="input-email"
            :name="`${name}[${index}].secretary.email`"
            placeholder="Secretary Email"
            :value="entity.secretary.email"
            :error="childError[index].secretary"
            @input="updateCurrentModelValue(entity.secretary, 'email', $event)"
          />
        </div>
      </div>
    </div>
    <UiButton v-if="!lockArray" type="button" theme="primary" class="button-add" @click="addMore">
      Add more
    </UiButton>
  </div>
</template>

<script>
import UiButton from "@/components/UI/UiButton/UiButton.vue";
import UiInput from "@/components/UI/UiInput/UiInput.vue";
import { Trash2Icon as Trash2 } from "vue-feather-icons";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "UiDocusignPersonal",
  components: { UiButton, UiInput, Trash2 },
  inheritAttrs: false,
  props: {
    value: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: ""
    },
    lockArray: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    childError: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    inputClassObject() {
      return {
        error: this.error,
        [this.size]: this.size
      };
    }
  },
  methods: {
    updateNewValue(data) {
      this.$emit("input", data);
    },
    addMore() {
      this.updateNewValue(
        this.value.concat({
          id: uuidv4(),
          name: "",
          director: { name: "", email: "" },
          secretary: { name: "", email: "" }
        })
      );
    },
    remove(removedId) {
      this.updateNewValue(this.value.filter(({ id }) => id !== removedId));
    },
    updateCurrentModelValue(elem, key, newValue) {
      elem[key] = newValue;
      this.$emit("input", this.value);
    },
    isChildError() {
      return false;
    }
  }
};
</script>
