<template>
  <UiBaseInput :name="$attrs.name" :label="label" :error="error" :size="size" :loading="loading">
    <UiDocusignStatusPersonal
      v-if="subType === 'personal'"
      novalidate
      :class="inputClassObject"
      data-testid="ui-input"
      :value="value"
      v-bind="$attrs"
      :disabled="$attrs.disabled"
    />
    <UiDocusignStatusEntity
      v-if="subType === 'entity'"
      novalidate
      :class="inputClassObject"
      data-testid="ui-input"
      :value="value"
      v-bind="$attrs"
      :disabled="$attrs.disabled"
    />
    <slot slot="label" name="label" />
    <slot slot="errors" name="errors" />
    <slot slot="leadingAddon" name="leadingAddon" />
    <slot slot="trailingAddon" name="trailingAddon" />
  </UiBaseInput>
</template>

<script>
import UiBaseInput from "@/components/UI/UiBaseInput/UiBaseInput";
import UiDocusignStatusPersonal from "@/components/UI/UiDocusign/UiDocusignStatusPersonal";
import UiDocusignStatusEntity from "@/components/UI/UiDocusign/UiDocusignStatusEntity";

export default {
  name: "UiInput",
  components: { UiBaseInput, UiDocusignStatusPersonal, UiDocusignStatusEntity },
  inheritAttrs: false,
  props: {
    error: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "base"
    },
    value: {
      type: Object,
      default: () => ({ isCompleted: false, envelopes: [] })
    },
    subType: {
      type: String,
      default: ""
    }
  },
  computed: {
    inputClassObject() {
      return {
        error: this.error,
        [this.size]: this.size
      };
    }
  }
};
</script>

<style>
/* purgecss start ignore */

/* Base Styles */
.ui-input {
  @apply block w-full text-xs;

  &::placeholder {
    @apply text-xs;

    @screen md {
      @apply text-sm;
    }
  }

  &[disabled] {
    @apply text-gray-500 bg-gray-200;
  }

  &.error {
    @apply border-red-300 text-red-900 pr-2;

    &::placeholder {
      @apply text-red-900;
    }

    &:focus {
      @apply border-red-300 shadow-outline-red;
    }
  }

  @screen md {
    @apply text-sm leading-5;
  }

  &.sm {
    @screen md {
      @apply text-xs;

      &::placeholder {
        @apply text-xs;
      }
    }
  }
}

.ui-input-dropdown {
  @apply absolute inset-y-0 right-0 flex items-center;
}

.ui-input-dropdown-label {
  @apply h-full py-0 pl-2 text-gray-500 bg-transparent border-transparent pr-7 text-xs;

  @screen md {
    @apply text-sm leading-5;
  }

  &.sm {
    @screen md {
      @apply text-xs;
    }
  }
}
/* purgecss end ignore */
</style>
