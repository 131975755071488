import { Machine, assign } from "xstate";
import { API, graphqlOperation } from "aws-amplify";
import { AddContextitem } from "@/graphql/mutations";
import { Attachment as AttachmentQuery } from "@/graphql/queries";
import { helperActions, getGraphQlData } from "@/utils/machine-helpers";

const initialState = {
  errors: [],
  loading: false
};

export default Machine(
  {
    id: "context",

    initial: "idle",

    context: {
      ...initialState
    },

    states: {
      idle: {
        on: {
          ADD_ATTACHMENT: "addAttachment",
          ADD_MESSAGE: "addMessage",
          DOWNLOAD_ATTACHMENT: "downloadAttachment"
        }
      },
      addAttachment: {
        entry: "setLoading",
        invoke: {
          src: "apiAddAttachment",
          onDone: {
            target: "added",
            actions: ["resetLoading"]
          },
          onError: {
            target: "idle",
            actions: ["assignErrors", "trackErrors"]
          }
        }
      },
      addMessage: {
        entry: "setLoading",
        invoke: {
          src: "apiAddMessage",
          onDone: {
            target: "added",
            actions: ["resetLoading"]
          },
          onError: {
            target: "idle",
            actions: ["assignErrors", "trackErrors"]
          }
        }
      },
      downloadAttachment: {
        invoke: {
          src: "apiDownloadAttachment",
          onDone: {
            target: "idle",
            actions: "navigateToDownloadUrl"
          },
          onError: {
            target: "idle",
            actions: ["assignErrors", "trackErrors"]
          }
        }
      },
      added: {
        type: "final",
        entry: "reloadGuarantee"
      }
    }
  },

  {
    actions: {
      ...helperActions,
      setLoading: assign({ loading: true }),
      resetLoading: assign({ loading: false }),
      navigateToDownloadUrl: (_, event) => {
        const downloadUrl = getGraphQlData(event, "attachment.url");

        if (downloadUrl) {
          const open = window.open(downloadUrl);

          // Handle if we can't open a window e.g on iOS...
          if (open == null || typeof open === "undefined") {
            window.document.location.href = downloadUrl;
          }
        }
      }
    },
    services: {
      apiAddAttachment(context, event) {
        const guaranteeId = context.guarantee?.guaranteeId;
        const { name, uploadKey, isPrivate } = event;

        return API.graphql(
          graphqlOperation(AddContextitem, {
            input: {
              guaranteeId,
              type: "ATTACHMENT",
              name,
              uploadKey,
              isPrivate
            }
          })
        );
      },
      apiAddMessage(context, event) {
        const guaranteeId = context.guarantee?.guaranteeId;
        const { content } = event;

        return API.graphql(
          graphqlOperation(AddContextitem, {
            input: {
              guaranteeId,
              type: "MESSAGE",
              content
            }
          })
        );
      },
      apiDownloadAttachment(context, event) {
        const guaranteeId = context.guarantee?.guaranteeId;
        const { attachmentId } = event;

        return API.graphql(
          graphqlOperation(AttachmentQuery, {
            input: {
              attachmentId,
              guaranteeId
            }
          })
        );
      }
    }
  }
);
