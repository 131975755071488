<template>
  <div>
    <span
      class="inline-flex items-center justify-center px-2 py-1 mr-2 text-xs w-100 font-bold leading-none rounded"
      style="width: 12ch"
      :class="{
        'text-green-100': value,
        'bg-green-600': value,
        'text-gray-100': !value,
        'bg-gray-500': !value
      }"
    >
      {{ value ? "SIGNED" : "PENDING" }}
    </span>
    <span>{{ label }}</span>
  </div>
</template>

<script>
export default {
  name: "UiDocusignStatusBox",
  inheritAttrs: false,
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: "N/A"
    }
  },
  computed: {
    inputClassObject() {
      return {
        error: this.error,
        [this.size]: this.size
      };
    }
  }
};
</script>
