<template>
  <UiBaseInput :name="$attrs.name" :label="label" :error="error" :size="size" :loading="loading">
    <UiDocusignPersonal
      v-if="subType === 'personal'"
      :name="name"
      novalidate
      :class="inputClassObject"
      data-testid="ui-input"
      :value="value"
      v-bind="$attrs"
      :disabled="$attrs.disabled"
      :lock-array="lockArray"
      :child-error="childError"
      @input="$emit('input', $event)"
    />
    <UiDocusignEntity
      v-if="subType === 'entity'"
      :name="name"
      novalidate
      :class="inputClassObject"
      data-testid="ui-input"
      :value="value"
      v-bind="$attrs"
      :disabled="$attrs.disabled"
      :lock-array="lockArray"
      :child-error="childError"
      @input="$emit('input', $event)"
    />
    <slot slot="label" name="label" />
    <slot slot="errors" name="errors" />
    <slot slot="leadingAddon" name="leadingAddon" />
    <slot slot="trailingAddon" name="trailingAddon" />
  </UiBaseInput>
</template>

<script>
import UiBaseInput from "@/components/UI/UiBaseInput/UiBaseInput";
import UiDocusignPersonal from "@/components/UI/UiDocusign/UiDocusignPersonal";
import UiDocusignEntity from "@/components/UI/UiDocusign/UiDocusignEntity";

export default {
  name: "UiInput",
  components: { UiBaseInput, UiDocusignPersonal, UiDocusignEntity },
  inheritAttrs: false,
  props: {
    name: {
      type: String,
      default: ""
    },
    error: {
      type: Boolean,
      default: false
    },
    childError: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "base"
    },
    value: {
      type: Array,
      default: () => []
    },
    subType: {
      type: String,
      default: ""
    },
    lockArray: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputClassObject() {
      return {
        error: this.error,
        [this.size]: this.size
      };
    }
  }
};
</script>

<style>
/* purgecss start ignore */

/* Base Styles */
.ui-input {
  @apply block w-full text-xs;

  &::placeholder {
    @apply text-xs;

    @screen md {
      @apply text-sm;
    }
  }

  &[disabled] {
    @apply text-gray-500 bg-gray-200;
  }

  &.error {
    @apply border-red-300 text-red-900 pr-2;

    &::placeholder {
      @apply text-red-900;
    }

    &:focus {
      @apply border-red-300 shadow-outline-red;
    }
  }

  @screen md {
    @apply text-sm leading-5;
  }

  &.sm {
    @screen md {
      @apply text-xs;

      &::placeholder {
        @apply text-xs;
      }
    }
  }
}

.ui-input-dropdown {
  @apply absolute inset-y-0 right-0 flex items-center;
}

.ui-input-dropdown-label {
  @apply h-full py-0 pl-2 text-gray-500 bg-transparent border-transparent pr-7 text-xs;

  @screen md {
    @apply text-sm leading-5;
  }

  &.sm {
    @screen md {
      @apply text-xs;
    }
  }
}
/* purgecss end ignore */

.input-email {
  margin-left: 10px;
}
.button-add {
  margin-top: 10px;
}
.button-delete {
  margin-left: 10px;
  align-self: center;
}
</style>
