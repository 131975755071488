<template>
  <li class="relative overflow-hidden lg:flex-1">
    <div class="overflow-hidden lg:border-0">
      <a aria-current="step">
        <span class="absolute  bg-primary w-full h-1 bottom-0" aria-hidden="true"></span>
        <span class="px-3 md:4 py-3 flex items-center justify-center text-sm font-medium lg:pl-6">
          <span class="flex-shrink-0">
            <span
              class="w-4 h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 flex items-center justify-center border-2 border-primary rounded-full"
            >
              <span class="text-primary">{{ order }}</span>
            </span>
          </span>
          <span class="mt-0.5 ml-2 md:ml-1 min-w-0 flex flex-col">
            <span class="text-xxs lg:text-xs font-semibold text-primary tracking-wide">{{
              name
            }}</span>
          </span>
        </span>
      </a>

      <div v-if="!isFirstStep">
        <UiStepsSeparator />
      </div>
    </div>
  </li>
</template>

<script>
import UiStepsSeparator from "./UiStepsSeparator.vue";

export default {
  name: "UiStepsCurrent",
  components: {
    UiStepsSeparator
  },
  props: {
    name: {
      type: String,
      default: () => "",
      required: true
    },
    order: {
      type: Number,
      default: () => 0,
      required: true
    },
    isFirstStep: {
      type: Boolean,
      default: () => false,
      required: true
    }
  }
};
</script>
