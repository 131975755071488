<template>
  <div id="main">
    <div class="watermark">DRAFT</div>
    <div class="grid justify-items-end">
      <img class="h-40" src="@/assets/images/logos/lb-logo-ai.png" />
    </div>
    <p class="font-bold text-center text-xl">LEASE BOND</p>
    <p class="text-right"><b>Bond No:</b> {{ bondNo }}</p>
    <table class="table-fixed">
      <tr>
        <td class="font-bold">TO:</td>
        <td>
          {{ guaranteeField.get("custodians") }} <b v-if="useTenantAndLandlord">(“the Landlord”)</b>
          <b v-if="!useTenantAndLandlord">(“the Lessor")</b>
        </td>
      </tr>
      <tr>
        <td class="font-bold">FOR:</td>
        <td>
          {{ guaranteeField.get("lessee") }} <b v-if="useTenantAndLandlord">(“the Tenant”)</b>
          <b v-if="!useTenantAndLandlord">(“the Lessee”)</b>
        </td>
      </tr>
      <tr>
        <td class="font-bold">DESCRIPTION:</td>
        <td>{{ guaranteeField.get("details") }} <b>(“the Lease Document”)</b></td>
      </tr>
      <tr>
        <td class="font-bold">BOND VALUE:</td>
        <td>
          <span v-dompurify-html="guaranteeField.get('bondValue')" />
        </td>
      </tr>
    </table>

    <br /><br />
    <p v-dompurify-html="guaranteeField.get('terms')" class="terms rich-text-content" />
  </div>
</template>

<script>
export default {
  name: "Bond",
  props: {
    guarantee: {
      type: Object,
      default: () => {}
    },
    isDraft: {
      type: Boolean,
      default: () => true
    }
  },
  computed: {
    useTenantAndLandlord() {
      //TODO remove hardcoding - this is a temp fix for Fraser's property
      const landlordField = this.guarantee?.fields?.find(f => f.id === "landlord");
      if (landlordField) {
        return landlordField.organizationId === "org83494ea2-8124-4f51-8331-daaead0af30a";
      }
      return false;
    },
    guaranteeField() {
      return new Map(this.guarantee?.fields?.map(f => [f.id, f.value]));
    },
    bondNo() {
      const issueDate = new Date(this.guaranteeField.get("issueDate"));
      const monthString = (issueDate.getUTCMonth() + 1).toString().padStart(2, "0");
      return `${issueDate.getUTCFullYear()}${monthString}-XXXX`;
    }
  }
};
</script>

<style scoped>
td {
  vertical-align: text-top;
  padding-top: 1em;
  min-width: 10em;
}
#main {
  margin: 2cm 2cm 2cm 2cm;
}

.rich-text-content {
  position: relative;
  >>> p {
    @apply mb-2;

    @screen md {
      @apply mb-3;
    }

    &:last-child {
      @apply mb-0;
    }
  }

  >>> ol {
    counter-reset: item;
    list-style: none;

    > li::before {
      content: "(" counter(item, lower-roman) ")";
      counter-increment: item;
      position: absolute;
      left: 30px;
    }

    p {
      display: inline-block;
      margin-left: 20px;
      position: relative;
    }

    > ul {
      list-style-type: circle;
    }
  }

  >>> ul {
    list-style-type: disc;
  }

  >>> li {
    margin-left: 3em;
    @apply mb-2;
  }
}

.watermark {
  transform: rotate(330deg);
  font-size: 14rem;
  color: rgba(255, 0, 0, 0.17);
  position: absolute;
  padding-top: 50%;

  user-select: none;

  @screen md {
    padding-top: 25%;
  }

  @screen lg {
    padding-top: 15%;
  }
}
</style>
