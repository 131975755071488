import { Machine, assign } from "xstate";
import axios from "axios";

const initialState = {
  address: {
    country: "",
    street: "",
    suburb: "",
    state: "",
    postcode: ""
  },
  addressCompletions: [],
  error: ""
};

export default Machine(
  {
    id: "address-search-machine",

    initial: "idle",

    context: {
      ...initialState
    },

    on: {
      ADDRESS_AUTO_COMPLETE: "addressFetchAutoComplete",
      FETCH_ADDRESS_DETAILS: "fetchAddressDetails",
      HIDE_COMPLETIONS: "idle",
      RESET: {
        target: "idle",
        actions: ["resetState", "emitAddressInput"]
      }
    },

    states: {
      idle: {},
      addressFetchAutoComplete: {
        entry: "assignAddressCompletions",
        invoke: {
          id: "addressAutoComplete",
          src: "addressAutoComplete",
          onDone: [
            {
              target: "withAddressCompletions",
              actions: "assignAddressCompletions",
              cond: "hasAddressCompletions"
            },
            {
              target: "withoutAddressCompletions"
            }
          ],
          onError: {
            target: "idle",
            actions: "assignError"
          }
        }
      },
      withAddressCompletions: {},
      withoutAddressCompletions: {},
      fetchAddressDetails: {
        invoke: {
          id: "fetchAddressDetails",
          src: "fetchAddressDetails",
          onDone: {
            target: "idle",
            actions: ["assignAddressDetails", "emitAddressInput"]
          },
          onError: {
            target: "idle",
            actions: "assignError"
          }
        }
      }
    }
  },
  {
    actions: {
      assignAddressCompletions: assign((context, event) => {
        return {
          addressCompletions: event?.data?.completions ?? []
        };
      }),
      assignAddressDetails: assign((context, event) => {
        const { data } = event;

        let street = data.address_line_1 ?? "";
        if (data.address_line_2) street += `, ${data.address_line_2}`;

        return {
          address: {
            ...context.address,
            street,
            suburb: data?.locality_name ?? "",
            state: data?.state_territory ?? "",
            postcode: data?.postcode ?? "",
            country: "AUS"
          },
          addressCompletions: []
        };
      }),
      assignError: assign({
        error: (context, event) => event.data?.response?.data?.errorMessage || event.data?.message
      }),
      emitAddressInput: context => {
        context.emit("input", context.address);
      },
      resetState: assign({
        ...initialState
      })
    },
    guards: {
      hasAddressCompletions: (context, event) => {
        return event?.data?.completions?.length;
      }
    },
    services: {
      addressAutoComplete: (context, event) => {
        const { street } = event;

        return axios
          .get(
            `https://api.addressfinder.io/api/au/address/autocomplete?key=TBMRGK3XE7CUJW8D9VPQ&q=${street}&format=json&paf=1&deliverable=1`
          )
          .then(response => response.data);
      },
      fetchAddressDetails: (context, event) => {
        const addressId = event.data?.addressId;

        return axios
          .get(
            `https://api.addressfinder.io/api/au/address/info?key=TBMRGK3XE7CUJW8D9VPQ&format=json&id=${addressId}&au_paf=1&gps=0`
          )
          .then(response => response.data);
      }
    }
  }
);
