<template>
  <div>
    <div class="flex flex-wrap items-center justify-between mb-4">
      <UiHeadline v-if="name" :size="2" class="mb-1 mr-1 sm:mb-0">{{ title }}</UiHeadline>

      <div class="text-right text-xs text-cool-gray-500 md:text-sm">
        <p v-if="guaranteeNumber">Guarantee #{{ guaranteeNumber }}</p>
        <p v-if="tenantName">{{ tenantName }}</p>
      </div>
    </div>

    <UiInfoPanel
      v-for="(infoPanel, i) in infoPanels"
      :key="i"
      :title="infoPanel.title"
      :content-details="infoPanel.contentDetails"
      :hidden-content="infoPanel.hiddenContent"
      :attachment-url="infoPanel.attachmentUrl"
      class="mb-6"
    />

    <form class="space-y-4 lg:space-y-5" @submit.prevent="onSubmitHandler">
      <ActionSection
        v-for="(section, i) in activeSections"
        :key="`${i}-${$v.$error}`"
        :index="i"
        :title="$get(section, 'title')"
        :description="$get(section, 'description')"
        :collapsible="$get(section, 'collapsible')"
        :error="$v.$error"
      >
        <ActionField
          v-for="(input, index) in section.inputs"
          :key="input.inputId"
          v-model.trim="actionInputs"
          :error="$v.actionInputs[input.inputId].$error"
          :errors="actionInputErrors[input.inputId]"
          :input="input"
          :no-border="i === sections.length - 1 && index === section.inputs.length - 1"
          :form-dirty="$v.$dirty"
          :report-status="reportNestedFormStatus"
          :field-validation-status="$v.actionInputs[input.inputId]"
          @input="saveFormToLocalStorage"
        />
      </ActionSection>

      <div class="flex items-center p-4 md:p-6">
        <UiButton
          type="button"
          theme="ghost"
          class="ml-auto"
          @click="sendToGuaranteeMachine('CANCEL_ACTION')"
        >
          Cancel
        </UiButton>
        <UiButton
          v-if="activeAction && activeAction.allowSave"
          id="save-action"
          theme="outline"
          class="ml-4"
          type="button"
          data-cy="saveAction"
          @click="onSaveHandler"
        >
          Save and Close
        </UiButton>
        <UiButton
          v-if="activeAction && activeAction.allowSubmit"
          id="submit-action"
          theme="primary"
          class="ml-4"
          data-cy="submitAction"
          type="submit"
        >
          {{ next }}
        </UiButton>
      </div>
    </form>
  </div>
</template>

<script>
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import {
  required,
  maxLength,
  maxValue,
  minLength,
  minValue,
  sameAs,
  email
} from "vuelidate/lib/validators";
import { australianGenericPhoneNumber, optionalAustralianPhoneNumber } from "@/utils/validations";
import formMixin from "@/mixins/form";
import UiButton from "@/components/UI/UiButton/UiButton";
import UiHeadline from "@/components/UI/UiHeadline/UiHeadline";
import UiInfoPanel from "@/components/UI/UiInfoPanel/UiInfoPanel";
import ActionSection from "@/components/Actions/ActionSection";
import ActionField from "@/components/Actions/ActionField";
import {
  restoreFormFromLocalStorage,
  saveFormToLocalStorage,
  typeCastByInputType
} from "@/utils/index";

export default {
  name: "Actions",
  components: {
    ActionField,
    ActionSection,
    UiButton,
    UiHeadline,
    UiInfoPanel
  },
  mixins: [formMixin],
  props: {
    activeAction: {
      type: Object,
      default: () => {}
    },
    guaranteeId: {
      type: String,
      default: ""
    },
    guaranteeNumber: {
      type: String,
      default: ""
    },
    tenantName: {
      type: String,
      default: ""
    },
    sendToGuaranteeMachine: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      actionInputs: {},
      actionInputErrors: {},
      nestedFormsStatuses: []
    };
  },
  computed: {
    infoPanels() {
      return this.activeAction?.infoPanels ?? [];
    },
    sections() {
      return this.activeAction?.sections ?? [];
    },
    activeSections() {
      let activeSections = [];
      this.sections?.forEach(section => {
        const sectionActiveInputs = this.filterActiveInputs(section.inputs);
        if (sectionActiveInputs?.length) {
          activeSections.push({
            ...section,
            inputs: sectionActiveInputs
          });
        }
      });
      return activeSections;
    },
    name() {
      return this.activeAction?.name ?? "";
    },
    title() {
      return this.activeAction?.title ?? this.activeAction?.name ?? "";
    },
    next() {
      return this.activeAction?.next ?? "Submit";
    }
  },
  validations() {
    const actionInputs = {};

    this.getActiveInputs(this.activeSections)?.forEach(input => {
      let rules = {};
      const errors = [];
      const booleanInput = input.__typename === "BooleanInputType";

      if (input.isRequired && !booleanInput) {
        rules.required = required;
        errors.push({ type: "required", message: `${input.label} is required.` });
      }

      if (input.isRequired && booleanInput) {
        rules.sameAs = sameAs(() => true);
        errors.push({ type: "required", message: `${input.label} is required.` });
      }

      if (input.minValue || input.minValue === 0) {
        rules.minValue = minValue(input.minValue);
        errors.push({
          type: "minValue",
          message: `${input.label} can't be less than ${input.minValue.toLocaleString("en")}.`
        });
      }

      if (input.maxValue) {
        rules.maxValue = maxValue(input.maxValue);
        errors.push({
          type: "maxValue",
          message: `${input.label} can't be more than ${input.maxValue.toLocaleString("en")}.`
        });
      }

      if (input.minLength) {
        rules.minLength = minLength(input.minLength);
        errors.push({
          type: "minLength",
          message: `${input.label} can't be less than ${input.minLength.toLocaleString(
            "en"
          )} characters.`
        });
      }

      if (input.maxLength) {
        rules.maxLength = maxLength(input.maxLength);
        errors.push({
          type: "maxLength",
          message: `${input.label} can't be more than ${input.maxLength.toLocaleString(
            "en"
          )} characters.`
        });
      }

      if (input.inputType) {
        if (input.inputType === "email") {
          rules.email = email;
          errors.push({ type: "email", message: "Invalid email address." });
        }

        if (input.inputType === "tel") {
          if (input.isRequired) {
            rules = { ...rules, ...australianGenericPhoneNumber };
          } else {
            rules = { ...rules, ...optionalAustralianPhoneNumber };
          }
          errors.push({ type: "numeric", message: `${input.label} must be a number.` });
          errors.push({
            type: "minLength",
            message: `${input.label} can't be less than 10 characters.`
          });
          errors.push({
            type: "maxLength",
            message: `${input.label} can't be more than 13 characters.`
          });
        }
      }
      if (input.__typename === "DocusignInputType") {
        const rule = {};
        if (input.type === "personal") {
          rule.name = { required };
          errors.push({ type: "$each.name.required", message: "Name is required." });
          if (input.lockArray) {
            rule.email = { required };
            errors.push({ type: "$each.email.required", message: "Email is required." });
          }
        } else if (input.type === "entity") {
          rule.name = { required };
          errors.push({ type: "$each.name.required", message: "Entity name is required." });
          rule.director = {
            name: { required: v => !input.lockArray || isNonEmptyString(v) },
            email: { required: v => !input.lockArray || isNonEmptyString(v) }
          };
          errors.push({
            type: "$each.director.name.required",
            message: "Director full name is required."
          });
          errors.push({
            type: "$each.director.email.required",
            message: "Director email is required."
          });
          rule.secretary = {
            required: v =>
              !input.lockArray || isNonEmptyString(v.name) === isNonEmptyString(v.email)
          };
          errors.push({
            type: "$each.secretary.required",
            message: "Secretary full name and email is required together."
          });
        }
        rules.$each = rule;
      }
      function isNonEmptyString(str) {
        return typeof str === "string" && str.length > 0;
      }

      if (input.__typename === "DocusignStatusInputType") {
        rules.isCompleted = {
          completed: sameAs(() => true)
        };
        errors.push({ type: "isCompleted.completed", message: "Signing is not finished." });
      }

      this.actionInputErrors[input.inputId] = errors;

      actionInputs[input.inputId] = rules;
    });

    return { actionInputs };
  },
  created() {
    const storedForm = restoreFormFromLocalStorage("actionsForm") || {};
    const savedForm = this.sections
      .map(section => section.inputs)
      .flat()
      ?.reduce((saved, input) => {
        const value =
          input.value || input.addressValue || input.organizationValue || input.fileValue;
        if (value) {
          saved[input.inputId] = typeCastByInputType(input.__typename, value);
          if (input.__typename === "DocusignInputType") {
            saved[input.inputId].forEach(v => {
              v.id = uuidv4();
            });
          }
        }
        return saved;
      }, {});

    this.actionInputs = {
      ...this.actionInputs,
      ...storedForm,
      ...savedForm
    };
  },
  methods: {
    reportNestedFormStatus(data) {
      const targetInput = this.nestedFormsStatuses.find(x => x.inputId === data.inputId);
      if (targetInput) {
        this.nestedFormsStatuses = this.nestedFormsStatuses.map(f =>
          f.inputId === data.inputId ? data : f
        );
      } else {
        this.nestedFormsStatuses.push(data);
      }
    },
    formatActionFields(data) {
      const inputValues = [];

      for (let [key, value] of Object.entries({ ...data, ...this.uploads })) {
        const InputData = {
          inputId: key,
          value: value
        };

        if (typeof value === "object") {
          const inputType = this.getActiveInputs(this.activeSections)?.find(
            input => input.inputId === key
          );

          if (!inputType) {
            continue;
          }
          const inputTypeValueMap = new Map([
            ["OrganizationInputType", "organizationValue"],
            ["AddressInputType", "addressValue"],
            ["FileInputType", "fileValue"]
          ]);

          const propForInputValue = inputTypeValueMap.get(inputType.__typename);
          if (propForInputValue) {
            InputData[propForInputValue] = value;
            InputData.value = "";
          }
          if (
            inputType?.__typename === "DocusignInputType" ||
            inputType?.__typename === "DocusignStatusInputType"
          ) {
            InputData.value = JSON.stringify(value, (k, v) => (k === "id" ? undefined : v));
          }
        }

        inputValues.push(InputData);
      }

      return inputValues;
    },
    saveFormToLocalStorage(e) {
      saveFormToLocalStorage("actionsForm", this.actionInputs);
    },
    onSaveHandler() {
      // validate using this.validate() from our formMixin...
      const { actionInputs } = this;

      const skipSaveDraftValidationActions = [
        "SubmitDraftApplication",
        "SubmitFacilityApplication",
        "UploadExistingGuarantee"
      ];
      const skipValidation = skipSaveDraftValidationActions.includes(this.activeAction.actionId);
      if (!skipValidation) {
        this.validateForm();
      }
      // If our inputs are valid...
      if (skipValidation || (!this.$v.$invalid && this.nestedInputsValid())) {
        const actionId = this.activeAction?.actionId;
        const inputValues = this.formatActionFields(actionInputs);
        const guaranteeId = this.guaranteeId;

        this.sendToGuaranteeMachine("SAVE_ACTION", {
          actionId,
          guaranteeId,
          inputValues
        });
      }
    },
    onSubmitHandler() {
      this.validateForm();
      const { actionInputs } = this;

      if (!this.$v.$invalid && this.nestedInputsValid()) {
        const actionId = this.activeAction?.actionId;
        const inputValues = this.formatActionFields(actionInputs);
        const guaranteeId = this.guaranteeId;

        this.sendToGuaranteeMachine("SUBMIT_ACTION", {
          actionId,
          guaranteeId,
          inputValues
        });
      }
    },
    filterActiveInputs(inputs) {
      return _.reduce(
        inputs,
        (list, input) => {
          if (
            !input.visibilityConditions?.length ||
            _.every(input.visibilityConditions, cond => {
              const prop = this.actionInputs[cond.property];
              const value = _.find(
                _.values(cond.value),
                val => !_.isUndefined(val) && !_.isNull(val) && val !== ""
              );

              let convertedProp;

              switch (typeof value) {
                case "boolean":
                  convertedProp = !!prop;
                  break;
                case "number":
                  convertedProp = Number(prop || 0);
                  break;
                default:
                  convertedProp = prop || "";
              }

              return cond.condition === "is" ? convertedProp === value : convertedProp !== value;
            })
          ) {
            list.push(input);
          }
          return list;
        },
        []
      );
    },
    getActiveInputs(activeSections) {
      let activeInputs = [];
      activeSections?.forEach(activeSection => {
        if (activeSection?.inputs?.length) {
          activeInputs.push(activeSection.inputs);
        }
      });
      return activeInputs.flat();
    },
    validateForm() {
      this.validate();
      document.querySelectorAll(".error")[0]?.focus();
    },
    nestedInputsValid() {
      return _.every(this.nestedFormsStatuses, f => f.valid);
    }
  }
};
</script>
