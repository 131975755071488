import { Machine, assign } from "xstate";
import { API, graphqlOperation } from "aws-amplify";
import VueScrollTo from "vue-scrollto";
import { Guarantee } from "@/graphql/queries";
import {
  PerformGuaranteeAction,
  SaveActionInputs,
  ResolveAmendment,
  SuggestAmendments
} from "@/graphql/mutations";
import { helperActions, getGraphQlData } from "@/utils/machine-helpers";
import { alertService, organizationService } from "@/main";
import { clearFormFromLocalStorage } from "@/utils/index";
import { isEmpty, find, capitalize } from "lodash";
import { router } from "@/router";

const initialState = {
  activeAction: {},
  errors: [],
  guarantee: {},
  participants: [],
  currentRole: {}
};

export default Machine(
  {
    id: "gm",

    initial: "idle",

    context: {
      ...initialState
    },

    on: {
      LOAD: "loading"
    },

    states: {
      idle: {
        on: {
          RETRY: "loading"
        }
      },
      loading: {
        entry: "resetState",
        invoke: {
          id: "loading",
          src: "guaranteeQuery",
          onDone: [
            {
              target: "viewingAction",
              cond: (_, event) => {
                const autoActiveActions = [
                  "SubmitDraftApplication",
                  "SubmitFacilityApplication",
                  "UploadExistingGuarantee",
                  "AgentSubmitForCreditCheck",
                  "SubmitForCreditCheck",
                  "AuthorizeIntermediary"
                ];
                return !!event.data?.data?.guarantee?.actions?.find(
                  (a, i) => autoActiveActions.includes(a.actionId) && i === 0
                );
              },
              actions: ["assignGuarantee", "scrollToTop"]
            },
            {
              target: "guarantee.active",
              // Only transition to target if the guard (cond) evaluates to true...
              cond: "isActive",
              actions: ["assignGuarantee", "scrollToTop"]
            },
            {
              // If the transition above fails we transition to...
              target: "guarantee.draft",
              actions: ["assignGuarantee", "scrollToTop"]
            }
          ],
          onError: {
            target: "loadError",
            actions: ["assignErrors", "trackErrors", "scrollToTop"]
          }
        }
      },
      guarantee: {
        entry: "checkMessageHash",
        exit: "resetErrors",
        initial: "draft",
        on: {
          RELOAD_GUARANTEE: "loading",
          VIEW_ACTION: "#gm.viewingAction"
        },
        states: {
          active: {
            exit: "resetErrors"
          },
          draft: {
            exit: "resetErrors",
            on: {
              RESOLVE_AMENDMENT: "#gm.resolving",
              SUBMIT_AMENDMENTS: "#gm.amending"
            }
          },
          hist: {
            type: "history"
          }
        }
      },
      viewingAction: {
        entry: ["clearAlert", "assignActiveAction"],
        on: {
          SUBMIT_ACTION: "performingAction",
          SAVE_ACTION: "savingActionInputs",
          CANCEL_ACTION: [
            {
              target: "navigateOut",
              cond: "isGuaranteeEntryStatus"
            },
            { target: "#gm.guarantee.hist" }
          ]
        }
      },
      navigateOut: {
        invoke: {
          src: "navigateToHome"
        }
      },
      savingActionInputs: {
        entry: ["resetErrors", "scrollToTop"],
        invoke: {
          id: "performingSave",
          src: "saveActionInputsMutation",
          onDone: [
            {
              target: "navigateOut",
              cond: "isGuaranteeEntryStatus"
            },
            {
              // If the transition above fails we transition to...
              target: "guarantee.draft",
              // Assign the response to our context...
              actions: [
                "assignGuarantee",
                "sendToAlertService",
                "resetActiveAction",
                "fetchNotifications",
                "clearActionFormFromLocalStorage"
              ]
            }
          ],
          onError: {
            target: "viewingAction",
            actions: ["assignErrors", "trackErrors", "scrollToTop"]
          }
        }
      },
      performingAction: {
        entry: ["resetErrors", "scrollToTop"],
        invoke: {
          id: "performingAction",
          src: "performGuaranteeActionMutation",
          onDone: [
            {
              cond: "hasNextGuarantee",
              actions: ["moveToNextGuarantee"]
            },
            {
              target: "viewingAction",
              cond: (_, event) => {
                console.log("Auto view condition", event);
                const autoActiveActions = [
                  "SubmitDraftApplication",
                  "SubmitFacilityApplication",
                  "UploadExistingGuarantee",
                  "AgentSubmitForCreditCheck",
                  "SubmitForCreditCheck",
                  "AuthorizeIntermediary"
                ];
                return !!event.data?.data?.performGuaranteeAction?.guarantee?.actions?.find(
                  (a, i) => autoActiveActions.includes(a.actionId) && i === 0
                );
              },
              actions: [
                "assignGuarantee",
                "sendToAlertService",
                "resetActiveAction",
                "fetchNotifications",
                "clearActionFormFromLocalStorage"
              ]
            },
            {
              target: "guarantee.active",
              // Only transition to target if the guard (cond) evaluates to true...
              cond: "isActive",
              // Assign the response to our context...
              actions: [
                "assignGuarantee",
                "sendToAlertService",
                "resetActiveAction",
                "fetchNotifications",
                "clearActionFormFromLocalStorage"
              ]
            },
            {
              // If the transition above fails we transition to...
              target: "guarantee.draft",
              // Assign the response to our context...
              actions: [
                "assignGuarantee",
                "sendToAlertService",
                "resetActiveAction",
                "fetchNotifications",
                "clearActionFormFromLocalStorage"
              ]
            }
          ],
          onError: {
            target: "viewingAction",
            actions: ["assignErrors", "trackErrors", "scrollToTop"]
          }
        }
      },
      amending: {
        entry: "scrollToTop",
        invoke: {
          id: "amending",
          src: "suggestAmendmentsMutation",
          onDone: {
            target: "amended",
            actions: [
              "assignGuarantee",
              () =>
                alertService.send("ALERT", {
                  alert: {
                    type: "success",
                    message: "Suggested changes have been sent successfully"
                  }
                })
            ]
          },
          onError: {
            target: "#gm.guarantee.hist",
            actions: ["assignErrors", "trackErrors"]
          }
        }
      },
      amended: {
        // Transition to this state from onDone of amending. Set this state as a global loading state in the guarantee view which should make the guarantee reload. We need a slight delay so the state is actually entered instead of using a null event
        after: {
          10: { target: "guarantee.draft" }
        }
      },
      resolving: {
        entry: "scrollToTop",
        invoke: {
          id: "resolving",
          src: "resolveAmendmentMutation",
          onDone: {
            target: "resolved",
            actions: "assignGuarantee"
          },
          onError: {
            target: "#gm.guarantee.hist",
            actions: ["assignErrors", "trackErrors"]
          }
        }
      },
      resolved: {
        // Transition to this state from onDone of resolving. Set this state as a global loading state in the guarantee view which should make the guarantee reload. We need a slight delay so the state is actually entered instead of using a null event
        after: {
          10: { target: "#gm.guarantee.hist" }
        }
      },
      loadError: {
        on: {
          RELOAD: "loading"
        }
      }
    }
  },
  {
    actions: {
      ...helperActions,
      moveToNextGuarantee: (_, event) => {
        const nextId = getGraphQlData(event, "performGuaranteeAction.actionResponse.guaranteeId");

        router.push({
          name: "NewApplication",
          params: { id: nextId }
        });
      },
      assignActiveAction: assign((context, event) => {
        if (isEmpty(event.action) && isEmpty(context.activeAction)) {
          const guarantee = context.guarantee || event.data.data.guarantee;

          return {
            activeAction: (guarantee?.actions || [])[0]
          };
        }
        return {
          activeAction: event.action || context.activeAction
        };
      }),
      assignGuarantee: assign((context, event) => {
        const { organization } = context;
        const guarantee =
          getGraphQlData(event, "guarantee") ||
          getGraphQlData(event, "resolveAmendment") ||
          getGraphQlData(event, "suggestAmendments") ||
          getGraphQlData(event, "performGuaranteeAction.guarantee") ||
          getGraphQlData(event, "saveActionInputs.guarantee");

        let currentRole;

        const participants = ["landlord", "guarantor", "tenant"].reduce(
          (list, role) => {
            const org = find(guarantee?.agreement?.fields, { id: role });
            if (org) {
              if (org.organizationId === organization.organizationId) {
                currentRole = role;
              }
              list.push({
                key: role,
                label: capitalize(role),
                organization: org
              });
            }
            return list;
          },
          [{ key: "admin", label: "eGuarantee" }]
        );

        if (!currentRole) currentRole = organization.role;

        return {
          guarantee,
          participants,
          currentRole
        };
      }),
      checkMessageHash: (context, event) => {
        // Check if a hash was in the url...
        if (context.urlHash) {
          // Wait for the dom to update...
          setTimeout(() => {
            // Scroll to the hash...
            VueScrollTo.scrollTo(context.urlHash);
          }, 10);
        }
      },
      clearActionFormFromLocalStorage: () => {
        clearFormFromLocalStorage("actionsForm");
      },
      fetchNotifications: () => organizationService.send("FETCH_NOTIFICATIONS"),
      resetActiveAction: assign({
        activeAction: {}
      }),
      resetErrors: assign({
        errors: []
      }),
      resetState: assign({
        ...initialState
      }),
      scrollToTop: () => {
        window.scrollTo(0, 0);
      },
      sendToAlertService: (context, event) => {
        alertService.send("ALERT", {
          alert:
            getGraphQlData(event, "performGuaranteeAction.responseMessage") ||
            getGraphQlData(event, "saveActionInputs.responseMessage")
        });
      },
      clearAlert: (_, event) => {
        alertService.send("CLEAR", {});
      }
    },
    guards: {
      isActive: (context, event) => {
        const statusId =
          getGraphQlData(event, "guarantee.status.statusId") ||
          getGraphQlData(event, "performGuaranteeAction.guarantee.status.statusId");

        return statusId === "ACTIVE";
      },
      hasNextGuarantee: (_, event) => {
        const nextId = getGraphQlData(event, "performGuaranteeAction.actionResponse.guaranteeId");

        return !isEmpty(nextId);
      },
      isGuaranteeEntryStatus: context =>
        ["SubmitDraftApplication", "SubmitFacilityApplication", "UploadExistingGuarantee"].includes(
          context.activeAction.actionId
        )
    },
    services: {
      guaranteeQuery: (context, event) => {
        // Get the guaranteeId from either the event or context...
        let guaranteeId =
          event.guaranteeId || context?.guarantee?.guaranteeId || context?.guaranteeId;

        return API.graphql(
          graphqlOperation(Guarantee, {
            input: { guaranteeId }
          })
        );
      },
      performGuaranteeActionMutation: (context, event) => {
        const { actionId, guaranteeId, inputValues } = event;

        return API.graphql(
          graphqlOperation(PerformGuaranteeAction, {
            input: { actionId, guaranteeId, inputValues }
          })
        );
      },
      saveActionInputsMutation: (context, event) => {
        const { actionId, guaranteeId, inputValues } = event;

        return API.graphql(
          graphqlOperation(SaveActionInputs, {
            input: { actionId, guaranteeId, inputValues }
          })
        );
      },
      resolveAmendmentMutation: (context, event) => {
        const { amendment } = event;

        return API.graphql(
          graphqlOperation(ResolveAmendment, {
            input: { ...amendment }
          })
        );
      },
      suggestAmendmentsMutation: (context, event) => {
        const { amendments, guaranteeId } = event;

        return API.graphql(
          graphqlOperation(SuggestAmendments, {
            input: { amendments, guaranteeId }
          })
        );
      },
      navigateToHome: () => {
        router.push({ name: "Home" });
      }
    }
  }
);
