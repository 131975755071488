<template>
  <Component
    :is="tag || `h${level}`"
    class="leading-tight tracking-tight text-cool-gray-700"
    :class="[
      ...((size || level) === 1 ? ['font-extrabold text-3xl sm:text-4xl md:text-5xl'] : []),
      ...((size || level) === 2 ? ['font-bold text-2xl sm:text-3xl md:text-4xl'] : []),
      ...((size || level) === 3 ? ['font-semibold text-xl sm:text-2xl md:text-3xl'] : []),
      ...((size || level) <= 4 ? ['font-semibold text-xl sm:text-xl md:text-2xl'] : []),
      ...((size || level) <= 5 ? ['font-semibold text-base md:text-lg'] : []),
      ...(underline ? ['border-b border-gray-300 border-solid pb-2'] : [])
    ]"
    data-testid="ui-headline"
    data-cy="ui-headline"
  >
    <slot />
  </Component>
</template>

<script>
export const UiHeadline = {
  name: "UiHeadline",
  props: {
    level: {
      default: 1,
      type: Number,
      validator(value) {
        // Here PurgeCSS picks up the selectors and
        // does not remove their styles from the CSS.
        const headlines = ["h1", "h2", "h3", "h4", "h5", "h6"];
        return headlines.includes(`h${value}`);
      }
    },
    size: {
      default: null,
      type: Number
    },
    tag: {
      default: null,
      type: String
    },
    underline: {
      type: Boolean,
      default: () => false
    }
  }
};

export default UiHeadline;
</script>
