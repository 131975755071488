import Vue from "vue";
import get from "just-safe-get";
import { logger } from "@/utils/index";
import { isDev, isLocal, isProduction } from "@/utils/constants";

// Logging for development...
Object.defineProperty(Vue.prototype, "$logger", {
  value: logger
});

// Use just-safe-get inside our components...
Object.defineProperty(Vue.prototype, "$get", {
  value: get
});

Vue.config.productionTip = isDev;

// Clear the console if we're hot reloading...
if (isLocal && module.hot) {
  module.hot.accept(); // already had this init code

  module.hot.addStatusHandler(status => {
    if (status === "prepare") console.clear();
  });
}

if (!isProduction) {
  console.group("Environment");
  console.log("%c VUE_APP_ENV", "color: #ffa600", process.env.VUE_APP_ENV);
  console.log("%c NODE_ENV", "color: #ffa600", process.env.NODE_ENV);
  console.groupEnd();
}
