<template>
  <div>
    <UiLoader v-if="loading" />

    <UiAlert v-if="state.context.error" theme="warning" title="Error">
      {{ state.context.error }}
    </UiAlert>

    <UiAlert v-if="state.context.message" :title="state.context.message" theme="success" />
    <UiAlert v-if="state.context.notice.title" :title="state.context.notice.title" theme="notice">
      {{ state.context.notice.message }}
    </UiAlert>

    <UpdatePasswordForm
      v-if="state.value === 'idle'"
      :loading="loading"
      @submit="updatePasswordHandler"
      @new-reset-link="newResetLink"
    />

    <ConfirmForm v-if="state.value === 'confirm'" @submit="confirmSubmitHandler" />
    <BackButton v-if="state.value === 'resentLink'" @submit="backToSignIn" />
  </div>
</template>

<script>
import { useMachine } from "@xstate/vue";
import { router } from "@/router";
import { computed } from "@vue/composition-api";
import resetPasswordMachine from "./resetpassword-machine";
import UpdatePasswordForm from "@/components/Auth/ResetPassword/UpdatePasswordForm";
import ConfirmForm from "@/components/Auth/SignIn/ConfirmForm";
import BackButton from "@/components/Auth/SignIn/BackButton";
import UiAlert from "@/components/UI/UiAlert/UiAlert";
import UiLoader from "@/components/UI/UiLoader/UiLoader";
import { watchState } from "@/utils/machine-helpers";

export default {
  name: "ResetPassword",
  components: {
    BackButton,
    ConfirmForm,
    UiAlert,
    UiLoader,
    UpdatePasswordForm
  },
  setup() {
    // Setup our state machine...
    const { state, send } = useMachine(resetPasswordMachine);
    watchState(state, "ResetPassword");

    const loading = computed(() =>
      ["authenticating", "confirming", "authenticated", "resending", "updatingPassword"].includes(
        state.value.value
      )
    );

    // Return what we want to expose to the template...
    return {
      loading,
      send,
      state
    };
  },
  mounted() {
    this.checkForAlert();
  },
  methods: {
    checkForAlert() {
      if (this.$route?.params?.error) {
        this.state.context.error = this.$route?.params?.error;
      }
    },
    newResetLink(data) {
      this.send({ type: "RESEND", data });
    },
    updatePasswordHandler(data) {
      this.send({ type: "UPDATE", data });
    },
    confirmSubmitHandler(data) {
      this.send({ type: "CONFIRM", data });
    },
    backToSignIn() {
      router.push({ name: "SignIn" });
    }
  }
};
</script>
