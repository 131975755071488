<template>
  <div @keyup.enter="onSubmit">
    <UiHeadline :size="4" class="mb-4">Forgot Password</UiHeadline>

    <p class="mb-4 text-sm text-gray-500">
      Enter the email address you registered with and we'll reset a password reset link.
    </p>

    <UiInput
      v-model.trim="form.email"
      label="Email"
      :error="$v.form.email.$error"
      placeholder="Enter your email"
      class="mb-4"
    >
      <template v-if="$v.form.email.$error" slot="errors">
        <p v-if="!$v.form.email.required">
          Email is required.
        </p>

        <p v-if="!$v.form.email.email">
          Valid email address required.
        </p>
      </template>
    </UiInput>

    <div class="flex flex-wrap items-center">
      <UiInput is="UiButton" theme="primary" @click="onSubmit">Reset</UiInput>
    </div>
  </div>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";
import formMixin from "@/mixins/form";
import UiButton from "@/components/UI/UiButton/UiButton";
import UiHeadline from "@/components/UI/UiHeadline/UiHeadline";
import UiInput from "@/components/UI/UiInput/UiInput";

export default {
  name: "ForgotPasswordForm",
  components: { UiButton, UiHeadline, UiInput },
  mixins: [formMixin],
  props: {
    email: {
      type: String,
      default: ""
    }
  },
  data(props) {
    return {
      form: {
        email: props.email
      }
    };
  },
  methods: {
    onSubmit() {
      // validate using this.validate() from our formMixin...
      this.validate();

      if (!this.$v.$invalid) {
        const { email } = this.form;

        this.$emit("submit", { email });
      }
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      }
    }
  }
};
</script>
