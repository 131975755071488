<template>
  <div class="notifications-wrap">
    <transition name="fade">
      <div v-if="active" class="notifications-background"></div>
    </transition>

    <transition name="slideIn">
      <div
        v-if="active"
        v-hotkey="{
          esc: onClose
        }"
        v-click-outside="onClose"
        class="notifications"
      >
        <div v-if="notificationsReady && notifications.length" class="space-y-4">
          <NotificationCard
            v-for="notification in notifications"
            :key="notification.id"
            :title="notification.title"
            :date="notification.notificationDate"
            :description="notification.description"
            :subtitle="notification.subtitle"
            :notification-id="notification.notificationId"
            :profile-pic="notification.profilePic"
            :guarantee-id="notification.guaranteeId"
            :message-id="notification.messageId"
            :unread="notification.isUnread"
            :send-to-organization-service="sendToOrganizationService"
            :dismissing="dismissingId === notification.notificationId"
            @close="onClose"
          />
        </div>

        <UiAlert
          v-if="notificationsReady && !notifications.length"
          theme="info"
          title="No notifications. You're up to date."
        />
      </div>
    </transition>
  </div>
  <!-- .notifications-background -->
</template>

<script>
import { useService } from "@xstate/vue";
import { computed } from "@vue/composition-api";
import { organizationService } from "@/main";
import { watchState } from "@/utils/machine-helpers";
import NotificationCard from "@/components/Cards/NotificationCard/NotificationCard";
import UiAlert from "@/components/UI/UiAlert/UiAlert";

export default {
  name: "Notifications",
  components: { NotificationCard, UiAlert },
  setup() {
    // Setup our state machine...
    const { state: organizationState, send: sendToOrganizationService } = useService(
      organizationService
    );

    watchState(organizationState, "Organization");

    const active = computed(() => organizationState.value.matches("ready.notificationsActive"));

    const notifications = computed(
      () => organizationState?.value?.context?.organization?.notifications ?? []
    );

    const dismissingId = computed(() => organizationState?.value?.context?.dismissingId ?? "");

    const notificationsReady = computed(
      () =>
        organizationState.value.matches("ready.notificationsActive.idle") ||
        organizationState.value.matches("ready.notificationsActive.dismissing")
    );

    // Return what we want to expose to the template...
    return {
      active,
      dismissingId,
      organizationState,
      notifications,
      notificationsReady,
      sendToOrganizationService
    };
  },
  methods: {
    onClose() {
      this.sendToOrganizationService("HIDE_NOTIFICATIONS");
    }
  }
};
</script>

<style scoped>
.notifications-wrap {
  @apply relative;
  z-index: 5;
}

.notifications-background {
  @apply min-h-screen w-full absolute;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.notifications {
  @apply w-full h-screen absolute border-t-2 border-gray-200 shadow p-4 right-0 overflow-scroll pb-20;
  background: #fbfbfb;
  z-index: 1;

  @screen sm {
    @apply max-w-sm;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.6s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slideIn-enter-active,
.slideIn-leave-active {
  transition: right 0.4s ease-in-out;
}

.slideIn-enter,
.slideIn-leave-to {
  right: -24rem;
}
</style>
