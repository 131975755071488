<template>
  <UiCard data-testid="stats-card" class="stats-card" theme="primary">
    <div class="flex items-center sm:block">
      <slot name="data">
        <div v-if="data" class="stats-card-value">
          {{ data }}
        </div>
      </slot>

      <div>
        <slot name="label">
          <p v-if="label" class="stats-card-label">
            {{ label }}
            <span v-if="showIndicator" class="ml-1 stats-card-indicator"
              ><AlertTriangleIcon size="1.2x"
            /></span>
            <!-- <span v-if="showIndicator" class="stats-card-indicator">!</span> -->
          </p>
        </slot>
        <slot name="meta">
          <p v-if="meta" class="stats-card-meta">
            {{ meta }}
            <slot name="action" />
          </p>
        </slot>
      </div>
    </div>
    <!-- .flex items-center -->
  </UiCard>
</template>

<script>
import { AlertTriangleIcon } from "vue-feather-icons";
import UiCard from "@/components/UI/UiCard/UiCard";

export default {
  name: "DataCard",
  components: { AlertTriangleIcon, UiCard },
  props: {
    data: {
      type: String,
      default: ""
    },
    meta: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    showIndicator: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
/* purgecss start ignore */
/* Base Styles */
.stats-card {
  @apply flex items-center justify-center w-full h-full;
}

.stats-card-value {
  @apply pr-3 text-green-500 font-black text-2xl;

  @screen sm {
    @apply pr-0 text-3xl;
  }

  @screen md {
    @apply text-4xl leading-10;
  }

  @screen lg {
    @apply text-5xl leading-tight;
  }
}

.stats-card-label {
  @apply text-cool-gray-600 text-xs font-semibold flex items-center;

  @screen md {
    @apply text-sm;
  }
}

.stats-card-meta {
  @apply text-gray-500 text-xs font-light;

  >>> a,
  >>> button {
    @apply text-primary;
  }

  @screen md {
    @apply text-xs;
  }
}

.stats-card-indicator {
  @apply text-red-600;
}

>>> .ui-card-wrap {
  @apply h-full flex items-baseline w-full;
}

>>> .ui-card-content {
  @screen sm {
    @apply p-2;
  }

  @screen md {
    @apply p-4;
  }

  @screen lg {
    @apply p-6;
  }
}
/* purgecss end ignore */
</style>
