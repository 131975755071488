<template>
  <Auth>
    <Registration />
  </Auth>
</template>

<script>
import Auth from "@/views/auth/Auth";
import Registration from "@/components/Auth/Registration/Registration";

export default {
  name: "RegisterView",
  components: { Auth, Registration },
  metaInfo: {
    title: "Register"
  }
};
</script>
