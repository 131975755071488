<template>
  <div class="ui-field" :class="classObject">
    <div v-if="label" class="w-full mb-2 sm:w-gr-small sm:mb-0">
      <slot name="label">
        <label :for="name" class="ui-field-label">{{ label }}</label>
      </slot>
    </div>

    <div :class="label ? 'w-full sm:w-gr-large' : 'w-full'">
      <div class="flex flex-wrap">
        <div class="w-full" :class="{ 'sm:w-gr-large': !fullWidthMeta }">
          <slot>
            <p v-if="value" class="ui-field-value">
              {{ value }}
            </p>
          </slot>
          <p v-if="info" v-dompurify-html="info" class="ui-field-info" />
        </div>
        <!-- .w-full sm:w-gr-large -->

        <div v-if="!fullWidthMeta" class="ui-field-meta">
          <slot name="meta" />
        </div>
        <!-- .w-full sm:w-gr-small -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UiField",
  props: {
    label: {
      type: String,
      default: ""
    },
    fullWidthMeta: {
      type: Boolean,
      default: false
    },
    info: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    theme: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    padding: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    classObject() {
      return {
        [this.theme]: this.theme,
        "no-border": this.noBorder
      };
    }
  }
};
</script>

<style scoped>
/* Base Styles */
.ui-field {
  @apply flex flex-wrap items-baseline p-2;

  &.secondary {
    @apply border-b border-gray-200 p-3;

    @screen md {
      @apply p-6;
    }

    &.no-border {
      @apply border-none;
    }
  }

  &.no-padding {
    @apply p-0 pb-4;
  }
}

.ui-field-label {
  @apply text-xs text-gray-400;

  @screen md {
    @apply text-sm;
  }
}

.ui-field-value {
  @apply text-sm text-gray-500 leading-relaxed whitespace-no-wrap;
}

.ui-field-info {
  @apply text-xs font-light text-gray-500 pl-3.5 mt-2;

  @screen md {
    @apply mt-4;
    padding: 0 0.85rem;
  }
}

.ui-field-meta {
  @apply w-full mt-2 text-xs font-light text-gray-500 pl-1.5;

  @screen sm {
    @apply mt-0 w-gr-small pl-2 pt-2.5;
  }
}
</style>
