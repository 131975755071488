<template>
  <div class="h-full">
    <!-- Intermediary Info -->
    <div
      v-if="state.matches('intermediaryInput')"
      class="flex flex-col justify-between p-10 h-full"
    >
      <div class="flex flex-col items-end w-full">
        <h1
          :size="2"
          class="text-newsecondary font-bold text-2xl sm:text-3xl md:text-4xl text-center sm:text-right"
        >
          Refer your client and you could earn $$$
        </h1>
        <p class="text-newwhite text-center sm:text-right">
          Refer a tenant through our referral program and<br />
          if we provide them a lease bond, we'll reward you<br />
          in proportion to the size of the bond!
        </p>
      </div>
      <div class="w-full sm:w-1/2 p-0 md:px-10 lg:px-24 mt-4">
        <p class="text-newwhite font-bold text-xl pl-1">First, let's hear about you</p>
        <div class="flex flex-auto gap-2 flex-col sm:flex-row my-4">
          <UiInput
            v-model.trim="state.context.intermediaryInfo.firstname"
            class="flex-grow"
            type="text"
            name="IntermediaryName"
            placeholder="Your first name"
            :on-enter-key="() => onSubmitIntermediaryInfo()"
            :error="$v.state.context.intermediaryInfo.firstname.$error"
          >
            <template v-if="$v.state.context.intermediaryInfo.firstname.$error" slot="errors">
              <p v-if="!$v.state.context.intermediaryInfo.firstname.required">
                First name is required.
              </p>
            </template>
            <template slot="label">
              <span class="text-newwhite">First name</span>
              <span class="text-red-500 text-xl"> *</span>
            </template>
          </UiInput>
          <UiInput
            v-model.trim="state.context.intermediaryInfo.lastname"
            class="flex-grow"
            type="text"
            name="IntermediaryName"
            placeholder="Your last name"
            :on-enter-key="() => onSubmitIntermediaryInfo()"
            :error="$v.state.context.intermediaryInfo.lastname.$error"
          >
            <template v-if="$v.state.context.intermediaryInfo.lastname.$error" slot="errors">
              <p v-if="!$v.state.context.intermediaryInfo.lastname.required">
                Last name is required.
              </p>
            </template>
            <template slot="label">
              <span class="text-newwhite">Last name</span>
              <span class="text-red-500 text-xl"> *</span>
            </template>
          </UiInput>
        </div>
        <UiInput
          v-model.trim="state.context.intermediaryInfo.phone"
          class="flex-grow mt-4"
          type="text"
          name="IntermediaryPhone"
          placeholder="0400 000 000"
          :on-enter-key="() => onSubmitIntermediaryInfo()"
          :error="$v.state.context.intermediaryInfo.phone.$error"
          @input="trimPhoneNumber('state.context.intermediaryInfo.phone', $event)"
        >
          <template v-if="$v.state.context.intermediaryInfo.phone.$error" slot="errors">
            <p
              v-if="
                !$v.state.context.intermediaryInfo.phone.minLength ||
                  !$v.state.context.intermediaryInfo.phone.maxLength
              "
            >
              A valid phone number is required.
            </p>
            <p v-if="!$v.state.context.intermediaryInfo.phone.noEmail">
              A valid email or phone number is required.
            </p>
          </template>
          <template slot="label">
            <span class="text-newwhite">Mobile phone</span>
            <span class="text-red-500 text-xl"> *</span>
          </template>
        </UiInput>
        <UiInput
          v-model.trim="state.context.intermediaryInfo.email"
          class="mt-4 flex-grow"
          type="text"
          name="IntermediaryEmail"
          placeholder="you@company.com"
          :on-enter-key="() => onSubmitIntermediaryInfo()"
          :error="$v.state.context.intermediaryInfo.email.$error"
        >
          <template v-if="$v.state.context.intermediaryInfo.email.$error" slot="errors">
            <p v-if="!$v.state.context.intermediaryInfo.email.email">
              A valid Company email is required.
            </p>
            <p v-if="!$v.state.context.intermediaryInfo.email.noPhone">
              A valid email or phone number is required.
            </p>
          </template>
          <template slot="label">
            <span class="text-newwhite">Company email</span>
            <span class="text-red-500 text-xl"> *</span>
          </template>
        </UiInput>
        <div class="mt-16">
          <UiButton
            button-class="px-10"
            type="button"
            theme="primary"
            @click="onSubmitIntermediaryInfo()"
          >
            <span class="font-light">Next</span>
            <font-awesome-icon class="pl-5" icon="fa-solid fa-arrow-right" inverse
          /></UiButton>
        </div>
      </div>
      <div class="flex justify-center sm:justify-end w-full gap-2 mt-4">
        <div class="bg-newsecondary rounded-full h-4 w-4"></div>
        <div class="bg-newgrey rounded-full h-4 w-4"></div>
        <div class="bg-newgrey rounded-full h-4 w-4"></div>
      </div>
    </div>

    <!-- Tenant Input -->
    <div v-if="state.matches('tenantInput')" class="flex flex-col justify-between p-10 h-full">
      <div class="flex flex-col items-end w-full">
        <h1
          :size="2"
          class="text-newsecondary font-bold text-2xl sm:text-3xl md:text-4xl text-center sm:text-right"
        >
          Refer your client and you could earn $$$
        </h1>
        <p class="text-newwhite text-center sm:text-right">
          Refer a tenant through our referral program and<br />
          if we provide them a lease bond, we'll reward you<br />
          in proportion to the size of the bond!
        </p>
      </div>
      <div class="w-full sm:w-1/2 p-0 md:px-10 lg:px-24 mt-4">
        <p class="text-newwhite font-bold text-xl pl-1">About your client</p>
        <div class="flex flex-auto gap-2 flex-col sm:flex-row mt-4">
          <UiInput
            v-model.trim="state.context.tenantInfo.firstname"
            class="flex-grow"
            type="text"
            name="TenantFirstName"
            placeholder="First name"
            :on-enter-key="() => onSubmitTenantInfo()"
            :error="$v.state.context.tenantInfo.firstname.$error"
          >
            <template v-if="$v.state.context.tenantInfo.firstname.$error" slot="errors">
              <p v-if="!$v.state.context.tenantInfo.firstname.required">
                First name is required.
              </p>
            </template>
            <template slot="label">
              <span class="text-newwhite">First name</span>
              <span class="text-red-500 text-xl"> *</span>
            </template>
          </UiInput>
          <UiInput
            v-model.trim="state.context.tenantInfo.lastname"
            class="flex-grow"
            type="text"
            name="TenantLastName"
            placeholder="Last name"
            :on-enter-key="() => onSubmitTenantInfo()"
            :error="$v.state.context.tenantInfo.lastname.$error"
          >
            <template v-if="$v.state.context.tenantInfo.lastname.$error" slot="errors">
              <p v-if="!$v.state.context.tenantInfo.lastname.required">
                Last name is required.
              </p>
            </template>
            <template slot="label">
              <span class="text-newwhite">Last name</span>
              <span class="text-red-500 text-xl"> *</span>
            </template>
          </UiInput>
        </div>
        <UiInput
          v-model.trim="state.context.tenantInfo.companyName"
          class="mt-4"
          type="text"
          name="Company Name"
          placeholder="Tenant's company name"
          :on-enter-key="() => onSubmitTenantInfo()"
          :error="$v.state.context.tenantInfo.companyName.$error"
        >
          <template v-if="$v.state.context.tenantInfo.companyName.$error" slot="errors">
            <p v-if="!$v.state.context.tenantInfo.companyName.required">
              Company name is required
            </p>
          </template>
          <template slot="label">
            <span class="text-newwhite">Company name</span>
            <span class="text-red-500 text-xl"> *</span>
          </template>
        </UiInput>
        <UiInput
          v-model.trim="state.context.tenantInfo.phone"
          class="mt-4 flex-grow"
          type="text"
          name="TenantPhone"
          placeholder="0410 000 000"
          :on-enter-key="() => onSubmitTenantInfo()"
          :error="$v.state.context.tenantInfo.phone.$error"
        >
          <template v-if="$v.state.context.tenantInfo.phone.$error" slot="errors">
            <p v-if="!$v.state.context.tenantInfo.phone">
              A valid phone number is required.
            </p>
          </template>
          <template slot="label">
            <span class="text-newwhite">Tenant contact number</span>
            <span class="text-red-500 text-xl"> *</span>
          </template>
        </UiInput>
        <UiInput
          v-model.trim="state.context.tenantInfo.email"
          class="mt-4 flex-grow"
          type="text"
          name="TenantEmail"
          placeholder="tenant@company.com"
          :on-enter-key="() => onSubmitTenantInfo()"
          :error="$v.state.context.tenantInfo.email.$error"
        >
          <template v-if="$v.state.context.tenantInfo.email.$error" slot="errors">
            <p v-if="!$v.state.context.tenantInfo.email.required">
              A valid company email is required.
            </p>
          </template>
          <template slot="label">
            <span class="text-newwhite">Tenant company email</span>
            <span class="text-red-500 text-xl"> *</span>
          </template>
        </UiInput>
        <div class="mt-16">
          <UiButton
            button-class="px-10"
            type="button"
            theme="primary"
            @click="onSubmitTenantInfo()"
          >
            <span class="font-normal">Next</span>
            <font-awesome-icon class="pl-5" icon="fa-solid fa-arrow-right" inverse
          /></UiButton>
        </div>
      </div>
      <div class="flex justify-center sm:justify-end w-full gap-2 mt-4">
        <div class="bg-newsecondary rounded-full h-4 w-4"></div>
        <div class="bg-newsecondary rounded-full h-4 w-4"></div>
        <div class="bg-newgrey rounded-full h-4 w-4"></div>
      </div>
    </div>

    <!-- Landlord Info -->
    <div
      v-if="state.matches('landlordInput') || state.matches('submittingReferral')"
      class="flex flex-col justify-between p-10 h-full"
    >
      <div class="flex flex-col items-end w-full">
        <h1
          :size="2"
          class="text-newsecondary font-bold text-2xl sm:text-3xl md:text-4xl text-center sm:text-right"
        >
          Refer your client and you could earn $$$
        </h1>
        <p class="text-newwhite text-center sm:text-right">
          Refer a tenant through our referral program and<br />
          if we provide them a lease bond, we'll reward you<br />
          in proportion to the size of the bond!
        </p>
      </div>
      <div class="w-full sm:w-1/2 p-0 md:px-10 lg:px-24 mt-4">
        <p class="text-newwhite font-bold text-xl pl-1">
          About the deal <span class="font-bold">(optional)</span>
        </p>
        <UiInput
          v-model.trim="state.context.landlordInfo.name"
          class="flex-grow"
          type="text"
          name="LandlordName"
          placeholder="Landlord name"
          :disabled="state.matches('submittingReferral')"
          :on-enter-key="() => onSubmitLandlordInfo()"
        >
          <template slot="label"><span class="text-newwhite">Landlord name</span></template>
        </UiInput>
        <UiInput
          v-model.trim="state.context.landlordInfo.leaseAddress"
          class="mt-4 flex-grow"
          type="text"
          name="LandlordLeaseAddress"
          :disabled="state.matches('submittingReferral')"
          :on-enter-key="() => onSubmitLandlordInfo()"
        >
          <template slot="label"><span class="text-newwhite">Lease address</span></template>
        </UiInput>
        <div class="flex flex-auto gap-2 flex-col sm:flex-row mt-4">
          <UiInput
            v-model.trim="state.context.landlordInfo.leaseValue"
            class="flex-2/3"
            type="number"
            name="LandlordLeaseValue"
            :disabled="state.matches('submittingReferral')"
            :on-enter-key="() => onSubmitLandlordInfo()"
          >
            <template slot="leadingAddon">$</template>
            <template slot="label"
              ><span class="text-newwhite">Lease security value</span></template
            >
          </UiInput>
        </div>
        <UiCheckbox
          v-model="state.context.landlordInfo.termsAccepted"
          class="mt-16"
          checkbox-type="tickbox"
          size="sm"
          :disabled="state.matches('submittingReferral')"
          :error="$v.state.context.landlordInfo.termsAccepted.$error"
          ><template slot="label"
            ><p class="text-newwhite text-sm">
              I agree to the
              <span class="cursor-pointer font-bold underline" @click="openTermsWindow"
                >terms & conditions</span
              >
            </p></template
          >
          <template v-if="$v.state.context.landlordInfo.termsAccepted.$error" slot="errors">
            <p>
              Please accept and agree to the terms & conditions
            </p>
          </template>
        </UiCheckbox>
        <div class="mt-4">
          <UiButton
            button-class="px-10"
            type="button"
            theme="primary"
            :disabled="state.matches('submittingReferral')"
            @click="onSubmitLandlordInfo()"
          >
            <LoaderIcon
              v-if="state.matches('submittingReferral')"
              size="1.3x"
              class="spin text-newwhite"
            />
            <span v-else class="font-normal">Submit</span>
          </UiButton>
          <div class="mt-3">
            <p class="text-newgrey text-sm">
              By submitting this form you are consenting to receive marketing communications from us
              in future, on the understanding that you have read and agree to our terms &
              conditions.
            </p>
          </div>
        </div>
      </div>
      <div class="flex justify-center sm:justify-end w-full gap-2 mt-4">
        <div class="bg-newsecondary rounded-full h-4 w-4"></div>
        <div class="bg-newsecondary rounded-full h-4 w-4"></div>
        <div class="bg-newsecondary rounded-full h-4 w-4"></div>
      </div>
    </div>
    <!-- end form -->

    <div v-if="state.matches('done')" class="flex flex-col justify-between h-full">
      <ThankYou
        subheader="for your referral"
        description="Our Customer Success team will contact your client within 1 business day. We will be in touch to let you know the outcome of the initial discussion."
      ></ThankYou>
    </div>
  </div>
</template>

<script>
import { LoaderIcon } from "vue-feather-icons";
import { useMachine } from "@xstate/vue";
import {
  required,
  requiredIf,
  minLength,
  maxLength,
  email as emailValidator,
  helpers
} from "vuelidate/lib/validators";

import formMixin from "@/mixins/form";
import ReferralFormMachine from "./referral-machine";

import UiButton from "@/components/UI/UiButton/UiButton";
import UiCheckbox from "@/components/UI/UiCheckbox/UiCheckbox";
import UiInput from "@/components/UI/UiInput/UiInput";
import ThankYou from "@/components/ThankYou/ThankYou.vue";

export default {
  name: "ReferralForm",
  components: {
    LoaderIcon,
    UiButton,
    UiCheckbox,
    UiInput,
    ThankYou
  },
  mixins: [formMixin],
  setup() {
    const { state, send } = useMachine(ReferralFormMachine);

    return {
      state,
      send
    };
  },
  methods: {
    trimPhoneNumber(propName, value) {
      const regexp = /\d+/g;
      const trimmedValue = [...value.matchAll(regexp)].join("");
      propName.split(".").reduce((prev, cur, index, arr) => {
        if (index === arr.length - 1) {
          prev[cur] = trimmedValue;
        } else {
          return prev[cur];
        }
      }, this);
    },
    onSubmitIntermediaryInfo() {
      this.validate();

      if (!this.$v.state.context.intermediaryInfo.$invalid) {
        this.$v.state.context.tenantInfo.$reset();
        this.send({ type: "TENANT_INPUT" });
      }
    },
    onSubmitTenantInfo() {
      this.validate();

      if (!this.$v.state.context.tenantInfo.$invalid) {
        this.$v.state.context.landlordInfo.$reset();
        this.send({ type: "LANDLORD_INPUT" });
      }
    },
    onSubmitLandlordInfo() {
      this.validate();

      if (!this.$v.state.context.landlordInfo.$invalid) {
        this.send({ type: "SUBMITTING_REFERRAL" });
      }
    },
    openTermsWindow() {
      window.open("https://www.eguarantee.com.au/terms", "_blank");
    }
  },
  validations() {
    return {
      state: {
        context: {
          intermediaryInfo: {
            firstname: { required },
            lastname: { required },
            email: {
              noPhone: requiredIf(context => {
                return !context.phone;
              }),
              email: emailValidator
            },
            phone: {
              minLength: minLength(3),
              maxLength: maxLength(17),
              noEmail: requiredIf(context => {
                return !context.email;
              })
            }
          },
          tenantInfo: {
            firstname: { required },
            lastname: { required },
            companyName: { required },
            email: {
              required,
              email: emailValidator
            },
            phone: {
              required,
              minLength: minLength(3),
              maxLength: maxLength(17)
            }
          },
          landlordInfo: {
            name: {},
            termsAccepted: { checked: value => value === true }
          }
        }
      }
    };
  }
};
</script>

<style scoped>
/* purgecss start ignore */

/* purgecss end ignore */
</style>
