<template>
  <UiBaseInput
    :name="$attrs.name"
    type="select"
    :size="labelSize"
    :label="label"
    :error="error"
    :loading="loading"
    :class="{ loading }"
  >
    <select
      :multiple="mulitple"
      class="block w-full form-select ui-select"
      :class="inputClass"
      data-testid="ui-select"
      :value="value"
      :disabled="loading || disabled"
      v-bind="$attrs"
      @input="$emit('input', $event.target.value)"
    >
      <option v-if="placeholder" disabled selected value="">{{ placeholder }}</option>
      <option
        v-for="option in options"
        :key="labelKey ? option.label : option.value"
        :value="option.value"
        >{{ option.label }}</option
      >
    </select>

    <slot slot="label" name="label" />
    <slot slot="errors" name="errors" />
  </UiBaseInput>
</template>

<script>
import UiBaseInput from "@/components/UI/UiBaseInput/UiBaseInput";

export default {
  name: "UiSelect",
  components: { UiBaseInput },
  inheritAttrs: false,
  props: {
    inputStyle: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    labelSize: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: ""
    },
    mulitple: {
      type: Boolean,
      default: false
    },
    labelKey: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputClass() {
      return {
        [this.inputStyle]: this.inputStyle,
        error: this.error,
        loading: this.loading,
        placeholder: !this.value
      };
    }
  }
};
</script>

<style scoped>
.ui-select {
  @apply text-xs;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  &:disabled {
    @apply opacity-50;
  }

  &.placeholder {
    @apply text-gray-400;
  }

  &.loading {
    background-image: none;

    >>> .ui-base-input-loader {
      top: 12px;
    }
  }

  &.error {
    @apply border-red-300 text-red-900 pr-2;
    background-position: right 0.5rem center;

    &:focus {
      @apply border-red-300 shadow-outline-red;
    }
  }

  @screen md {
    @apply text-sm;
  }
}

.loading {
  >>> .ui-base-input-loader {
    top: 12px;
  }
}
</style>
