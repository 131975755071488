<template>
  <div @keyup.enter="onSubmit">
    <UiHeadline :size="4" class="mb-4">Confirm Account</UiHeadline>

    <p class="mb-4 text-sm text-gray-500">
      You've been sent an SMS to confirm your account details before signing in.
    </p>

    <UiInput
      v-model.trim="form.code"
      name="code"
      label="Code"
      class="mb-4"
      :error="$v.form.code.$error"
    >
      <template v-if="$v.form.code.$error" slot="errors">
        <p v-if="!$v.form.code.required">
          A code is required.
        </p>
      </template>
    </UiInput>

    <div class="flex gap-1">
      <UiInput is="UiButton" theme="primary" @click="onSubmit">Submit</UiInput>
      <v-popover slot="meta" offset="16">
        <UiButton theme="link" class="text-xxs text-gray-500">Didn't get a Code?</UiButton>
        <template slot="popover">
          <a v-close-popover class="tooltip-close">x</a>
          <p>
            We don't store your password and we can't request a new code without it. If you didn't
            receive a code, please click below to restart the sign in process.
          </p>
          <a href="/sign-in">
            <p style="color: #6DDD83">Restart Sign In</p>
          </a>
        </template>
      </v-popover>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import formMixin from "@/mixins/form";
import UiButton from "@/components/UI/UiButton/UiButton";
import UiHeadline from "@/components/UI/UiHeadline/UiHeadline";
import UiInput from "@/components/UI/UiInput/UiInput";

export default {
  name: "ConfirmForm",
  components: { UiButton, UiHeadline, UiInput },
  mixins: [formMixin],
  data() {
    return {
      form: {
        code: ""
      }
    };
  },
  methods: {
    onSubmit() {
      // validate using this.validate() from our formMixin...
      this.validate();

      if (!this.$v.$invalid) {
        const { code } = this.form;

        this.$emit("submit", { code });
      }
    }
  },
  validations: {
    form: {
      code: {
        required
      }
    }
  }
};
</script>
