<template>
  <div class="w-full flex flex-col justify-between">
    <ChatRecipientsSelector
      v-if="showRecipientsSelector"
      :recipient-options="recipientOptions"
      @create-new-group="confirmCreateNewGroup"
      @clear="onCancel"
    />

    <portal to="recipientsSelectorPortal">
      <div class="text-center ">
        <button
          type="button"
          :class="
            `mt-4 inline-flex text-sm items-center px-3 py-2 border border-transparent shadow-sm  font-medium rounded-md  bg-gray-200 text-primary hover:bg-gray-300  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${
              false ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
            }`
          "
          :disabled="false"
          @click="onNewChatGroupClick"
        >
          <PlusCircleIcon />
          <span class="ml-1">New Group</span>
        </button>
      </div>
    </portal>

    <ChatMessageList
      :messages="messages"
      :send-to-guarantee-machine="sendToGuaranteeMachine"
      :guarantee-id="guaranteeId"
    />
    <ChatMessageComposer
      v-if="showChatMessageComposer"
      :recipient-options="recipientOptions"
      :send-to-guarantee-machine="sendToGuaranteeMachine"
      :guarantee-id="guaranteeId"
    />
  </div>
</template>

<script>
import ChatMessageList from "@/components/Chat/ChatMessageList.vue";
import ChatMessageComposer from "@/components/Chat/ChatMessageComposer.vue";
import ChatRecipientsSelector from "@/components/Chat/ChatRecipientsSelector.vue";
import { PlusCircleIcon } from "@vue-hero-icons/outline";

export default {
  name: "ChatMainContentArea",
  components: {
    ChatMessageComposer,
    ChatMessageList,
    ChatRecipientsSelector,
    PlusCircleIcon
  },
  props: {
    guaranteeId: {
      type: String,
      required: true
    },
    messages: {
      type: Array,
      default: () => []
    },
    sendToGuaranteeMachine: {
      type: Function,
      default: () => {}
    },
    recipientOptions: {
      type: Array,
      default: () => []
    },
    messagingAllowed: {
      type: Boolean,
      default: () => false
    }
  },
  inject: ["recipients"],
  data() {
    return {
      showRecipientsSelector: false
    };
  },
  computed: {
    showChatMessageComposer() {
      return (
        this.messagingAllowed &&
        !this.showRecipientsSelector &&
        this.recipients.activeRecipients.length > 0
      );
    }
  },
  watch: {
    messages() {
      this.showRecipientsSelector = false;
    }
  },
  methods: {
    onNewChatGroupClick() {
      this.showRecipientsSelector = !this.showRecipientsSelector;
    },
    confirmCreateNewGroup(recipients) {
      const self = this;
      this.$emit("create-new-group", {
        recipients,
        closeRecipientSelector: () => {
          self.showRecipientsSelector = false;
        }
      });
    },
    onCancel() {
      this.showRecipientsSelector = false;
    }
  }
};
</script>
