<template>
  <div class="mb-6">
    <div class="block">
      <div class="border-b border-gray-200">
        <nav class="-mb-px flex space-x-8" aria-label="Tabs">
          <div
            v-for="tab in tabs"
            :key="tab.key"
            :class="
              `${currentTab === tab.key &&
                'border-primary text-primary'} group inline-flex items-center py-3 px-1 border-b-2 font-medium text-sm cursor-pointer`
            "
            @click="handleTabSelect(tab.key)"
          >
            <component :is="tab.icon" />
            <span class="mx-1 font-semibold text-base">{{ tab.label }}</span>
            <span v-if="tab.badge">({{ tab.badge }})</span>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UiTabs",
  props: {
    tabs: {
      type: Array,
      default: () => []
    },
    currentTab: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {};
  },
  methods: {
    handleTabSelect(tab) {
      this.$emit("on-select", tab);
    }
  }
};
</script>
