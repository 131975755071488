<template>
  <!-- overlay -->
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>

      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
        >&#8203;</span
      >

      <div
        class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
      >
        <!-- close button -->
        <div v-if="closable" class="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
          <button
            type="button"
            class="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <span class="sr-only">Close</span>
            <XIcon @click="$emit('close')" />
          </button>
        </div>
        <!-- header -->
        <div class="text-center">
          <slot name="header">
            <div
              class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"
            >
              <QuestionMarkCircleIcon class="text-green-600" />
            </div>
          </slot>

          <!-- body -->
          <div class="mt-3 text-center sm:mt-5">
            <slot name="body">
              <h3 class="text-lg leading-6 font-medium text-gray-900">
                {{ title }}
              </h3>
              <div class="mt-2">
                <p class="text-sm text-gray-500">
                  {{ message }}
                </p>
              </div></slot
            >
          </div>
        </div>
        <!-- footer -->
        <div class="mt-5 flex justify-center gap-5">
          <slot name="footer">
            <UiButton theme="primary" size="lg" @click="$emit('primaryActionClicked')">
              {{ primaryActionLabel }}
            </UiButton>

            <UiButton theme="outline" size="lg" @click="$emit('secondaryActionClicked')">
              {{ secondaryActionLabel }}
            </UiButton>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UiButton from "@/components/UI/UiButton/UiButton";
import { QuestionMarkCircleIcon, XIcon } from "@vue-hero-icons/outline";

export default {
  name: "UiModal",
  components: {
    UiButton,
    QuestionMarkCircleIcon,
    XIcon
  },
  props: {
    title: {
      type: String,
      default: () => "Title"
    },
    message: {
      type: String,
      default: () => "Message"
    },
    primaryActionLabel: {
      type: String,
      default: () => "OK"
    },
    secondaryActionLabel: {
      type: String,
      default: () => "Cancel"
    },
    closable: {
      type: Boolean,
      default: () => true
    }
  }
};
</script>
