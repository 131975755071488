<template>
  <div class="h-full">
    <div v-if="!state.matches('done')" class="flex items-center justify-center p-10 h-full">
      <!-- CONTACT FORM -->
      <div
        v-if="state.matches('contactInput') || state.matches('submittingContact')"
        class="flex flex-col items-center justify-center max-w-md"
      >
        <div>
          <UiHeadline :size="2" class="text-newsecondary">We're here to help!</UiHeadline>
        </div>
        <div class="mt-7">
          <p class="text-newsecondary">
            Want to know more about our product? Let's get in touch!
          </p>
        </div>
        <img
          class="my-7 h-28"
          src="@/assets/images/new-icons/icon-pre-qualification-contact-us.svg"
          alt="contact-us-form"
        />
        <div class="mt-7">
          <p class="text-newwhite text-xl">
            Leave us your details and our awesome team will be in touch!
          </p>
          <p class="text-newwhite mt-4 text-xl">
            Alternatively, give us a call on
            <span class="cursor-pointer font-bold" @click="callEguarantee">1300 561 804</span>
          </p>
        </div>
        <div class="w-full mt-4">
          <div class="flex justify-between gap-2">
            <UiInput
              v-model.trim="state.context.contactInfo.firstname"
              class="mt-5 flex-grow"
              input-style="text-lg"
              size="text-base"
              type="text"
              name="First Name"
              placeholder="First name"
              :on-enter-key="submitContactInfo"
              :error="$v.state.context.contactInfo.firstname.$error"
            >
              <template v-if="$v.state.context.contactInfo.firstname.$error" slot="errors">
                <p v-if="!$v.state.context.contactInfo.firstname.required">
                  First name is required.
                </p>
              </template>
              <template slot="label"><span class="text-newwhite">First name</span></template>
            </UiInput>
            <UiInput
              v-model.trim="state.context.contactInfo.lastname"
              class="mt-5 flex-grow"
              input-style="text-lg"
              size="text-base"
              type="text"
              name="Last Name"
              placeholder="Last name"
              :on-enter-key="submitContactInfo"
              :error="$v.state.context.contactInfo.lastname.$error"
            >
              <template v-if="$v.state.context.contactInfo.lastname.$error" slot="errors">
                <p v-if="!$v.state.context.contactInfo.lastname.required">
                  Last name is required.
                </p>
              </template>
              <template slot="label"><span class="text-newwhite">Last name</span></template>
            </UiInput>
          </div>
          <UiInput
            v-model.trim="state.context.contactInfo.companyName"
            class="mt-5"
            input-style="text-lg"
            size="text-base"
            type="text"
            name="Company Name"
            placeholder="Your company"
            :on-enter-key="submitContactInfo"
            :error="$v.state.context.contactInfo.companyName.$error"
          >
            <template v-if="$v.state.context.contactInfo.companyName.$error" slot="errors">
              <p v-if="!$v.state.context.contactInfo.companyName.required">
                Company name is required
              </p>
            </template>
            <template slot="label"><span class="text-newwhite">Company name</span></template>
          </UiInput>
          <UiInput
            v-model.trim="state.context.contactInfo.mobilePhone"
            class="mt-5 flex-grow"
            input-style="text-lg"
            size="text-base"
            type="text"
            name="mobileNumber"
            placeholder="eg. 0410 000 000"
            :on-enter-key="submitContactInfo"
            :error="$v.state.context.contactInfo.mobilePhone.$error"
            @input="trimPhoneNumber('state.context.contactInfo.mobilePhone', $event)"
          >
            <template v-if="$v.state.context.contactInfo.mobilePhone.$error" slot="errors">
              <p v-if="!$v.state.context.contactInfo.mobilePhone.noEmail">
                A valid email or phone number is required.
              </p>
            </template>
            <template slot="label"><span class="text-newwhite">Mobile phone</span></template>
          </UiInput>
          <UiInput
            v-model.trim="state.context.contactInfo.companyEmail"
            class="mt-5"
            input-style="text-lg"
            size="text-base"
            type="text"
            name="CompanyEmail"
            placeholder="you@company.com"
            :on-enter-key="submitContactInfo"
            :error="$v.state.context.contactInfo.companyEmail.$error"
          >
            <template v-if="$v.state.context.contactInfo.companyEmail.$error" slot="errors">
              <p v-if="!$v.state.context.contactInfo.companyEmail.email">
                A valid email is required
              </p>
              <p v-if="!$v.state.context.contactInfo.companyEmail.noPhone">
                A valid email or phone number is required.
              </p>
            </template>
            <template slot="label"><span class="text-newwhite">Company email</span></template>
          </UiInput>
          <UiSelect
            v-model="state.context.contactInfo.enquiryType"
            class="mt-5"
            input-style="text-base"
            label-size="text-base"
            name="EnquiryType"
            placeholder="What your enquiry is about"
            :options="enquiryTypeList"
            :error="$v.state.context.contactInfo.enquiryType.$error"
          >
            <template v-if="$v.state.context.contactInfo.enquiryType.$error" slot="errors">
              <p v-if="!$v.state.context.contactInfo.enquiryType.required">
                Enquiry type is required.
              </p>
            </template>
            <template slot="label"><span class="text-newwhite">Enquiry type</span></template>
          </UiSelect>
          <UiTextarea
            v-model.trim="state.context.contactInfo.enquiryMessage"
            class="mt-5"
            input-style="text-base"
            label-size="text-base"
            name="EnquiryMessage"
          >
            <template slot="label"><span class="text-newwhite">Enquiry (optional)</span></template>
          </UiTextarea>
          <UiCheckbox
            v-model.trim="state.context.contactInfo.termsAccepted"
            class="mt-5"
            checkbox-type="tickbox"
            size="sm"
            :error="$v.state.context.contactInfo.termsAccepted.$error"
            ><template slot="label"
              ><p class="text-newwhite text-sm">
                I agree to the
                <span class="cursor-pointer font-bold underline" @click="openTermsWindow"
                  >terms & conditions</span
                >
              </p></template
            >
            <template v-if="$v.state.context.contactInfo.termsAccepted.$error" slot="errors">
              <p>
                Please accept and agree to the terms & conditions
              </p>
            </template>
          </UiCheckbox>
        </div>
        <div class="flex mt-7 w-full gap-2">
          <UiButton
            full-width
            type="button"
            button-class="text-xl py-3"
            theme="primary"
            :disabled="state.matches('submittingContact')"
            @click="submitContactInfo"
          >
            <LoaderIcon
              v-if="state.matches('submittingContact')"
              size="1.3x"
              class="spin text-newwhite"
            />
            <span v-else class="font-normal">Submit</span>
          </UiButton>
        </div>
        <div class="mt-3">
          <p class="text-newgrey text-sm">
            By submitting this form you are consenting to receive marketing communications from us
            in future, on the understanding that you have read and agree to our terms & conditions.
          </p>
        </div>
      </div>
    </div>
    <div v-else-if="state.matches('done')" class="flex flex-col justify-between h-full">
      <ThankYou
        subheader="for reaching out"
        description="We will be in touch as soon as we possibly can."
      ></ThankYou>
    </div>
  </div>
</template>

<script>
import { LoaderIcon } from "vue-feather-icons";
import { useMachine } from "@xstate/vue";
import { required, requiredIf, email as emailValidator, helpers } from "vuelidate/lib/validators";
import formMixin from "@/mixins/form";
import ContactUsMachine from "./contact-us-machine";
import UiButton from "@/components/UI/UiButton/UiButton";
import phoneCodes from "@/utils/international-phone";

import UiCheckbox from "@/components/UI/UiCheckbox/UiCheckbox.vue";
import UiHeadline from "@/components/UI/UiHeadline/UiHeadline.vue";
import UiInput from "@/components/UI/UiInput/UiInput";
import UiSelect from "@/components/UI/UiSelect/UiSelect";
import UiTextarea from "@/components/UI/UiTextarea/UiTextarea";
import ThankYou from "@/components/ThankYou/ThankYou.vue";

export default {
  name: "ContactUsForm",
  components: {
    LoaderIcon,
    UiButton,
    UiCheckbox,
    UiHeadline,
    UiInput,
    UiSelect,
    UiTextarea,
    ThankYou
  },
  mixins: [formMixin],
  setup() {
    const { state, send } = useMachine(ContactUsMachine);

    const enquiryTypeList = [
      { label: "solutions for landlords", value: "solutions for landlords" },
      { label: "solutions for tenants", value: "solutions for tenants" },
      { label: "solutions for intermediaries", value: "solutions for intermediaries" },
      { label: "technical support", value: "technical support" },
      { label: "other", value: "other" }
    ];

    return {
      enquiryTypeList,
      phoneCodes,
      state,
      send
    };
  },
  mounted() {
    this.send("CONTACT_INPUT", { ...this.$route.params });
  },
  methods: {
    trimPhoneNumber(propName, value) {
      const regexp = /\d+/g;
      const trimmedValue = [...value.matchAll(regexp)].join("");
      propName.split(".").reduce((prev, cur, index, arr) => {
        if (index === arr.length - 1) {
          prev[cur] = trimmedValue;
        } else {
          return prev[cur];
        }
      }, this);
    },
    submitContactInfo() {
      this.validate();

      if (!this.$v.state.context.contactInfo.$error) {
        this.send("SUBMIT", { submitType: "contactSubmit" });
      }
    },
    openTermsWindow() {
      window.open("https://www.eguarantee.com.au/terms", "_blank");
    },
    callEguarantee() {
      window.open("tel:1300561804");
    }
  },
  validations() {
    return {
      state: {
        context: {
          contactInfo: {
            firstname: { required },
            lastname: { required },
            companyName: {
              required
            },
            mobilePhone: {
              noEmail: requiredIf(context => !context.companyEmail),
              phoneDigits: value => helpers.regex(value, /^[0-9]*$/)
            },
            companyEmail: {
              noPhone: requiredIf(context => !context.mobilePhone),
              email: emailValidator
            },
            enquiryType: { required },
            termsAccepted: { checked: value => value === true }
          }
        }
      }
    };
  }
};
</script>

<style scoped>
/* purgecss start ignore */

/* purgecss end ignore */
</style>
