<template>
  <div class="h-screen flex flex-col bg-newwhite">
    <!-- Nav -->
    <nav class="bg-newwhite">
      <div class="container h-16">
        <div class="flex justify-between h-full items-center">
          <div class="flex items-center">
            <router-link class="mb-3 mr-4" to="/">
              <UiBrandLogo class="h-7" />
            </router-link>
            <p class="font-light text-sm text-newprimary">
              secure platform
            </p>
          </div>
          <div>
            <p class="text-md text-newprimary">
              Have questions? Call us on <strong><a href="tel:1300561804">1300 561 804</a></strong>
            </p>
          </div>
        </div>
      </div>
    </nav>

    <!-- Main Content -->
    <div class="bg-newtertiary rounded-t-3xl flex-auto relative">
      <div
        v-if="alert && alert.message"
        class="mx-4 mt-4 sm:max-w-md sm:mx-auto absolute left-1/2 transform -translate-x-1/2"
      >
        <UiAlert
          :title="alert.message"
          :theme="alert.type"
          :items="alert.items"
          :timer="alert.timer"
          show-clear-button
          @clear="sendToAlertService('CLEAR')"
        />
      </div>
      <Notifications />

      <slot></slot>
    </div>
  </div>
</template>

<script>
import { useService } from "@xstate/vue";
import { computed } from "@vue/composition-api";
import { alertService, organizationService } from "@/main";
import UiAlert from "@/components/UI/UiAlert/UiAlert";
import Notifications from "@/components/Notifications/Notifications";
import UiBrandLogo from "@/components/UI/UiBrandLogo/UiBrandLogo";

export default {
  setup() {
    // Setup our state machine...
    const { state: alertState, send: sendToAlertService } = useService(alertService);

    const { state: organizationState, send: sendToOrganizationService } = useService(
      organizationService
    );

    const preventScrolling = computed(() =>
      organizationState.value.matches("ready.notificationsActive") ? "prevent-scrolling" : ""
    );

    const alert = computed(() => alertState?.value?.context?.alert);

    // Return what we want to expose to the template...
    return {
      alert,
      preventScrolling,
      sendToAlertService,
      sendToOrganizationService
    };
  },
  name: "GetStartLayout",
  components: { UiAlert, Notifications, UiBrandLogo },
  metaInfo() {
    return {
      bodyAttrs: {
        class: ["default-layout", this.preventScrolling]
      }
    };
  },
  watch: {
    $route(to, from) {
      // When the route changes...
      if (to.path !== from.path) {
        // Refetch the notifications...
        this.sendToOrganizationService("FETCH_NOTIFICATIONS");

        // And clear the alert message...
        if (this.alert?.message) this.sendToAlertService("CLEAR");
      }
    }
  }
};
</script>

<style>
.default-layout {
  background-color: #f5f6f8;

  &.prevent-scrolling {
    @apply overflow-hidden;
  }
}
</style>
