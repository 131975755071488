<template>
  <li class="relative overflow-hidden lg:flex-1">
    <div class="overflow-hidden border-b-0 rounded-t-md lg:border-0">
      <a class="group">
        <span
          class="absolute top-0 left-0 w-1 h-full bg-transparent group-hover:bg-gray-200 lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
          aria-hidden="true"
        ></span>
        <span class="px-3 md:4 py-3 flex items-center justify-center text-sm font-medium lg:pl-6">
          <span class="flex-shrink-0">
            <span
              class="w-4 h-4 md:w-6 md:h-6 lg:w-8 lg:h-8 flex items-center justify-center bg-primary rounded-full"
            >
              <svg
                class="w-4 h-4 md:w-6 md:h-6 text-white"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                />
              </svg>
            </span>
          </span>
          <span class="mt-0.5 ml-2 md:ml-1 min-w-0 flex flex-col">
            <span class="text-xxs lg:text-xs font-semibold tracking-wide">{{ name }}</span>
          </span>
        </span>
      </a>
      <div v-if="!isFirstStep">
        <UiStepsSeparator />
      </div>
    </div>
  </li>
</template>

<script>
import UiStepsSeparator from "./UiStepsSeparator.vue";
export default {
  name: "UiStepsCompleted",
  components: {
    UiStepsSeparator
  },
  props: {
    name: {
      type: String,
      default: () => "",
      required: true
    },
    order: {
      type: Number,
      default: () => 0,
      required: true
    },
    isFirstStep: {
      type: Boolean,
      default: () => false,
      required: true
    }
  }
};
</script>
