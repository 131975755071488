<template>
  <header>
    <div class="container ui-header">
      <slot />
    </div>
  </header>
</template>

<script>
export default {
  name: "UiHeader"
};
</script>
