<template>
  <UiCard
    theme="primary"
    data-testid="notification-card"
    class="notification-card"
    :class="{ 'opacity-50': dismissing }"
    size="sm"
  >
    <slot>
      <div class="flex flex-col items-start mb-3">
        <p class="flex items-center mb-1">
          <span class="notification-card-title">{{ title }}</span>
        </p>
        <p
          v-if="date"
          class="w-full flex items-center justify-start text-gray-500 gap-1 text-xs font-light"
        >
          <span v-if="unread" class="notification-card-dot" data-testid="notification-dot" />
          <v-popover slot="meta" offset="16">
            {{ timeSince }}
            <template slot="popover">
              <p class="p-2">
                {{ formattedDate }}
              </p>
            </template>
          </v-popover>
        </p>
      </div>

      <div class="flex items-center mb-4">
        <div class="flex-shrink-0 w-6 h-6 mr-2">
          <img
            class="w-6 h-auto rounded-full"
            :src="profilePicPath"
            :alt="`Sender profile picture`"
            data-testid="profile-pic"
          />
        </div>
        <div v-if="description" class="notification-card-description">
          {{ description }}
        </div>
      </div>

      <div class="flex items-center">
        <p v-if="subtitle" class="notification-card-subtitle">
          {{ subtitle }}
        </p>

        <div class="ml-auto notification-card-actions">
          <UiButton
            theme="link"
            size="sm"
            remove-padding
            button-class="font-light"
            @click="onViewClick"
          >
            View
          </UiButton>

          <UiButton
            theme="link"
            size="sm"
            remove-padding
            button-class="font-light text-gray-500"
            class="ml-2"
            :loading="dismissing"
            @click="onDismissClick"
          >
            Dismiss
          </UiButton>
        </div>
      </div>
    </slot>
  </UiCard>
</template>

<script>
import { formatDistanceToNowStrict, parseISO } from "date-fns";
import UiButton from "@/components/UI/UiButton/UiButton";
import UiCard from "@/components/UI/UiCard/UiCard";
import eguaranteeCheckIcon from "@/assets/images/logos/eguarantee-check.png";

export default {
  name: "NotificationCard",
  components: { UiButton, UiCard },
  props: {
    date: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    },
    dismissing: {
      type: Boolean,
      default: false
    },
    guaranteeId: {
      type: String,
      default: ""
    },
    notificationId: {
      type: String,
      required: true
    },
    messageId: {
      type: String,
      default: ""
    },
    profilePic: {
      type: String,
      default: ""
    },
    sendToOrganizationService: {
      type: Function,
      required: true
    },
    subtitle: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      required: true
    },
    unread: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    profilePicPath() {
      return this.profilePic ? this.profilePic : eguaranteeCheckIcon;
    },
    timeSince() {
      const date = parseISO(this.date);

      return `${formatDistanceToNowStrict(date)} ago`;
    },
    formattedDate() {
      return this.date ? new Date(this.date).toLocaleString() : "";
    }
  },
  methods: {
    onDismissClick() {
      this.sendToOrganizationService({
        type: "DISMISS",
        notificationId: this.notificationId
      });
    },
    onViewClick() {
      this.$emit("close");

      if (this.messageId) {
        localStorage.setItem("activeChatMessageId", this.messageId);
      }

      this.$router
        .push({
          name: "Guarantee",
          params: { id: this.guaranteeId }
        })
        .catch(error => {
          // We're catching the error here and only sending it to the error service if it's not a NavigationDuplicated error.
          // NavigationDuplicated happens when we're trying to navigate to a route that we're already on
          if (!error.name === "NavigationDuplicated") this.$logger.error(error);
        });
    }
  }
};
</script>

<style scoped>
/* Base Styles */
.notification-card {
  @apply w-full;
}

.notification-card-title {
  @apply text-gray-900 font-normal text-sm;
}

.notification-card-dot {
  @apply inline-block h-2 w-2 bg-red-500 rounded-full relative border border-white;
  margin-left: 2px;
}

.notification-card-description {
  @apply text-gray-600 text-sm font-light;
}

.notification-card-subtitle {
  @apply text-xs text-gray-600 font-light;
}

.notification-card-actions {
  @apply text-xs font-light;
}
</style>
