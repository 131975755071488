<template>
  <div class="chat-message-composer">
    <div class="border-t border-gray-200">
      <div v-if="messagesState.matches('idle')">
        <UiRichText
          v-model.trim="messageContent"
          name="messageContent"
          :error="$v.messageContent.$error"
          label=""
          placeholder="Type a new message"
        >
          <template v-if="$v.messageContent.$error" slot="errors">
            <p v-if="!$v.messageContent.required">
              Please enter a message
            </p>
          </template>
        </UiRichText>
      </div>

      <UiButton
        theme="primary"
        size="base"
        class="float-right m-2"
        :disabled="!messageIdle"
        @click="onSendClick"
      >
        <PaperAirplaneIcon slot="leading-icon" class="w-5 h-5 mr-2 transform rotate-90" />
        {{ messageDelivering ? "Sending" : "Send" }}
      </UiButton>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { useMachine } from "@xstate/vue";
import { computed } from "@vue/composition-api";
import messagesMachine from "@/components/Messages/messages-machine";
import formMixin from "@/mixins/form";
import UiRichText from "@/components/UI/UiRichText/UiRichText";
import UiButton from "@/components/UI/UiButton/UiButton";
import { PaperAirplaneIcon } from "@vue-hero-icons/outline";

export default {
  name: "ChatMessageComposer",
  components: { UiRichText, UiButton, PaperAirplaneIcon },
  mixins: [formMixin],
  setup(props) {
    const { guaranteeId, sendToGuaranteeMachine } = props;
    const machineOptions = {
      context: { guaranteeId },
      actions: {
        reloadGuarantee: () => sendToGuaranteeMachine("RELOAD_GUARANTEE", { guaranteeId })
      }
    };

    const { state: messagesState, send: sendToMessagesMachine } = useMachine(
      messagesMachine,
      machineOptions
    );

    const errors = computed(() => messagesState.value?.context?.errors);

    return {
      errors,
      messagesState,
      sendToMessagesMachine
    };
  },
  props: {
    guaranteeId: {
      type: String,
      required: true
    },
    sendToGuaranteeMachine: {
      type: Function,
      default: () => {}
    },
    recipientOptions: {
      type: Array,
      default: () => []
    }
  },
  inject: ["recipients"],
  data() {
    return {
      messageContent: ""
    };
  },
  computed: {
    messageIdle() {
      return this.messagesState.matches("idle");
    },
    messageDelivering() {
      return this.messagesState.matches("sending");
    }
  },
  methods: {
    onSendClick() {
      this.validate();
      if (!this.$v.$invalid) {
        this.sendToMessagesMachine("SEND", {
          messageContent: this.messageContent,
          recipients: this.recipients.activeRecipients
        });
      }
    }
  },
  validations: {
    messageContent: {
      required
    }
  }
};
</script>

<style scoped>
.message-content {
  @apply whitespace-pre-wrap w-full mb-4;
  widows: 100% !important;
}
</style>

<style>
.chat-message-composer .ql-editor {
  min-height: 100px !important;
}
</style>
