<template>
  <div>
    <UiLoader v-if="loading" />

    <UiAlert v-if="state.context.error" theme="warning" title="Error">
      {{ state.context.error }}
    </UiAlert>

    <UiAlert v-if="state.context.message" :title="state.context.message" theme="success" />
    <UiAlert
      v-if="state.context.notice.title"
      :title="state.context.notice.title"
      :content="state.context.notice.message"
      theme="notice"
    ></UiAlert>

    <SignInForm
      v-if="state.value === 'idle'"
      @submit="signInSubmitHandler"
      @forgot-password="forgotPasswordHandler"
      @sso-sign-in="ssoSignInSubmitHandler"
    />

    <ConfirmForm v-if="state.value === 'confirm'" @submit="confirmSubmitHandler" />

    <ForgotPasswordForm
      v-if="state.value === 'reset'"
      :email="state.context.email"
      @submit="resetHandler"
      @update-password="send('UPDATE')"
    />

    <BackButton v-if="state.value === 'sentResetLink'" @submit="send('RESTART')" />
    <SetNewPasswordForm v-if="state.value === 'setNewPassword'" @submit="setNewPassword" />
  </div>
</template>

<script>
import { useMachine } from "@xstate/vue";
import { computed } from "@vue/composition-api";
import signInMachine from "./signin-machine";
import SignInForm from "@/components/Auth/SignIn/SignInForm";
import ConfirmForm from "@/components/Auth/SignIn/ConfirmForm";
import ForgotPasswordForm from "@/components/Auth/SignIn/ForgotPasswordForm";
import BackButton from "@/components/Auth/SignIn/BackButton";
import SetNewPasswordForm from "@/components/Auth/SignIn/SetNewPasswordForm";
import UiAlert from "@/components/UI/UiAlert/UiAlert";
import UiLoader from "@/components/UI/UiLoader/UiLoader";
import { watchState } from "@/utils/machine-helpers";
import router from "@/router";

export default {
  name: "SignIn",
  components: {
    BackButton,
    ConfirmForm,
    SignInForm,
    ForgotPasswordForm,
    UiAlert,
    UiLoader,
    SetNewPasswordForm
  },
  setup() {
    // Setup our state machine...
    const { state, send } = useMachine(signInMachine);
    watchState(state, "SignIn");

    const loading = computed(() =>
      ["authenticating", "confirming", "authenticated", "resetting", "ssoSigningIn"].includes(
        state.value.value
      )
    );

    // Return what we want to expose to the template...
    return {
      loading,
      send,
      state
    };
  },
  mounted() {
    this.checkForAlert();
    this.checkForCreatedAccount();
  },
  methods: {
    checkForAlert() {
      if (this.$route?.params?.error) {
        this.state.context.error = this.$route?.params?.error;
      }
    },
    checkForCreatedAccount() {
      if (router.currentRoute.query?.createdAccount === "success") {
        this.state.context.message = "Successfully Created Your Account";
      }
    },
    confirmSubmitHandler(data) {
      this.send({ type: "CONFIRM", data });
    },
    forgotPasswordHandler(data) {
      this.send({ type: "SHOW_RESET", data });
    },
    ssoSignInSubmitHandler(data) {
      this.send({ type: "SSO_SIGN_IN", data });
    },
    signInSubmitHandler(data) {
      this.send({ type: "AUTHENTICATE", data });
    },
    resetHandler(data) {
      this.send({ type: "RESET", data });
    },
    setNewPassword(data) {
      this.send({ type: "SET_NEW_PASSWORD", data });
    }
  }
};
</script>
