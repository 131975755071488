<template>
  <div class="open-negotiation-context">
    <div class="flex flex-col w-full mb-2">
      <div v-if="!items().length" class="mb-2">
        <MetaCard icon-type="exclamationTriangle" :title="placeholderTitle" />
      </div>
      <div v-for="(item, i) in items()" :key="i" class="mb-2">
        <MessageCard
          v-if="item.type === 'MESSAGE'"
          :sender-role="item.organization.role"
          :sender-name="item.user.firstname + ' ' + item.user.lastname"
          :sender-organization="item.organization.name"
          :date-sent="item.created"
          :profile-pic="item.organization.profilePic"
          class="w-full"
        >
          <div v-dompurify-html="item.content" class="message-content" />
        </MessageCard>
        <MetaCard
          v-if="item.type === 'ATTACHMENT'"
          icon-type="paperclip"
          :info="item.name"
          class="w-full"
        >
          <div slot="title" class="flex justify-between attachment-title flex-grow">
            <div>
              <p>
                {{ item.organization.name }}
                <span class="sender-badge" :class="item.organization.role">{{
                  item.organization.role | capitalize
                }}</span>
              </p>
              <p>{{ item.user.firstname + " " + item.user.lastname }}</p>
            </div>
            <p class="font-light">
              {{ item.created | formatDateTimeFromString }}
            </p>
          </div>
          <UiButton
            slot="action"
            theme="link"
            remove-padding
            @click="onDownloadAttachment(item.attachmentId)"
            >Download</UiButton
          >
        </MetaCard>
      </div>
    </div>
    <transition
      enter-active-class="animate__animated animate__pulse"
      leave-active-class="animate__animated animate__fadeOutUp"
    >
      <AttachmentsUploader
        v-if="showUpload"
        class="mb-4 mr-4"
        disable-private
        :loading="loading"
        @attach-document="onAttachDocument"
      />
    </transition>
    <div class="send-message">
      <UiCard theme="secondary">
        <UiLoader v-if="loading" label="Sending" />
        <UiRichText
          v-model.trim="messageContent"
          name="messageContent"
          class="message-content"
          label="Send Message"
          :disabled="loading"
          required
        />
        <div class="flex justify-end">
          <UiButton size="sm" class="mt-2 mr-4" @click="showUpload = !showUpload"
            >Add Attachment</UiButton
          >
          <UiButton
            theme="primary"
            size="sm"
            class="mt-2"
            :disabled="loading"
            @click="onSendMessage"
            >Send</UiButton
          >
        </div>
      </UiCard>
    </div>
  </div>
</template>

<script>
import { filter } from "lodash";
import { useMachine } from "@xstate/vue";
import { computed } from "@vue/composition-api";
import UiCard from "@/components/UI/UiCard/UiCard";
import UiRichText from "@/components/UI/UiRichText/UiRichText";
import UiButton from "@/components/UI/UiButton/UiButton";
import UiLoader from "@/components/UI/UiLoader/UiLoader";
import MetaCard from "@/components/Cards/MetaCard/MetaCard";
import AttachmentsUploader from "@/components/Attachments/AttachmentsUploader";
import MessageCard from "@/components/Cards/MessageCard/MessageCard";
import contextMachine from "@/components/GuaranteeContext/context-machine";

export default {
  name: "OpenNegotiationContext",
  components: {
    UiCard,
    UiRichText,
    UiButton,
    UiLoader,
    AttachmentsUploader,
    MetaCard,
    MessageCard
  },
  props: {
    guarantee: {
      type: Object,
      required: true
    },
    sendToGuaranteeMachine: {
      type: Function,
      required: true
    }
  },
  setup(props) {
    const { sendToGuaranteeMachine, guarantee } = props;
    const { state, send } = useMachine(contextMachine, {
      context: {
        guarantee
      },
      actions: {
        reloadGuarantee: () =>
          sendToGuaranteeMachine("RELOAD_GUARANTEE", {
            guaranteeId: guarantee.guaranteeId
          })
      }
    });
    const loading = computed(() => state.value.context.loading);
    return {
      items() {
        const { statusId } = this.guarantee.status;
        return filter(this.guarantee.context || [], { statusId });
      },
      messageContent: "",
      showUpload: false,
      placeholderTitle:
        'If you have further enquiries for the tenant or the guarantor please use the "Send Message" form below to communicate with them.',
      loading,
      send
    };
  },
  methods: {
    onDownloadAttachment(attachmentId) {
      this.send("DOWNLOAD_ATTACHMENT", { attachmentId });
    },
    onSendMessage() {
      this.send("ADD_MESSAGE", { content: this.messageContent });
    },
    onAttachDocument(name, uploadKey, _, isPrivate) {
      this.send("ADD_ATTACHMENT", { name, uploadKey, isPrivate });
    }
  }
};
</script>

<style scoped>
.open-negotiation-context {
  @apply flex flex-wrap mb-4;
}

.send-message {
  @apply flex-grow;
}

.message-content {
  @apply whitespace-pre-wrap w-full;
}

.attachment-title {
  @apply text-xs text-gray-500;
}

.sender-badge {
  @apply rounded bg-white shadow-sm text-xs text-gray-600 px-3 py-0.5 ml-1;

  &.guarantor,
  &.admin {
    @apply bg-primary text-white;
  }

  &.landlord {
    @apply bg-green-400 text-white;
  }

  &.tenant {
    @apply bg-blue-400 text-white;
  }
}

.message-content {
  >>> strong {
    @apply font-bold;
  }

  >>> em,
  i {
    @apply italic;
  }

  >>> ul {
    @apply list-disc list-inside;
  }

  >>> ol {
    @apply list-decimal list-inside;
  }

  >>> blockquote {
    @apply border-l-4 border-solid pl-2;
  }

  >>> code {
    @apply font-mono bg-gray-100;
  }
}
</style>
