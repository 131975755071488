var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('UiBaseInput',{staticClass:"ui-checkbox-wrap",attrs:{"name":_vm.$attrs.name,"error":_vm.error,"decorated":false}},[_c('div',{staticClass:"flex items-center",class:{ 'pointer-events-none': _vm.disabled }},[(_vm.checkboxType === 'toggle')?_c('span',{staticClass:"ui-checkbox",class:{ active: _vm.value, error: _vm.error },attrs:{"role":"checkbox","tabindex":"0","aria-checked":_vm.value,"data-testid":"ui-checkbox","data-cy":_vm.$attrs.name},on:{"click":function($event){return _vm.$emit('input', !_vm.value)}}},[_c('span',{staticClass:"ui-checkbox-toggle",attrs:{"aria-hidden":"true"}})]):(_vm.checkboxType === 'tickbox')?_c('input',{staticClass:"form-checkbox",class:[
        _vm.value || _vm.error ? 'active' : '',
        _vm.size === 'sm' ? 'h-4 w-4' : '',
        _vm.size === 'md' ? 'h-5 w-5' : '',
        _vm.size === 'lg' ? 'h-6 w-6' : ''
      ],attrs:{"type":"checkbox","tabindex":"0","aria-checked":_vm.value,"data-testid":"ui-checkbox","data-cy":_vm.$attrs.name},domProps:{"checked":_vm.value},on:{"click":function($event){return _vm.$emit('input', !_vm.value)}}}):_vm._e(),_c('span',{staticClass:"ui-checkbox-label",class:( _obj = { error: _vm.error }, _obj[_vm.labelClass] = _vm.labelClass, _obj )},[_vm._t("label",function(){return [_c('span',{directives:[{name:"dompurify-html",rawName:"v-dompurify-html",value:(_vm.label),expression:"label"}]})]})],2)]),(_vm.error)?_vm._t("errors",null,{"slot":"errors"}):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }