<template>
  <UiCard theme="primary" class="ui-info-panel" data-testid="ui-info-panel">
    <UiHeadline v-if="title" :size="5">{{ title }}</UiHeadline>

    <div class="ui-info-panel-content">
      <div
        v-if="contentDetails"
        v-dompurify-html="contentDetails"
        class="ui-info-panel-content-details"
      />

      <a
        v-if="attachmentUrl"
        class="primary text-s border rounded bg-primary text-white p-2 mt-2 inline-block"
        :href="attachmentUrl"
        target="_blank"
        >Download</a
      >

      <button
        v-if="hiddenContent"
        class="mt-4 ui-info-panel-toggle"
        :class="{ active: showHiddenContent }"
        @click="showHiddenContent = !showHiddenContent"
      >
        {{ showHiddenContent ? "Less" : "More" }} info&hellip;
      </button>

      <div
        v-if="hiddenContent && showHiddenContent"
        v-dompurify-html="hiddenContent"
        class="mt-2 ui-info-panel-hidden-content"
      />
    </div>
    <!-- .ui-info-panel-content -->
  </UiCard>
</template>

<script>
import UiCard from "@/components/UI/UiCard/UiCard";
import UiHeadline from "@/components/UI/UiHeadline/UiHeadline";

export default {
  name: "UiInfoPanel",
  components: { UiCard, UiHeadline },
  props: {
    contentDetails: {
      type: String,
      default: ""
    },
    hiddenContent: {
      type: String,
      default: ""
    },
    title: {
      type: String,
      default: ""
    },
    attachmentUrl: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      showHiddenContent: false
    };
  }
};
</script>

<style scoped>
/* Base Styles */
.ui-info-panel-content {
  @apply font-normal text-xs text-gray-500 mt-3 p-4 bg-gray-100 rounded;

  @screen md {
    @apply text-sm;
  }

  @screen lg {
    @apply p-6;
  }
}

.ui-info-panel-content-details {
  @apply mt-1;

  >>> p {
    @apply mb-2;

    &:last-child {
      @apply mb-0;
    }
  }

  >>> ul {
    li {
      margin-inline-start: 32px;
      @apply list-disc;
    }
  }

  >>> ol {
    li {
      margin-inline-start: 32px;
      @apply list-decimal;
    }
  }
}

.ui-info-panel-hidden-content {
  @apply text-xs;

  >>> h3 {
    @apply mt-4 mb-1 font-bold;

    &:first-child {
      @apply mt-0;
    }
  }

  >>> p {
    @apply mb-1;

    &:last-child {
      @apply mb-0;
    }
  }

  >>> ul {
    @apply mt-2;

    li {
      @apply mt-1;
    }
  }
}

.ui-info-panel-toggle {
  @apply outline-none text-primary text-xs;

  &.active {
    @apply text-tertiary;
  }
}
</style>
