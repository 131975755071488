import { Machine, assign } from "xstate";
import { API, graphqlOperation } from "aws-amplify";
import {
  AddAttachment,
  addAttachmentToOrganization,
  CreateAttachmentsZip as CreateAttachmentsZipMutation,
  RemoveAttachment as RemoveAttachmentMutation
} from "@/graphql/mutations";
import {
  Attachment as AttachmentQuery,
  AttachmentsZip as AttachmentsZipQuery
} from "@/graphql/queries";
import { helperActions, getGraphQlData } from "@/utils/machine-helpers";

const initialState = {
  errors: [],
  customName: "",
  attachment: "",
  attachmentsZip: null
};

export default Machine(
  {
    id: "attachment",

    initial: "idle",

    context: {
      ...initialState
    },

    states: {
      idle: {
        entry: "resetState",
        exit: ["assignCustomName", "assignAttachment"],
        on: {
          ATTACH: "attaching",
          DOWNLOAD: "downloading",
          REMOVE: "removing",
          DOWNLOAD_ALL: "downloadingAll"
        }
      },
      attaching: {
        invoke: {
          id: "attaching",
          src: "addAttachment",
          onDone: {
            target: "attached"
          },
          onError: {
            target: "error",
            actions: ["assignErrors", "trackErrors"]
          }
        }
      },
      attached: {
        invoke: {
          id: "attached",
          src: "reloadParent",
          onDone: "idle"
        }
      },
      downloading: {
        invoke: {
          id: "downloading",
          src: "downloadAttachment",
          onDone: {
            target: "idle",
            actions: "navigateToDownloadUrl"
          },
          onError: {
            target: "error",
            actions: ["assignErrors", "trackErrors"]
          }
        }
      },
      removing: {
        invoke: {
          id: "removing",
          src: "removeAttachment",
          onDone: {
            target: "attached"
          },
          onError: {
            target: "error",
            actions: ["assignErrors", "trackErrors"]
          }
        }
      },
      downloadingAll: {
        invoke: {
          id: "downloadingAll",
          src: "downloadAll",
          onDone: {
            target: "waitingToDownload",
            actions: assign({
              attachmentsZip: (_, event) => getGraphQlData(event, "createAttachmentsZip")
            })
          },
          onError: {
            target: "error",
            actions: ["assignErrors", "trackErrors"]
          }
        }
      },
      waitingToDownload: {
        invoke: {
          src: "checkDownload",
          onDone: {
            actions: assign({
              attachmentsZip: (_, event) => getGraphQlData(event, "attachmentsZip")
            })
          },
          onError: {
            target: "error",
            actions: ["assignErrors", "trackErrors"]
          }
        },
        always: [
          { target: "idle", cond: "attachmentsZipReady", actions: ["navigateToDownloadUrl"] }
        ],
        after: {
          5000: { target: "waitingToDownload" }
        }
      },
      error: {
        exit: "resetErrors",
        on: {
          RETRY: "attaching",
          DOWNLOAD: "downloading",
          REMOVE: "removing",
          DOWNLOAD_ALL: "downloadingAll"
        }
      }
    }
  },
  {
    actions: {
      ...helperActions,
      assignCustomName: assign({
        customName: (context, event) => event?.customName
      }),
      assignAttachment: assign({
        attachment: (context, event) => event?.attachment
      }),
      navigateToDownloadUrl: (context, event) => {
        const downloadUrl =
          getGraphQlData(event, "attachment.url") || getGraphQlData(event, "attachmentsZip.url");

        if (downloadUrl) {
          const open = window.open(downloadUrl, "_blank");

          // Handle if we can't open a window e.g on iOS...
          if (open == null || typeof open === "undefined") {
            window.document.location.href = downloadUrl;
          }
        }
      },
      resetErrors: assign({
        errors: []
      }),
      resetState: assign({
        ...initialState
      })
    },
    services: {
      addAttachment: (context, event) => {
        const { parentId, parentType, customName, attachment } = context;
        const { recipients, isPrivate } = event;

        const name = customName || attachment.name;
        const uploadKey = attachment.key;

        if ("guarantee" === parentType) {
          return API.graphql(
            graphqlOperation(AddAttachment, {
              input: {
                guaranteeId: parentId,
                name,
                uploadKey,
                recipients,
                isPrivate
              }
            })
          );
        } else if ("organization" === parentType) {
          return API.graphql(
            graphqlOperation(addAttachmentToOrganization, {
              input: {
                name,
                uploadKey,
                isPrivate
              }
            })
          );
        }
      },
      downloadAttachment: async (context, event) => {
        const { parentId, parentType } = context;
        const { attachmentId } = event;

        const input = { attachmentId };
        if ("guarantee" === parentType) input.guaranteeId = parentId;

        return await API.graphql(graphqlOperation(AttachmentQuery, { input }));
      },
      removeAttachment: (context, event) => {
        const { parentId, parentType } = context;
        const { attachmentId } = event;

        const input = { attachmentId };
        if ("guarantee" === parentType) input.guaranteeId = parentId;

        return API.graphql(graphqlOperation(RemoveAttachmentMutation, { input }));
      },
      downloadAll: context => {
        const { parentId, parentType } = context;

        const input = {};
        if ("guarantee" === parentType) input.guaranteeId = parentId;

        return API.graphql(graphqlOperation(CreateAttachmentsZipMutation, { input }));
      },
      checkDownload: context => {
        const { attachmentsZip } = context;
        return API.graphql(
          graphqlOperation(AttachmentsZipQuery, {
            input: {
              id: attachmentsZip.id
            }
          })
        );
      }
    },
    guards: {
      attachmentsZipReady: context => {
        const { attachmentsZip } = context;
        return attachmentsZip && (attachmentsZip.isReady || attachmentsZip.isFailed);
      }
    }
  }
);
