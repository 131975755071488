<template>
  <GuaranteeLayout>
    <UiLoader v-if="loading" slot="loader" />
    <!-- GUARANTEE -->
    <template v-if="guaranteeState.matches('guarantee')" #header>
      <div class="-m-1 guarantee-actions md:-m-2 pb-5">
        <div v-for="a in actions" :key="a.actionId" class="p-1 md:p-2">
          <UiButton
            v-tooltip="
              !canPerformAction ? 'Amendments need to be actioned before you can continue.' : ''
            "
            :theme="actionThemeSelector(a.priority)"
            :disabled="!canPerformAction"
            :data-cy="a.actionId"
            @click="sendToGuaranteeMachine('VIEW_ACTION', { action: a })"
          >
            {{ a.name }}
          </UiButton>
        </div>
      </div>
      <!-- .guarantee-actions -->

      <!-- Message Updates -->
      <div class="-mx-1 md:-mx-2">
        <div class="px-1 py-2 md:p-2">
          <UiButton
            v-if="guaranteeViewable() && !showGuaranteeMainView()"
            @click="forceShowGuarantee = true"
            >View Guarantee Details</UiButton
          >
        </div>
        <!-- .p-2 -->
      </div>
      <!-- .my-2 -->
    </template>
    <!-- #header -->

    <!-- GUARANTEE STEPS -->
    <template v-if="!loading" #steps>
      <GuaranteeSteps :deal-stage="dealStage" :guarantee-type="guaranteeType" />
    </template>

    <template v-if="negotiableState() && !guaranteeState.matches('viewingAction')" #contextual>
      <OpenNegotiationContext
        v-if="guarantee"
        class="mb-8"
        :guarantee="guarantee"
        :send-to-guarantee-machine="sendToGuaranteeMachine"
      />
    </template>

    <template v-if="showGuaranteeMainView()" #main>
      <UiTabs :tabs="tabs()" :current-tab="currentTab" @on-select="handleTabSelect" />

      <div v-if="currentTab === 'application'">
        <Guarantee
          v-if="guarantee"
          :key="guaranteeId"
          :disabled="loadingWithGuarantee"
          :guarantee="guarantee"
          class="mb-6 md:mb-10 lg:mb-12"
          @amendments-submitted="onAmendmentsSubmitted($event, guaranteeId)"
          @resolve-amendment="onResolveAmendment($event)"
        />
      </div>

      <div v-else-if="currentTab === 'messaging'">
        <ChatConsole
          :guarantee="guarantee"
          :guarantee-id="guaranteeId"
          class="mb-8 lg:mb-0"
          :messages="messages"
          :current-role="currentRole"
          :send-to-guarantee-machine="sendToGuaranteeMachine"
        />
      </div>
    </template>
    <!-- /GUARANTEE -->

    <!-- SIDEBAR -->
    <div v-if="guaranteeState.matches('guarantee')" slot="sidebar" class="space-y-8">
      <Events
        v-if="guaranteeState.matches('guarantee')"
        :events="guarantee.events"
        :status="guarantee.status"
        class="lg:mb-12"
      />

      <Attachments
        v-if="guaranteeState.matches('guarantee')"
        :attachments="guarantee.attachments"
        :allow-attaching="guarantee.permissions.attachment"
        :reload-parent="() => sendToGuaranteeMachine('RELOAD_GUARANTEE', { guaranteeId })"
        :parent-id="guarantee.guaranteeId"
        :allowed-recipients="allowedRecipients()"
        parent-type="guarantee"
      />
    </div>
    <!-- /SIDEBAR -->

    <!-- ACTIONS -->
    <Actions
      v-if="guaranteeState.matches('viewingAction')"
      slot="main"
      :active-action="activeAction"
      :guarantee-id="guaranteeId"
      :guarantee-number="guarantee.guaranteeNumber"
      :tenant-name="getTenantName()"
      :send-to-guarantee-machine="sendToGuaranteeMachine"
    />
    <!-- /ACTIONS -->

    <!-- ERRORS -->
    <template #errors>
      <UiAlert
        v-if="errors.length"
        :show-clear-button="false"
        theme="warning"
        title="There was an error"
        :items="errors"
        class="mx-auto"
      />

      <div v-if="guaranteeState.matches('loadError')" class="flex justify-center">
        <UiButton
          class="mt-4"
          theme="primary"
          @click="sendToGuaranteeMachine('RELOAD', { guaranteeId })"
        >
          Reload Guarantee
        </UiButton>
      </div>
      <!-- .flex justify-center -->
    </template>
    <!-- /ERRORS -->
  </GuaranteeLayout>
</template>

<script>
import { includes } from "lodash";
import { useMachine } from "@xstate/vue";
import { computed, inject, ref } from "@vue/composition-api";
import { DocumentTextIcon, ChatAltIcon } from "@vue-hero-icons/outline";
import guaranteeMachine from "@/components/Guarantee/guarantee-machine";
import { watchState } from "@/utils/machine-helpers";
import UiAlert from "@/components/UI/UiAlert/UiAlert";
import UiButton from "@/components/UI/UiButton/UiButton";
import UiLoader from "@/components/UI/UiLoader/UiLoader";
import Guarantee from "@/components/Guarantee/Guarantee";
import GuaranteeLayout from "@/components/Guarantee/GuaranteeLayout";
import Actions from "@/components/Actions/Actions";
import Events from "@/components/Events/Events";
import Attachments from "@/components/Attachments/Attachments";
import OpenNegotiationContext from "@/components/GuaranteeContext/OpenNegotiationContext";
import UiTabs from "@/components/UI/UiTabs/UiTabs.vue";
import ChatConsole from "@/components/Chat/ChatConsole.vue";
import GuaranteeSteps from "@/components/GuaranteeSteps/GuaranteeSteps.vue";

export default {
  name: "GuaranteeView",
  components: {
    Actions,
    Attachments,
    Events,
    Guarantee,
    GuaranteeLayout,
    OpenNegotiationContext,
    UiAlert,
    UiButton,
    UiLoader,
    UiTabs,
    GuaranteeSteps,
    ChatConsole
  },
  setup(_, context) {
    const guaranteeId = computed(() => context.root?.$route?.params?.id);

    const currentTab = ref("application");
    const organization = computed(() => inject("organization"));
    const machineOptions = {
      context: {
        urlHash: context.root?.$route?.hash,
        organization,
        guaranteeId
      }
    };

    // Setup our state machine...
    const { state: guaranteeState, send: sendToGuaranteeMachine } = useMachine(
      guaranteeMachine,
      machineOptions
    );

    watchState(guaranteeState, "Guarantee");

    const guarantee = computed(() => guaranteeState.value?.context?.guarantee);

    const actions = computed(() => guaranteeState.value?.context?.guarantee?.actions ?? []);

    const canPerformAction = computed(() => {
      const fields = guaranteeState.value?.context?.guarantee?.fields ?? [];

      // Filter the fields to see if any fields have a proposedValue...
      const proposedValues = fields.filter(field => field.proposedValue);

      // Return true if there are no proposed values...
      return !proposedValues.length;
    });

    const currentRole = inject("userRole");

    const messages = computed(
      () =>
        guaranteeState.value?.context?.guarantee?.messages?.map(message => ({
          ...message,
          sessionId: [...message.recipients, message.senderRole].sort().join(", "),
          sessionName: [...message.recipients, message.senderRole]
            .filter(recipient => recipient !== currentRole.value)
            .sort()
            .join(", ")
        })) ?? []
    );

    const activeAction = computed(() => guaranteeState.value?.context?.activeAction);

    const dealStage = computed(() => guaranteeState.value?.context?.guarantee?.status?.dealStage);
    const guaranteeType = computed(() => guaranteeState.value?.context?.guarantee?.guaranteeType);

    const errors = computed(() => guaranteeState.value?.context?.errors ?? []);

    // Global loading state...
    const loading = computed(
      () =>
        guaranteeState.value.matches("loading") ||
        guaranteeState.value.matches("amending") ||
        guaranteeState.value.matches("amended") ||
        guaranteeState.value.matches("resolving") ||
        guaranteeState.value.matches("resolved") ||
        guaranteeState.value.matches("performingAction") ||
        guaranteeState.value.matches("savingActionInputs")
    );

    // This loading state is used to show the guarantee whilst something is happening, e.g amending
    // It also means if there is an error the users inputs won't disappear
    const loadingWithGuarantee = computed(
      () => guaranteeState.value.matches("amending") || guaranteeState.value.matches("resolving")
    );

    const participants = computed(() => guaranteeState.value?.context?.participants);

    sendToGuaranteeMachine("LOAD", { guaranteeId: guaranteeId.value });

    return {
      actions,
      activeAction,
      canPerformAction,
      errors,
      guarantee,
      guaranteeId,
      guaranteeState,
      loading,
      loadingWithGuarantee,
      messages,
      sendToGuaranteeMachine,
      participants,
      currentRole,
      organization,
      forceShowGuarantee: false,
      currentTab,
      dealStage,
      guaranteeType
    };
  },
  mounted() {
    const messageId = localStorage.getItem("activeChatMessageId");
    if (messageId) {
      this.currentTab = "messaging";
    }
  },
  methods: {
    guaranteeViewable() {
      return this.guaranteeState.matches("guarantee") || this.loadingWithGuarantee;
    },
    showGuaranteeMainView() {
      return this.guaranteeViewable() && (!this.negotiableState() || this.forceShowGuarantee);
    },
    negotiableState() {
      return (
        this.guarantee?.status?.negotiable &&
        this.guarantee?.status?.negotiable?.length &&
        (includes(this.guarantee?.status?.negotiable, this.currentRole) ||
          this.currentRole === "admin")
      );
    },
    actionThemeSelector(priority) {
      const themes = {
        1: "primary",
        2: "outline"
      };

      return themes[priority] ?? "";
    },
    onAmendmentsSubmitted(amendments, guaranteeId) {
      this.sendToGuaranteeMachine("SUBMIT_AMENDMENTS", {
        amendments,
        guaranteeId
      });
    },
    onResolveAmendment(amendment) {
      this.sendToGuaranteeMachine("RESOLVE_AMENDMENT", { amendment });
    },
    handleTabSelect(tab) {
      if (tab === "bond") {
        let routeData = this.$router.resolve({ name: "GuaranteeBond" });
        window.open(routeData.href, "_blank");
        return;
      }
      this.currentTab = tab;
    },
    tabs() {
      const tabs = [{ key: "application", label: "Application", icon: DocumentTextIcon }];

      //TODO - This is a quick fix to disallow messaging from the eGuarantee Broker account
      // In the situation where we swap broker accounts between eG and another, any messages are visible to the new account
      if (this.organization?.value?.organizationId !== "org8bea6bcf-7a82-408d-ab71-57432a892c05") {
        tabs.push({ key: "messaging", label: "Messaging", icon: ChatAltIcon });
      }

      if (
        this.guarantee?.status?.isDraft &&
        this.guarantee.fields?.find(field => field.id === "landlord") &&
        // Hide the Draft Bond tab from landlords in this final step to avoid ambiguity with the final bond
        // Is a requirement for now as the Assetinsure team still uploads a pdf bond. This will be removed once we generate the bond
        !(
          this.guarantee.status?.dealStage === "Documentation" &&
          this.organization?.value?.role === "landlord"
        )
      ) {
        tabs.push({ key: "bond", label: "Draft Bond", icon: DocumentTextIcon });
      }

      return tabs;
    },
    allowedRecipients() {
      let recipients = [];
      switch (this.organization?.value?.role) {
        case "admin":
          recipients = ["agent", "tenant", "guarantor", "landlord"];
          break;
        case "agent":
          recipients = [];
          break;
        case "guarantor":
          recipients = ["tenant", "agent", "landlord"];
          break;
        case "tenant":
          recipients = [];
          break;
        case "landlord":
          recipients = [];
          break;
        default:
          break;
      }
      return recipients;
    },
    getTenantName() {
      if (this.guarantee?.guaranteeType === "FACILITY") {
        return this.guarantee?.fields?.find(field => field.id === "Tenant")?.value;
      }
      return this.guarantee?.fields?.find(field => field.id === "tenant")?.value;
    }
  },
  beforeRouteUpdate(to, from, next) {
    // called when the route that renders this component has changed,
    // but this component is reused in the new route.
    // e.g when we want to load a new guarantee but we're already viewing one
    this.sendToGuaranteeMachine("LOAD", { guaranteeId: this.guaranteeId });

    // We always need to call next()...
    next();
  }
};
</script>

<style scoped>
.message-updates {
  @apply inline-flex rounded-md bg-white overflow-hidden border border-gray-300 text-gray-500 font-light;

  &:hover {
    @apply text-gray-700;
  }

  @screen sm {
    @apply w-auto;
  }
}

.message-updates-label {
  @apply inline-flex items-center px-3 py-1.5 text-xs leading-5;
}

.message-updates-count {
  @apply pl-2 pr-2.5 py-1.5 text-xs font-medium leading-5 border-l border-gray-300;

  &.messages-exist {
    @apply text-blue-400;
  }
}

.guarantee-actions {
  @apply flex flex-wrap items-center;
}
</style>
