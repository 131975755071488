<template>
  <div>
    <UiCard theme="primary" class="mb-6">
      <p class="absolute top-2 right-3 text-xs text-cool-gray-400 sm:hidden">
        ABN/NZBN: {{ organization.abn }}
      </p>

      <div class="flex flex-wrap">
        <div class="w-full lg:w-1/2">
          <div class="hidden sm:block text-xs text-cool-gray-400 mb-2 md:mb-4">
            <p>ABN/NZBN: {{ organization.abn }}</p>
          </div>

          <UiInput
            v-model.trim="form.name"
            :disabled="updating || !permissions.manageProfile"
            class="mb-2 md:mb-4"
            label="Company Name"
            placeholder="My Company Pty Ltd"
            :error="$v.form.name.$error"
          >
            <template v-if="$v.form.name.$error" slot="errors">
              <p v-if="!$v.form.name.required">
                Organisation name is required.
              </p>
            </template>
          </UiInput>

          <UiInput
            v-model.trim="form.phone"
            :disabled="updating || !permissions.manageProfile"
            class="mb-2 md:mb-4"
            label="Company Phone"
            placeholder="+61 2 1234 5678"
            :error="$v.form.phone.$error"
          >
            <template v-if="$v.form.phone.$error" slot="errors">
              <p v-if="!$v.form.phone.required">
                A company phone number is required.
              </p>
            </template>
          </UiInput>

          <UiInput
            v-model.trim="form.address.street"
            :disabled="updating || !permissions.manageProfile"
            class="mb-2 md:mb-4"
            label="Street"
            placeholder="1 George St"
            :error="$v.form.address.street.$error"
          >
            <template v-if="$v.form.address.street.$error" slot="errors">
              <p v-if="!$v.form.address.street.required">
                Street is required.
              </p>
            </template>
          </UiInput>

          <div class="flex flex-wrap items-baseline -mx-2">
            <div class="w-1/2 px-2">
              <UiInput
                v-model.trim="form.address.suburb"
                :disabled="updating || !permissions.manageProfile"
                class="mb-2 md:mb-4"
                label="Suburb"
                placeholder="Sydney"
                :error="$v.form.address.suburb.$error"
              >
                <template v-if="$v.form.address.suburb.$error" slot="errors">
                  <p v-if="!$v.form.address.suburb.required">
                    Suburb is required.
                  </p>
                </template>
              </UiInput>
            </div>

            <div class="w-1/2 px-2">
              <UiSelect
                v-model="form.address.state"
                :disabled="updating || !permissions.manageProfile"
                class="mb-2 md:mb-4"
                label="State"
                placeholder="NSW"
                :options="states"
                :error="$v.form.address.state.$error"
              >
                <template v-if="$v.form.address.state.$error" slot="errors">
                  <p v-if="!$v.form.address.state.required">
                    State is required.
                  </p>
                </template>
              </UiSelect>
            </div>
          </div>

          <div class="flex flex-wrap items-baseline -mx-2">
            <div class="w-1/2 px-2">
              <UiInput
                v-model.trim="form.address.postcode"
                :disabled="updating || !permissions.manageProfile"
                label="Post Code"
                placeholder="2000"
                :error="$v.form.address.postcode.$error"
              >
                <template v-if="$v.form.address.postcode.$error" slot="errors">
                  <p v-if="!$v.form.address.postcode.required">
                    Postcode is required.
                  </p>
                </template>
              </UiInput>
            </div>

            <div class="w-1/2 px-2">
              <UiSelect
                v-model="form.address.country"
                :disabled="updating || !permissions.manageProfile"
                label="Country"
                placeholder="Australia"
                :options="countries"
                :error="$v.form.address.country.$error"
              >
                <template v-if="$v.form.address.country.$error" slot="errors">
                  <p v-if="!$v.form.address.country.required">
                    Country is required.
                  </p>
                </template>
              </UiSelect>
            </div>
          </div>
        </div>

        <div class="w-full lg:w-1/2 lg:pl-8">
          <UiFileInput
            :key="organization.profilePic"
            v-model="form.profilePicFile"
            :src="defaultProfilePic"
            :disabled="updating || !permissions.manageProfile || readOnly"
            label="Company Logo"
            upload-type="profilePic"
            help-text="(jpg or png)"
            mime-types="image/jpeg,image/png"
            class="mb-2 md:mb-4"
            @uploading="uploading = $event"
            @input="uploading = false"
          />
        </div>
      </div>

      <div class="flex justify-center mt-3 sm:justify-end lg:mt-0">
        <UiButton
          v-if="permissions.manageProfile && !readOnly"
          theme="primary"
          size="sm"
          :disabled="uploading || updating || !permissions.manageProfile"
          :loading="updating"
          @click="onUpdateOrganization"
          >{{ updating ? "Saving" : "Save" }} Changes</UiButton
        >
      </div>
    </UiCard>
  </div>
</template>

<script>
import { numeric, required } from "vuelidate/lib/validators";
import formMixin from "@/mixins/form";
import UiButton from "@/components/UI/UiButton/UiButton";
import UiInput from "@/components/UI/UiInput/UiInput";
import UiSelect from "@/components/UI/UiSelect/UiSelect";
import UiCard from "@/components/UI/UiCard/UiCard";
import UiFileInput from "@/components/UI/UiFileInput/UiFileInput";
import countries from "@/utils/countries";
import states from "@/utils/states";
import eguaranteeCheckIcon from "@/assets/images/logos/eguarantee-check.png";

export default {
  name: "OrganizationDetails",
  components: { UiButton, UiCard, UiFileInput, UiInput, UiSelect },
  mixins: [formMixin],
  props: {
    organization: {
      type: Object,
      required: true
    },
    sendToOrganizationService: {
      type: Function,
      required: true
    },
    updating: {
      type: Boolean,
      default: false
    },
    permissions: {
      type: Object,
      default: () => ({
        manageProfile: false,
        manageUsers: false
      })
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      countries,
      form: {
        address: {
          street: this.organization?.address?.street,
          suburb: this.organization?.address?.suburb,
          state: this.organization?.address?.state,
          postcode: this.organization?.address?.postcode,
          country: this.organization?.address?.country
        },
        phone: this.organization?.phone,
        name: this.organization?.name,
        profilePicFile: []
      },
      uploading: false,
      states
    };
  },
  computed: {
    defaultProfilePic() {
      return this.organization?.profilePic || eguaranteeCheckIcon;
    }
  },
  methods: {
    onUpdateOrganization() {
      // validate using this.validate from our formMixin...
      this.validate();

      // If our inputs are valid...
      if (!this.$v.$invalid) {
        const data = {
          ...this.form,
          profilePicKey: this.form?.profilePicFile[0]?.key
        };

        delete data.profilePicFile;

        this.sendToOrganizationService({
          type: "UPDATE_ORGANIZATION",
          data
        });
      }
    }
  },
  validations: {
    form: {
      phone: {},
      address: {
        street: {},
        suburb: {},
        state: {},
        postcode: {},
        country: {}
      },
      name: { required }
    }
  }
};
</script>
