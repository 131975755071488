import { validationMixin } from "vuelidate";

export default {
  mixins: [validationMixin],
  methods: {
    focusFirstError(component = this) {
      if (component.error) {
        // Find our focusable inputs...
        const inputs = component.$el.querySelectorAll("input", "textarea", "select");

        // If an input exists then focus the first one...
        if (inputs.length) inputs[0].focus();

        // Return true to stop checking other elements...
        return true;
      }

      let focused = false;

      component.$children.some(childComponent => {
        focused = this.focusFirstError(childComponent);
        return focused;
      });

      return focused;
    },
    validate() {
      this.$v.$touch();
      this.$nextTick(() => this.focusFirstError());
    }
  }
};
