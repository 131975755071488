<template>
  <div @keyup.enter="onSubmit">
    <UiHeadline :size="4" class="mb-4">Update Your Password</UiHeadline>

    <!-- EMAIL -->
    <UiInput
      v-model.trim="form.email"
      label="Email"
      :error="$v.form.email.$error"
      placeholder="Enter your email"
      class="mb-4"
      disabled
    >
      <template v-if="$v.form.email.$error" slot="errors">
        <p v-if="!$v.form.email.required">
          Email is required.
        </p>

        <p v-if="!$v.form.email.email">
          Valid email address required.
        </p>
      </template>
    </UiInput>

    <!-- PASSWORD -->
    <UiInput
      v-model.trim="form.password"
      label="New Password"
      type="password"
      placeholder="Enter new password"
      :error="$v.form.password.$error"
      class="mb-4"
      :disabled="loading"
    >
      <template v-if="$v.form.password.$error" slot="errors">
        <p v-if="!$v.form.password.required">
          New password is required.
        </p>

        <p v-if="!$v.form.password.minLength">
          Password must be at least
          {{ $v.form.password.$params.minLength.min }} characters.
        </p>

        <p v-if="!$v.form.password.hasLowerCase">
          Password must contain at least 1 lower case character.
        </p>

        <p v-if="!$v.form.password.hasUpperCase">
          Password must contain at least 1 upper case character.
        </p>

        <p v-if="!$v.form.password.hasNumber">
          Password must contain at least 1 number.
        </p>
      </template>
    </UiInput>

    <!-- CONFIRM PASSWORD -->
    <UiInput
      v-model.trim="form.passwordConfirm"
      placeholder="Confirm new password"
      label="Confirm Password"
      type="password"
      class="mb-4"
      :error="$v.form.passwordConfirm.$error"
      :disabled="loading"
    >
      <template v-if="$v.form.passwordConfirm.$error" #errors>
        <p v-if="!$v.form.passwordConfirm.required">
          Confirm password is required.
        </p>
        <p v-if="!$v.form.passwordConfirm.sameAsPassword">
          Passwords must be identical.
        </p>
      </template>
    </UiInput>

    <div class="flex flex-wrap items-center">
      <UiInput is="UiButton" theme="primary" @click="onSubmit">Update Password</UiInput>

      <p class="ml-2 text-xs text-gray-500">
        <button class="outline-none" @click="onNewLink">
          Request New Link
        </button>
      </p>
    </div>
    <!-- .flex items-center -->
  </div>
</template>

<script>
import { email, required, numeric, minLength, sameAs } from "vuelidate/lib/validators";
import { hasLowerCase, hasUpperCase, hasNumber } from "@/utils/validations";
import { router } from "@/router";
import formMixin from "@/mixins/form";
import UiButton from "@/components/UI/UiButton/UiButton";
import UiHeadline from "@/components/UI/UiHeadline/UiHeadline";
import UiInput from "@/components/UI/UiInput/UiInput";

export default {
  name: "UpdatePasswordForm",
  components: { UiButton, UiHeadline, UiInput },
  mixins: [formMixin],
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const { email } = router.currentRoute.query;

    return {
      form: {
        email,
        password: "",
        passwordConfirm: ""
      }
    };
  },
  methods: {
    onSubmit() {
      // validate using this.validate() from our formMixin...
      this.validate();

      if (!this.$v.$invalid) {
        const { password } = this.form;
        const { email, code } = router.currentRoute.query;

        this.$emit("submit", { email, code, password });
      }
    },
    onNewLink() {
      this.$emit("new-reset-link", { email: this.form.email });
    }
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        minLength: minLength(8),
        hasLowerCase,
        hasUpperCase,
        hasNumber
      },
      passwordConfirm: {
        required,
        sameAsPassword: sameAs("password")
      }
    }
  }
};
</script>
