<template>
  <div>
    <UiHeadline :size="4" class="mb-4">{{ headline }}</UiHeadline>

    <p v-if="state.matches('verifying')">Verifying&hellip;</p>

    <UiAlert v-if="state.context.error" theme="warning" title="There was an error">
      {{ state.context.error }}
    </UiAlert>

    <RegistrationForm
      v-if="state.matches('verified')"
      :contact-email="state.context.user.email"
      :contact-name="state.context.user.name"
      @submit="submitHandler"
    />

    <UiLoader v-if="loading" />

    <Unverified v-if="state.matches('unverified')" />
  </div>
</template>

<script>
import { useMachine } from "@xstate/vue";
import { computed } from "@vue/composition-api";
import registrationMachine from "./registration-machine";
import UiHeadline from "@/components/UI/UiHeadline/UiHeadline";
import UiAlert from "@/components/UI/UiAlert/UiAlert";
import UiLoader from "@/components/UI/UiLoader/UiLoader";
import RegistrationForm from "@/components/Auth/Registration/RegistrationForm";
import Unverified from "@/components/Auth/Registration/Unverified";
import { watchState } from "@/utils/machine-helpers";

export default {
  name: "Registration",
  components: { RegistrationForm, UiAlert, UiHeadline, UiLoader, Unverified },
  setup() {
    // Setup our state machine...
    const { state, send } = useMachine(registrationMachine);

    watchState(state, "Registration");

    const organizationName = computed(() => state?.value?.context?.user?.organizationName ?? "");

    // Create a computed headline...
    const headline = computed(() => {
      const states = {
        verifying: "Verifying organisation",
        verified: `You’ve been authorised to join ${organizationName.value}`,
        unverified: `Unauthorized to join ${organizationName.value}`
      };

      return states[state.value.value];
    });

    // Setup our loading states...
    const loading = computed(() =>
      [
        "verifying",
        "registering",
        "confirmed",
        "unconfirmed",
        "authenticated",
        "unauthenticated"
      ].includes(state.value.value)
    );

    // Return what we want to expose to the template...
    return {
      headline,
      loading,
      state,
      send
    };
  },
  mounted() {
    this.verifyOrganization();
  },
  methods: {
    submitHandler(data) {
      this.send({ type: "REGISTER", data });
    },
    verifyOrganization() {
      const data = {
        name: this.$route?.query?.name,
        email: this.$route?.query?.email,
        organizationId: this.$route?.query?.oid,
        organizationName: this.$route?.query?.org
      };

      this.send({ type: "VERIFY", data });
    }
  }
};
</script>
