<template>
  <UiBaseInput class="ui-checkbox-wrap" :name="$attrs.name" :error="error" :decorated="false">
    <div class="flex items-center" :class="{ 'pointer-events-none': disabled }">
      <span
        v-if="checkboxType === 'toggle'"
        role="checkbox"
        tabindex="0"
        :aria-checked="value"
        data-testid="ui-checkbox"
        :data-cy="$attrs.name"
        class="ui-checkbox"
        :class="{ active: value, error }"
        @click="$emit('input', !value)"
      >
        <span aria-hidden="true" class="ui-checkbox-toggle"></span>
      </span>
      <input
        v-else-if="checkboxType === 'tickbox'"
        type="checkbox"
        :checked="value"
        tabindex="0"
        :aria-checked="value"
        data-testid="ui-checkbox"
        :data-cy="$attrs.name"
        class="form-checkbox"
        :class="[
          value || error ? 'active' : '',
          size === 'sm' ? 'h-4 w-4' : '',
          size === 'md' ? 'h-5 w-5' : '',
          size === 'lg' ? 'h-6 w-6' : ''
        ]"
        @click="$emit('input', !value)"
      />

      <span class="ui-checkbox-label" :class="{ error, [labelClass]: labelClass }">
        <slot name="label">
          <span v-dompurify-html="label" />
        </slot>
      </span>
    </div>
    <!-- .flex items-center -->

    <slot v-if="error" slot="errors" name="errors" />
  </UiBaseInput>
</template>

<script>
import UiBaseInput from "@/components/UI/UiBaseInput/UiBaseInput";

export default {
  name: "UiCheckbox",
  components: { UiBaseInput },
  inheritAttrs: false,
  props: {
    size: {
      type: String,
      default: "lg"
    },
    error: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    labelClass: {
      type: String,
      default: ""
    },
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    checkboxType: {
      type: String,
      default: "toggle",
      validator(val) {
        const availableType = ["toggle", "tickbox"];
        return availableType.includes(val);
      }
    }
  }
};
</script>

<style scoped>
/* Base Styles */
.ui-checkbox {
  @apply relative flex-shrink-0 inline-block h-6 transition-colors duration-200 ease-in-out bg-gray-200 border-2 border-transparent rounded-full cursor-pointer w-11;

  &.active {
    @apply bg-primary;

    .ui-checkbox-toggle {
      @apply translate-x-5;
    }
  }

  &.error {
    @apply bg-red-500;
  }

  &:focus {
    @apply outline-none shadow-outline;
  }
}

.ui-checkbox-toggle {
  @apply inline-block w-5 h-5 transition duration-200 ease-in-out transform translate-x-0 bg-white rounded-full shadow;
}

.ui-checkbox-label {
  @apply ml-2 text-xs text-gray-600 relative;
  top: -1px;

  &.error {
    @apply text-red-900;
    padding-right: 16px;
  }

  @screen md {
    @apply text-sm;
  }
}

.ui-base-input-errors {
  padding-left: 3.2rem;
}

.form-checkbox {
  @apply bg-gray-200 transition-colors duration-200 ease-in-out cursor-pointer;
  &.active {
    @apply bg-primary;
  }
  &.error {
    @apply bg-red-500;
  }
}
</style>
