<template>
  <span :class="['button-wrap', theme, fullWidth ? 'w-full' : '']">
    <Component
      :is="tag"
      class="button"
      :class="[
        size,
        theme,
        buttonClass,
        removePadding ? 'remove-padding' : '',
        fullWidth ? 'w-full' : ''
      ]"
      data-testid="ui-button"
      :disabled="disabled || loading"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <slot name="leading-icon" />
      <slot />
      <slot name="trailing-icon">
        <LoaderIcon v-if="loading" size=".9x" class="ml-1 spin" />
      </slot>
    </Component>
  </span>
</template>

<script>
import { LoaderIcon } from "vue-feather-icons";

export default {
  name: "UiButton",
  components: { LoaderIcon },
  inheritAttrs: false,
  props: {
    buttonClass: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    removePadding: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "base"
    },
    tag: {
      default: "button",
      type: String
    },
    theme: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped>
/* Base Styles */
.button-wrap {
  @apply inline-flex;

  &:not(.link) {
    @apply rounded-md shadow-sm;
  }
}

.button {
  @apply inline-flex justify-center items-center border font-medium rounded transition ease-in-out duration-150 cursor-pointer;

  &:focus {
    @apply outline-none;
  }

  &[disabled] {
    @apply opacity-50 cursor-not-allowed;
  }
}

/* Button Sizes */
.xs {
  @apply px-2 py-1 text-xxs leading-4;
}

.sm {
  @apply px-2 py-1 text-xxs leading-4;

  @screen md {
    @apply px-2.5 py-1.5 text-xs;
  }
}

.md {
  @apply px-2.5 py-1.5 text-xs leading-4;

  @screen md {
    @apply px-3 py-2 text-sm;
  }
}

.base {
  @apply px-3 py-2 text-xs leading-4;

  @screen md {
    @apply px-4 py-2 text-sm leading-5;
  }
}

.lg {
  @apply px-3.5 py-2 text-sm leading-5;

  @screen md {
    @apply px-4 py-2 text-base leading-6;
  }
}

.xl {
  @apply px-4 py-2 text-sm leading-6;

  @screen md {
    @apply px-6 py-3 text-base;
  }
}

.remove-padding {
  @apply p-0;
}

/* Button Themes */
.ghost {
  @apply text-primary bg-transparent border-transparent font-bold;

  &:hover {
    @apply text-tertiary;
  }

  &:focus {
    @apply border-blue-700 shadow-outline-blue;
  }

  &:active {
    @apply text-blue-700;
  }
}

.outline {
  @apply border-primary text-primary bg-transparent font-bold;

  &:hover {
    @apply text-tertiary border-tertiary;
  }

  &:focus {
    @apply border-blue-700 shadow-outline-blue;
  }

  &:active {
    @apply text-blue-700;
  }
}

.primary {
  @apply border-transparent text-white bg-primary font-bold;

  &:hover {
    @apply bg-newblue;
  }

  &:focus {
    @apply border-blue-500 shadow-outline-blue;
  }

  &:active {
    @apply bg-newblue;
  }
}

.secondary {
  @apply border-transparent text-green-800 bg-secondary font-bold;

  &:hover {
    @apply bg-green-600 text-white;
  }

  &:focus {
    @apply border-green-300 shadow-outline-green;
  }

  &:active {
    @apply bg-green-600;
  }
}

.tertiary {
  @apply border-transparent font-bold text-white bg-tertiary;

  &:hover {
    @apply bg-green-500;
  }

  &:focus {
    @apply border-blue-300 shadow-outline-blue;
  }

  &:active {
    @apply bg-primary;
  }
}

.link {
  @apply border-transparent text-primary bg-transparent shadow-none rounded-none;

  &:hover {
    @apply text-tertiary;
  }

  &:focus {
    @apply text-blue-800;
  }

  &:active {
    @apply text-tertiary;
  }
}

.warning {
  @apply border-red-300 text-red-500 bg-red-50;

  &:hover {
    @apply bg-red-500 text-white;
  }

  &:focus {
    @apply border-red-800 shadow-outline-red;
  }

  &:active {
    @apply bg-red-600;
  }
}
</style>
