<template>
  <div class="flex flex-col items-center min-h-screen px-4 py-12 bg-white justify-baseline">
    <UiBrandLogo class="w-auto h-10 mb-6" />

    <UiHeadline :size="3" class="mb-4 text-cool-gray-600">404 - Page Not Found</UiHeadline>

    <p class="max-w-md mx-auto text-center text-cool-gray-600">
      Sorry but we couldn't find the page that you're looking for. Please check the spelling and try
      again or
      <router-link to="/">start again</router-link>.
    </p>
  </div>
</template>

<script>
import UiBrandLogo from "@/components/UI/UiBrandLogo/UiBrandLogo";
import UiHeadline from "@/components/UI/UiHeadline/UiHeadline";

export default {
  name: "FourOhFour",
  components: { UiBrandLogo, UiHeadline }
};
</script>
