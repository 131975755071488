<template>
  <div v-if="!organizationState.matches('error')">
    <UiHeader>
      <div class="flex flex-wrap items-center">
        <UiHeadline :size="2" class="mr-auto"> Dashboard </UiHeadline>

        <div
          v-if="organizationState.matches('ready')"
          class="flex flex-wrap w-full mt-4 sm:w-auto sm:mt-0"
        >
          <GenerateReport class="order-last ml-auto sm:order-none" report-type="guarantee" />

          <UiButton
            v-if="canUpdateFacilityAmount"
            class="sm:ml-3"
            theme="outline"
            :disabled="loading || organizationState.matches('gettingOrganization')"
            :loading="guaranteesState.matches('creatingDraft')"
            @click="createDraftApplication('FACILITY')"
          >
            Apply for a Facility Increase
          </UiButton>

          <UiButton
            v-if="canCreateGuarantee"
            class="ml-3"
            theme="tertiary"
            :disabled="loading || organizationState.matches('gettingOrganization')"
            :loading="guaranteesState.matches('creatingDraft')"
            data-cy="createGuarantee"
            @click="onCreateGuaranteeButtonClick"
            >{{ createGuaranteeLabel }}
          </UiButton>
        </div>
      </div>
    </UiHeader>

    <UiMain>
      <UiModal
        v-if="modalVisible"
        title="Choose Bond Type"
        message="Would you like to apply for a single bond, or a facility for multiple bonds?"
        @close="closeModal"
      >
        <template #footer>
          <div class="flex gap-6 mt-4 justify-items-center">
            <div
              class="flex-col p-3 m-auto bg-gray-100 rounded-lg cursor-pointer w-36 group hover:bg-gray-200"
              @click="createDraftApplication('LEASE_BOND')"
            >
              <div class="flex items-center justify-center">
                <DocumentIcon class="w-12 h-12 text-gray-300 group-hover:text-gray-400" />
              </div>
              <div
                class="mt-2 text-base font-semibold text-center text-gray-500 group-hover:text-gray-700"
              >
                Single Bond
              </div>
            </div>
            <div
              class="flex-col p-3 m-auto bg-gray-100 rounded-lg cursor-pointer w-36 group hover:bg-gray-200"
              @click="createDraftApplication('FACILITY')"
            >
              <div class="flex items-center justify-center">
                <DocumentDuplicateIcon class="w-12 h-12 text-gray-300 group-hover:text-gray-400" />
              </div>
              <div
                class="mt-2 text-base font-semibold text-center text-gray-500 group-hover:text-gray-700"
              >
                Facility
              </div>
            </div>
          </div>
        </template>
      </UiModal>
      <div class="py-6 md:py-8">
        <Stats :send-to-guarantees-service="sendToGuaranteesService" />

        <GuaranteesListControls
          v-show="guaranteesState.matches('loaded')"
          :loading="guaranteesState.matches('loaded.searching')"
          :send-to-guarantees-service="sendToGuaranteesService"
          :filters="filters"
        />

        <UiLoader v-if="loading" class="my-8" />

        <!-- Guarantees -->
        <GuaranteesList
          v-if="guaranteesState.matches('loaded')"
          :guarantees="guarantees"
          :send-to-guarantees-service="sendToGuaranteesService"
          :can-load-more="canLoadMore"
          :loading="loading"
          :can-create-guarantee="canCreateGuarantee"
          :create-guarantee-label="createGuaranteeLabel"
        />

        <!-- Empty State -->
        <div v-if="!guarantees.length && !loading" class="flex justify-center mt-8">
          <UiAlert theme="info" title="No guarantees found.">
            <div v-if="guaranteesState.matches('loaded.filtered')">
              <p>We couldn't find any guarantees for those filters.</p>
            </div>

            <div v-else>
              <p>It looks like you haven't created any guarantees yet.</p>
              <p v-if="canCreateGuarantee" class="mt-2">
                Click
                <a
                  class="font-bold cursor-pointer hover:text-tertiary"
                  @click="onCreateGuaranteeButtonClick"
                  >{{ createGuaranteeLabel }}</a
                >
                to get started.
              </p>
            </div>
          </UiAlert>
        </div>

        <!-- Errors -->
        <div v-if="guaranteesState.matches('error')" class="mx-4 mt-8 sm:max-w-md sm:mx-auto">
          <UiAlert theme="warning" title="Error loading guarantees" :items="errors" />
        </div>
      </div>
    </UiMain>
  </div>
</template>

<script>
import features from "@/mixins/features";
import { useService, useMachine } from "@xstate/vue";
import { computed, ref } from "@vue/composition-api";
import { organizationService } from "@/main";
import guaranteesMachine from "@/components/Guarantees/guarantees-machine";
import { watchState } from "@/utils/machine-helpers";
import UiAlert from "@/components/UI/UiAlert/UiAlert";
import UiHeader from "@/components/UI/UiHeader/UiHeader";
import UiHeadline from "@/components/UI/UiHeadline/UiHeadline";
import UiLoader from "@/components/UI/UiLoader/UiLoader";
import UiMain from "@/components/UI/UiMain/UiMain";
import UiButton from "@/components/UI/UiButton/UiButton";
import UiModal from "@/components/UI/UiModal/UiModal.vue";
import GuaranteesList from "@/components/Guarantees/GuaranteesList/GuaranteesList";
import GuaranteesListControls from "@/components/Guarantees/GuaranteesListControls/GuaranteesListControls";
import Stats from "@/components/Stats/Stats";
import GenerateReport from "@/components/Reports/GenerateReport";
import { DocumentIcon, DocumentDuplicateIcon } from "@vue-hero-icons/outline";

export default {
  setup(_, context) {
    // Setup our state machines...
    const { state: organizationState } = useService(organizationService);

    const { state: guaranteesState, send: sendToGuaranteesService } = useMachine(
      guaranteesMachine,
      {
        context: {
          filters: context.root.$route.query || {}
        }
      }
    );

    watchState(guaranteesState, "Guarantees");

    const isLandlord = computed(
      () => organizationState?.value?.context?.organization?.role === "landlord"
    );

    const role = computed(() => organizationState?.value?.context?.organization?.role);

    const guarantees = computed(() => guaranteesState.value?.context?.guarantees);

    // Note: These filters don't update the search input field
    const filters = computed(() => guaranteesState.value?.context?.filters);

    const errors = computed(() => guaranteesState.value?.context?.errors);

    const loading = computed(
      () =>
        guaranteesState.value?.matches("loading") ||
        guaranteesState.value?.matches("loaded.filtering") ||
        guaranteesState.value?.matches("loaded.searching") ||
        guaranteesState.value?.matches("deleting")
    );

    const canLoadMore = computed(() => guaranteesState.value?.context?.paginationKey !== null);

    const canCreateGuarantee = computed(() => {
      const creators = ["landlord", "tenant", "agent"];

      return creators.includes(role.value);
    });

    const canUpdateFacilityAmount = computed(() => {
      return facilityActive.value && role.value === "tenant";
    });

    const createGuaranteeLabel = computed(() => {
      return role.value === "landlord" ? "Upload Existing" : "Start New Application";
    });

    const modalVisible = ref(false);

    const closeModal = () => {
      modalVisible.value = false;
    };

    const showModal = () => {
      modalVisible.value = true;
    };

    const facilityActive = computed(
      () => !!organizationState?.value?.context?.organization?.facilities?.length
    );

    const createDraftApplication = bondType => {
      sendToGuaranteesService("CREATE_DRAFT", {
        guaranteeType: bondType
      });
      closeModal();
    };

    const onCreateGuaranteeButtonClick = () => {
      if (role.value === "tenant" || role.value === "agent") {
        if (facilityActive.value || !features.methods.featureEnabled("facility")) {
          createDraftApplication("LEASE_BOND");
        } else {
          showModal();
        }
      } else if (role.value === "landlord") {
        createDraftApplication("BANK_GUARANTEE");
      } else {
        createDraftApplication("LEASE_BOND");
      }
    };

    // Return what we want to expose to the template...
    return {
      canCreateGuarantee,
      canLoadMore,
      createGuaranteeLabel,
      errors,
      loading,
      organizationState,
      filters,
      guarantees,
      guaranteesState,
      role,
      sendToGuaranteesService,
      modalVisible,
      closeModal,
      showModal,
      onCreateGuaranteeButtonClick,
      createDraftApplication,
      isLandlord,
      canUpdateFacilityAmount
    };
  },
  metaInfo: {
    title: "Guarantees"
  },
  name: "Guarantees",
  components: {
    GenerateReport,
    GuaranteesList,
    GuaranteesListControls,
    Stats,
    UiAlert,
    UiButton,
    UiLoader,
    UiHeader,
    UiHeadline,
    UiMain,
    UiModal,
    DocumentIcon,
    DocumentDuplicateIcon
  }
};
</script>
