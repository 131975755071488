<template>
  <div>
    <div v-if="state.matches('loaded')" class="stats" data-testid="stats">
      <!-- general guarantee stats -->
      <div
        v-for="(stat, i) in stats"
        :key="i"
        class="stats-card-wrap"
        :class="statsCardWrapWidthStyles"
      >
        <StatsCard
          class="stats-card"
          :data="stat.value"
          :label="stat.title"
          :meta="stat.subtitle"
          :show-indicator="stat.showIndicator"
        >
          <button
            v-if="stat.filters"
            slot="action"
            class="outline-none"
            @click="onFilterClick(stat.filters)"
          >
            View
          </button>
        </StatsCard>
      </div>
      <!-- facility stats -->
      <div
        v-for="stat in facilityStats"
        :key="stat.id"
        class="stats-card-wrap"
        :class="statsCardWrapWidthStyles"
      >
        <StatsCard
          class="stats-card"
          :data="formatFacilityStatData(stat.remainingAmount)"
          label="Facility Amount Available"
          :meta="formatFacilityStatMeta(stat)"
          :show-indicator="false"
        >
        </StatsCard>
      </div>
    </div>

    <div v-if="state.matches('error')" class="stats-error">
      Sorry we couldn't load the stats right now. Would you like to
      <UiButton theme="link" button-class="italic" remove-padding @click="send('RETRY')"
        >retry?</UiButton
      >
    </div>
  </div>
</template>

<script>
import { format } from "date-fns";
import pickBy from "lodash.pickby";
import { computed, inject } from "@vue/composition-api";
import { useMachine } from "@xstate/vue";
import statsMachine from "./stats-machine";
import StatsCard from "@/components/Cards/StatsCard/StatsCard.vue";
import UiButton from "@/components/UI/UiButton/UiButton";
import { toFinancialNumber } from "@/utils/financial";
import pluralize from "pluralize";
import abbreviate from "number-abbreviate";

export default {
  name: "Stats",
  components: { StatsCard, UiButton },
  setup() {
    // Setup our state machine...
    const { state, send } = useMachine(statsMachine);
    const organization = inject("organization");

    //watchState(state, "Stats");

    send("LOAD");

    const stats = computed(() => {
      return state.value?.context?.stats;
    });

    const facilityStats = computed(() => {
      return organization.value?.facilities || [];
    });

    const statsTotal = computed(() => stats.value?.length + facilityStats.value?.length);

    const statsCardWrapWidthStyles = computed(() =>
      statsTotal.value > 3
        ? "w-full md:w-1/3 md:max-w-xs lg:w-1/4 lg:max-w-sm"
        : "w-full md:w-1/3 md:max-w-xs lg:w-1/3 lg:max-w-sm"
    );

    return {
      send,
      state,
      stats,
      facilityStats,
      statsCardWrapWidthStyles
    };
  },
  props: {
    sendToGuaranteesService: {
      type: Function,
      required: true
    }
  },
  methods: {
    onFilterClick(statFilters) {
      // Check the filer has a value...
      const formattedFilters = pickBy(statFilters, value => value);

      // Map the formattedFilters properties and create an array of filters to pass to the guarantees machine...
      const filters = Object.entries(formattedFilters).map(([type, value]) => ({
        type,
        value
      }));

      this.sendToGuaranteesService("FILTER", { filters, resetFilters: true });
    },
    formatFacilityStatData(value) {
      return `$${abbreviate(value, 1)}`;
    },
    formatFacilityStatMeta(stat) {
      return `${toFinancialNumber(
        stat.allocatedAmount
      )} has been utilised by ${stat.guaranteesCount || 0} ${pluralize(
        "bond",
        stat.guaranteesCount || 0
      )}. The review date for this facility is ${format(new Date(stat.expiry), "d MMM")}`;
    }
  }
};
</script>

<style scoped>
/* Base Styles */
.stats {
  @apply flex flex-wrap justify-between mb-4 -mx-2;
}

.stats-error {
  @apply text-sm mb-4 text-cool-gray-500 italic;
}

.stats-card-wrap {
  @apply w-full mb-4 px-2 flex items-center;
}
</style>
