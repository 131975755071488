<template>
  <div
    :class="
      `rounded-sm p-3  border-b border-gray-200 cursor-pointer hover:bg-white text-primary select-none ${isActive &&
        'bg-gray-200'}`
    "
    @click="selectSession"
  >
    <div class="flex items-center">
      <div class="ml-4">
        <div class="text-sm font-bold text-primary">
          {{ formattedSessionName }}
        </div>
      </div>
      <div class="ml-auto">
        <div>
          <span
            :class="
              ` ml-auto inline-block py-0.5 px-3 text-xs rounded-full ${
                isActive ? 'bg-white' : 'bg-gray-200'
              }`
            "
          >
            {{ total }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChatSessionItem",
  props: {
    sessionId: {
      type: String,
      default: () => ""
    },
    sessionName: {
      type: String,
      default: () => ""
    },
    total: {
      type: Number,
      default: () => 0
    },
    isActive: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {};
  },
  computed: {
    formattedSessionName() {
      return this.sessionName?.replace(/admin/g, "eGuarantee");
    }
  },
  mounted() {},
  methods: {
    selectSession() {
      this.$emit("select-session", this.sessionId);
    }
  }
};
</script>
