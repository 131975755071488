<template>
  <div v-if="currentRole" class="flex rounded-lg m-auto border border-gray-200 console">
    <ChatSidebarArea
      :current-filter="currentSession"
      :session-list="sessionList"
      @select-session="selectSession"
    />
    <ChatMainContentArea
      :messaging-allowed="guarantee.permissions.messaging"
      :messages="filtered(currentSession)"
      :send-to-guarantee-machine="sendToGuaranteeMachine"
      :guarantee-id="guaranteeId"
      :recipient-options="availableRecipientOptions"
      @create-new-group="createNewSession"
    />
  </div>
</template>

<script>
import ChatMainContentArea from "@/components/Chat/ChatMainContentArea.vue";
import ChatSidebarArea from "@/components/Chat/ChatSidebarArea.vue";
import { filter, find, includes, orderBy, uniqBy } from "lodash";
import { parseISO } from "date-fns";
import { getUserAttributes } from "@/lib/auth";

export default {
  name: "ChatConsole",
  components: {
    ChatMainContentArea,
    ChatSidebarArea
  },
  props: {
    guaranteeId: {
      type: String,
      required: true
    },
    guarantee: {
      type: Object,
      required: true,
      default: () => {}
    },
    messages: {
      type: Array,
      default: () => []
    },
    sendToGuaranteeMachine: {
      type: Function,
      required: true
    },
    currentRole: {
      type: String,
      default: () => ""
    }
  },
  provide() {
    return {
      recipients: this.reactive
    };
  },
  data() {
    return {
      recipients: [],
      temporalySessions: [],
      currentChatSessionId: "",
      organizationId: "",
      recipientOptions: [
        { label: "Tenant", value: "tenant" },
        { label: "Landlord", value: "landlord" },
        { label: "Guarantor", value: "guarantor" },
        { label: "Agent/Tenant Rep/Broker", value: "agent" },
        { label: "eGuarantee", value: "admin" }
      ],
      reactive: {
        activeRecipients: []
      }
    };
  },

  computed: {
    sessionList() {
      return this.temporalySessions.concat(
        uniqBy(this.messages, "sessionId").map(group => {
          return {
            sessionId: group.sessionId,
            sessionName: group.sessionName,
            total: this.filtered(group.sessionId).length,
            recipients: group.sessionId
              .split(", ")
              .filter(recipient => recipient !== this.currentRole)
              .sort()
          };
        })
      );
    },
    currentSession() {
      return (
        this.currentChatSessionId || (this.sessionList[0] && this.sessionList[0].sessionId) || ""
      );
    },
    availableRecipientOptions() {
      return this.recipientOptions.filter(r => r.value !== this.currentRole);
    }
  },
  watch: {
    currentSession(newSessionId) {
      const session = this.sessionList.find(s => s.sessionId === newSessionId);
      if (session) {
        this.reactive.activeRecipients = session.recipients;
      }
    }
  },
  mounted() {
    this.getOrganizationId();

    this.reactive.activeRecipients = (this.sessionList[0] && this.sessionList[0].recipients) || "";

    const messageId = localStorage.getItem("activeChatMessageId");
    if (messageId) {
      this.selectSessionByMessage(messageId);
      localStorage.removeItem("activeChatMessageId");
    }
  },
  methods: {
    async getOrganizationId() {
      this.organizationId = await getUserAttributes("custom:organizationId");
    },
    landlord() {
      return find(this.guarantee.fields || [], { id: "landlord" });
    },
    tenant() {
      return find(this.guarantee.fields || [], { id: "tenant" });
    },
    guarantor() {
      return find(this.guarantee.fields || [], { id: "guarantor" });
    },
    filtered(sessionId) {
      //filtering by role
      let result = filter(
        this.messages,
        m => m.sessionId === sessionId || (m.isSender && includes(m.recipients, sessionId))
      );
      // sorting in descending order
      result = orderBy(result, re => parseISO(re.sentDate).getTime(), ["asc"]);
      return result;
    },
    selectSession(sessionId) {
      this.currentChatSessionId = sessionId;
    },
    selectSessionByMessage(messageId) {
      const message = this.messages.find(message => message.id === messageId);
      if (message) {
        this.selectSession(message.sessionId);
      }
    },
    createNewSession({ recipients, closeRecipientSelector }) {
      this.reactive.activeRecipients = recipients;
      const newSessionId = [...recipients, this.currentRole].sort().join(", ");
      const newSessionName = [...recipients, this.currentRole]
        .filter(recipient => recipient !== this.currentRole)
        .sort()
        .join(", ");
      const isSessionExist = this.sessionList.find(session => session.sessionId === newSessionId);
      if (isSessionExist) {
        alert("This chat group already exist.");
      } else {
        closeRecipientSelector();
        this.temporalySessions.unshift({
          sessionId: newSessionId,
          sessionName: newSessionName,
          total: 0,
          type: "temp",
          recipients
        });
        this.currentChatSessionId = newSessionId;
      }
    }
  }
};
</script>

<style scoped>
.console {
  height: 750px !important;
}
</style>
