<template>
  <div
    :class="
      `w-10 h-10 rounded-full ${avatarColor} flex justify-center items-center text-gray-500 font-semibold`
    "
  >
    {{ avatarText }}
  </div>
</template>

<script>
import roleColorMapping from "@/utils/role-color-mapping";

export default {
  name: "UiTextAvatar",
  props: {
    name: {
      type: String,
      default: () => ""
    },
    role: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return { roleColorMapping };
  },
  computed: {
    avatarText() {
      const nameSegments = this.name.split(" ");
      return nameSegments.length > 1
        ? `${nameSegments[0] && nameSegments[0][0]}${nameSegments[1] && nameSegments[1][0]}`
        : "";
    },
    avatarColor() {
      return this.roleColorMapping[this.role]
        ? `bg-${this.roleColorMapping[this.role]}`
        : "bg-gray-100";
    }
  },
  methods: {
    handleTabSelect(tab) {
      this.$emit("on-select", tab);
    }
  }
};
</script>
