<template>
  <div v-if="contentVisible">
    <UiHeader>
      <UiHeadline :size="2">Manage Users</UiHeadline>
    </UiHeader>
    <UiMain v-if="organizationDataReady">
      <div class="py-8">
        <div class="flex flex-wrap">
          <div class="w-full mb-6 md:w-gr-small md:pr-6 lg:pr-12 xl:pr-16 md:mb-0">
            <UiHeadline :level="2" :size="5">User Roles</UiHeadline>
            <p class="settings-subtitle"></p>
          </div>

          <div class="w-full md:w-gr-large">
            <UserRoles
              :permissions="organization.permissions"
              :user-roles="userRoles"
              :editing="editingUserRole"
              :editing-role="editingRole"
              :organization="organization"
              :saving="profileState.matches('savingUserRole')"
              :read-only="isReadOnly"
              @edit="onEditUserRole"
              @add="onAddUserRole"
              @select-perm="onSelectPerm"
              @cancel="resetEditUserRole"
              @save="onSaveUserRole"
              @remove="onRemoveUserRole"
            />
          </div>
        </div>
      </div>

      <div class="py-8">
        <div class="flex flex-wrap">
          <div class="w-full mb-6 md:w-gr-small md:pr-6 lg:pr-12 xl:pr-16 md:mb-0">
            <UiHeadline :level="2" :size="5">Team Members</UiHeadline>

            <p class="settings-subtitle">
              Team members who can access and action guarantees on behalf of this organisation.
            </p>
          </div>

          <div class="w-full md:w-gr-large">
            <TeamMembers
              :key="users.length"
              :permissions="organization.permissions"
              :team="users"
              :user-roles="userRoles"
              :user-groups="userGroups"
              :send-to-organization-service="sendToProfileService"
              :read-only="isReadOnly"
              :remove-loading="profileState.matches('ready.removingTeamMember')"
              :invite-loading="profileState.matches('ready.invitingTeamMember')"
              @save="onSaveUser"
            />
          </div>
        </div>
      </div>

      <div v-if="showUserGroups()" class="py-8">
        <div class="flex flex-wrap">
          <div class="w-full mb-6 md:w-gr-small md:pr-6 lg:pr-12 xl:pr-16 md:mb-0">
            <UiHeadline :level="2" :size="5">User Groups</UiHeadline>

            <p class="settings-subtitle">
              Allocate your users to a group to grant access to bonds that fall within its
              addresses. When a user is added to multiple groups, their access is the combination of
              all addresses across groups containing the user.
            </p>
          </div>

          <div class="w-full md:w-gr-large">
            <UserGroups
              :key="userGroups.length"
              :organization="organization"
              :user-groups="userGroups"
              :users="users"
              :read-only="isReadOnly"
              :creating="profileState.matches('ready.creatingUserGroup')"
              :updating="profileState.matches('ready.updatingUserGroup')"
              :removing="profileState.matches('ready.removingUserGroup')"
              :edit-on-create="editGroupOnCreate"
              @create="onCreateUserGroup"
              @save="onSaveUserGroup"
              @delete="onDeleteUserGroup"
            />
          </div>
        </div>
      </div>

      <div class="py-8">
        <div class="flex flex-wrap">
          <div class="w-full mb-6 md:w-gr-small md:pr-6 lg:pr-12 xl:pr-16 md:mb-0">
            <UiHeadline :level="2" :size="5">User Activities</UiHeadline>

            <p class="settings-subtitle">
              Report of user activities.
            </p>
          </div>

          <div class="w-full md:w-gr-large">
            <UiCard theme="primary">
              <div class="flex justify-end">
                <GenerateReport report-type="userActivity" />
              </div>
            </UiCard>
          </div>
        </div>
      </div>
    </UiMain>
  </div>
</template>

<script>
import { useService, useMachine } from "@xstate/vue";
import { computed, watch } from "@vue/composition-api";
import { find, includes } from "lodash";
import { organizationService as profileService } from "@/main";
import GenerateReport from "@/components/Reports/GenerateReport";
import organizationMachine from "@/components/Organizations/organization-machine";
import UiCard from "@/components/UI/UiCard/UiCard";
import UiHeader from "@/components/UI/UiHeader/UiHeader";
import UiHeadline from "@/components/UI/UiHeadline/UiHeadline";
import UiMain from "@/components/UI/UiMain/UiMain";
import UserRoles from "@/components/Settings/UserRoles";
import TeamMembers from "@/components/Settings/TeamMembers";
import UserGroups from "@/components/Settings/UserGroups";

export default {
  name: "ManageUsers",
  components: {
    TeamMembers,
    UserRoles,
    UiHeader,
    UiHeadline,
    UiMain,
    UserGroups,
    UiCard,
    GenerateReport
  },
  setup(props, context) {
    const { state: profileState, send: sendToProfileService } = useService(profileService);

    const { state: organizationState, send: sendToOrganizationService } = useMachine(
      organizationMachine
    );

    const organizationId = computed(() => context.root?.$route?.params?.id);

    const isAdmin = computed(() => profileState?.value?.context?.organization?.role === "admin");

    watch(isAdmin, currentValue => {
      if (organizationId.value && currentValue) {
        sendToOrganizationService("LOAD_ORGANIZATION", { organizationId: organizationId.value });
      }
    });

    const organizationMatchByRouteParam = computed(() => {
      return organizationState?.value?.context?.organization ?? {};
    });

    const currentLoggedInOrganization = computed(() => {
      return profileState?.value?.context?.organization ?? {};
    });

    const organization = organizationId.value
      ? organizationMatchByRouteParam
      : currentLoggedInOrganization;

    const organizationDataReady = computed(() =>
      organizationId.value
        ? organizationState?.value?.matches("loaded")
        : profileState?.value?.matches("ready")
    );

    const userRoles = computed(() => organization?.value?.userRoles ?? []);
    const users = computed(() => organization?.value?.users ?? []);
    const userGroups = computed(() => organization?.value?.userGroups ?? []);

    const contentVisible = computed(() => {
      if (organizationId.value && !isAdmin.value) {
        return false;
      }
      return true;
    });
    const isReadOnly = !!organizationId.value;

    return {
      organization,
      profileState,
      sendToProfileService,
      users,
      userRoles,
      userGroups,
      editingUserRole: false,
      addingUserRole: false,
      editingRole: {},
      editGroupOnCreate: false,
      organizationDataReady,
      contentVisible,
      isReadOnly
    };
  },
  methods: {
    showUserGroups() {
      return true;
    },
    onEditUserRole(roleId) {
      this.editingRole = find(this.userRoles, { id: roleId });
      this.editingUserRole = true;
    },
    onAddUserRole() {
      this.editingRole = {
        id: undefined,
        name: "",
        description: "",
        permissions: ["READ"]
      };
      this.editingUserRole = true;
    },
    onSelectPerm(perm) {
      if (includes(this.editingRole.permissions, perm)) {
        return (this.editingRole.permissions = this.editingRole.permissions.filter(
          p => p !== perm
        ));
      }
      return this.editingRole.permissions.push(perm);
    },
    onSaveUserRole() {
      this.sendToProfileService({ type: "SAVE_USER_ROLE", userRole: this.editingRole });
      this.resetEditUserRole();
    },
    resetEditUserRole() {
      this.editingRole = {};
      this.editingUserRole = false;
    },
    onRemoveUserRole(roleId) {
      for (const user of this.users) {
        const roleToCheck = user.userRoles?.find(role => role.id === roleId);
        if (roleToCheck) {
          return alert(
            `Cannot remove role ${roleToCheck.name} as it is currently assigned to a user. Please unassign the role from all users before removing.`
          );
        }
      }

      const roleName = this.userRoles.find(role => role.id === roleId)?.name;

      if (
        confirm(`Are you sure you want to remove ${roleName ? `role ${roleName}` : "this role"}?`)
      ) {
        this.sendToProfileService({ type: "REMOVE_USER_ROLE", userRoleId: roleId });
      }
    },
    onCreateUserGroup(userGroup) {
      const existingGroup = this.userGroups.find(
        group => group.name.toUpperCase() === userGroup.name.toUpperCase()
      );
      if (existingGroup) {
        return alert(
          `A user group with name '${userGroup.name}' already exists. Please choose another name.`
        );
      }
      this.sendToProfileService({ type: "CREATE_USER_GROUP", userGroup });
      this.editGroupOnCreate = true;
    },
    onSaveUserGroup(userGroup) {
      this.sendToProfileService({ type: "SAVE_USER_GROUP", userGroup });
    },
    onDeleteUserGroup(userGroupId) {
      this.sendToProfileService({ type: "REMOVE_USER_GROUP", userGroupId });
    },
    onSaveUser(user) {
      this.sendToProfileService({ type: "UPDATE_USER", user });
    }
  }
};
</script>

<style scoped>
.settings-subtitle {
  @apply mt-2 text-sm text-gray-600;
}
</style>
