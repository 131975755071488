<template>
  <UiField :theme="theme" :label="label" :class="!padding ? 'no-padding' : ''" full-width-meta>
    <div class="relative flex flex-wrap items-baseline -mx-2">
      <div class="w-full px-2">
        <UiInput
          v-model="searchInput"
          type="text"
          :disabled="disabled"
          placeholder="Start typing address"
          @input="onSearchInput($event)"
        >
          <slot slot="errors" name="streetErrors" />
        </UiInput>
      </div>

      <!-- AUTOCOMPLETIONS -->
      <ul
        v-if="addressSearchState.matches('withAddressCompletions')"
        v-click-outside="onHideCompletions"
        class="address-completions"
      >
        <li
          v-for="completion in addressCompletions"
          :key="completion.id"
          class="address-completion-item"
          :data-id="completion.id"
          @click="onStreetAutoCompleteClick"
        >
          {{ completion.full_address }}
        </li>
      </ul>

      <div
        v-if="addressSearchState.matches('withoutAddressCompletions') && searchInput"
        v-click-outside="onHideCompletions"
        class="address-completions"
      >
        <button class="address-completion-item focus:outline-none" @click="onManualAddressEnter">
          Address not found... click to enter manually.
        </button>
      </div>
    </div>

    <div v-if="addressFieldsActive" class="mt-4">
      <div class="flex flex-wrap items-baseline -mx-2">
        <!-- UNIT -->
        <div class="w-full px-2 sm:w-1/2">
          <UiInput
            v-model="address.unit"
            type="text"
            :disabled="disabled"
            :error="streetError"
            :name="`${name}.unit`"
            placeholder="(Unit 5 or Level 14)"
            class="mb-4"
            @input="onFieldInput('street', $event)"
          >
            <slot slot="errors" name="streetErrors" />
          </UiInput>
        </div>
        <!-- STREET -->
        <div class="w-full px-2 sm:w-1/2">
          <UiInput
            v-model="address.street"
            type="text"
            :disabled="disabled"
            :error="streetError"
            :name="`${name}.street`"
            placeholder="30 George St"
            class="mb-4"
            @input="onFieldInput('street', $event)"
          >
            <slot slot="errors" name="streetErrors" />
          </UiInput>
        </div>
      </div>
      <div class="flex flex-wrap items-baseline -mx-2">
        <!-- SUBURB -->
        <div class="w-full px-2 sm:w-1/2">
          <UiInput
            v-model="address.suburb"
            type="text"
            :disabled="disabled"
            :error="suburbError"
            :name="`${name}.suburb`"
            placeholder="Sydney"
            class="mb-4"
            @input="onFieldInput('suburb', $event)"
          >
            <slot slot="errors" name="suburbErrors" />
          </UiInput>
        </div>
        <!-- STATE -->
        <div class="w-full px-2 sm:w-1/2">
          <UiSelect
            v-model="address.state"
            :disabled="disabled"
            :error="stateError"
            class="mb-4"
            :name="`${name}.state`"
            placeholder="NSW"
            :options="states"
            @input="onFieldInput('state', $event)"
          >
            <slot slot="errors" name="stateErrors" />
          </UiSelect>
        </div>
      </div>

      <div class="flex flex-wrap items-baseline -mx-2">
        <!-- POSTCODE -->
        <div class="w-full px-2 sm:w-1/2">
          <UiInput
            v-model="address.postcode"
            type="text"
            :disabled="disabled"
            :error="postcodeError"
            :name="`${name}.postcode`"
            placeholder="2000"
            class="mb-4"
            @input="onFieldInput('postcode', $event)"
          >
            <slot slot="errors" name="postcodeErrors" />
          </UiInput>
        </div>
        <!-- COUNTRY -->
        <div class="w-full px-2 sm:w-1/2">
          <UiSelect
            v-model="address.country"
            :disabled="disabled"
            :error="countryError"
            class="mb-4 sm:mb-0"
            :name="`${name}.country`"
            placeholder="Australia"
            :options="countries"
            @input="onFieldInput('country', $event)"
          >
            <slot slot="errors" name="countryErrors" />
          </UiSelect>
        </div>
      </div>
    </div>
  </UiField>
</template>

<script>
import { computed, ref } from "@vue/composition-api";
import { useMachine } from "@xstate/vue";
import { watchState } from "@/utils/machine-helpers";
import addressSearchMachine from "@/components/AddressSearchInput/address-search-machine";
import UiField from "@/components/UI/UiField/UiField";
import UiInput from "@/components/UI/UiInput/UiInput";
import UiSelect from "@/components/UI/UiSelect/UiSelect";
import states from "@/utils/states";
import countries from "@/utils/countries";

export default {
  name: "AddressSearchInput",
  components: { UiField, UiInput, UiSelect },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    countryError: {
      type: Boolean,
      default: false
    },
    postcodeError: {
      type: Boolean,
      default: false
    },
    stateError: {
      type: Boolean,
      default: false
    },
    streetError: {
      type: Boolean,
      default: false
    },
    suburbError: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {}
    },
    padding: {
      type: Boolean,
      default: true
    },
    theme: {
      type: String,
      default: "secondary"
    }
  },
  setup(props, { emit }) {
    const machineOptions = {
      context: {
        emit
      }
    };

    // Setup our state machine...
    const { state: addressSearchState, send: sendToAddressSearchService } = useMachine(
      addressSearchMachine,
      machineOptions
    );

    watchState(addressSearchState, "AddressSearch");

    const error = computed(() => addressSearchState?.value?.context?.error ?? []);

    const addressCompletions = computed(
      () => addressSearchState?.value?.context?.addressCompletions ?? []
    );

    const address = computed(() => props.value || addressSearchState?.value?.context?.address);

    const showAddressFields = ref(false);

    const addressFieldsActive = computed(() => {
      return (
        showAddressFields.value ||
        address?.value?.street ||
        address?.value?.suburb ||
        address?.value?.state ||
        address?.value?.postcode ||
        address?.value?.country
      );
    });

    const searchInput = ref("");

    const onManualAddressEnter = () => {
      showAddressFields.value = true;
      searchInput.value = "";
      sendToAddressSearchService("RESET");
    };

    const onHideCompletions = () => {
      sendToAddressSearchService("HIDE_COMPLETIONS");
    };

    // Return what we want to expose to the template...
    return {
      address,
      addressCompletions,
      addressSearchState,
      countries,
      error,
      onHideCompletions,
      onManualAddressEnter,
      searchInput,
      sendToAddressSearchService,
      addressFieldsActive,
      states
    };
  },
  methods: {
    onSearchInput(value) {
      this.sendToAddressSearchService("ADDRESS_AUTO_COMPLETE", {
        street: value
      });
    },
    onFieldInput(type, value) {
      this.$emit("input", this.address);
    },
    onStreetAutoCompleteClick(event) {
      const addressId = event.target?.dataset?.id;

      if (addressId) {
        const result = this.sendToAddressSearchService({
          type: "FETCH_ADDRESS_DETAILS",
          data: { addressId }
        });

        this.searchInput = "";
      }
    }
  }
};
</script>

<style scoped>
.address-completions {
  @apply absolute z-10 mx-2 bg-cool-gray-50 border rounded-md shadow-lg top-10 overflow-hidden;

  @screen md {
    @apply top-11;
  }
}

.address-completion-item {
  @apply p-2 text-xs border-b cursor-pointer text-cool-gray-600;

  &:hover {
    @apply bg-cool-gray-200 text-tertiary;
  }

  @screen md {
    @apply text-sm px-3;
  }
}
</style>
