<template>
  <div class="flex flex-wrap items-center mb-4">
    <div class="w-full mb-4 md:w-1/2 md:mb-0">
      <div class="flex flex-wrap items-center">
        <!-- GUARANTEE TYPE -->
        <SelectFilter
          label="Type"
          type="guaranteeType"
          :options="guaranteeTypeOptions"
          :value="guaranteeType"
          @input="onFilterChange"
        />

        <!-- STATUS -->
        <SelectFilter
          label="Status"
          type="primaryStatus"
          :options="statusOptions"
          :value="primaryStatus"
          @input="onFilterChange"
        />

        <div class="date-filters">
          <!-- ISSUED DATE -->
          <DateFilter
            label="Issue Date"
            type="issueDate"
            :value="issueDate"
            @input="onFilterChange"
          />

          <!-- EXPIRY DATE -->
          <DateFilter
            label="Expiry Date"
            type="expiryDate"
            class="ml-2"
            :value="expiryDate"
            @input="onFilterChange"
          />
        </div>
      </div>
      <!-- .flex -->
    </div>
    <!-- .w-full md:w-1/2 -->

    <div class="w-full md:flex-1">
      <UiInput
        v-stream:input="searchInput$"
        type="search"
        name="search"
        :value="guaranteeSearchInput"
        :loading="loading"
        :placeholder="guaranteeSearchPlaceholder"
        class="gdtc-search"
        :dropdown-items="guaranteeSearchFields"
        :dropdown-value="guaranteeSearchFieldsValue"
        @dropdown-input="onSearchDropdownInput"
      />
    </div>
    <!-- .w-full md:w-1/2 -->
  </div>
  <!-- .flex flex-wrap -->
</template>

<script>
import { Subject } from "rxjs";
import { debounceTime, map, tap } from "rxjs/operators";
import UiInput from "@/components/UI/UiInput/UiInput";
import DateFilter from "@/components/Guarantees/GuaranteesListControls/DateFilter";
import SelectFilter from "@/components/Guarantees/GuaranteesListControls/SelectFilter";

const guaranteeSearchFields = [
  { label: "Guarantee #", value: "guaranteeNumber" },
  { label: "Bond Number", value: "externalId" },
  { label: "Landlord", value: "landlordName" },
  { label: "Tenant", value: "tenantName" },
  { label: "Address", value: "address" }
];

const statusOptions = [
  { label: "All", value: "" },
  { label: "Active", value: "ACTIVE" },
  { label: "Application", value: "APPLICATION" },
  { label: "Completed", value: "COMPLETED" },
  { label: "Draft", value: "DRAFT" }
];

const guaranteeTypeOptions = [
  { label: "All", value: "ALL_BONDS" },
  { label: "Facility", value: "FACILITY" },
  { label: "Lease Bond", value: "LEASE_BOND" },
  { label: "Bank Guarantee", value: "BANK_GUARANTEE" }
];

export default {
  name: "GuaranteesListControls",
  components: { DateFilter, SelectFilter, UiInput },
  inject: ["organization"],
  props: {
    filters: {
      type: Object,
      default: () => {}
    },
    loading: {
      type: Boolean,
      default: false
    },
    sendToGuaranteesService: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      guaranteeSearchFields,
      guaranteeSearchFieldsValue: "",
      statusOptions,
      guaranteeTypeOptions
    };
  },
  computed: {
    currentRole() {
      return this.organization?.value?.role;
    },
    expiryDate() {
      return {
        from: this.filters?.expiryDateFrom || "",
        to: this.filters?.expiryDateTo || ""
      };
    },
    guaranteeSearchPlaceholder() {
      const placeholder = {
        guaranteeNumber: "Search for a guarantee number",
        externalId: "Search for a bond number",
        landlordName: "Search for a landlord",
        tenantName: "Search for a tenant",
        address: "Search for an address"
      };

      return placeholder[this.guaranteeSearchFieldsValue];
    },
    issueDate() {
      return {
        from: this.filters?.issueDateFrom || "",
        to: this.filters?.issueDateTo || ""
      };
    },
    primaryStatus() {
      return this.filters?.primaryStatus || "";
    },
    guaranteeType() {
      return this.filters?.guaranteeType || "";
    }
  },
  watch: {
    currentRole(role) {
      this.assignGuaranteeSearchFieldsValue(role);
    }
  },
  mounted() {
    this.assignGuaranteeSearchFieldsValue(this.currentRole);
  },
  methods: {
    onSearchDropdownInput(value) {
      this.guaranteeSearchFieldsValue = value;

      this.onSearch(this.guaranteeSearchInput);
    },
    assignGuaranteeSearchFieldsValue(role) {
      this.guaranteeSearchFieldsValue = role === "tenant" ? "guaranteeNumber" : "tenantName";
    },
    onFilterChange(filter) {
      // Send an event to the guarantees state machine with the filter settings
      this.sendToGuaranteesService("FILTER", { filters: [filter] });
    },
    onSearch(value) {
      const filter = {
        type: this.guaranteeSearchFieldsValue,
        value
      };

      this.sendToGuaranteesService("SEARCH", { filter });
    }
  },
  subscriptions() {
    // declare the receiving Subjects
    this.searchInput$ = new Subject();

    // ...then create subscriptions using the Subjects as source stream.
    // the source stream emits in the format of `{ event: HTMLEvent, data?: any }`
    return {
      guaranteeSearchInput: this.searchInput$.pipe(
        map(({ event }) => event.msg),
        debounceTime(1000),
        tap(value => this.onSearch(value))
      )
    };
  }
};
</script>

<style scoped>
/* purgecss start ignore */
.date-filters {
  @apply flex items-center relative;
  z-index: 1;
}
/* purgecss end ignore */
</style>
