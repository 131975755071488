<template>
  <div
    class="uploader-area"
    :class="
      `${draggingFileEnter ? 'border-2  border-dashed' : 'border border-gray-300 border-solid'}`
    "
  >
    <div :class="{ error: error || mimeTypeInvalid }">
      <input
        ref="fileInput"
        class="uploader-input"
        :disabled="disabled"
        type="file"
        :accept="mimeTypes"
        :multiple="multiple"
        @change="onChange"
        @dragenter="onDragEnter"
        @dragleave="onDragLeave"
      />

      <div class="text-center">
        <slot name="preview-image">
          <svg
            class="w-12 h-12 mx-auto text-gray-400"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </slot>

        <p class="mt-1 text-xs font-light text-cool-gray-400">
          <button type="button" class="uploader-area-button" :class="{ error }">
            Upload a file
          </button>
          or drag and drop
        </p>

        <p v-if="helpText" class="mt-1 text-xs font-light text-cool-gray-400">
          {{ helpText }}
        </p>

        <p v-if="mimeTypeInvalid" class="mt-1 text-xs text-red-500">
          That type of file is not allowed.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { isMimeTypeValid } from "@/utils/validations";

export default {
  name: "FileInput",
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    helpText: {
      type: String,
      default: ""
    },
    mimeTypes: {
      type: String,
      default: ""
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      mimeTypeInvalid: false,
      draggingFileEnter: false
    };
  },
  methods: {
    onChange(event) {
      this.mimeTypeInvalid = false;

      var files = event.target.files || event.dataTransfer.files;

      if (!files.length) return false;

      const fileList = Object.keys(files).map(key => files[key]);
      this.mimeTypeInvalid = fileList.some(file => !isMimeTypeValid(file.type, this.mimeTypes));

      if (!this.mimeTypeInvalid) {
        this.$emit("change", fileList);
      }
    },
    onDragEnter() {
      this.draggingFileEnter = true;
    },
    onDragLeave() {
      this.draggingFileEnter = false;
    }
  }
};
</script>

<style scoped>
.uploader-input {
  @apply w-full h-full absolute opacity-0 z-0 cursor-pointer;
  left: 0;
  top: 0;
}

.uploader-area {
  @apply flex justify-center w-full px-6 pt-5 pb-6 rounded-md shadow-sm cursor-pointer relative;

  &.error {
    @apply border-red-300;
  }
}

.uploader-area-button {
  @apply font-normal transition duration-150 ease-in-out text-primary;

  &:hover {
    @apply text-blue-500;
  }

  &:focus {
    @apply outline-none underline;
  }

  &.error {
    @apply text-red-600;
  }
}
</style>
