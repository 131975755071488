<template>
  <img
    src="@/assets/images/logos/eguarantee-logo.svg"
    alt="eGuarantee Logo"
    class="ui-brand-logo"
    data-testid="ui-brand-logo"
  />
</template>

<script>
export default {
  name: "UiBrandLogo"
};
</script>
