<template>
  <div class="ui-alert" :class="{ [theme]: theme, [size]: size }" data-testid="ui-alert">
    <button v-if="showClearButton" class="ui-alert-clear-button" @click="$emit('clear')">
      <XIcon size=".9x" />
    </button>

    <div class="flex">
      <div class="flex items-baseline flex-shrink-0">
        <CheckCircleIcon v-if="theme === 'success'" class="text-green-500 ui-alert-icon" />
        <XCircleIcon v-if="theme === 'warning'" class="text-red-500 ui-alert-icon" />
        <InformationCircleIcon v-if="theme === 'info'" class="text-blue-500 ui-alert-icon" />
        <ExclamationCircleIcon v-if="theme === 'notice'" class="text-yellow-500 ui-alert-icon" />
      </div>

      <div>
        <h3 v-if="title" class="ui-alert-title">
          {{ title }}
        </h3>

        <div
          v-if="items.length || content.length || $slots.default"
          class="ui-alert-content"
          data-cy="alertMessage"
        >
          <slot>
            <ul v-if="items" class="pl-5 space-y-2 list-disc">
              <li v-for="(item, i) in items" :key="i">
                {{ item }}
              </li>
            </ul>
            <ul v-if="content" class="space-y-2 list-disc">
              <p v-for="(c, i) in content" :key="i">
                {{ c }}
              </p>
            </ul>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  CheckCircleIcon,
  InformationCircleIcon,
  XCircleIcon,
  ExclamationCircleIcon,
  XIcon
} from "@vue-hero-icons/outline";

export default {
  name: "UiAlert",
  components: { CheckCircleIcon, InformationCircleIcon, ExclamationCircleIcon, XCircleIcon, XIcon },
  props: {
    items: {
      type: Array,
      default: () => []
    },
    content: {
      type: Array,
      default: () => []
    },
    theme: {
      type: String,
      default: ""
    },
    showClearButton: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: ""
    },
    timer: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ""
    }
  },
  mounted() {
    if (this.timer) this.clear();
  },
  methods: {
    clear() {
      // If we supply a timer clear the alert after the timer amount (multiplied by 1000 for seconds)...
      setTimeout(() => {
        this.$emit("clear");
      }, this.timer * 1000);
    }
  }
};
</script>

<style scoped>
/* Base Styles */
.ui-alert {
  @apply max-w-xl p-4 mb-4 rounded-md relative border shadow;
}

.ui-alert-icon {
  @apply h-5 mr-2;

  @screen md {
    @apply h-6;
  }
}

.ui-alert-title {
  @apply text-sm font-semibold leading-5;

  @screen md {
    @apply text-base leading-normal;
  }
}

.ui-alert-content {
  @apply mt-2 text-xs leading-5;

  @screen md {
    @apply text-sm;
  }
}

.ui-alert-clear-button {
  @apply absolute text-gray-500 outline-none;
  right: 5px;
  top: 5px;
}

/* Sizes */
.sm {
  @apply p-2 mb-2;

  .ui-alert-icon {
    @apply h-4 mr-1;

    @screen md {
      @apply h-5;
    }
  }

  .ui-alert-title {
    @apply text-sm leading-4;

    @screen md {
      @apply leading-5;
    }
  }

  .ui-alert-content {
    @apply mt-2 text-xs leading-3;
  }
}

/* Themes */
.warning {
  @apply bg-red-50 border-red-300;

  .ui-alert-title,
  .ui-alert-clear-button {
    @apply text-red-500;
  }

  .ui-alert-content {
    @apply text-red-500;
  }
}
.notice {
  @apply bg-yellow-50 border-yellow-300;

  .ui-alert-title,
  .ui-alert-clear-button {
    @apply text-yellow-500;
  }

  .ui-alert-content {
    @apply text-yellow-500;
  }
}

.info {
  @apply bg-blue-50 border-blue-200;

  .ui-alert-title,
  .ui-alert-clear-button {
    @apply text-blue-500;
  }

  .ui-alert-content {
    @apply text-blue-500;
  }
}

.success {
  @apply bg-green-100 border-green-300;

  .ui-alert-title,
  .ui-alert-clear-button {
    @apply text-green-500;
  }

  .ui-alert-content {
    @apply text-green-500;
  }
}
</style>
