import DefaultLayout from "@/layouts/DefaultLayout.vue";
import GetStartLayout from "@/layouts/GetStartLayout.vue";
import AuthLayout from "@/layouts/AuthLayout.vue";
import Guarantees from "@/views/Guarantees.vue";
import Guarantee from "@/views/Guarantee.vue";
import GuaranteeBond from "@/views/GuaranteeBond.vue";
import Settings from "@/views/Settings.vue";
import ManageUsers from "@/views/ManageUsers.vue";
import Documents from "@/views/Documents.vue";
import Register from "@/views/auth/Register.vue";
import ResetPassword from "@/views/auth/ResetPassword.vue";
import SignIn from "@/views/auth/SignIn.vue";
import Confirm from "@/views/auth/Confirm.vue";
import CreateAccount from "@/views/CreateAccount.vue";
import FourOhFour from "@/views/FourOhFour.vue";
import { isAuthenticated } from "@/lib/auth";
import { logger } from "@/utils/index";

const authRedirectCheck = async (to, from, next) => {
  try {
    const authenticated = await isAuthenticated();

    // If the user is authenticated then redirect them to the home route...
    if (authenticated) {
      return next({
        name: "Home"
      });
    }

    // Otherwise continue to the auth route...
    next();
  } catch (error) {
    logger.error(error, "authRedirectCheck");
    next();
  }
};

const ssoRedirectCheck = async (to, from, next) => {
  try {
    const authenticated = await isAuthenticated();

    // If the user is authenticated then redirect them to the home route...
    if (authenticated) {
      return next({
        name: "Home"
      });
    }
  } catch (error) {
    logger.error(error, "ssoRedirectCheck");
  }

  return next({
    name: "SignIn",
    params: {
      error:
        "Could not complete SSO Sign-in. Please email our support team (contact@eguarantee.com.au)"
    }
  });
};

const marketingRedirectCheck = async (to, from, next) => {
  try {
    if (to.meta.externalRedirect) {
      window.location.href = to.meta.externalRedirect;
    } else {
      next({ name: "404" });
    }
  } catch (error) {
    logger.error(error, "marketingRedirectCheck");
  }
};

export default [
  // Non-authenticated routes
  {
    name: "404",
    component: FourOhFour,
    path: "*",
    meta: { requiresAuth: false, layout: AuthLayout }
  },
  {
    path: "/get-on",
    name: "GetOn",
    redirect: "/get-started"
  },
  {
    path: "/get-started",
    name: "PreQualification",
    beforeEnter: marketingRedirectCheck,
    meta: { externalRedirect: "https://www.eguarantee.com.au/get-started" }
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    beforeEnter: marketingRedirectCheck,
    meta: { externalRedirect: "https://www.eguarantee.com.au/enquire-now" }
  },
  {
    path: "/landlord",
    name: "LandlordContactUs",
    beforeEnter: marketingRedirectCheck,
    meta: { externalRedirect: "https://www.eguarantee.com.au/landlord" }
  },
  {
    path: "/referrals",
    name: "Referrals",
    beforeEnter: marketingRedirectCheck,
    meta: { externalRedirect: "https://www.eguarantee.com.au/referral" }
  },
  {
    path: "/create-account",
    name: "CreateAccount",
    component: CreateAccount,
    meta: { requiresAuth: false, layout: GetStartLayout },
    beforeEnter: authRedirectCheck
  },
  {
    path: "/welcome",
    name: "Welcome",
    component: CreateAccount,
    meta: { requiresAuth: false, layout: GetStartLayout, welcome: true }
  },
  {
    path: "/login",
    name: "Login",
    redirect: "/sign-in"
  },
  {
    path: "/sign-in",
    name: "SignIn",
    component: SignIn,
    meta: { requiresAuth: false, layout: AuthLayout },
    beforeEnter: authRedirectCheck
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: ResetPassword,
    meta: { requiresAuth: false, layout: AuthLayout },
    beforeEnter: authRedirectCheck
  },
  // Redirects from auth links (i.e. emails)
  {
    path: "/register",
    name: "Register",
    component: Register,
    meta: { requiresAuth: false, layout: AuthLayout },
    beforeEnter: authRedirectCheck
  },
  {
    path: "/register-confirm",
    name: "Confirm",
    component: Confirm,
    meta: { requiresAuth: false, layout: AuthLayout },
    beforeEnter: authRedirectCheck
  },
  {
    path: "/redirect",
    name: "SSORedirect",
    beforeEnter: ssoRedirectCheck,
    meta: { requiresAuth: false }
  },
  // Authenticated routes
  {
    path: "/",
    name: "Home",
    redirect: "/guarantees",
    meta: { requiresAuth: true, layout: DefaultLayout }
  },
  {
    path: "/guarantees",
    name: "Guarantees",
    component: Guarantees,
    meta: { requiresAuth: true, layout: DefaultLayout }
  },
  {
    path: "/guarantee/:id",
    name: "Guarantee",
    component: Guarantee,
    meta: { requiresAuth: true, layout: DefaultLayout }
  },
  {
    path: "/guarantee/:id/draft-certificate",
    name: "GuaranteeBond",
    component: GuaranteeBond,
    meta: { requiresAuth: true }
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: { requiresAuth: true, layout: DefaultLayout }
  },
  {
    path: "/organisation/documents",
    name: "Documents",
    component: Documents,
    meta: { requiresAuth: true, layout: DefaultLayout }
  },
  {
    path: "/manage-users",
    name: "ManageUsers",
    component: ManageUsers,
    meta: { requiresAuth: true, layout: DefaultLayout }
  }
];
