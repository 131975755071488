<template>
  <div class="relative">
    <Component :is="tag" class="ui-card-wrap" :class="classObject" data-testid="ui-card">
      <div :class="{ 'ui-card-content': padding }">
        <slot />
      </div>
    </Component>
  </div>
</template>

<script>
export default {
  name: "UiCard",
  props: {
    enableCssOverflow: {
      type: Boolean,
      default: true
    },
    padding: {
      type: Boolean,
      default: true
    },
    size: {
      default: "base",
      type: String
    },
    tag: {
      default: "div",
      type: String
    },
    theme: {
      default: "",
      type: String
    }
  },
  computed: {
    classObject() {
      return {
        "overflow-hidden": !this.enableCssOverflow,
        [`ui-card-${this.theme}`]: this.theme,
        [`ui-card-${this.size}`]: this.size
      };
    }
  }
};
</script>

<style scoped>
/* purgecss start ignore */
/* Base Styles */

/* Sizes */
.ui-card-base {
  .ui-card-content {
    @apply px-4 py-5;

    @screen sm {
      @apply p-6;
    }
  }
}

.ui-card-sm {
  .ui-card-content {
    @apply px-2 py-3;

    @screen sm {
      @apply p-4;
    }
  }
}

/* Themes */
.ui-card-primary {
  @apply bg-white rounded-md shadow-md border border-gray-200;
}

.ui-card-secondary {
  @apply bg-cool-gray-50 rounded-md;
}

.ui-card-paper {
  @apply ui-card-primary relative;
  z-index: 3;
}

.ui-card-paper-layer-1,
.ui-card-paper-layer-2 {
  @apply ui-card-paper absolute h-full w-full;
}

.ui-card-paper-layer-1 {
  top: -5px;
  transform: rotate(0.3deg);
  z-index: 2;

  @screen md {
    transform: rotate(0.8deg);
  }
}

.ui-card-paper-layer-2 {
  top: -1px;
  transform: rotate(-0.3deg);
  z-index: 1;

  @screen md {
    transform: rotate(-0.8deg);
  }
}
</style>
