<template>
  <div class="mb-6">
    <nav aria-label="Progress">
      <ol
        role="list"
        class="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0 flex items-center w-full"
      >
        <div v-for="step in stepsInfo" :key="step.name" class="flex-grow">
          <div v-if="step.state === 'completed'">
            <UiStepsCompleted
              :name="step.name"
              :order="step.order"
              :is-first-step="isFirstStep(step.order)"
            />
          </div>
          <div v-if="step.state === 'current'">
            <UiStepsCurrent
              :name="step.name"
              :order="step.order"
              :is-first-step="isFirstStep(step.order)"
            />
          </div>
          <div v-if="step.state === 'upcoming'">
            <UIStepsUpcoming :name="step.name" :order="step.order" />
          </div>
        </div>
      </ol>
    </nav>
  </div>
</template>

<script>
import UiStepsCompleted from "./UiStepsCompleted.vue";
import UiStepsCurrent from "./UiStepsCurrent.vue";
import UIStepsUpcoming from "./UIStepsUpcoming.vue";

export default {
  name: "UiSteps",
  components: {
    UiStepsCompleted,
    UiStepsCurrent,
    UIStepsUpcoming
  },
  props: {
    steps: {
      type: Array,
      default: () => [],
      required: true
    },
    currentStep: {
      type: Number,
      default: () => 1,
      required: true
    }
  },
  computed: {
    stepsInfo() {
      return this.steps.map((step, index) => {
        const stepOrder = index + 1;
        let stepState = "";
        if (this.currentStep === stepOrder) {
          stepState = "current";
        } else if (this.currentStep > stepOrder) {
          stepState = "completed";
        } else {
          stepState = "upcoming";
        }
        return { name: step, state: stepState, order: stepOrder };
      });
    }
  },
  methods: {
    isFirstStep(stepOrder) {
      return stepOrder === 1;
    }
  }
};
</script>
