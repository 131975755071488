import Vue from "vue";
import toLower from "lodash.tolower";
import startCase from "lodash.startcase";
import isEmpty from "lodash.isempty";
import { format, parseISO } from "date-fns";
import abbreviate from "number-abbreviate";
import { toFinancialNumber } from "@/utils/financial";

Vue.filter("lowercase", function(value) {
  if (!value) return "";
  value = value.toString();
  return value.toLowerCase();
});

Vue.filter("capitalize", function(value) {
  if (!value) return "";
  value = value.toString();
  return startCase(toLower(value));
});

Vue.filter("formatDateTime", function(value) {
  if (isEmpty(value)) return "";
  return format(value, "d MMM yyyy HH:mm:ss");
});

Vue.filter("formatDate", function(value) {
  if (isEmpty(value)) return "";
  return format(value, "d MMM yyyy");
});

Vue.filter("formatDateTimeFromString", function(value) {
  if (isEmpty(value)) return "";
  return format(parseISO(value), "d MMM yyyy HH:mm:ss");
});

Vue.filter("formatDateFromString", function(value) {
  if (isEmpty(value)) return "";
  return format(parseISO(value), "d MMM yyyy");
});

Vue.filter("financial", function(value) {
  return toFinancialNumber(value);
});

Vue.filter("abbrvNumber", function(value) {
  return typeof value === "number" ? abbreviate(value, 1) : value;
});
