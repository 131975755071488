import gql from "graphql-tag";
import { GuaranteeParts } from "@/graphql/fragments";

export const CreateDraftApplication = gql`
  mutation CreateDraftApplication($input: CreateDraftApplicationInput!) {
    createDraftApplication(input: $input) {
      guaranteeId
      guaranteeNumber
      status {
        statusId
        isDraft
        label
        allowAmendments
      }
    }
  }
`;

export const SaveDraftApplication = gql`
  mutation SaveDraftApplication($input: SaveDraftApplicationInput!) {
    saveDraftApplication(input: $input) {
      guaranteeId
    }
  }
`;

export const SubmitDraftApplication = gql`
  mutation SubmitDraftApplication($input: SubmitDraftApplicationInput!) {
    submitDraftApplication(input: $input) {
      guarantee {
        guaranteeId
      }
      responseMessage {
        message
        type
      }
    }
  }
`;

export const DeleteApplication = gql`
  mutation DeleteApplication($input: DeleteApplicationInput!) {
    deleteApplication(input: $input) {
      guaranteeId
    }
  }
`;

export const UploadExistingGuarantee = gql`
  mutation UploadExistingGuarantee($input: UploadExistingGuaranteeInput!) {
    uploadExistingGuarantee(input: $input) {
      guarantee {
        guaranteeId
      }
      responseMessage {
        message
        type
      }
    }
  }
`;

export const PerformGuaranteeAction = gql`
  ${GuaranteeParts}
  mutation performGuaranteeAction($input: PerformGuaranteeActionInput!) {
    performGuaranteeAction(input: $input) {
      guarantee {
        ...GuaranteeParts
      }
      actionResponse {
        guaranteeId
      }
      responseMessage {
        message
        type
      }
    }
  }
`;

export const SaveActionInputs = gql`
  ${GuaranteeParts}
  mutation SaveActionInputs($input: PerformGuaranteeActionInput!) {
    saveActionInputs(input: $input) {
      guarantee {
        ...GuaranteeParts
      }
      responseMessage {
        message
        type
      }
    }
  }
`;

export const SuggestAmendments = gql`
  ${GuaranteeParts}
  mutation suggestAmendments($input: SuggestAmendmentsInput!) {
    suggestAmendments(input: $input) {
      ...GuaranteeParts
    }
  }
`;

export const ResolveAmendment = gql`
  ${GuaranteeParts}
  mutation resolveAmendment($input: ResolveAmendmentInput!) {
    resolveAmendment(input: $input) {
      ...GuaranteeParts
    }
  }
`;

export const AddContextitem = gql`
  mutation addContextItem($input: AddContextItemInput!) {
    addContextItem(input: $input) {
      guaranteeId
    }
  }
`;

export const AddAttachment = gql`
  mutation addAttachment($input: AddAttachmentToGuaranteeInput!) {
    addAttachment(input: $input) {
      guaranteeId
    }
  }
`;

export const RemoveAttachment = gql`
  mutation removeAttachment($input: RemoveAttachmentInput!) {
    removeAttachment(input: $input) {
      attachmentId
    }
  }
`;

export const RemoveMessage = gql`
  mutation removeMessage($input: RemoveMessageInput!) {
    removeMessage(input: $input) {
      messageId
    }
  }
`;

export const addAttachmentToOrganization = gql`
  mutation addAttachmentToOrganization($input: AddAttachmentToOrganizationInput!) {
    addAttachmentToOrganization(input: $input) {
      organizationId
    }
  }
`;

export const SendMessage = gql`
  mutation sendMessage($input: SendMessageInput!) {
    sendMessage(input: $input) {
      guaranteeId
    }
  }
`;

export const GenerateReport = gql`
  mutation generateReport {
    generateReport {
      url
    }
  }
`;

export const GenerateUserReport = gql`
  mutation generateUserReport($input: GenerateUserReportInput!) {
    generateUserReport(input: $input) {
      url
    }
  }
`;

export const ReadNotifications = gql`
  mutation readNotifications {
    readNotifications {
      notifications {
        notificationId
        title
        subtitle
        description
        notificationDate
        profilePic
        guaranteeId
        isUnread
      }
    }
  }
`;

export const DismissNotification = gql`
  mutation dismissNotification($input: DismissNotificationInput!) {
    dismissNotification(input: $input) {
      notifications {
        notificationId
        title
        subtitle
        description
        notificationDate
        profilePic
        guaranteeId
        isUnread
      }
    }
  }
`;

export const UpdateOrganization = gql`
  mutation updateOrganization($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      organizationId
      permissions {
        manageProfile
        manageUsers
      }
      role
      name
      abn
      phone
      address {
        street
        suburb
        postcode
        state
        country
      }
      profilePic
      notifications {
        notificationId
        title
        subtitle
        description
        notificationDate
        guaranteeId
        isUnread
      }
      users {
        firstname
        lastname
        email
        phone
        status
        userRoles {
          id
          name
        }
        userGroupIds
      }
      subsidiaries {
        abn
        name
      }
      userRoles {
        id
        name
        description
        permissions
      }
    }
  }
`;

export const RemoveTeamMember = gql`
  mutation removeUser($input: RemoveUserInput) {
    removeUser(input: $input) {
      users {
        id
        firstname
        lastname
        email
        phone
        status
        userRoles {
          id
          name
        }
        userGroupIds
      }
    }
  }
`;

export const InviteTeamMember = gql`
  mutation inviteUser($input: UserInput) {
    inviteUser(input: $input) {
      users {
        id
        firstname
        lastname
        email
        phone
        status
        userRoles {
          id
          name
        }
        userGroupIds
      }
    }
  }
`;

export const AddSubsidiary = gql`
  mutation addSubsidiary($input: AddSubsidiaryInput!) {
    addSubsidiary(input: $input) {
      subsidiaries {
        abn
        name
      }
    }
  }
`;

export const RemoveSubsidiary = gql`
  mutation removeSubsidiary($input: RemoveSubsidiaryInput!) {
    removeSubsidiary(input: $input) {
      subsidiaries {
        abn
        name
      }
    }
  }
`;

export const CreateAttachmentsZip = gql`
  mutation CreateAttachmentsZip($input: CreateAttachmentsZipInput!) {
    createAttachmentsZip(input: $input) {
      id
      name
      isReady
      isFailed
      url
    }
  }
`;

export const AddOrganizationUserRole = gql`
  mutation AddOrganizationUserRole($input: AddOrganizationUserRoleInput!) {
    addOrganizationUserRole(input: $input) {
      userRoles {
        id
        name
        description
        permissions
      }
    }
  }
`;

export const UpdateOrganizationUserRole = gql`
  mutation UpdateOrganizationUserRole($input: UpdateOrganizationUserRoleInput!) {
    updateOrganizationUserRole(input: $input) {
      userRoles {
        id
        name
        description
        permissions
      }
    }
  }
`;

export const RemoveOrganizationUserRole = gql`
  mutation RemoveOrganizationUserRole($input: RemoveOrganizationUserRoleInput!) {
    removeOrganizationUserRole(input: $input) {
      userRoles {
        id
        name
        description
        permissions
      }
    }
  }
`;

export const CreateUserGroup = gql`
  mutation CreateUserGroup($input: CreateUserGroupInput!) {
    createUserGroup(input: $input) {
      userGroups {
        id
        name
        addresses {
          street
          suburb
          postcode
          state
          country
        }
        userEmails
      }
    }
  }
`;

export const UpdateUserGroup = gql`
  mutation UpdateUserGroup($input: UpdateUserGroupInput!) {
    updateUserGroup(input: $input) {
      userGroups {
        id
        name
        addresses {
          unit
          street
          suburb
          postcode
          state
          country
        }
        userEmails
      }
    }
  }
`;

export const RemoveUserGroup = gql`
  mutation removeUserGroup($input: RemoveUserGroupInput) {
    removeUserGroup(input: $input) {
      userGroups {
        id
        name
        addresses {
          street
          suburb
          postcode
          state
          country
        }
        userEmails
      }
    }
  }
`;

export const UpdateUser = gql`
  mutation UpdateUser($input: UpdateUserInput) {
    updateUser(input: $input) {
      users {
        id
        firstname
        lastname
        email
        phone
        status
        userRoles {
          id
          name
        }
        userGroupIds
      }
    }
  }
`;
