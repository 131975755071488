<template>
  <div class="uploader-wrap" :class="{ disabled, error }">
    <div v-if="notification" v-dompurify-html="notification" class="uploader-notification" />

    <label v-if="label" :for="id" class="uploader-label">{{ label }}</label>

    <FileItem
      v-for="(file, index) in files"
      :key="`${file.name}-${index}`"
      class="flex items-center justify-between w-full mb-4 text-xs text-gray-600 form-input md:text-sm"
      :index="index"
      :file="file"
      :uploading="uploading && index === files.length - 1"
      :downloading="downloading"
      @download="onDownloadClick"
      @remove="onRemoveClick"
    />

    <FileInput
      v-if="allowMultiple || !files.length"
      :error="error"
      :mime-types="mimeTypes"
      :help-text="helpText"
      :disabled="disabled"
      :multiple="allowMultiple"
      @change="onChange"
    >
      <img v-if="src" slot="preview-image" class="uploader-preview-image" :src="src" :alt="label" />
    </FileInput>

    <p v-for="(e, i) in errors" :key="i" class="uploader-error">
      {{ e }}
    </p>
  </div>
</template>

<script>
import { useMachine } from "@xstate/vue";
import { computed, watch } from "@vue/composition-api";
import uploaderMachine from "./uploader-machine";
import { watchState } from "@/utils/machine-helpers";
import formMixin from "@/mixins/form";

import FileInput from "./FileInput";
import FileItem from "./FileItem";

export default {
  name: "UiFileInput",
  components: { FileItem, FileInput },
  mixins: [formMixin],
  props: {
    allowMultiple: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    existingFile: {
      type: [Object, null],
      default: null
    },
    helpText: {
      type: String,
      default: ""
    },
    id: {
      type: String,
      default: ""
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    mimeTypes: {
      type: String,
      default: ""
    },
    notification: {
      type: String,
      default: ""
    },
    src: {
      type: String,
      default: ""
    },
    uploadType: {
      type: String,
      default: ""
    },
    value: {
      type: [Array, String],
      default: () => []
    }
  },
  setup({ existingFile, uploadType, value }, { emit }) {
    let existingFiles = [];
    // Check for an existing file which is passed through from ExistingApplicationFields...
    if (existingFile) existingFiles.push(existingFile);
    // If a value is passed in and it's a string we need to split it as it could be a comma delimited string of previous uploads...
    if (value && value.length > 0) existingFiles = existingFiles.concat(value);

    const machineOptions = {
      context: {
        emit,
        files: existingFiles
      }
    };
    // Setup our state machine...
    const { send, service, state } = useMachine(uploaderMachine, machineOptions);

    watchState(state, "UiFileInput");

    const uploading = computed(() => {
      const { value } = state.value;
      const loadingStates = ["presign", "upload"];

      return loadingStates.includes(value);
    });

    const downloading = computed(() => {
      return state?.value?.value === "download";
    });

    const errors = computed(() => {
      return state?.value?.context?.errors;
    });

    const files = computed(() => {
      return state?.value?.context?.files;
    });

    let uploadFileQueues = [];
    // When any file just uploaded finished, continues with next file.
    watch(state, (currentState, prevState) => {
      if (prevState?.value === "uploaded" && currentState?.value === "idle") {
        const firstTaskInQueue = uploadFileQueues[0];
        if (firstTaskInQueue) {
          firstTaskInQueue();
          uploadFileQueues.shift();
        }
      }
    });

    const onChange = files => {
      emit("uploading", true);

      // Send the UPLOAD event with the file...
      files.forEach((file, index) => {
        if (index === 0) {
          send("UPLOAD", { file, uploadType });
        } else {
          const uploadFuncRef = send.bind(this, "UPLOAD", { file, uploadType });
          uploadFileQueues.push(uploadFuncRef);
        }
      });
    };

    const onRemoveClick = file => {
      send("REMOVE_FILE", { file });
    };

    const onDownloadClick = file => {
      send("DOWNLOAD", { file });
    };

    // Return what we want to expose to the template...
    return {
      downloading,
      errors,
      files,
      onChange,
      onDownloadClick,
      onRemoveClick,
      state,
      send,
      service,
      uploading,
      uploadFileQueues
    };
  }
};
</script>

<style scoped>
.uploader-wrap {
  @apply relative;

  &.disabled {
    @apply opacity-75 cursor-not-allowed;
  }

  &.error {
    @apply border-2 border-red-500 rounded-lg;
  }
}

.uploader-label {
  @apply block text-sm font-medium leading-5 text-gray-700 mb-2 ml-1;
}

.uploader-preview-image {
  @apply w-20 mx-auto cursor-pointer;
}

.uploader-notification {
  @apply font-normal text-xs text-gray-500 mb-3 p-4 bg-gray-100 rounded;

  @screen md {
    @apply text-sm;
  }
}

.uploader-error {
  @apply text-red-700 text-xs mt-2 pl-2;
}
</style>
