import { getCookie, setCookie, deleteCookie, clearAllCookies } from "@/utils/storages";

export class AmplifyAuthCookieStorage {
  static setItem(key, value) {
    return setCookie(key, value, 365);
  }
  static getItem(key) {
    return getCookie(key);
  }
  static removeItem(key) {
    deleteCookie(key);
  }
  static clear() {
    clearAllCookies();
  }
}
