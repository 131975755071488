<template>
  <div class="flex items-center">
    <div class="w-full bg-white">
      <UiMultiselect
        v-model="selectedRecipients"
        class="w-full"
        label=""
        :error="false"
        placeholder="Enter recipients of this chat group"
        :value="selectedRecipients"
        :options="recipientOptions"
        @clear="$emit('clear', $event)"
      >
      </UiMultiselect>
    </div>

    <div
      v-show="showConfirmCreatinGroupButton"
      class="px-3 flex items-center h-full bg-gray-200 hover:bg-gray-300 text-green-500 cursor-pointer select-none border-b border-solid "
      @click="createNewGroup"
    >
      <CheckIcon />
    </div>
  </div>
</template>

<script>
import UiMultiselect from "@/components/UI/UiMultiselect/UiMultiselect";
import { CheckIcon } from "@vue-hero-icons/outline";

export default {
  name: "ChatRecipientsSelector",
  components: {
    UiMultiselect,
    CheckIcon
  },
  props: {
    recipientOptions: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      selectedRecipients: []
    };
  },
  computed: {
    showConfirmCreatinGroupButton() {
      return !!this.selectedRecipients.length;
    }
  },
  mounted() {
    document.querySelector(".multiselect-search input").focus();
  },
  methods: {
    createNewGroup() {
      this.$emit("create-new-group", this.selectedRecipients);
    }
  }
};
</script>
