<script>
import { computed } from "@vue/composition-api";
import { useMachine } from "@xstate/vue";
import { watchState } from "@/utils/machine-helpers";
import asicSearchMachine from "@/components/AbnSearch/asic-search-machine";

export default {
  name: "AbnSearchLogic",
  props: {
    skipOrganizationCheck: {
      type: Boolean,
      default: false
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  setup({ skipOrganizationCheck, value }, { emit }) {
    const machineOptions = {
      context: {
        emit,
        skipOrganizationCheck,
        name: value ? value.name : "",
        contactEmail: value ? value.contactEmail : "",
        contactFirstname: value ? value.contactFirstname : "",
        contactLastname: value ? value.contactLastname : "",
        contactPhone: value ? value.contactPhone : ""
      }
    };
    // Setup our state machine...
    const {
      service: asicSearchService,
      state: asicSearchState,
      send: sendToAsicSearchService
    } = useMachine(asicSearchMachine, machineOptions);

    watchState(asicSearchState, "AsicSearch");

    const companyName = computed(() => asicSearchState?.value?.context?.company?.name);

    const errors = computed(() => asicSearchState?.value?.context?.errors ?? []);

    // If an ABN has been passed in then we need to search for it...
    if (value?.abn) sendToAsicSearchService("SEARCH", { abn: value?.abn });

    // Return what we want to expose to the template...
    return {
      asicSearchService,
      asicSearchState,
      companyName,
      errors,
      sendToAsicSearchService
    };
  },
  methods: {
    onAsicSearch(abn) {
      this.$emit("input", { abn });

      this.sendToAsicSearchService("SEARCH", {
        abn
      });
    },
    onContactInput(inputName, data) {
      this.sendToAsicSearchService("UPDATE_CONTACT", { [inputName]: data });
    }
  },
  render() {
    return this.$scopedSlots.default({
      abn: this.value?.abn,
      asicSearchService: this.asicSearchService,
      asicSearchState: this.asicSearchState,
      companyName: this.companyName,
      errors: this.errors,
      onAsicSearch: this.onAsicSearch,
      onContactInput: this.onContactInput
    });
  }
};
</script>
