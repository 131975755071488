<template>
  <div class="w-full">
    <div class="flex items-center">
      <slot />

      <button class="field-toggle" @click="onToggleClick">
        <ChevronUpIcon v-if="toggleActive" size="0.8x" />
        <ChevronDownIcon v-else size="0.8x" />
      </button>
    </div>

    <UiLoader v-if="state.matches('loading')" class="mt-4" />

    <div v-if="toggleActive && state.matches('error')" class="organization-error">
      Sorry but we couldn't load the organizations details right now.
      <UiButton theme="link" remove-padding size="sm" @click="send('RETRY')">Retry?</UiButton>
    </div>

    <div v-if="toggleActive && state.matches('loaded')" class="organization-details">
      <div class="w-full mb-3 md:w-1/2">
        <p v-if="address.street">{{ address.street }}</p>
        <p v-if="address.suburb">{{ address.suburb }}</p>
        <p v-if="address.state">{{ address.state }}</p>
        <p v-if="address.postcode">{{ address.postcode }}</p>
        <p v-if="address.country">{{ address.country }}</p>

        <a v-if="phone" class="mt-2 contact-detail contact-link" :href="`tel:${phone}`">
          <span class="contact-icon">
            <PhoneIcon size="0.9x" />
          </span>
          {{ phone }}
        </a>
      </div>

      <div class="w-full space-y-2 md:w-1/2">
        <div v-for="(user, i) in users" :key="i">
          <p class="contact-detail">
            <span class="contact-icon">
              <UserIcon size="0.9x" />
            </span>
            {{ user.firstname }} {{ user.lastname }}
          </p>

          <a v-if="user.email" class="contact-detail contact-link" :href="`mailto:${user.email}`">
            <span class="contact-icon">
              <MailIcon size="0.9x" />
            </span>
            {{ user.email }}
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon, ChevronUpIcon, MailIcon, PhoneIcon, UserIcon } from "vue-feather-icons";
import { useMachine } from "@xstate/vue";
import organizationFieldMachine from "@/components/Guarantee/fields/organization-field-machine";
import { computed } from "@vue/composition-api";
import UiButton from "@/components/UI/UiButton/UiButton";
import UiLoader from "@/components/UI/UiLoader/UiLoader";

export default {
  name: "OrganizationFieldValue",
  components: {
    ChevronDownIcon,
    ChevronUpIcon,
    MailIcon,
    PhoneIcon,
    UiButton,
    UiLoader,
    UserIcon
  },
  setup(props) {
    const { organizationId } = props;
    const machineOptions = {
      context: { organizationId }
    };

    // Setup our state machine...
    const { state, send } = useMachine(organizationFieldMachine, machineOptions);

    // watchState(state, "Organization Field");

    const organization = computed(() => state.value?.context?.organization);
    const address = computed(() => state.value?.context?.organization?.address);
    const phone = computed(() => state.value?.context?.organization?.phone);
    const users = computed(() => state.value?.context?.organization?.users);

    // Return what we want to expose to the template...
    return {
      address,
      organization,
      phone,
      send,
      state,
      users
    };
  },
  props: {
    organizationId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      toggleActive: false
    };
  },
  methods: {
    onToggleClick() {
      this.toggleActive = !this.toggleActive;

      if (this.state.matches("idle") || this.state.matches("error")) this.send("LOAD");
    }
  }
};
</script>

<style scoped>
.field-toggle {
  @apply text-cool-gray-500 ml-1 outline-none;
}

.organization-details {
  @apply w-full flex flex-wrap mt-2 text-xs text-gray-500 leading-relaxed;

  @screen md {
    @apply text-sm;
  }
}

.organization-error {
  @apply w-full mt-2 text-xs text-cool-gray-500;

  @screen md {
    @apply text-sm;
  }
}

.contact-detail {
  @apply flex items-center;
}

.contact-link {
  @apply text-primary;
}

.contact-icon {
  @apply text-gray-500 mr-1 block;
}
</style>
