import gql from "graphql-tag";
import { GuaranteeParts, OrganizationParts } from "@/graphql/fragments";

export const Guarantees = gql`
  query Guarantees($input: GuaranteesInput!) {
    guarantees(input: $input) {
      guarantees {
        applicationType
        guaranteeType
        expiryDate
        guaranteeId
        guaranteeNumber
        issueDate
        propertyAddress
        externalId
        tenant {
          name
          profilePic
        }
        landlord {
          name
          profilePic
        }
        status {
          primaryStatus
          label
        }
      }
      paginationKey
    }
  }
`;

export const GuaranteeStats = gql`
  query GuaranteeStats {
    guaranteeStats {
      stats {
        value
        title
        subtitle
        showIndicator
        filters {
          primaryStatus
          expiryDateFrom
          expiryDateTo
        }
      }
    }
  }
`;

export const Guarantee = gql`
  ${GuaranteeParts}
  query Guarantee($input: GuaranteeInput!) {
    guarantee(input: $input) {
      ...GuaranteeParts
    }
  }
`;

export const Application = gql`
  query Guarantee($input: GuaranteeInput!) {
    guarantee(input: $input) {
      guaranteeId
      status {
        statusId
        label
        isDraft
        primaryStatus
        negotiable
      }
      application {
        expiryDate
        fixedRentAdjustment
        custodians
        guarantor {
          organizationId
          abn
          name
          contactEmail
          contactFirstname
          contactLastname
        }
        landlord {
          organizationId
          abn
          name
          contactEmail
          contactFirstname
          contactLastname
        }
        tenant {
          organizationId
          abn
          name
          contactEmail
          contactFirstname
          contactLastname
          contactPhone
        }
        issueDate
        details
        amountGuaranteed
        guaranteeCertificate
        guaranteeNumber
        propertyAddress {
          street
          suburb
          state
          postcode
          country
        }
        propertyType
        securityDepositValue
        tenantCurrentBank
        tenantNatureOfBusiness
      }
    }
  }
`;

export const Session = gql`
  ${OrganizationParts}
  query Session {
    session {
      ...OrganizationParts
    }
  }
`;

export const Organization = gql`
  query Organization($input: OrganizationInput!) {
    organization(input: $input) {
      organizationId
      role
      name
      abn
      address {
        street
        suburb
        postcode
        state
        country
      }
      phone
      profilePic
    }
  }
`;

export const Organizations = gql`
  query Organizations {
    organizations {
      organizationId
      name
      abn
      phone
      role
    }
  }
`;

export const AsicABNSearch = gql`
  query AsicABNSearch($input: AsicABNSearchInput!) {
    asicABNSearch(input: $input) {
      organizationId
      abn
      address {
        country
        postcode
        state
        street
        suburb
      }
      name
    }
  }
`;

export const Attachment = gql`
  query Attachment($input: AttachmentInput!) {
    attachment(input: $input) {
      name
      url
    }
  }
`;

export const AttachmentsZip = gql`
  query AttachmentsZip($input: AttachmentsZipInput!) {
    attachmentsZip(input: $input) {
      id
      name
      isReady
      isFailed
      url
    }
  }
`;
