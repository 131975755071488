<template>
  <div>
    <UiCard theme="primary" class="mb-6">
      <div class="flex justify-end">
        <GenerateReport report-type="userRole" />
      </div>
      <p class="subtitle">
        Manage user roles
      </p>

      <div v-for="(role, i) in userRoles" :key="i" class="flex flex-wrap mb-3">
        <div class="w-full sm:w-1/3">
          <p class="item-title">
            {{ role.name }}
          </p>
          <p v-if="role.isDefault" class="subtitle">SSO default role</p>
        </div>

        <p class="item-content w-full sm:w-1/3">
          {{ role.description }}
        </p>

        <div
          v-if="
            !includes(role.permissions, 'ADMIN') &&
              !saving &&
              !readOnly &&
              permissions.manageProfile
          "
          class="flex justify-end ml-auto"
        >
          <UiButton
            theme="link"
            title="Edit"
            size="sm"
            remove-padding
            @click="$emit('edit', role.id)"
          >
            Edit
          </UiButton>
          <UiButton
            class="ml-4"
            theme="link"
            title="Remove"
            size="sm"
            remove-padding
            @click="$emit('remove', role.id)"
          >
            Remove
          </UiButton>
        </div>
      </div>

      <div v-if="editing" class="flex flex-wrap justify-between mb-3 mt-6">
        <p class="w-full subtitle font-bold ml-3">Add/Edit User Role</p>
        <UiInput
          v-model="editingRole.name"
          :disabled="saving"
          label="Name"
          class="w-full px-2 mb-2 sm:w-1/2"
        />

        <UiInput
          v-model="editingRole.description"
          :disabled="saving"
          label="Description"
          class="w-full px-2 mb-2 sm:w-1/2"
        />
        <div class="flex flex-wrap w-full">
          <p class="subtitle w-full ml-3 mt-2">Permissions</p>
          <div v-for="(perm, key) in PERMISSIONS" :key="key" class="w-full mb-2">
            <UiCheckbox
              :disabled="saving"
              :label="roleLabel(key)"
              :value="includes(editingRole.permissions, perm)"
              @input="$emit('select-perm', perm)"
            />
          </div>
        </div>
        <div v-if="!saving" class="flex">
          <UiButton class="ml-3 mt-3" theme="primary" @click="$emit('save')">Save</UiButton>
          <UiButton class="ml-2 mt-3" @click="$emit('cancel')">Cancel</UiButton>
        </div>
      </div>
      <div
        v-if="!editing && !readOnly && permissions.manageProfile"
        class="flex justify-center sm:justify-end mt-6"
      >
        <UiButton size="sm" theme="outline" @click="$emit('add')">Add New User Role</UiButton>
      </div>
    </UiCard>
  </div>
</template>

<script>
import { includes } from "lodash";
import UiCard from "@/components/UI/UiCard/UiCard";
import UiButton from "@/components/UI/UiButton/UiButton";
import UiInput from "@/components/UI/UiInput/UiInput.vue";
import UiCheckbox from "@/components/UI/UiCheckbox/UiCheckbox";
import { PERMISSIONS, PERMISSION_LABELS, PERMISSIONS_DESCRIPTIONS } from "@/utils/permissions";
import GenerateReport from "@/components/Reports/GenerateReport";

export default {
  name: "UserRoles",
  components: {
    UiCard,
    UiButton,
    UiCheckbox,
    UiInput,
    GenerateReport
  },
  props: {
    editing: {
      type: Boolean,
      default: false
    },
    saving: {
      type: Boolean,
      default: false
    },
    userRoles: {
      type: Array,
      default: () => []
    },
    editingRole: {
      type: Object,
      default: () => {}
    },
    organization: {
      type: Object,
      default: () => {}
    },
    permissions: {
      type: Object,
      default: () => ({
        manageProfile: false,
        manageUsers: false
      })
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    return {
      PERMISSIONS,
      includes
    };
  },
  methods: {
    roleLabel(key) {
      const desc =
        PERMISSIONS_DESCRIPTIONS[key][this.organization?.role] ||
        PERMISSIONS_DESCRIPTIONS[key].default;
      return desc;
    }
  }
};
</script>

<style scoped>
.subtitle {
  @apply mb-4 text-sm text-gray-400;
}

.item-title {
  @apply text-sm text-gray-500 font-semibold;
}

.item-content {
  @apply text-sm text-gray-500;
}
</style>
