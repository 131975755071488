<template>
  <div class="status-update" :class="statusLowerCase" data-testid="status-update">
    <slot>
      <UiStatusIcon v-if="status" :status="status" /><span
        v-if="label"
        class="status-update-label"
        >{{ label }}</span
      >
    </slot>
  </div>
</template>

<script>
import UiStatusIcon from "@/components/UI/UiStatusIcon/UiStatusIcon";

export default {
  name: "StatusUpdate",
  components: { UiStatusIcon },
  props: {
    label: {
      type: String,
      default: ""
    },
    status: {
      type: String,
      default: ""
    }
  },
  computed: {
    statusLowerCase() {
      return this.status?.toLowerCase();
    }
  }
};
</script>

<style scoped>
/* purgecss start ignore */

/* Base Styles */
.status-update-label {
  @apply font-semibold capitalize inline-block ml-1 text-white text-xxs;

  @screen sm {
    @apply text-xs;
  }
}

.status-update {
  @apply inline-flex items-center px-2.5 py-0.5 rounded-full text-xs leading-4 bg-cool-gray-500 text-white;

  &.active {
    @apply bg-green-500;
  }

  &.draft {
    @apply bg-cool-gray-600;
  }

  &.cancelled {
    @apply bg-red-500;
  }

  &.deleted {
    @apply bg-cool-gray-300;

    .status-update-label {
      @apply text-cool-gray-400;
    }
  }

  &.completed {
    @apply bg-purple-800;
  }

  &.application {
    @apply bg-cool-gray-400;
  }

  &.none {
    @apply bg-primary;
  }
}

/* purgecss end ignore */
</style>
