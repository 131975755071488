<template>
  <div class="guarantee-field-value-wrap">
    <div v-if="richText" v-dompurify-html="richText" />

    <p v-if="proposedValue" class="guarantee-field-value-meta">
      Original Value:
    </p>

    <div v-if="value" :class="{ 'guarantee-field-actual-value': proposedValue }">
      {{ leadingValue }}
      {{ value }}
      {{ trailingValue }}
    </div>

    <p v-if="proposedValue" class="mt-1 md:mt-2 guarantee-field-value-meta">
      <em>{{ proposedBy }}</em> Proposed Change:
    </p>

    <div v-if="proposedValue" class="guarantee-field-proposed-value">
      {{ leadingValue }}
      {{ proposedValue }}
      {{ trailingValue }}
    </div>
  </div>
</template>

<script>
export default {
  name: "GuaranteeFieldValue",
  props: {
    richText: {
      type: String,
      default: ""
    },
    leadingValue: {
      type: String,
      default: ""
    },
    proposedValue: {
      type: String,
      default: ""
    },
    trailingValue: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    proposedBy: {
      type: String,
      default: ""
    }
  }
};
</script>

<style scoped>
.guarantee-field-value-wrap {
  @apply text-xs text-gray-500 leading-relaxed;

  @screen md {
    @apply text-sm;
  }
}

.guarantee-field-proposed-value {
  @apply block;
}

.guarantee-field-actual-value {
  @apply italic text-cool-gray-400;
}

.guarantee-field-value-meta {
  @apply block text-xs font-semibold mb-1;
}
</style>
