import { assign, Machine } from "xstate";
import { API, graphqlOperation } from "aws-amplify";
import find from "lodash.find";
import { Session as SessionQuery } from "@/graphql/queries";
import {
  AddSubsidiary as AddSubsidiaryMutation,
  ReadNotifications as ReadNotificationsMutation,
  DismissNotification as DismissNotificationMutation,
  UpdateOrganization as UpdateOrganizationMutation,
  RemoveSubsidiary as RemoveSubsidiaryMutation,
  RemoveTeamMember as RemoveTeamMemberMutation,
  InviteTeamMember as InviteTeamMemberMutation,
  AddOrganizationUserRole as AddOrganizationUserRoleMutation,
  UpdateOrganizationUserRole as UpdateOrganizationUserRoleMutation,
  CreateUserGroup as CreateUserGroupMutation,
  UpdateUserGroup as UpdateUserGroupMutation,
  RemoveUserGroup as RemoveUserGroupMutation,
  UpdateUser as UpdateUserMutation,
  RemoveOrganizationUserRole as RemoveOrganizationUserRoleMutation
} from "@/graphql/mutations";
import { getGraphQlData, helperActions } from "@/utils/machine-helpers";
import { alertService } from "@/main";
import { getUserAttributes, signout } from "@/lib/auth";

const initialState = {
  dismissingId: "",
  errors: [],
  organization: {}
};

export default Machine(
  {
    id: "organization",

    initial: "setup",

    context: {
      ...initialState
    },

    on: {
      FETCH_ORGANIZATION: "fetchingOrganization",
      RESET: {
        actions: "resetState"
      }
    },

    states: {
      setup: {},
      fetchingOrganization: {
        entry: "resetState",
        invoke: {
          id: "fetchingOrganization",
          src: "fetchOrganization",
          onDone: {
            target: "ready",
            actions: ["assignOrganization", "configureTracking"]
          },
          onError: [
            {
              target: "unauthorised",
              cond: "isUnauthorised"
            },
            {
              target: "error",
              actions: [
                "assignErrors",
                "trackErrors",
                context =>
                  alertService.send("ALERT", {
                    alert: {
                      type: "warning",
                      message: "Could not load organisation",
                      items: context.errors
                    }
                  })
              ]
            }
          ]
        }
      },
      ready: {
        initial: "idle",
        on: {
          SHOW_NOTIFICATIONS: ".notificationsActive",
          HIDE_NOTIFICATIONS: [
            {
              target: ".notificationsMarkingAsRead",
              cond: "isUnread"
            },
            {
              target: ".idle"
            }
          ]
        },
        states: {
          idle: {
            on: {
              ADD_SUBSIDIARY: "addingSubsidiary",
              FETCH_NOTIFICATIONS: "fetchingNotifications",
              INVITE_TEAM_MEMBER: "invitingTeamMember",
              REMOVE_SUBSIDIARY: "removingSubsidiary",
              REMOVE_TEAM_MEMBER: "removingTeamMember",
              UPDATE_ORGANIZATION: "updating",
              SAVE_USER_ROLE: "savingUserRole",
              REMOVE_USER_ROLE: "removingUserRole",
              CREATE_USER_GROUP: "creatingUserGroup",
              SAVE_USER_GROUP: "updatingUserGroup",
              REMOVE_USER_GROUP: "removingUserGroup",
              UPDATE_USER: "updatingUser"
            }
          },
          addingSubsidiary: {
            invoke: {
              id: "addingSubsidiary",
              src: "addSubsidiary",
              onDone: {
                target: "idle",
                actions: "updateSubsidiaries"
              },
              onError: {
                target: "idle",
                actions: [
                  "assignErrors",
                  "trackErrors",
                  context =>
                    alertService.send("ALERT", {
                      alert: {
                        type: "warning",
                        message: "Couldn't add subsidiary",
                        items: context.errors
                      }
                    }),
                  "scrollToTop"
                ]
              }
            }
          },
          notificationsMarkingAsRead: {
            invoke: {
              id: "notificationsMarkingAsRead",
              src: "markNotificationsAsRead",
              onDone: {
                target: "idle",
                actions: "updateNotifications"
              }
            }
          },
          notificationsActive: {
            initial: "idle",
            on: {
              DISMISS: ".dismissing"
            },
            states: {
              idle: {},
              dismissing: {
                entry: "assignDismissingId",
                invoke: {
                  id: "dismissing",
                  src: "dismissNotification",
                  onDone: {
                    target: "idle",
                    actions: ["updateNotifications", "assignDismissingId"]
                  }
                }
              }
            }
          },
          updating: {
            invoke: {
              id: "updating",
              src: "updateOrganization",
              onDone: {
                target: "idle",
                actions: "assignOrganization"
              },
              onError: {
                target: "idle",
                actions: [
                  "assignErrors",
                  "trackErrors",
                  context =>
                    alertService.send("ALERT", {
                      alert: {
                        type: "warning",
                        message: "Couldn't update organization",
                        items: context.errors
                      }
                    })
                ]
              }
            }
          },
          removingSubsidiary: {
            invoke: {
              id: "removingSubsidiary",
              src: "removeSubsidiary",
              onDone: {
                target: "idle",
                actions: "updateSubsidiaries"
              },
              onError: {
                target: "idle",
                actions: [
                  "assignErrors",
                  "trackErrors",
                  context =>
                    alertService.send("ALERT", {
                      alert: {
                        type: "warning",
                        message: "Couldn't remove subsidiary",
                        items: context.errors
                      }
                    })
                ]
              }
            }
          },
          removingTeamMember: {
            invoke: {
              id: "removingTeamMember",
              src: "removeTeamMember",
              onDone: {
                target: "idle",
                actions: "updateTeamMembers"
              },
              onError: {
                target: "idle",
                actions: [
                  "assignErrors",
                  "trackErrors",
                  context =>
                    alertService.send("ALERT", {
                      alert: {
                        type: "warning",
                        message: "Couldn't remove team member",
                        items: context.errors
                      }
                    })
                ]
              }
            }
          },
          invitingTeamMember: {
            invoke: {
              id: "invitingTeamMember",
              src: "inviteTeamMember",
              onDone: {
                target: "idle",
                actions: "updateTeamMembers"
              },
              onError: {
                target: "idle",
                actions: [
                  "assignErrors",
                  "trackErrors",
                  context =>
                    alertService.send("ALERT", {
                      alert: {
                        type: "warning",
                        message: "Couldn't invite team member",
                        items: context.errors
                      }
                    })
                ]
              }
            }
          },
          fetchingNotifications: {
            invoke: {
              id: "fetchingNotifications",
              src: "fetchOrganization",
              onDone: {
                target: "idle",
                actions: "updateNotifications"
              },
              onError: {
                target: "idle"
              }
            }
          },
          savingUserRole: {
            invoke: {
              src: "saveUserRole",
              onDone: {
                target: "idle",
                actions: "updateUserRoles"
              },
              onError: {
                target: "idle",
                actions: [
                  "assignErrors",
                  "trackErrors",
                  context =>
                    alertService.send("ALERT", {
                      alert: {
                        type: "warning",
                        message: "Error saving user role",
                        items: context.errors
                      }
                    })
                ]
              }
            }
          },
          creatingUserGroup: {
            invoke: {
              src: "createUserGroup",
              onDone: {
                target: "idle",
                actions: "updateUserGroups"
              },
              onError: {
                target: "idle",
                actions: [
                  "assignErrors",
                  "trackErrors",
                  context =>
                    alertService.send("ALERT", {
                      alert: {
                        type: "warning",
                        message: "Error creating a new UserGroup",
                        items: context.errors
                      }
                    })
                ]
              }
            }
          },
          updatingUserGroup: {
            invoke: {
              src: "updateUserGroup",
              onDone: {
                target: "idle",
                actions: "updateUserGroups"
              },
              onError: {
                target: "idle",
                actions: [
                  "assignErrors",
                  "trackErrors",
                  context =>
                    alertService.send("ALERT", {
                      alert: {
                        type: "warning",
                        message: "Error updating UserGroup",
                        items: context.errors
                      }
                    })
                ]
              }
            }
          },
          removingUserGroup: {
            invoke: {
              id: "removingUserGroup",
              src: "removeUserGroup",
              onDone: {
                target: "idle",
                actions: "updateUserGroups"
              },
              onError: {
                target: "idle",
                actions: [
                  "assignErrors",
                  "trackErrors",
                  context =>
                    alertService.send("ALERT", {
                      alert: {
                        type: "warning",
                        message: "Couldn't remove this user group",
                        items: context.errors
                      }
                    })
                ]
              }
            }
          },
          updatingUser: {
            invoke: {
              src: "updateUser",
              onDone: {
                target: "idle",
                actions: "updateUsers"
              },
              onError: {
                target: "idle",
                actions: [
                  "assignErrors",
                  "trackErrors",
                  context =>
                    alertService.send("ALERT", {
                      alert: {
                        type: "warning",
                        message: "Error updating User",
                        items: context.errors
                      }
                    })
                ]
              }
            }
          },
          removingUserRole: {
            invoke: {
              src: "removeUserRole",
              onDone: {
                target: "idle",
                actions: "updateUserRoles"
              },
              onError: {
                target: "idle",
                actions: [
                  "assignErrors",
                  "trackErrors",
                  context =>
                    alertService.send("ALERT", {
                      alert: {
                        type: "warning",
                        message: "Error updating User",
                        items: context.errors
                      }
                    })
                ]
              }
            }
          }
        }
      },
      error: {
        on: {
          RETRY: "fetchingOrganization"
        }
      },
      unauthorised: {
        entry: "signOut"
      }
    }
  },
  {
    actions: {
      assignDismissingId: assign({
        dismissingId: (context, event) => event.notificationId || ""
      }),
      ...helperActions,
      configureTracking: async (context, event) => {
        const email = await getUserAttributes("email");
        const role = context.organization?.role;

        window.Sentry?.configureScope(scope => {
          scope.setTag("role", role);
        });

        window.LogRocket?.identify(email, {
          role
        });
      },
      assignOrganization: assign({
        organization: (context, event) =>
          getGraphQlData(event, "session") || getGraphQlData(event, "updateOrganization")
      }),
      signOut: () => {
        signout(
          "Account does not have access to an organisation. Please contact support for further details."
        );
      },
      resetState: assign({
        ...initialState
      }),
      scrollToTop: () => {
        window.scrollTo(0, 0);
      },
      updateNotifications: assign({
        organization: (context, event) => {
          return {
            ...context.organization,
            notifications:
              getGraphQlData(event, "session.notifications") ||
              getGraphQlData(event, "organization.notifications") ||
              getGraphQlData(event, "dismissNotification.notifications") ||
              getGraphQlData(event, "readNotifications.notifications")
          };
        }
      }),
      updateSubsidiaries: assign({
        organization: (context, event) => {
          const subsidiaries =
            getGraphQlData(event, "addSubsidiary.subsidiaries") ||
            getGraphQlData(event, "removeSubsidiary.subsidiaries");

          return {
            ...context.organization,
            subsidiaries
          };
        }
      }),
      updateTeamMembers: assign({
        organization: (context, event) => {
          const users =
            getGraphQlData(event, "removeUser.users") || getGraphQlData(event, "inviteUser.users");

          return {
            ...context.organization,
            users
          };
        }
      }),
      updateUserRoles: assign({
        organization: (context, event) => {
          const userRoles =
            getGraphQlData(event, "addOrganizationUserRole.userRoles") ||
            getGraphQlData(event, "updateOrganizationUserRole.userRoles") ||
            getGraphQlData(event, "removeOrganizationUserRole.userRoles");

          return {
            ...context.organization,
            userRoles
          };
        }
      }),
      updateUserGroups: assign({
        organization: (context, event) => {
          const userGroups =
            getGraphQlData(event, "createUserGroup.userGroups") ||
            getGraphQlData(event, "updateUserGroup.userGroups") ||
            getGraphQlData(event, "removeUserGroup.userGroups");

          return {
            ...context.organization,
            userGroups
          };
        }
      }),
      updateUsers: assign({
        organization: (context, event) => {
          const users = getGraphQlData(event, "updateUser.users");

          return {
            ...context.organization,
            users
          };
        }
      })
    },
    guards: {
      isUnread: (context, event) => {
        // Filter through the notifications and see if any are unread using isUnread...
        const notifications = context.organization?.notifications || [];

        return notifications.filter(n => n.isUnread).length;
      },
      isUnauthorised: (context, event) => {
        const unAuthorisedError = "[401] User not authorized to access organisation";

        return find(event.data?.errors, ["message", unAuthorisedError]) !== undefined;
      }
    },
    services: {
      addSubsidiary: (context, event) => {
        const input = {
          abn: event.subsidiary?.abn,
          name: event.subsidiary?.name,
          organizationId: context.organization?.organizationId
        };

        return API.graphql(graphqlOperation(AddSubsidiaryMutation, { input }));
      },
      dismissNotification: (context, event) => {
        const input = {
          notificationId: event.notificationId
        };

        return API.graphql(graphqlOperation(DismissNotificationMutation, { input }));
      },
      fetchOrganization: () => {
        return API.graphql(graphqlOperation(SessionQuery));
      },
      inviteTeamMember: (context, event) => {
        const input = {
          email: event.email,
          firstname: event.firstname,
          lastname: event.lastname,
          roleIds: event.roleIds,
          userGroupIds: event.userGroupIds
        };

        return API.graphql(graphqlOperation(InviteTeamMemberMutation, { input }));
      },
      markNotificationsAsRead: () => {
        return API.graphql(graphqlOperation(ReadNotificationsMutation));
      },
      removeSubsidiary: (context, event) => {
        const input = {
          abn: event.abn,
          organizationId: context.organization?.organizationId
        };

        return API.graphql(graphqlOperation(RemoveSubsidiaryMutation, { input }));
      },
      removeTeamMember: (context, event) => {
        const input = {
          email: event.email
        };

        return API.graphql(graphqlOperation(RemoveTeamMemberMutation, { input }));
      },
      updateOrganization: (context, event) => {
        const input = { ...event.data };

        return API.graphql(graphqlOperation(UpdateOrganizationMutation, { input }));
      },
      saveUserRole: (context, event) => {
        const { userRole } = event;
        if (!userRole.id) {
          return API.graphql(
            graphqlOperation(AddOrganizationUserRoleMutation, { input: userRole })
          );
        }
        userRole.isDefault = undefined; //TODO remove this line when support for selecting SSO default role is added
        return API.graphql(
          graphqlOperation(UpdateOrganizationUserRoleMutation, { input: userRole })
        );
      },
      removeUserRole: (context, event) => {
        const { userRoleId } = event;
        return API.graphql(
          graphqlOperation(RemoveOrganizationUserRoleMutation, { input: { id: userRoleId } })
        );
      },
      createUserGroup: (context, event) => {
        const { userGroup } = event;

        return API.graphql(
          graphqlOperation(CreateUserGroupMutation, {
            input: { ...userGroup, organizationId: context.organization?.organizationId }
          })
        );
      },
      updateUserGroup: (context, event) => {
        const { userGroup } = event;
        const { id, name, addresses, userEmails } = userGroup;
        return API.graphql(
          graphqlOperation(UpdateUserGroupMutation, {
            input: {
              userGroupId: id,
              name,
              addresses,
              userEmails,
              organizationId: context.organization?.organizationId
            }
          })
        );
      },
      removeUserGroup: (context, event) => {
        const input = {
          userGroupId: event.userGroupId,
          organizationId: context.organization?.organizationId
        };
        return API.graphql(graphqlOperation(RemoveUserGroupMutation, { input }));
      },
      updateUser: (_, event) => {
        const { user } = event;
        return API.graphql(graphqlOperation(UpdateUserMutation, { input: user }));
      }
    }
  }
);
