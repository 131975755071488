<template>
  <UiBaseInput
    :name="$attrs.name"
    :label="label"
    :error="error"
    :size="size"
    :loading="loading"
    :leading-width="leadingWidth"
  >
    <input
      :ref="inputRef"
      class="form-input ui-input"
      novalidate
      :class="inputClassObject"
      data-testid="ui-input"
      :value="value"
      v-bind="$attrs"
      :disabled="disabled"
      @input="$emit('input', $event.target.value)"
      @blur="$emit('blur', $event.target.value)"
      @click="$emit('click', $event)"
      @keyup.enter="onEnterKey"
    />

    <div v-if="dropdownItems.length && !loading" class="ui-input-dropdown">
      <select
        :aria-label="dropdownLabel"
        class="form-select ui-input-dropdown-label"
        :class="{ [size]: size }"
        @input="$emit('dropdown-input', $event.target.value)"
      >
        <option
          v-for="(item, i) in dropdownItems"
          :key="i"
          :value="item.value"
          :selected="item.value === dropdownValue"
          >{{ item.label }}</option
        >
      </select>
    </div>

    <!-- Pass the slots through to the UiBaseInput slots -->
    <slot slot="label" name="label" />
    <slot slot="errors" name="errors" />
    <slot slot="leadingAddon" name="leadingAddon" />
    <slot slot="trailingAddon" name="trailingAddon" />
  </UiBaseInput>
</template>

<script>
import UiBaseInput from "@/components/UI/UiBaseInput/UiBaseInput";

export default {
  name: "UiInput",
  components: { UiBaseInput },
  inheritAttrs: false,
  props: {
    error: {
      type: Boolean,
      default: false
    },
    dropdownItems: {
      type: Array,
      default: () => []
    },
    dropdownLabel: {
      type: String,
      default: ""
    },
    dropdownValue: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    leadingWidth: {
      type: String,
      default: "sm"
    },
    size: {
      type: String,
      default: "base"
    },
    value: {
      type: [String, Number],
      default: ""
    },
    onEnterKey: {
      type: Function,
      default: () => {
        return () => {};
      }
    },
    inputStyle: {
      type: String,
      default: ""
    },
    inputRef: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    inputClassObject() {
      return {
        [this.inputStyle]: this.inputStyle,
        error: this.error,
        [this.size]: this.size
      };
    }
  }
};
</script>

<style scoped>
/* purgecss start ignore */

/* Base Styles */
.ui-input {
  @apply block w-full text-xs;

  &::placeholder {
    @apply text-xs;

    @screen md {
      @apply text-sm;
    }
  }

  &[disabled] {
    @apply text-gray-400 bg-opacity-50;
  }

  &.error {
    @apply border-red-300 text-red-900 pr-2;

    &::placeholder {
      @apply text-red-900;
    }

    &:focus {
      @apply border-red-300 shadow-outline-red;
    }
  }

  @screen md {
    @apply text-sm leading-5;
  }

  &.sm {
    @screen md {
      @apply text-xs;

      &::placeholder {
        @apply text-xs;
      }
    }
  }
}

.ui-input-dropdown {
  @apply absolute inset-y-0 right-0 flex items-center;
}

.ui-input-dropdown-label {
  @apply h-full py-0 pl-2 text-gray-500 bg-transparent border-transparent pr-7 text-xs;

  @screen md {
    @apply text-sm leading-5;
  }

  &.sm {
    @screen md {
      @apply text-xs;
    }
  }
}
/* purgecss end ignore */
</style>
