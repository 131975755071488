<template>
  <div class="sm:relative">
    <label class="flex flex-wrap items-center">
      <span v-if="label" class="date-filter-label">{{ label }}:</span>
      <button class="date-filter-toggle" @click="onToggleClick">
        <span class="date-filter-toggle-label" :class="{ selected: filterSelected }">{{
          handle
        }}</span>

        <ChevronUpIcon v-if="toggleActive" size="0.55x" />
        <ChevronDownIcon v-else size="0.55x" />
      </button>
    </label>

    <div
      v-if="toggleActive"
      v-hotkey="{
        esc: onToggleClose
      }"
      class="date-filter-inputs"
    >
      <button class="date-filter-inputs-close" @click="onToggleClose">
        <XCircleIcon size="1x" />
      </button>

      <UiDateInput
        v-model="form.from"
        size="sm"
        class="mb-4 date-filter-input"
        :config="uiDateInputConfig"
        :error="$v.form.from.$error"
        @input="onInput('from', $event)"
      >
        <span slot="label" class="flex flex-wrap">{{ label }} From </span>
      </UiDateInput>

      <UiDateInput
        v-model="form.to"
        size="sm"
        :config="uiDateInputConfig"
        class="date-filter-input"
        :error="$v.form.to.$error"
        @input="onInput('to', $event)"
      >
        <span slot="label" class="flex flex-wrap">{{ label }} To </span>
      </UiDateInput>
    </div>
  </div>
</template>

<script>
import { format, isAfter, isBefore, parseISO } from "date-fns";
import { ChevronDownIcon, ChevronUpIcon, XCircleIcon } from "vue-feather-icons";
import capitalize from "lodash.capitalize";
import formMixin from "@/mixins/form";
import UiDateInput from "@/components/UI/UiDateInput/UiDateInput";

const defaultValue = "All";

export default {
  name: "DateFilter",
  components: { ChevronDownIcon, ChevronUpIcon, UiDateInput, XCircleIcon },
  mixins: [formMixin],
  props: {
    label: {
      type: String,
      default: ""
    },
    type: {
      type: String,
      required: true
    },
    value: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      form: this.value,
      toggleActive: false,
      uiDateInputConfig: {
        allowInput: false,
        altFormat: "d/m/Y",
        altInput: true,
        dateFormat: "Y-m-d"
      }
    };
  },
  computed: {
    handle() {
      const formatDate = date => format(parseISO(date), "d/MM/yy");
      const { from, to } = this.value;
      const fromDate = from ? formatDate(from) : "";
      const toDate = to ? formatDate(to) : "";
      const divider = from && to ? " - " : "";

      const handle = `${fromDate}${divider}${toDate}` || defaultValue;
      return handle;
    },
    filterSelected() {
      return this.handle !== defaultValue;
    }
  },
  beforeUpdate() {
    this.form = this.value;
  },
  methods: {
    onInput(selector, value) {
      // Update our internal state...
      this.form[selector] = value;

      // Validate the form fields...
      this.validate();

      // If they're valid then emit the updated values...
      if (!this.$v.$invalid) {
        const { type } = this;

        const data = {
          type: `${type}${capitalize(selector)}`,
          value: selector === "to" ? value.split("T")[0] + "T23.59.59.999Z" : value
        };

        this.$emit("input", data);
        this.onToggleClose();
      }
    },
    onToggleClick() {
      this.toggleActive = !this.toggleActive;
    },
    onToggleClose() {
      this.toggleActive = false;
    }
  },
  validations: {
    form: {
      from: {
        isAfter: (input, vm) => {
          const from = parseISO(input);
          const to = parseISO(vm.to);
          return !isAfter(from, to);
        }
      },
      to: {
        isBefore: (input, vm) => {
          const to = parseISO(input);
          const from = parseISO(vm.from);
          return !isBefore(to, from);
        }
      }
    }
  }
};
</script>

<style scoped>
/* purgecss start ignore */
.date-filter-label {
  @apply text-cool-gray-500 mr-1 text-xs font-light whitespace-no-wrap;
}

.date-filter-toggle {
  @apply flex items-center text-cool-gray-500 outline-none;
}

.date-filter-toggle-label {
  @apply inline-block text-xs text-primary mr-1;

  &.selected {
    @apply font-semibold;
  }
}

.date-filter-inputs {
  @apply absolute p-2 bg-cool-gray-50 border rounded shadow-md mt-2 left-0 right-0 w-48;

  @screen sm {
    @apply p-3 left-auto right-auto;
  }
}

.date-filter-inputs-close {
  @apply absolute text-cool-gray-400 outline-none;
  right: 8px;
  top: 8px;
  z-index: 1;
}

.date-filter-input {
  @apply w-full;
}

>>> .ui-date-input-calendar {
  left: -50%;

  @screen sm {
    left: 0;
  }
}
/* purgecss end ignore */
</style>
