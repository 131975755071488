<template>
  <UiCard theme="paper" class="md:mt-3" :class="{ 'guarantee-disabled': disabled }">
    <!-- Header -->
    <div class="flex flex-wrap items-center justify-between px-2 mb-6">
      <UiHeadline :level="3">Summary</UiHeadline>
      <!-- <img
        src="@/assets/images/logos/eguarantee-logo-grayscale.svg"
        alt="eGuarantee Logo"
        class="mb-2 mr-2 brand-logo sm:mb-0"/> -->

      <p class="guarantee-number">Guarantee #{{ guarantee.guaranteeNumber }}</p>
    </div>
    <!-- .flex -->
    <!--
    <img
      v-if="isDraft"
      class="draft-stamp"
      src="@/assets/images/misc/draft.png"
      alt="Draft Stamp"/> -->

    <!-- Fields -->
    <div class="space-y-1 md:space-y-4" data-cy="egauranteeContractSection">
      <GuaranteeField
        v-for="field in guaranteeFields()"
        :key="field.id"
        v-model="amendments"
        :original-value="fieldValues[field.id]"
        :guarantee-id="guarantee.guaranteeId"
        :field="field"
        :allow-amendments="allowAmendments"
        :error="$v.amendments[field.id].$error"
        :errors="amendmentInputErrors[field.id]"
        @resolve-amendment="$emit('resolve-amendment', $event)"
      />

      <div v-if="amended" id="amendment-actions" class="space-x-2 controls">
        <router-link v-slot="{ href, navigate }" to="/">
          <UiButton type="button" theme="outline" :href="href" @click="navigate">Cancel</UiButton>
        </router-link>

        <UiButton type="submit" theme="primary" @click="onAmendmentsSubmit"
          >Submit Amendments</UiButton
        >
      </div>
    </div>
  </UiCard>
</template>

<script>
import isEmpty from "lodash.isempty";
import { required, maxLength, maxValue, minLength, minValue } from "vuelidate/lib/validators";
import formMixin from "@/mixins/form";
import UiButton from "@/components/UI/UiButton/UiButton";
import UiCard from "@/components/UI/UiCard/UiCard";
import UiHeadline from "@/components/UI/UiHeadline/UiHeadline";
import GuaranteeField from "@/components/Guarantee/fields/GuaranteeField";

export default {
  name: "Guarantee",
  components: { GuaranteeField, UiButton, UiCard, UiHeadline },
  mixins: [formMixin],
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    guarantee: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      amendments: {},
      fieldValues: this.setupAmendments(),
      amendmentInputErrors: []
    };
  },
  computed: {
    allowAmendments() {
      return this.guarantee?.status?.allowAmendments;
    },
    negotiable() {
      return this.guarantee?.status?.negotiable;
    },
    amended() {
      return !isEmpty(this.amendments);
    },
    isDraft() {
      return this.guarantee?.status?.isDraft;
    }
  },
  validations() {
    const amendments = {};
    const fields = this.guarantee?.fields ?? [];

    fields.forEach(field => {
      const rules = {};
      const errors = [];

      if (field.isRequired) {
        rules.required = required;
        errors.push(`${field.label} is required.`);
      }

      if (field.minValue) {
        rules.minValue = minValue(field.minValue);
        errors.push(`${field.label} can't be less than ${field.minValue}.`);
      }

      if (field.maxValue) {
        rules.maxValue = maxValue(field.maxValue);
        errors.push(`${field.label} can't be more than ${field.maxValue}.`);
      }

      if (field.minLength) {
        rules.minLength = minLength(field.minLength);
        errors.push(`${field.label} can't be less than ${field.minLength} characters.`);
      }

      if (field.maxLength) {
        rules.maxLength = maxLength(field.maxLength);
        errors.push(`${field.label} can't be more than ${field.maxLength} characters.`);
      }

      this.amendmentInputErrors[field.id] = errors;

      amendments[field.id] = rules;
    });

    return { amendments };
  },
  methods: {
    onAmendmentsSubmit() {
      // validate using this.validate() from our formMixin...
      this.validate();

      if (!this.$v.$invalid) {
        const data = this.amendments;
        // Objec.keys returns an array then we map to the schema structure...
        const amendments = Object.keys(data).map(key => {
          return { fieldId: key, value: data[key] };
        });

        this.$emit("amendments-submitted", amendments);
      }
    },
    setupAmendments() {
      const fields = this.guarantee?.fields ?? [];

      return (
        // Get the editable fields...
        fields
          .filter(field => field.isEditable)
          // Return an object with the field's id as the key and the value as the value...
          .reduce((accumulator, field) => ((accumulator[field.id] = field.value), accumulator), {})
      );
    },
    guaranteeFields() {
      return this.guarantee?.fields?.filter(field => field.displayOnSummary) || [];
    }
  }
};
</script>

<style scoped>
.brand-logo {
  height: 34px;
}

.guarantee-number {
  @apply font-semibold text-gray-500 top-1 relative text-sm;

  @screen md {
    @apply text-base;
  }
}

.guarantee-disabled {
  @apply opacity-75 pointer-events-none;
}

.draft-stamp {
  @apply absolute h-6 opacity-50;
  left: 189px;
  top: 13px;
  transform: rotate(8deg);

  @screen sm {
    @apply h-10;
    left: 233px;
    top: 21px;
  }
}

.controls {
  @apply flex flex-wrap items-center justify-end p-4 pb-10;
}
</style>
