<template>
  <div class="space-y-4 md:space-y-6">
    <GuaranteeListItem
      v-for="guarantee in guarantees"
      :key="guarantee.guaranteeId"
      :guarantee="guarantee"
      :user-role="userRole"
      :send-to-guarantees-service="sendToGuaranteesService"
    />

    <div v-if="guarantees.length && canLoadMore" class="flex justify-center">
      <UiButton theme="primary" size="sm" :loading="loading" @click="onFetchMore"
        >Load More</UiButton
      >
    </div>
    <!-- .flex justify-center -->
  </div>
</template>

<script>
import { useService } from "@xstate/vue";
import { computed } from "@vue/composition-api";
import { organizationService } from "@/main";
import GuaranteeListItem from "@/components/Guarantees/GuaranteesList/GuaranteeListItem";
import UiButton from "@/components/UI/UiButton/UiButton";

export default {
  name: "GuaranteesList",
  components: { GuaranteeListItem, UiButton },
  setup() {
    // Setup our state machine...
    const { state: organizationState } = useService(organizationService);

    const userRole = computed(() => organizationState?.value?.context?.organization?.role);

    // Return what we want to expose to the template...
    return {
      userRole
    };
  },
  props: {
    canLoadMore: {
      type: Boolean,
      default: false
    },
    createGuaranteeLabel: {
      type: String,
      default: ""
    },
    guarantees: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    sendToGuaranteesService: {
      type: Function,
      required: true
    }
  },
  methods: {
    onFetchMore() {
      if (this.canLoadMore) this.sendToGuaranteesService("FETCH_MORE");
    }
  }
};
</script>
