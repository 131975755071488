<template>
  <div>
    <UiHeadline :size="4" class="mb-2">Confirm Registration</UiHeadline>

    <p class="mb-4 text-sm text-gray-500">
      A confirmation code has been sent to your <strong>email address</strong>. Please enter it
      below to confirm your account.
    </p>

    <UiLoader v-if="loading" />

    <UiAlert v-if="state.context.error" theme="warning" title="There was an error">
      {{ state.context.error }}
    </UiAlert>

    <UiAlert v-if="state.context.message" theme="info" :title="state.context.message" />

    <ConfirmationForm
      v-if="!loading"
      :resending-code="state.value === 'resendingCode'"
      @submit="submitHandler"
      @resend-code="resendCodeHandler"
    />
  </div>
</template>

<script>
import { useMachine } from "@xstate/vue";
import { computed } from "@vue/composition-api";
import confirmationMachine from "./confirmation-machine";
import UiAlert from "@/components/UI/UiAlert/UiAlert";
import UiHeadline from "@/components/UI/UiHeadline/UiHeadline";
import UiLoader from "@/components/UI/UiLoader/UiLoader";
import ConfirmationForm from "@/components/Auth/Confirmation/ConfirmationForm";
import { watchState } from "@/utils/machine-helpers";

export default {
  name: "Confirmation",
  components: { ConfirmationForm, UiAlert, UiHeadline, UiLoader },
  setup() {
    // Setup our state machine...
    const { state, send } = useMachine(confirmationMachine);

    watchState(state, "Confirmation");

    // Create a computed prop to handle all of our loading states...
    const loading = computed(() => {
      const { value } = state.value;
      const loadingStates = ["confirming", "confirmed", "signedin"];

      return loadingStates.includes(value);
    });

    // Return what we want to expose to the template...
    return {
      loading,
      state,
      send
    };
  },
  methods: {
    resendCodeHandler(email) {
      this.send({ type: "RESEND_CODE", data: { email } });
    },
    submitHandler(data) {
      this.send({ type: "CONFIRM", data });
    }
  }
};
</script>
