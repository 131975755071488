<template>
  <Auth>
    <ResetPassword />
  </Auth>
</template>

<script>
import Auth from "@/views/auth/Auth";
import ResetPassword from "@/components/Auth/ResetPassword/ResetPassword";

export default {
  name: "ResetPasswordView",
  components: { Auth, ResetPassword },
  metaInfo: {
    title: "Reset Password"
  }
};
</script>
