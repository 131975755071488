<template>
  <div class="absolute top-0 left-0 w-3 inset-0 lg:block" aria-hidden="true">
    <svg
      class="h-full w-full text-gray-300"
      viewBox="0 0 16 82"
      fill="none"
      preserveAspectRatio="none"
    >
      <path
        d="M0.5 0V31L10.5 41L0.5 51V82"
        stroke="currentcolor"
        vector-effect="non-scaling-stroke"
      />
    </svg>
  </div>
</template>

<script>
export default {
  name: "UiStepsSeparator"
};
</script>
