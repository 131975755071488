<template>
  <div v-if="role && display" class="user-role" :class="role">{{ role }}</div>
</template>

<script>
import { isDev, isLocal } from "@/utils/constants";

export default {
  name: "UserRole",
  props: {
    role: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      display: isDev || isLocal
    };
  }
};
</script>

<style scoped>
.user-role {
  @apply text-blue-400 rounded absolute uppercase font-semibold left-0 right-0 mx-auto text-center;
  font-size: 9px;
  top: 3px;

  &.guarantor {
    color: #272bb0;
  }

  &.tenant {
    color: #38b197;
  }

  &.landlord {
    color: #38b197;
  }
}
</style>
