<template>
  <div>
    <!-- Nav -->
    <UiNav />

    <div v-if="alert && alert.message" class="mx-4 mt-4 sm:max-w-md sm:mx-auto">
      <UiAlert
        :title="alert.message"
        :theme="alert.type"
        :items="alert.items"
        :timer="alert.timer"
        show-clear-button
        @clear="sendToAlertService('CLEAR')"
      />
    </div>
    <!-- .max-w-md mx-auto -->

    <!-- Main Content -->
    <div class="min-h-screen">
      <Notifications />

      <div class="py-4 sm:py-10">
        <slot></slot>
      </div>
    </div>

    <UiFooter />
  </div>
</template>

<script>
import { useService } from "@xstate/vue";
import { computed } from "@vue/composition-api";
import { alertService, organizationService } from "@/main";
import UiAlert from "@/components/UI/UiAlert/UiAlert";
import UiFooter from "@/components/UI/UiFooter/UiFooter";
import UiNav from "@/components/UI/UiNav/UiNav";
import Notifications from "@/components/Notifications/Notifications";

export default {
  setup() {
    // Setup our state machine...
    const { state: alertState, send: sendToAlertService } = useService(alertService);

    const { state: organizationState, send: sendToOrganizationService } = useService(
      organizationService
    );

    const preventScrolling = computed(() =>
      organizationState.value.matches("ready.notificationsActive") ? "prevent-scrolling" : ""
    );

    const alert = computed(() => alertState?.value?.context?.alert);

    // Return what we want to expose to the template...
    return {
      alert,
      preventScrolling,
      sendToAlertService,
      sendToOrganizationService
    };
  },
  name: "DefaultLayout",
  components: { UiAlert, Notifications, UiFooter, UiNav },
  metaInfo() {
    return {
      bodyAttrs: {
        class: ["default-layout", this.preventScrolling]
      }
    };
  },
  watch: {
    $route(to, from) {
      // When the route changes...
      if (to.path !== from.path) {
        // Refetch the notifications...
        this.sendToOrganizationService("FETCH_NOTIFICATIONS");

        // And clear the alert message...
        if (this.alert?.message) this.sendToAlertService("CLEAR");
      }
    }
  }
};
</script>

<style>
.default-layout {
  background-color: #f5f6f8;

  &.prevent-scrolling {
    @apply overflow-hidden;
  }
}
</style>
