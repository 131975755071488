/**
 * Calculates the Net Present Value of a given initial investment
 * cost and an array of cash flow values with the specified discount rate.
 *
 * @param {number} discountRate The discount rate percentage
 * @param {number} initialCost The initial investment
 * @param {array} cashFlows An array of future payment amounts
 * @return {number} The calculated Net Present Value
 */

const calNPV = (discountRate, initialCost, cashflows) => {
  return cashflows.reduce(
    (accumulator, currentValue, index) =>
      accumulator + currentValue / Math.pow(discountRate / 100 + 1, index + 1),
    initialCost
  );
};

/**
 * Calculates in percent, the change between 2 numbers.
 * e.g from 200 to 400 = 100%
 *
 * @param {number} oldNumber The initial value
 * @param {number} newNumber The value that changed
 * @return {number} The calculated Net Present Value
 */
const calPercentageChange = (oldNumber, newNumber) => {
  const diff = oldNumber - newNumber;
  return (diff / oldNumber) * 100;
};

const toFinancialNumber = value => {
  if (typeof value !== "number") return "";
  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    currencySign: "accounting"
  });
  return formatter.format(value);
};

export { calNPV, calPercentageChange, toFinancialNumber };
