<template>
  <div class="-m-2">
    <AddressSearchInput
      v-model.trim="form.address"
      theme=""
      name="address"
      :street-error="parentFormDirtied && $v.form.address.street.$error"
      :suburb-error="parentFormDirtied && $v.form.address.suburb.$error"
      :state-error="parentFormDirtied && $v.form.address.state.$error"
      :postcode-error="parentFormDirtied && $v.form.address.postcode.$error"
      :country-error="parentFormDirtied && $v.form.address.country.$error"
      full-width-meta
      @input="onAddressInput"
    >
      <template v-if="parentFormDirtied && $v.form.address.street.$error" slot="streetErrors">
        <p v-if="!$v.form.address.street.required">
          Street is required.
        </p>
      </template>

      <template v-if="parentFormDirtied && $v.form.address.suburb.$error" slot="suburbErrors">
        <p v-if="!$v.form.address.suburb.required">
          Suburb is required.
        </p>
      </template>

      <template v-if="parentFormDirtied && $v.form.address.postcode.$error" slot="postcodeErrors">
        <p v-if="!$v.form.address.postcode.required">
          Postcode is required.
        </p>
      </template>

      <template v-if="parentFormDirtied && $v.form.address.country.$error" slot="countryErrors">
        <p v-if="!$v.form.address.country.required">
          Country is required.
        </p>
      </template>
    </AddressSearchInput>
  </div>
</template>

<script>
import formMixin from "@/mixins/form";
import AddressSearchInput from "@/components/AddressSearchInput/AddressSearchInput";
import { required } from "vuelidate/lib/validators";

export default {
  name: "UiAddressInput",
  components: {
    AddressSearchInput
  },
  mixins: [formMixin],
  props: {
    name: {
      type: String,
      default: ""
    },
    role: {
      type: String,
      default: ""
    },
    value: {
      type: Object,
      default: () => {}
    },
    formDirty: {
      type: Boolean,
      default: false
    },
    reportStatus: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      form: {
        address: this.value
      },
      parentFormDirtied: this.formDirty
    };
  },
  watch: {
    formDirty: function(val) {
      this.parentFormDirtied = val;
    },
    "$v.$error": function(val) {
      this.reportStatus({ inputId: this.name, valid: !val });
    }
  },
  created() {
    this.validateForm();
  },
  methods: {
    validateForm() {
      this.$v.$touch();
      setTimeout(() => {
        document.querySelectorAll(".error")[0]?.focus();
      }, 100);
    },
    onAddressInput(address) {
      this.$emit("input", address);
    }
  },
  validations() {
    const address = {
      unit: {},
      country: {
        required
      },
      street: {
        required
      },
      suburb: {
        required
      },
      state: {},
      postcode: {
        required
      }
    };

    return {
      form: {
        address
      }
    };
  }
};
</script>
