<template>
  <div class="container">
    <Bond v-if="!loading && guarantee.fields" :guarantee="guarantee" :is-draft="isDraft" />
  </div>
</template>

<script>
import { useMachine } from "@xstate/vue";
import { computed, inject, ref } from "@vue/composition-api";
import featuresMixin from "@/mixins/features";
import guaranteeMachine from "@/components/Guarantee/guarantee-machine";
import { watchState } from "@/utils/machine-helpers";
import Bond from "@/components/Guarantee/Bond";

export default {
  name: "GuaranteeView",
  components: {
    Bond
  },
  mixins: [featuresMixin],
  setup(_, context) {
    const guaranteeId = computed(() => context.root?.$route?.params?.id);

    const organization = computed(() => inject("organization"));
    const machineOptions = {
      context: {
        urlHash: context.root?.$route?.hash,
        organization,
        guaranteeId
      }
    };

    // Setup our state machine...
    const { state: guaranteeState, send: sendToGuaranteeMachine } = useMachine(
      guaranteeMachine,
      machineOptions
    );

    watchState(guaranteeState, "Guarantee");

    const guarantee = computed(() => guaranteeState.value?.context?.guarantee);

    // Global loading state...
    const loading = computed(
      () =>
        guaranteeState.value.matches("loading") ||
        guaranteeState.value.matches("amending") ||
        guaranteeState.value.matches("amended") ||
        guaranteeState.value.matches("resolving") ||
        guaranteeState.value.matches("resolved") ||
        guaranteeState.value.matches("performingAction") ||
        guaranteeState.value.matches("savingActionInputs")
    );

    sendToGuaranteeMachine("LOAD", { guaranteeId: guaranteeId.value });

    const isDraft = true; //TODO disable draft and show actual document instead

    return {
      isDraft,
      guarantee,
      loading
    };
  },
  methods: {}
};
</script>

<style scoped>
.container {
  width: 1024px;
}

@media print {
  html,
  body {
    display: none; /* hide whole page */
  }
}
</style>
