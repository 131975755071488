<template>
  <div class="select-filter">
    <label class="flex items-center">
      <span v-if="label" class="select-filter-label">{{ label }}:</span>

      <select
        :key="value"
        v-autoResizeSelect="5"
        class="form-select select-filter-select"
        :class="filterClasses"
        @change="$emit('input', { type, value: $event.target.value })"
      >
        <option
          v-for="(option, i) in options"
          :key="i"
          :value="option.value"
          :selected="option.value === value"
          >{{ option.label }}</option
        >
      </select>
    </label>
  </div>
  <!-- .select-filter -->
</template>

<script>
export default {
  name: "SelectFilter",
  props: {
    label: {
      type: String,
      default: ""
    },
    options: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      required: true
    },
    value: {
      type: String,
      default: ""
    }
  },
  computed: {
    filterSelected() {
      return this.value !== this.options[0].value;
    },
    filterClasses() {
      return {
        selected: this.filterSelected,
        [this.value.toLowerCase()]: this.value
      };
    }
  }
};
</script>

<style scoped>
/* purgecss start ignore */
.select-filter-label {
  @apply text-cool-gray-500 mr-1 text-xs font-light;
}

.select-filter-select {
  @apply inline-block text-xs text-primary border-none bg-transparent;
  -webkit-appearance: none;
  appearance: none;
  background-size: 1.1em;
  padding: 2px 18px 2px 0px;

  &:focus {
    @apply shadow-none;
  }

  &.selected {
    @apply font-semibold;
  }

  &.active {
    @apply text-green-500;
  }

  &.draft {
    @apply text-cool-gray-600;
  }

  &.cancelled {
    @apply text-red-500;
  }

  &.deleted {
    @apply text-cool-gray-400;
  }

  &.completed {
    @apply text-purple-800;
  }

  &.application {
    @apply text-cool-gray-400;
  }
}
/* purgecss end ignore */
</style>
