<template>
  <UiCard theme="primary" :enable-css-overflow="true">
    <div class="flex justify-end">
      <GenerateReport report-type="user" />
    </div>
    <p class="mb-4 text-sm text-gray-400">
      Grant access to your organisation by adding team members below
    </p>

    <div class="mb-6 space-y-4">
      <div v-for="(member, i) in team" :key="i" class="team-member">
        <div class="flex flex-wrap items-center flex-grow">
          <div class="flex items-center w-full sm:mb-0 sm:w-2/5">
            <UiBadge :status="lowercase(member.status)" class="mr-2" />

            <p class="team-member-name">{{ member.firstname }} {{ member.lastname }}</p>
          </div>

          <div class="w-auto">
            <p class="team-member-email">
              {{ member.email }}
            </p>
          </div>

          <div v-if="actionAllowed" class="flex justify-end ml-auto">
            <UiButton
              :disabled="member.email === currentUserEmail || inviteLoading || removeLoading"
              theme="link"
              :title="member.email === currentUserEmail ? 'You can\'t remove your own account' : ''"
              size="sm"
              remove-padding
              :loading="inviteLoading"
              @click="onRemoveClick(member.email, `${member.firstname} ${member.lastname}`)"
            >
              Remove
            </UiButton>
          </div>
        </div>

        <div class="w-full mb-3">
          <div class="flex">
            <p class="team-member-name mt-1">
              <strong>User Roles:</strong>
              {{ member.userRoles.map(role => role.name).join(", ") }}
            </p>
            <UiButton v-if="actionAllowed" size="sm" theme="link" @click="editUserRole(member)"
              >Update</UiButton
            >
          </div>
        </div>
      </div>
    </div>

    <div v-if="editing" class="mb-4">
      <UiMultiselect
        v-model="editingRoleIds"
        :label="`Update Roles for: ${editingMember.firstname} ${editingMember.lastname}`"
        :options="userRolesOptions()"
        :error="!editingRoleIds.length"
      >
        <template v-if="!editingRoleIds.length" slot="errors">
          <p>
            Must select at least 1 user role
          </p>
        </template>
      </UiMultiselect>
      <UiButton
        v-if="permissions.manageUsers"
        size="sm"
        theme="primary"
        class="mt-2"
        @click="editingSave"
        >Save</UiButton
      >
      <UiButton v-if="permissions.manageUsers" size="sm" theme="" class="ml-2 mt-2" @click="cancel"
        >Cancel</UiButton
      >
    </div>

    <!-- INVITING -->
    <div v-if="inviting">
      <p class="mb-4 text-sm text-gray-400">Invite a new team member</p>
      <div class="flex flex-wrap mb-3 gap-3">
        <UiInput
          v-model.trim="form.firstname"
          label="First Name"
          class="flex-grow"
          :error="$v.form.firstname.$error"
        >
          <template v-if="$v.form.firstname.$error" slot="errors">
            <p v-if="!$v.form.firstname.required">
              First name is required.
            </p>
          </template>
        </UiInput>

        <UiInput
          v-model.trim="form.lastname"
          label="Last Name"
          class="flex-grow"
          :error="$v.form.lastname.$error"
        >
          <template v-if="$v.form.lastname.$error" slot="errors">
            <p v-if="!$v.form.lastname.required">
              Last name is required.
            </p>
          </template>
        </UiInput>
      </div>
      <div class="flex flex-wrap mb-3">
        <UiInput
          v-model.trim="form.email"
          class="flex-1"
          label="Email"
          :error="$v.form.email.$error"
        >
          <template v-if="$v.form.email.$error" slot="errors">
            <p v-if="!$v.form.email.required">
              Email is required.
            </p>

            <p v-if="!$v.form.email.email">
              Valid email address required.
            </p>
          </template>
        </UiInput>
      </div>
      <div class="flex flex-wrap mb-3 gap-3">
        <UiMultiselect
          v-model="form.roleIds"
          label="Roles"
          class="flex-1"
          :options="userRolesOptions()"
          :error="$v.form.roleIds.$error"
        >
          <template v-if="$v.form.roleIds.$error" slot="errors">
            <p v-if="!$v.form.roleIds.required">
              User Role is required
            </p>

            <p v-if="!$v.form.roleIds.minLength">
              Must select at least 1 user role
            </p>
          </template>
        </UiMultiselect>
        <div class="flex-1 flex flex-col gap-1">
          <UiMultiselect
            v-model="form.userGroupIds"
            label="Groups"
            class="flex-1"
            :options="userGroupsOptions()"
          />
          <p class="mb-2 ml-1 text-xs text-gray-400 font-medium">
            Optional - but a user must be added to a group to access lease bonds.
          </p>
        </div>
      </div>
    </div>

    <div v-show="!editing" class="flex justify-center sm:justify-end">
      <UiButton
        v-if="actionAllowed && !ssoOn"
        size="sm"
        :theme="inviting ? 'primary' : 'outline'"
        :loading="inviteLoading"
        :disabled="inviteLoading || removeLoading"
        @click="onInviteClick"
      >
        Invite Team Member
      </UiButton>
    </div>
    <!-- .flex justify-center -->
  </UiCard>
</template>

<script>
import { email, required, minLength } from "vuelidate/lib/validators";
import formMixin from "@/mixins/form";
import UiBadge from "@/components/UI/UiBadge/UiBadge";
import UiButton from "@/components/UI/UiButton/UiButton";
import UiCard from "@/components/UI/UiCard/UiCard";
import UiInput from "@/components/UI/UiInput/UiInput";
import UiMultiselect from "@/components/UI/UiMultiselect/UiMultiselect";
import { getUserAttributes } from "@/lib/auth";
import GenerateReport from "@/components/Reports/GenerateReport";
import { enforceSSO } from "@/utils/machine-helpers";

export default {
  name: "TeamMembers",
  components: { UiBadge, UiButton, UiCard, UiInput, UiMultiselect, GenerateReport },
  mixins: [formMixin],
  props: {
    sendToOrganizationService: {
      type: Function,
      required: true
    },
    team: {
      type: Array,
      required: true
    },
    userRoles: {
      type: Array,
      default: () => []
    },
    userGroups: {
      type: Array,
      default: () => []
    },
    permissions: {
      type: Object,
      default: () => ({
        manageProfile: false,
        manageUsers: false
      })
    },
    inviteLoading: {
      type: Boolean,
      default: false
    },
    removeLoading: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data(props) {
    return {
      currentUserEmail: "",
      form: {
        email: "",
        firstname: "",
        lastname: "",
        roleIds: [],
        userGroupIds: []
      },
      inviting: false,
      editing: false,
      editingMember: {},
      editingRoleIds: []
    };
  },
  computed: {
    actionAllowed() {
      return this.permissions.manageProfile && !this.readOnly;
    },
    ssoOn() {
      return enforceSSO(this.currentUserEmail);
    }
  },
  mounted() {
    this.getCurrentUserEmail();
  },
  methods: {
    userRolesOptions() {
      return this.userRoles.map(role => ({
        label: role.name,
        value: role.id
      }));
    },
    userGroupsOptions() {
      return this.userGroups.map(group => ({
        label: group.name,
        value: group.id
      }));
    },
    async getCurrentUserEmail() {
      this.currentUserEmail = await getUserAttributes("email");
    },
    lowercase(value) {
      return value.toLowerCase();
    },
    onInviteClick() {
      if (!this.inviting) return (this.inviting = true);

      this.validate();

      if (!this.$v.$invalid) {
        const { email, firstname, lastname, roleIds, userGroupIds } = this.form;

        this.sendToOrganizationService({
          type: "INVITE_TEAM_MEMBER",
          email,
          firstname,
          lastname,
          roleIds,
          userGroupIds
        });
      }
    },
    editUserRole(member) {
      this.editing = true;
      this.editingMember = member;
      this.editingRoleIds = member.userRoles.map(role => role.id);
    },
    editingSave() {
      if (this.editing && this.editingRoleIds.length > 0) {
        this.$emit("save", {
          id: this.editingMember.id,
          roleIds: this.editingRoleIds
        });
        this.editing = false;
      }
    },
    cancel() {
      this.editing = false;
    },
    onRemoveClick(email, name) {
      if (window.confirm(`Are you sure you want to remove ${name} from your team?`)) {
        this.sendToOrganizationService({
          type: "REMOVE_TEAM_MEMBER",
          email
        });
      }
    }
  },
  validations: {
    form: {
      email: {
        email,
        required
      },
      firstname: {
        required
      },
      lastname: {
        required
      },
      roleIds: {
        required,
        minLength: minLength(1)
      },
      userGroupIds: {}
    }
  }
};
</script>

<style scoped>
.team-member-name {
  @apply text-sm text-gray-500;
}

.team-member-email {
  @apply text-sm text-gray-500;
}
</style>
