import { Machine, assign } from "xstate";
import { API, graphqlOperation } from "aws-amplify";
import { GuaranteeStats as GuaranteeStatsQuery } from "@/graphql/queries";
import { getGraphQlData, helperActions } from "@/utils/machine-helpers";

const initialState = {
  errors: [],
  stats: []
};

export default Machine(
  {
    id: "stats-machine",

    initial: "idle",

    context: {
      ...initialState
    },

    states: {
      idle: {
        on: {
          LOAD: "loading"
        }
      },
      loading: {
        invoke: {
          id: "loading",
          src: "loadStats",
          onDone: {
            target: "loaded",
            actions: "assignStats"
          },
          onError: {
            target: "error",
            actions: ["assignErrors", "trackErrors"]
          }
        }
      },
      loaded: {
        type: "final"
      },
      error: {
        on: {
          RETRY: "loading"
        }
      }
    }
  },
  {
    actions: {
      ...helperActions,
      assignStats: assign({
        stats: (context, event) => getGraphQlData(event, "guaranteeStats.stats")
      }),
      resetState: assign({
        ...initialState
      })
    },
    // guards: {
    // },
    services: {
      loadStats: (context, event) => {
        return API.graphql(graphqlOperation(GuaranteeStatsQuery));
      }
    }
  }
);
