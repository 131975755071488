<template>
  <div>
    <div v-for="(recipient, index) in value" :key="recipient.id" class="flex flex-col">
      <div class="flex flex-row">
        <UiInput
          type="text"
          :name="`${name}[${index}].name`"
          placeholder="Full Name"
          :disabled="lockArray"
          :value="recipient.name"
          :error="childError[index].name"
          @input="updateCurrentModelValue(recipient, 'name', $event)"
        />
        <UiInput
          type="email"
          class="input-email"
          :name="`${name}[${index}].email`"
          placeholder="Email"
          :value="recipient.email"
          :error="childError[index].email"
          @input="updateCurrentModelValue(recipient, 'email', $event)"
        />
        <Trash2 v-if="!lockArray" class="button-delete" @click="remove(recipient.id)" />
      </div>
    </div>
    <UiButton v-if="!lockArray" type="button" theme="primary" class="button-add" @click="addMore">
      Add more
    </UiButton>
  </div>
</template>

<script>
import UiButton from "@/components/UI/UiButton/UiButton.vue";
import UiInput from "@/components/UI/UiInput/UiInput.vue";
import { Trash2Icon as Trash2 } from "vue-feather-icons";
import { v4 as uuidv4 } from "uuid";

export default {
  name: "UiDocusignPersonal",
  components: { UiButton, UiInput, Trash2 },
  inheritAttrs: false,
  props: {
    value: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: ""
    },
    lockArray: {
      type: Boolean,
      default: false
    },
    error: {
      type: Boolean,
      default: false
    },
    childError: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    inputClassObject() {
      return {
        error: this.error,
        [this.size]: this.size
      };
    }
  },
  methods: {
    updateNewValue(data) {
      this.$emit("input", data);
    },
    addMore() {
      this.updateNewValue(
        this.value.concat({
          id: uuidv4(),
          name: "",
          email: ""
        })
      );
    },
    remove(removedId) {
      this.updateNewValue(this.value.filter(({ id }) => id !== removedId));
    },
    updateCurrentModelValue(elem, key, newValue) {
      elem[key] = newValue;
      this.$emit("input", this.value);
    }
  }
};
</script>
