import Vue from "vue";
import { interpret } from "xstate";

import "@/lib/amplify";
import "@/config/directives";
import "@/config/filters";
import "@/config/misc";
import "@/config/plugins";

import alertMachine from "@/machines/alert-machine";
import organizationMachine from "@/machines/organization-machine";

import router from "./router";

import "@/assets/styles/nprogress.css";
import "@/assets/styles/tooltips.css";
import "@/assets/styles/index.css";
import "@vueform/multiselect/themes/default.css";
import "typeface-open-sans";

import "flatpickr/dist/flatpickr.css";

import App from "@/components/App/App.vue";
import VueDOMPurifyHTML from "vue-dompurify-html";
import PortalVue from "portal-vue";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

/* import specific icons */
import {
  faBusinessTime,
  faHeart,
  faCircleQuestion,
  faComments,
  faLock,
  faArrowRight,
  faArrowLeft,
  faEnvelope,
  faMobileScreenButton,
  faRocket,
  faHouse,
  faPhone
} from "@fortawesome/free-solid-svg-icons";

/* add icons to the library */
library.add(
  faArrowLeft,
  faArrowRight,
  faBusinessTime,
  faCircleQuestion,
  faComments,
  faEnvelope,
  faHeart,
  faHouse,
  faLock,
  faMobileScreenButton,
  faPhone,
  faRocket
);

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);

export const alertService = interpret(alertMachine).start();

export const organizationService = interpret(organizationMachine).start();

Vue.use(VueDOMPurifyHTML, {
  hooks: {
    afterSanitizeAttributes: currentNode => {
      if ("target" in currentNode) {
        currentNode.setAttribute("target", "_blank");
        currentNode.setAttribute("rel", "noopener");
      }
    }
  }
});

Vue.use(PortalVue);

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
