<template>
  <component
    :is="icon"
    data-testid="ui-status-icon"
    class="ui-status-icon"
    :class="statusLowerCase"
    size=".8x"
  />
</template>

<script>
import {
  ArrowRightCircleIcon,
  CheckCircleIcon,
  CircleIcon,
  PlayCircleIcon,
  XCircleIcon
} from "vue-feather-icons";

export default {
  name: "UiStatusIcon",
  props: {
    status: {
      default: "",
      type: String
    }
  },
  computed: {
    icon() {
      switch (this.status) {
        case "ACTIVE":
          return PlayCircleIcon;
        case "DRAFT":
          return ArrowRightCircleIcon;
        case "DELETED":
          return XCircleIcon;
        case "CANCELLED":
          return XCircleIcon;
        case "COMPLETED":
          return CheckCircleIcon;
        case "NONE":
          return null;
        default:
          return CircleIcon;
      }
    },
    statusLowerCase() {
      return this.status?.toLowerCase();
    }
  }
};
</script>

<style scoped>
/* Base Styles */

/* Statuses */
.ui-status-icon {
  @apply text-white;

  &.active,
  &.cancelled,
  &.draft {
    font-size: 14px;
  }

  &.completed {
    font-size: 13px;
  }

  &.deleted {
    @apply text-cool-gray-400;
  }
}
</style>
