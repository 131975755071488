const Features = {
  calculator: process.env.VUE_APP_FEATURES_CALCULATOR,
  facility: process.env.VUE_APP_FEATURES_FACILITY
};

const featureEnabled = feature => {
  return Features[feature] === "true";
};

export default {
  methods: {
    featureEnabled
  }
};
