<template>
  <div>
    <ChatSessionItem
      v-for="(session, index) in sessionList"
      :key="index"
      :session-id="session.sessionId"
      :session-name="session.sessionName"
      :is-active="currentFilter === session.sessionId"
      :total="session.total"
      @select-session="$emit('select-session', $event)"
    />

    <portal-target name="recipientsSelectorPortal"></portal-target>
  </div>
</template>

<script>
import ChatSessionItem from "@/components/Chat/ChatSessionItem.vue";

export default {
  name: "ChatSessionList",
  components: {
    ChatSessionItem
  },
  props: {
    sessionList: {
      type: Array,
      default: () => []
    },
    currentFilter: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      showRecipientsSelector: false
    };
  }
};
</script>
