import Vue from "vue";
import { Vue as VueIntegration } from "@sentry/integrations";
import VueCompositionApi from "@vue/composition-api";
import VueMeta from "vue-meta";
import VueHotkey from "v-hotkey";
import vClickOutside from "v-click-outside";
import VueRx from "vue-rx";
import { isProduction, isLocal } from "@/utils/constants";
import { VPopover, VClosePopover, VTooltip } from "v-tooltip";
import VueScrollTo from "vue-scrollto";

// Setup Vue Plugins
Vue.use(VueCompositionApi);
Vue.use(VueMeta);
Vue.use(VueHotkey);
Vue.use(vClickOutside);
Vue.use(VueRx);
Vue.use(VueScrollTo, {
  duration: 1000
});

Vue.directive("tooltip", VTooltip);
Vue.directive("close-popover", VClosePopover);
Vue.component("v-popover", VPopover);

// Setup Sentry
const integrations = !isLocal ? [new VueIntegration({ Vue, logErrors: !isProduction })] : [];

window.Sentry?.init({
  dsn: "https://f3b07b4ab3494b5fa1866ec6fed2786a@o405949.ingest.sentry.io/5273634",
  integrations,
  environment: process.env.VUE_APP_ENV,
  beforeBreadcrumb(breadcrumb, hint) {
    if (breadcrumb.category === "ui.click") {
      const { target } = hint.event;

      // If the target is an anchor link or button then prepend the innertext and tagname...
      if (["A", "BUTTON"].includes(target.tagName)) {
        breadcrumb.message = `${target.tagName} ${target.innerText} - ${breadcrumb.message}`;
      } else {
        // Otherwise just log the tagname...
        breadcrumb.message = `${target.tagName} - ${breadcrumb.message}`;
      }
    }
    return breadcrumb;
  }
});
