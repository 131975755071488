import { createMachine, assign } from "xstate";
import { Auth } from "aws-amplify";
import { router } from "@/router";

const initialState = {
  email: "",
  error: "",
  message: "",
  notice: {
    title: "",
    message: ""
  },
  user: null
};

export default createMachine(
  {
    id: "sign-in",

    initial: "idle",

    context: {
      ...initialState
    },

    states: {
      idle: {
        on: {
          AUTHENTICATE: "authenticating",
          SHOW_RESET: "reset",
          SSO_SIGN_IN: "ssoSigningIn"
        }
      },
      authenticating: {
        entry: ["resetState", "setEmail"],
        invoke: {
          id: "authenticating",
          src: (context, event) => Auth.signIn(event.data.email.toLowerCase(), event.data.password),
          onDone: [
            {
              target: "confirm",
              // Only transition to 'confirm' if the guard (cond) evaluates to true
              cond: "isMfaAuth",
              // Assign the user to our context
              actions: assign((context, event) => ({ user: event.data }))
            },
            {
              target: "setNewPassword",
              cond: "newPassword",
              actions: assign((context, event) => ({ user: event.data }))
            },
            {
              target: "authenticated",
              // Only transition to 'signedin' if the guard (cond) evaluates to true
              cond: "isAuthenticated"
            },
            {
              // If the transition above fails we transition to idle
              target: "idle",
              actions: assign({
                error: "Could not sign you in"
              })
            }
          ],
          onError: [
            {
              target: "idle",
              cond: "mustResetPassword",
              actions: assign({
                notice: () => ({
                  title: "Please Reset Your Password",
                  message: [
                    "As part of a recent security upgrade, we require that all users perform a one-time password reset.",
                    "Please enter you email address, click continue, then click the 'Forgot password?' link and follow the prompts."
                  ]
                })
              })
            },
            {
              target: "idle",
              actions: "assignError"
            }
          ]
        }
      },
      confirming: {
        entry: "resetState",
        invoke: {
          id: "confirming",
          src: (context, event) => {
            const { code, challengeName } = event.data;

            return Auth.confirmSignIn(context.user, code, challengeName);
          },
          onDone: [
            {
              target: "authenticated",
              // Only transition to 'signedin' if the guard (cond) evaluates to true
              cond: "isAuthenticated"
            },
            {
              // If the transition above fails we transition to idle
              target: "idle",
              actions: assign({
                error: "Could not confirm your code"
              })
            }
          ],
          onError: {
            target: "confirm",
            actions: "assignError"
          }
        }
      },
      confirm: {
        on: {
          CONFIRM: "confirming"
        }
      },
      authenticated: {
        entry: (context, event) => router.push({ name: "Home" }),
        type: "final"
      },
      ssoSigningIn: {
        entry: ["resetState", "setEmail"],
        invoke: {
          id: "ssoSigningIn",
          src: async (context, event) => {
            const customProvider = event.data.email.split("@").pop();
            const {
              VUE_APP_COGNITO_DOMAIN: cognitoDomain,
              VUE_APP_COGNITO_USER_POOL_WEB_CLIENT_ID: clientId,
              VUE_APP_SIGN_IN_REDIRECT: redirect
            } = process.env;

            // Auth.federatedSignIn() doesn't support a custom idp_identifier, only identity_provider name, so we use this instead
            window.location.href = `https://${cognitoDomain}/oauth2/authorize?response_type=code&idp_identifier=${customProvider}&client_id=${clientId}&redirect_uri=${encodeURIComponent(
              redirect
            )}`;
          },
          onDone: [
            {
              target: "authenticated",
              cond: "isAuthenticated"
            }
          ],
          onError: {
            target: "idle",
            actions: "assignError"
          }
        }
      },
      reset: {
        entry: "setEmail",
        on: {
          RESET: "resetting"
        }
      },
      resetting: {
        entry: "resetState",
        invoke: {
          id: "resetting",
          src: async (context, event) => await Auth.forgotPassword(event.data.email.toLowerCase()),
          onDone: {
            target: "sentResetLink",
            actions: assign({
              message: () => "Password reset link sent to your email, you may close this tab."
            })
          },
          onError: [
            {
              target: "idle",
              cond: "userNotFound",
              actions: assign({
                error: "User not found"
              })
            },
            {
              target: "idle",
              actions: "assignError"
            }
          ]
        }
      },
      sentResetLink: {
        on: {
          RESTART: "idle"
        }
      },
      setNewPassword: {
        on: {
          SET_NEW_PASSWORD: "settingNewPassword"
        }
      },
      settingNewPassword: {
        entry: "resetState",
        invoke: {
          id: "settingNewPassword",
          src: (context, event) => {
            const { password } = event.data;
            return Auth.completeNewPassword(context.user, password);
          },
          onDone: [
            {
              target: "confirm",
              // Only transition to 'confirm' if the guard (cond) evaluates to true
              cond: "isMfaAuth",
              // Assign the user to our context
              actions: assign((context, event) => ({ user: event.data }))
            },
            {
              target: "authenticated",
              // Only transition to 'signedin' if the guard (cond) evaluates to true
              cond: "isAuthenticated"
            },
            {
              // If the transition above fails we transition to idle
              target: "idle",
              actions: assign({
                error: "Could not sign you in"
              })
            }
          ],
          onError: {
            target: "idle",
            actions: "assignError"
          }
        }
      }
    }
  },
  {
    actions: {
      assignError: assign({
        error: (context, event) => event?.data?.response?.data?.errorMessage || event?.data?.message
      }),
      resetState: assign({
        error: "",
        message: "",
        notice: {
          title: "",
          message: ""
        }
      }),
      setEmail: assign((context, event) => ({
        email: event.data?.email?.toLowerCase() ?? ""
      }))
    },
    guards: {
      isAuthenticated: (context, event) => {
        return !!event?.data?.username;
      },
      isMfaAuth: (context, event) => {
        return (
          event?.data?.challengeName === "SMS_MFA" ||
          event?.data?.challengeName === "SOFTWARE_TOKEN_MFA"
        );
      },
      newPassword: (context, event) => {
        return event?.data?.challengeName === "NEW_PASSWORD_REQUIRED";
      },
      mustResetPassword: (context, event) => {
        return event?.data?.code === "PasswordResetRequiredException";
      },
      userNotFound: (context, event) => {
        return event?.data?.code === "UserNotFoundException";
      }
    }
  }
);
