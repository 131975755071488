import { Machine, assign } from "xstate";
import { Auth } from "aws-amplify";
import axios from "axios";
import { router } from "@/router";
import { removeErrorCode } from "@/utils/machine-helpers";
import { isDev, isLocal } from "@/utils/constants";
import { checkPhoneNumber } from "@/utils/international-phone";

export default Machine(
  {
    id: "registration",

    initial: "idle",

    context: {
      error: "",
      userConfirmed: false,
      user: {
        email: "",
        name: "",
        organizationId: "",
        password: "",
        phoneNumber: ""
      }
    },

    states: {
      idle: {
        on: {
          VERIFY: "verifying"
        }
      },
      verifying: {
        entry: "assignUser",
        invoke: {
          id: "verifyOrganization",
          src: "verifyOrganization",
          onDone: [
            {
              target: "verified",
              // Only transition to 'verified' if the guard (cond) evaluates to true
              cond: "isOrganizationVerified"
            },
            {
              // If the transition above fails we transition to unverified
              target: "unverified"
            }
          ],
          onError: {
            target: "unverified",
            actions: assign({
              error: (context, event) => {
                const error =
                  removeErrorCode(event.data?.response?.data?.errorMessage) ||
                  removeErrorCode(event.data?.message);

                return isDev || isLocal ? error : "";
              }
            })
          }
        }
      },
      unverified: {
        type: "final"
      },
      verified: {
        on: {
          REGISTER: "registering"
        }
      },
      registering: {
        entry: "assignUser",
        invoke: {
          id: "registering",
          src: "registerUser",
          onDone: [
            {
              target: "confirmed",
              cond: "isUserConfirmed"
            },
            {
              target: "unconfirmed"
            }
          ],
          onError: {
            target: "verified",
            actions: "assignError"
          }
        }
      },
      confirmed: {
        invoke: {
          id: "confirmed",
          src: "authenticateUser",
          onDone: "authenticated",
          onError: "unauthenticated"
        }
      },
      unconfirmed: {
        entry: "redirectToConfirmationPage",
        type: "final"
      },
      authenticated: {
        entry: "navigateToHomeRoute",
        type: "final"
      },
      unauthenticated: {
        entry: "navigateToSignInRoute",
        type: "final"
      }
    }
  },
  {
    actions: {
      assignError: assign({
        error: (context, event) => event.data?.message
      }),
      assignUser: assign({
        user: (context, event) => {
          return {
            ...context.user,
            ...event.data
          };
        }
      }),
      navigateToHomeRoute: (context, event) => router.push({ name: "Home" }),
      navigateToSignInRoute: (context, event) => {
        return router.push({
          name: "SignIn",
          params: { error: event.data?.message }
        });
      },
      redirectToConfirmationPage: (context, event) => {
        // If the user isn't confirmed then send them to the confirmation page
        router.push({ name: "Confirm", query: { email: context.user.email } });
      }
    },
    guards: {
      isOrganizationVerified: (context, event) => {
        return event.data === true;
      },
      isUserConfirmed: (context, event) => {
        return event.data?.userConfirmed;
      }
    },
    services: {
      authenticateUser: (context, event) => {
        return Auth.signIn(context.user.email, context.user.password);
      },
      registerUser: context => {
        return Auth.signUp({
          username: context.user?.email,
          password: context.user?.password,
          attributes: {
            name: context.user?.name,
            email: context.user?.email,
            "custom:organizationId": context.user?.organizationId,
            phone_number: checkPhoneNumber(context.user?.phoneNumber).phoneNumber // - E.164 number convention...
            // [+] [country code] [subscriber number including area code] and can have a maximum of fifteen digits.
          }
        });
      },
      verifyOrganization: (context, event) => {
        const url = `${process.env.VUE_APP_SERVICES_REST_ENDPOINT}/organization/check`;
        const options = {
          params: {
            ...event.data
          }
        };

        return axios.get(url, options).then(response => response.data);
      }
    }
  }
);
