<template>
  <div id="messages-container" class="p-8 overflow-y-scroll h-full">
    <div v-if="newChatSession" class="text-center h-full flex items-center justify-center">
      <div>
        <h4 class="font-bold mb-4 text-gray-600">You're starting a new conversation</h4>
        <span class="text-sm">Type your first message below.</span>
      </div>
    </div>

    <div v-else>
      <div v-for="(message, index) in messages" :key="index">
        <div
          v-if="
            index === 0 ||
              showDateLineSeparator(message.sentDate, messages[index > 0 ? index - 1 : 0].sentDate)
          "
          class="text-center mb-2"
        >
          <div>
            <span class="top-0 bg-gray-100 text-gray-500 text-xs px-4">{{
              dateLineSeparatorText(message.sentDate)
            }}</span>
          </div>
          <div class="border-b border-gray-200 border-solid -mt-2.5"></div>
        </div>
        <ChatMessageItem
          :messageId="message.id"
          :message="message.messageContent"
          :is-sender="message.isSender"
          :sender-role="message.senderRole"
          :sender-name="message.senderName"
          :sender-organization="message.organizationName"
          :sent-date="message.sentDate"
          :profile-pic="message.organizationProfilePic"
          :send-to-guarantee-machine="sendToGuaranteeMachine"
          :guarantee-id="guaranteeId"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ChatMessageItem from "@/components/Chat/ChatMessageItem.vue";
import { parseISO, format, isToday, isSameDay } from "date-fns";

export default {
  name: "ChatMessageList",
  components: {
    ChatMessageItem
  },
  props: {
    messages: {
      type: Array,
      default: () => []
    },
    sendToGuaranteeMachine: {
      type: Function,
      default: () => {}
    },
    guaranteeId: {
      type: String,
      required: true
    }
  },
  inject: ["recipients"],
  data() {
    return {
      scrollTimer: {}
    };
  },
  computed: {
    newChatSession() {
      return this.messages.length === 0 && this.recipients.activeRecipients.length > 0;
    }
  },
  mounted() {
    this.scrollTimer = setTimeout(() => {
      this.scrollToLastMessage();
      this.focusMessageEditor();
      clearTimeout(this.scrollTimer);
    }, 100);
  },
  updated() {
    this.$nextTick(function() {
      this.scrollToLastMessage();
      this.focusMessageEditor();
    });
  },
  methods: {
    showDateLineSeparator(currentMessageSentDate, prevMessageSentDate) {
      return !isSameDay(parseISO(currentMessageSentDate), parseISO(prevMessageSentDate));
    },
    dateLineSeparatorText(sentDate) {
      return isToday(parseISO(sentDate)) ? "today" : format(parseISO(sentDate), "MMM dd, yyyy ");
    },
    scrollToLastMessage() {
      const messageContainerEl = document.getElementById("messages-container");
      if (messageContainerEl && messageContainerEl.scrollHeight) {
        messageContainerEl.scrollTop = messageContainerEl.scrollHeight;
      }
    },
    focusMessageEditor() {
      const editorEl = document.querySelector(".ql-editor");
      if (editorEl) {
        editorEl.focus();
      }
    }
  }
};
</script>
