<template>
  <div v-if="contentVisible">
    <UiHeader>
      <UiHeadline :size="2">Settings</UiHeadline>
    </UiHeader>

    <UiMain v-if="organizationDataReady">
      <div class="py-8">
        <div class="flex flex-wrap">
          <div class="w-full mb-6 md:w-gr-small md:pr-6 lg:pr-12 xl:pr-16 md:mb-0">
            <UiHeadline :level="2" :size="5">Organisation Profile</UiHeadline>

            <p class="mt-2 text-sm text-gray-600">
              This organisation information is displayed publicly on the site.
            </p>
          </div>
          <!-- .w-full md:w-gr-small md:pr-6 lg:pr-12 xl:pr-16 -->

          <div class="w-full md:w-gr-large">
            <OrganizationDetails
              :permissions="organization.permissions"
              :organization="organization"
              :send-to-organization-service="sendToProfileService"
              :updating="profileState.matches('ready.updating')"
              :read-only="isReadOnly"
            />
          </div>
          <!-- .w-full md:w-gr-large -->
        </div>
        <!-- .flex flex-wrap -->
      </div>
    </UiMain>
  </div>
</template>

<script>
import { useService, useMachine } from "@xstate/vue";
import { computed, watch } from "@vue/composition-api";
import { organizationService as profileService } from "@/main";
import organizationMachine from "@/components/Organizations/organization-machine";
import UiHeader from "@/components/UI/UiHeader/UiHeader";
import UiHeadline from "@/components/UI/UiHeadline/UiHeadline";
import UiMain from "@/components/UI/UiMain/UiMain";
import OrganizationDetails from "@/components/Settings/OrganizationDetails";

export default {
  name: "Settings",
  components: {
    OrganizationDetails,
    UiHeader,
    UiHeadline,
    UiMain
  },
  setup(props, context) {
    // Setup our state machine...
    const { state: profileState, send: sendToProfileService } = useService(profileService);

    const { state: organizationState, send: sendToOrganizationService } = useMachine(
      organizationMachine
    );

    const organizationId = computed(() => context.root?.$route?.params?.id);

    const isAdmin = computed(() => profileState?.value?.context?.organization?.role === "admin");

    watch(isAdmin, currentValue => {
      if (organizationId.value && currentValue) {
        sendToOrganizationService("LOAD_ORGANIZATION", { organizationId: organizationId.value });
      }
    });

    const organizationMatchByRouteParam = computed(() => {
      return organizationState?.value?.context?.organization ?? {};
    });

    const currentLoggedInOrganization = computed(() => {
      return profileState?.value?.context?.organization ?? {};
    });

    const organization = organizationId.value
      ? organizationMatchByRouteParam
      : currentLoggedInOrganization;

    const organizationDataReady = computed(() =>
      organizationId.value
        ? organizationState?.value?.matches("loaded")
        : profileState?.value?.matches("ready")
    );

    const contentVisible = computed(() => {
      if (organizationId.value && !isAdmin.value) {
        return false;
      }
      return true;
    });

    const isReadOnly = !!organizationId.value;

    // Return what we want to expose to the template...
    return {
      organization,
      profileState,
      sendToProfileService,
      organizationDataReady,
      contentVisible,
      isReadOnly
    };
  }
};
</script>
