import { render, staticRenderFns } from "./UiTextarea.vue?vue&type=template&id=663c3efe&scoped=true"
import script from "./UiTextarea.vue?vue&type=script&lang=js"
export * from "./UiTextarea.vue?vue&type=script&lang=js"
import style0 from "./UiTextarea.vue?vue&type=style&index=0&id=663c3efe&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "663c3efe",
  null
  
)

export default component.exports