<template>
  <div class="hidden md:block">
    <UiSteps v-if="steps.length > 0" :steps="steps" :current-step="currentStep" />
  </div>
</template>

<script>
import UiSteps from "@/components/UI/UISteps/UiSteps.vue";

export default {
  name: "GuaranteeSteps",
  components: {
    UiSteps
  },
  props: {
    dealStage: {
      type: String,
      default: () => "Application",
      required: true
    },
    guaranteeType: {
      type: String,
      default: () => "LEASE_BOND",
      required: false
    }
  },
  computed: {
    steps() {
      if (this.dealStage === "Terminated Client" || this.dealStage === "Unqualified") {
        return [];
      }

      switch (this.guaranteeType) {
        case "FACILITY":
          return ["Application", "Credit", "Expression of Interest", "Documentation", "Active"];
        case "LEASE_BOND":
        default:
          return [
            "Application",
            "Credit",
            "Expression of Interest",
            "Landlord Review",
            "Documentation",
            "Active"
          ];
      }
    },
    currentStep() {
      return this.steps.indexOf(this.dealStage) + 1;
    }
  }
};
</script>
