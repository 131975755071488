<template>
  <div class="flex flex-wrap justify-center">
    <button class="outline-none" @click="backToSignIn">
      <div class="flex flex-row justify-center text-sm mt-4 text-gray-500">
        <ArrowLeftIcon class="mr-2" size="1.4x" />
        Back to Sign in
      </div>
    </button>
  </div>
</template>

<script>
import { ArrowLeftIcon } from "@vue-hero-icons/outline";

export default {
  name: "BackButton",
  components: { ArrowLeftIcon },
  methods: {
    backToSignIn() {
      this.$emit("submit");
    }
  }
};
</script>
