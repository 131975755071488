<template>
  <div class="ui-loader" data-testid="ui-loader">
    <loader-icon size="1.3x" class="spin text-cool-gray-500" />

    <p class="ui-loader-label">{{ label }}</p>
  </div>
</template>

<script>
import { LoaderIcon } from "vue-feather-icons";

export default {
  name: "UiLoader",
  components: { LoaderIcon },
  props: {
    label: {
      type: String,
      default: "Loading"
    }
  }
};
</script>

<style scoped>
/* Base Styles */
.ui-loader {
  @apply flex flex-col justify-center items-center;
}

.ui-loader-label {
  @apply text-xs mt-1 text-cool-gray-500;

  @screen md {
    @apply text-sm;
  }
}
</style>
