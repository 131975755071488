<template>
  <div @keyup.enter="onSubmit">
    <!-- EMAIL (disabled) -->
    <UiInput
      v-model.trim="form.email"
      name="email"
      placeholder="Your email address"
      :disabled="true"
      class="mb-4"
      :error="$v.form.email.$error"
    >
      <template v-if="$v.form.email.$error" #errors>
        <p v-if="!$v.form.email.required">
          Email is required.
        </p>

        <p v-if="!$v.form.email.email">
          Valid email address required.
        </p>
      </template>
    </UiInput>

    <!-- NAME (hidden) -->
    <UiInput
      v-model.trim="form.name"
      type="hidden"
      name="name"
      placeholder="Your name"
      :disabled="true"
      class="mb-4"
    />

    <!-- PASSWORD -->
    <UiInput
      v-model.trim="form.password"
      type="password"
      placeholder="Your password"
      class="mb-4"
      :error="$v.form.password.$error"
    >
      <template v-if="$v.form.password.$error" #errors>
        <p v-if="!$v.form.password.required">
          Password is required.
        </p>
        <p v-if="!$v.form.password.minLength">
          Password must be at least
          {{ $v.form.password.$params.minLength.min }} characters.
        </p>
        <p v-if="!$v.form.password.maxLength">
          Password can't be more than
          {{ $v.form.password.$params.maxLength.max }} characters.
        </p>
        <p v-if="!$v.form.password.lowercase">
          Password needs at least 1 lowercase character.
        </p>
        <p v-if="!$v.form.password.uppercase">
          Password needs at least 1 uppercase character.
        </p>
        <p v-if="!$v.form.password.number">
          Password needs at least 1 number.
        </p>
      </template>
    </UiInput>

    <!-- CONFIRM PASSWORD -->
    <UiInput
      v-model.trim="form.passwordConfirm"
      placeholder="Confirm password"
      type="password"
      class="mb-4"
      :error="$v.form.passwordConfirm.$error"
    >
      <template v-if="$v.form.passwordConfirm.$error" #errors>
        <p v-if="!$v.form.passwordConfirm.required">
          Confirm password is required.
        </p>
        <p v-if="!$v.form.passwordConfirm.sameAsPassword">
          Passwords must be identical.
        </p>
      </template>
    </UiInput>

    <!-- COUNTRIES PHONE CODE-->
    <UiSelect
      v-model="form.countryPhoneCode"
      :error="$v.form.countryPhoneCode.$error"
      class="mb-4"
      :name="`phoneCode`"
      placeholder="Select a country"
      :options="phoneCodes"
      :label-key="true"
    >
      <template v-if="$v.form.countryPhoneCode.$error" #errors>
        <p v-if="!$v.form.countryPhoneCode.required">
          Country is required.
        </p>
      </template>
    </UiSelect>

    <!-- MOBILE PHONE -->
    <UiInput
      v-model.trim="form.phoneNumber"
      placeholder="Your mobile number"
      type="tel"
      class="mb-4"
      :error="!!(form.countryPhoneCode && $v.form.phoneNumber.$error)"
      :disabled="!form.countryPhoneCode"
    >
      <template v-if="form.countryPhoneCode && $v.form.phoneNumber.$error" #errors>
        <p v-if="!$v.form.phoneNumber.required">
          Mobile number is required.
        </p>
        <p v-if="!$v.form.phoneNumber.numeric">
          Mobile number must contain only numbers.
        </p>
        <p v-if="!$v.form.phoneNumber.isValidMobileNumber">
          Must be a valid mobile number.
        </p>
      </template>
    </UiInput>

    <!-- CONFIRM MOBILE PHONE -->
    <UiInput
      v-model.trim="form.confirmPhoneNumber"
      placeholder="Confirm mobile number"
      type="tel"
      class="mb-4"
      :error="!!(form.countryPhoneCode && $v.form.confirmPhoneNumber.$error)"
      :disabled="!form.countryPhoneCode"
    >
      <template v-if="form.countryPhoneCode && $v.form.confirmPhoneNumber.$error" #errors>
        <p v-if="!$v.form.confirmPhoneNumber.sameAsPhoneNumber">
          Must be the same as your mobile number.
        </p>
      </template>
    </UiInput>

    <div
      class="mb-4 info-panel"
      :class="{
        'animate__animated animate__pulse': !$v.form.confirmPhoneNumber.$invalid
      }"
    >
      <h3 class="mb-2 font-semibold">Why do we ask for a phone number?</h3>

      <p class="mb-2">
        We take security seriously, so we require your phone number to authenticate you.
        <span v-if="!$v.form.confirmPhoneNumber.$invalid">
          Whenever you sign in, we will send an SMS message to
          <strong class="tracking-wider">{{ form.phoneNumber }}</strong> .</span
        >
      </p>
      <p>
        Please make sure you enter your phone number correctly as it cannot be changed.
      </p>
    </div>
    <!-- .info-panel -->

    <!-- TERMS -->
    <UiCheckbox
      v-model="form.acceptsTerms"
      class="mb-4"
      label-class="text-xs"
      :error="$v.form.acceptsTerms.$error"
    >
      <template v-if="$v.form.acceptsTerms.$error" #errors>
        <p v-if="!$v.form.acceptsTerms.sameAs">
          Please accept and agree to the Terms of Use.
        </p>
      </template>

      <template #label
        >Accept and agree to the
        <a href="https://eguarantee.com.au/terms" target="_blank">
          Terms of Use
        </a>
      </template>
    </UiCheckbox>

    <div class="flex items-center">
      <UiInput is="UiButton" theme="primary" @click="onSubmit">Create Account</UiInput>

      <p class="block ml-2 text-xs text-gray-500">
        &hellip;or <router-link to="/sign-in">Sign in</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import { email, required, maxLength, minLength, sameAs } from "vuelidate/lib/validators";
import formMixin from "@/mixins/form";
import UiButton from "@/components/UI/UiButton/UiButton";
import UiCheckbox from "@/components/UI/UiCheckbox/UiCheckbox";
import UiInput from "@/components/UI/UiInput/UiInput";
import UiSelect from "@/components/UI/UiSelect/UiSelect";
import phoneCodes from "@/utils/international-phone";
import { validMobileNumber } from "@/utils/validations";

export default {
  name: "RegistrationForm",
  components: { UiButton, UiCheckbox, UiInput, UiSelect },
  mixins: [formMixin],
  props: {
    contactEmail: {
      type: String,
      default: ""
    },
    contactName: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      phoneCodes,
      form: {
        email: this.contactEmail,
        name: this.contactName,
        password: "",
        passwordConfirm: "",
        countryPhoneCode: "61",
        phoneNumber: "",
        acceptsTerms: false
      }
    };
  },
  validations: {
    form: {
      email: {
        required,
        email
      },
      password: {
        required,
        maxLength: maxLength(32),
        minLength: minLength(8),
        lowercase: password => {
          // Minimum of 1 Lowercase Letter
          if (false === /[a-z]/.test(password)) return false;
          return true;
        },
        uppercase: password => {
          // Minimum of 1 Uppercase Letter
          if (false === /[A-Z]/.test(password)) return false;
          return true;
        },
        number: password => {
          // Minimum of 1 Number
          if (false === /\d/.test(password)) return false;
          return true;
        }
      },
      passwordConfirm: {
        required,
        sameAsPassword: sameAs("password")
      },
      countryPhoneCode: {
        required
      },
      phoneNumber: validMobileNumber,
      confirmPhoneNumber: {
        sameAsPhoneNumber: sameAs("phoneNumber")
      },
      acceptsTerms: {
        required,
        sameAs: sameAs(() => true)
      }
    }
  },
  methods: {
    onSubmit() {
      // validate using this.validate() from our formMixin...
      this.validate();

      if (!this.$v.$invalid) {
        const { email, name, password, countryPhoneCode, phoneNumber } = this.form;

        this.$emit("submit", {
          email,
          name,
          password,
          phoneNumber: `+${countryPhoneCode}${phoneNumber}`
        });
      }
    }
  }
};
</script>

<style scoped>
.info-panel {
  @apply font-normal text-xs text-gray-500 mt-3 p-4 bg-gray-100 rounded;
}
</style>
