<template>
  <div :is="tag" class="ui-badge" :class="status" data-testid="ui-badge">
    <slot>
      <Component :is="iconType" v-if="iconType" :class="width" />
      {{ label }}
    </slot>
  </div>
</template>

<script>
import { LockClosedIcon, LockOpenIcon, ShieldCheckIcon } from "@vue-hero-icons/outline";
import { includes, capitalize } from "lodash";

export default {
  name: "UiBadge",
  props: {
    status: {
      type: String,
      default: ""
    },
    tag: {
      type: String,
      default: "div"
    }
  },
  computed: {
    iconType() {
      switch (this.status) {
        case "active":
          return ShieldCheckIcon;
        case "invited":
          return LockOpenIcon;
        case "inactive":
          return LockClosedIcon;
        default:
          return null;
      }
    },
    label() {
      return capitalize(this.status);
    },
    width() {
      if (includes(["active", "invited", "inactive"], this.status)) {
        return "ui-badge-icon min-width";
      }
      return "ui-badge-icon";
    }
  }
};
</script>

<style scoped>
/* Base Styles */
.ui-badge {
  @apply flex items-center justify-center rounded bg-white shadow-sm text-xs text-gray-600 px-3 py-0.5 text-center;

  &.active {
    @apply bg-green-400 text-white;
  }

  &.invited {
    @apply bg-primary text-white;
  }

  &.inactive {
    @apply bg-red-200 text-red-500;
  }

  &.min-with {
    min-width: 89;
  }
}

.ui-badge-icon {
  @apply w-3 h-3 mr-1;
}
</style>
