<template>
  <div>
    <div v-if="0 === value.envelopes.length">
      Digital signature is initializing, please wait and return later
    </div>
    <div class="grid grid-cols-2">
      <template v-for="(entity, i) in entities">
        <div :key="i" class="col-span-2">{{ entity.name }}</div>
        <UiDocusignStatusBox
          :key="i + 100"
          :value="entity.director.isCompleted"
          :label="entity.director.name == null ? 'N/A' : entity.director.name"
        />
        <UiDocusignStatusBox
          v-if="entity.secretary.name"
          :key="i + 200"
          :value="entity.secretary.isCompleted"
          :label="entity.secretary.name == null ? 'N/A' : entity.secretary.name"
        />
      </template>
    </div>
  </div>
</template>

<script>
import UiDocusignStatusBox from "@/components/UI/UiDocusign/UiDocusignStatusBox";

export default {
  name: "UiDocusignStatusEntity",
  components: { UiDocusignStatusBox },
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ""
    },
    value: {
      type: Object,
      default: () => ({ isCompleted: false, envelopes: [] })
    }
  },
  computed: {
    inputClassObject() {
      return {
        error: this.error,
        [this.size]: this.size
      };
    },
    entities() {
      const entities = [];
      if (this.value.data) {
        entities.push(...this.value.data);
        const recipientStatus = {};
        this.value.envelopes.forEach(envelope => {
          envelope.recipients.forEach(recipient => {
            if (recipient.email) {
              recipientStatus[recipient.email] = recipient.isCompleted;
            }
          });
        });
        entities.forEach(entity => {
          if (entity.director.email) {
            entity.director.isCompleted = recipientStatus[entity.director.email];
          }
          if (entity.secretary?.email) {
            entity.secretary.isCompleted = recipientStatus[entity.secretary.email];
          }
        });
      }
      return entities;
    }
  }
};
</script>
