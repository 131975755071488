<template>
  <div class="w-full max-w-sm mx-auto">
    <UiBrandLogo class="w-auto h-10 mx-auto mb-6 lg:mx-0" />

    <slot></slot>
  </div>
</template>

<script>
import UiBrandLogo from "@/components/UI/UiBrandLogo/UiBrandLogo";

export default {
  name: "AuthView",
  components: { UiBrandLogo }
};
</script>
