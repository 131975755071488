import { Machine, assign } from "xstate";
import {
  deleteFile,
  getPresignedDeleteUrl,
  getPresignedUploadUrl,
  getPresignedDownloadUrl,
  uploadFile
} from "@/utils/index";
import { helperActions } from "@/utils/machine-helpers";

const initialState = {
  errors: [],
  files: [],
  presignedUrls: []
};

export default Machine(
  {
    id: "uploader",

    initial: "idle",

    context: {
      ...initialState
    },

    on: {
      REMOVE_FILE: { actions: ["removeFile", "emitFiles"] }
    },

    states: {
      idle: {
        on: {
          UPLOAD: "presign",
          DOWNLOAD: "download"
        }
      },
      download: {
        invoke: {
          id: "download",
          src: "downloadFile",
          onDone: {
            target: "idle"
          },
          onError: {
            target: "error",
            actions: ["assignErrors", "trackErrors"]
          }
        }
      },
      presign: {
        entry: "assignFiles",
        invoke: {
          id: "presign",
          src: "getPresignedUploadUrl",
          onDone: {
            target: "upload",
            actions: "addUrlsToFile"
          },
          onError: {
            target: "error",
            actions: ["assignErrors", "trackErrors"]
          }
        }
      },
      upload: {
        invoke: {
          id: "upload",
          src: "uploadFile",
          onDone: {
            target: "uploaded"
          },
          onError: {
            target: "error",
            actions: ["assignErrors", "trackErrors"]
          }
        }
      },
      uploaded: {
        entry: "emitFiles",
        after: {
          100: { target: "idle" }
        }
      },
      error: {
        on: {
          RETRY: "presign"
        },
        exit: "resetErrors"
      }
    }
  },
  {
    actions: {
      ...helperActions,
      assignErrors: assign({
        errors: ({ errors }, event) => {
          return [event?.data?.message];
        }
      }),
      assignFiles: assign({
        files: ({ files }, { file }) => [...files, file]
      }),
      addUrlsToFile: ({ files }, event) => {
        const mostRecentFile = files.pop();

        const updatedFile = {
          file: mostRecentFile,
          name: mostRecentFile.name,
          key: event.data?.data?.key,
          upload: event.data?.data
        };

        files.push(updatedFile);
      },
      emitFiles: ({ emit, files }, event) => {
        const keep = files.map(f => ({ key: f.key, name: f.name }));

        emit("input", keep);
      },
      removeFile: assign({
        files: ({ files }, { file }) => {
          const fileToRemove = files.find(f => f.name === file.name);
          getPresignedDeleteUrl(fileToRemove.key).then(resp => {
            deleteFile(resp.data?.presignedUrl);
          });
          return files.filter(f => f.name !== file.name);
        }
      }),
      resetErrors: assign({
        errors: []
      }),
      resetState: assign({
        ...initialState
      })
    },
    services: {
      downloadFile: (context, event) => {
        return getPresignedDownloadUrl(event.file.key, event.file.name).then(response => {
          window.open(response.data?.presignedUrl, "_blank");
        });
      },
      getPresignedUploadUrl: ({ files }, { uploadType }) => {
        const mostRecentFile = files[files.length - 1];
        return getPresignedUploadUrl(mostRecentFile, uploadType);
      },
      uploadFile: ({ files }, event) => {
        const { file, upload } = files[files.length - 1];
        return uploadFile(upload.presignedUrl, file);
      }
    }
  }
);
