<template>
  <div
    class="overflow-hidden bg-white rounded-md shadow"
    :class="{ 'opacity-75': status === 'DELETED' }"
  >
    <div
      class="flex flex-wrap justify-between px-2 py-3 border-b border-gray-200 sm:py-5 sm:px-4 sm:px-6"
    >
      <div class="flex items-center my-1 mr-1">
        <img v-if="profilePic" class="h-10 mr-2 rounded sm:mr-4" :src="profilePic" :alt="title" />

        <div>
          <UiHeadline :level="3" :size="5" class="capitalize">{{ title | lowercase }}</UiHeadline>
          <p v-if="guarantee.guaranteeNumber" class="mr-2 text-xs text-cool-gray-400">
            #{{ guarantee.guaranteeNumber }}
          </p>
        </div>
      </div>
      <!-- .flex items-center -->

      <div class="flex items-center self-center my-1 sm:mt-0">
        <UiButton
          v-if="canDelete"
          theme="link"
          size="sm"
          class="order-last sm:order-none"
          @click="onDeleteClick"
          ><Trash2Icon class="trash-icon" size="1x"
        /></UiButton>

        <router-link v-slot="{ href, navigate }" :to="path">
          <UiButton
            tag="a"
            :href="href"
            :target="target"
            theme="tertiary"
            size="sm"
            @click="navigate"
            >{{ actionButtonLabel }}
          </UiButton>
        </router-link>
      </div>
    </div>

    <div class="flex flex-wrap items-center px-4 py-2">
      <div class="meta-content">
        <StatusUpdate status="NONE" :label="guaranteeTypeLabel" />
      </div>

      <div class="meta-content">
        <StatusUpdate v-if="status" :status="status" :label="statusLabel | lowercase" />
      </div>

      <!-- .status -->

      <div v-if="guarantee.issueDate" class="meta-content">
        <span class="meta-label">Issued:</span>
        {{ guarantee.issueDate | formatDateFromString }}
      </div>

      <div v-if="guarantee.expiryDate" class="meta-content">
        <span class="meta-label">Expires:</span>
        {{ guarantee.expiryDate | formatDateFromString }}
      </div>

      <div v-if="guarantee.externalId" class="meta-content">
        <span class="meta-label">Bond Number:</span>
        {{ guarantee.externalId }}
      </div>

      <div v-if="tenantName" class="meta-content">
        <span class="meta-label">Tenant:</span>
        {{ tenantName }}
      </div>

      <div v-if="landlordName" class="meta-content">
        <span class="meta-label">Property Management:</span>
        {{ landlordName }}
      </div>

      <div v-if="guarantor" class="meta-content">
        <span class="meta-label">Guarantor:</span>
        {{ guarantor }}
      </div>

      <div v-if="guarantee.propertyAddress" class="meta-content">
        <span class="meta-label">Property: </span>
        <span class="mr-2">{{ guarantee.propertyAddress }}</span>
        <a
          v-if="propertyMapsUrl"
          :href="propertyMapsUrl"
          target="_blank"
          class="text-xs text-blue-500"
          >(map)</a
        >
      </div>
    </div>
    <!-- .flex items-center -->
  </div>
</template>

<script>
import { Trash2Icon } from "vue-feather-icons";
import StatusUpdate from "@/components/StatusUpdate/StatusUpdate";
import UiButton from "@/components/UI/UiButton/UiButton";
import UiHeadline from "@/components/UI/UiHeadline/UiHeadline";
import eguaranteeCheckIcon from "@/assets/images/logos/eguarantee-check.png";

export default {
  name: "GuaranteeListItem",
  components: {
    StatusUpdate,
    Trash2Icon,
    UiButton,
    UiHeadline
  },
  props: {
    guarantee: {
      type: Object,
      required: true
    },
    sendToGuaranteesService: {
      type: Function,
      required: true
    },
    userRole: {
      type: String,
      default: ""
    }
  },
  data(props) {
    return {
      active: false,
      target: props.userRole === "admin" || props.userRole === "guarantor" ? "_blank" : undefined
    };
  },
  computed: {
    actionButtonLabel() {
      return this.guarantee.status?.primaryStatus === "APPLICATION"
        ? "View Application"
        : "View Guarantee";
    },
    canDelete() {
      return this.guarantee?.status?.primaryStatus === "APPLICATION";
    },
    guarantor() {
      return this.guarantee?.guarantor?.name ?? "";
    },
    landlordName() {
      return this.guarantee?.landlord?.name ?? "";
    },
    guaranteeType() {
      return this.guarantee?.guaranteeType ?? "";
    },
    path() {
      const { guaranteeId } = this.guarantee;
      let path = `/guarantee/${guaranteeId}`;
      return path;
    },
    guaranteeTypeLabel() {
      return this.guaranteeType.replace("_", " ").toLowerCase();
    },
    propertyMapsUrl() {
      const query = encodeURIComponent(this.guarantee?.propertyAddress);

      return query ? `https://www.google.com/maps/search/?api=1&query=${query}` : "";
    },
    profilePic() {
      const tenantIcon = this.guarantee?.tenant?.profilePic ?? eguaranteeCheckIcon;
      const landlordIcon = this.guarantee?.landlord?.profilePic ?? eguaranteeCheckIcon;
      const role = {
        guarantor: tenantIcon,
        tenant: landlordIcon,
        landlord: tenantIcon
      };

      return role[this.userRole] || tenantIcon;
    },
    status() {
      return this.guarantee?.status?.primaryStatus ?? "";
    },
    statusLabel() {
      return this.guarantee?.status?.label ?? "";
    },
    tenantName() {
      return this.guarantee?.tenant?.name ?? "";
    },
    title() {
      const defaultTitle = {
        APPLICATION: "Edit Application",
        DELETED: "Application Deleted",
        DEFAULT: "View Guarantee"
      };

      const title = {
        guarantor: this.guarantee?.tenant?.name ?? defaultTitle[this.status || "DEFAULT"],
        tenant: this.guarantee?.landlord?.name ?? defaultTitle[this.status || "DEFAULT"],
        landlord: this.guarantee?.tenant?.name ?? defaultTitle[this.status || "DEFAULT"]
      };

      return title[this.userRole] ?? this.guarantee?.tenant?.name;
    }
  },
  methods: {
    onDeleteClick() {
      if (window.confirm("Are you sure you want to delete this application?")) {
        const { guaranteeId } = this.guarantee;

        this.sendToGuaranteesService("DELETE", { guaranteeId });
      }
    },
    onToggleClick() {
      this.active = !this.active;
    }
  }
};
</script>

<style scoped>
.meta-content {
  @apply w-full text-xs text-cool-gray-500 font-normal mr-1 py-1;

  @screen sm {
    @apply w-auto;
  }

  @screen md {
    @apply mr-2;
  }
}

.meta-label {
  @apply text-xs font-light text-cool-gray-500;
}

.trash-icon {
  @apply text-cool-gray-600;

  &:hover {
    @apply text-red-600 transform scale-110;
  }
}
</style>
