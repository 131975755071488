<template>
  <div class=" w-4/12 border-r border-gray-200 h-full">
    <ChatSessionList
      :session-list="sessionList"
      :current-filter="currentFilter"
      @select-session="$emit('select-session', $event)"
    />
  </div>
</template>

<script>
import ChatSessionList from "@/components/Chat/ChatSessionList.vue";

export default {
  name: "ChatSidebarArea",
  components: {
    ChatSessionList
  },
  props: {
    recipientFilters: {
      type: Array,
      default: () => []
    },
    currentFilter: {
      type: String,
      default: () => ""
    },
    sessionList: {
      type: Array,
      default: () => []
    }
  }
};
</script>
