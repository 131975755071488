<template>
  <!-- Main Content -->
  <div class="container flex flex-col h-full">
    <div class="flex-auto">
      <ContactUsForm v-if="$route.meta.contactUs"></ContactUsForm>
      <LandlordContactUsForm v-else-if="$route.meta.landlordContactUs"></LandlordContactUsForm>
      <ReferralForm v-else-if="$route.meta.referral"></ReferralForm>
      <WelcomeForm v-else-if="$route.meta.welcome"></WelcomeForm>
      <CreateAccountForm v-else></CreateAccountForm>
    </div>
    <div class="flex flex-col flex-initial pl-10 pb-10 pr-10 gap-4 sm:flex-row justify-between">
      <p class="text-newgrey text-xs font-articulatcf">
        eGuarantee | Level 4, 45 Clarence St, Sydney NSW 2000 © Copyright
        {{ new Date().getFullYear() }}
      </p>
      <div>
        <a
          class="text-newgrey text-xs cursor-pointer font-articulatcf font-bold mr-3"
          href="https://www.eguarantee.com.au/terms"
          target="_blank"
        >
          terms of use
        </a>
        <a
          class="text-newgrey text-xs cursor-pointer font-articulatcf font-bold"
          href="https://www.eguarantee.com.au/privacy"
          target="_blank"
        >
          privacy policy
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import ContactUsForm from "@/components/ContactUs/ContactUsForm.vue";
import LandlordContactUsForm from "@/components/ContactUs/LandlordContactUsForm.vue";
import CreateAccountForm from "@/components/CreateAccount/CreateAccountForm.vue";
import WelcomeForm from "@/components/CreateAccount/WelcomeForm.vue";
import ReferralForm from "@/components/Referral/ReferralForm.vue";

export default {
  name: "CreateAccount",
  components: {
    ContactUsForm,
    LandlordContactUsForm,
    CreateAccountForm,
    ReferralForm,
    WelcomeForm
  }
};
</script>

<style scoped></style>
