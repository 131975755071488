import { Machine, assign } from "xstate";
import { API, graphqlOperation } from "aws-amplify";
import { SendMessage, RemoveMessage } from "@/graphql/mutations";
import { helperActions } from "@/utils/machine-helpers";

const initialState = {
  errors: [],
  guaranteeId: "",
  messageContent: "",
  currentFilter: "All"
};

export default Machine(
  {
    id: "messages-machines",

    initial: "idle",

    context: {
      ...initialState
    },

    states: {
      idle: {
        on: {
          SEND: "sending",
          REMOVE: "removing"
        }
      },
      sending: {
        entry: "assignMessageContent",
        invoke: {
          id: "sending",
          src: "sendMessage",
          onDone: {
            target: "sent",
            actions: ["resetState", "reloadGuarantee"]
          },
          onError: {
            target: "error",
            actions: ["assignErrors", "trackErrors"]
          }
        }
      },
      removing: {
        invoke: {
          id: "removing",
          src: "removeMessage",
          onDone: {
            target: "sent",
            actions: ["resetState", "reloadGuarantee"]
          },
          onError: {
            target: "error",
            actions: ["assignErrors", "trackErrors"]
          }
        }
      },
      sent: {
        after: {
          10: { target: "idle" }
        }
      },
      error: {
        on: {
          RETRY: "sending"
        }
      }
    }
  },
  {
    actions: {
      ...helperActions,
      assignMessageContent: assign({
        messageContent: (context, event) => event.messageContent || context.messageContent,
        recipients: (context, event) => event.recipients || context.recipients
      }),
      resetErrors: assign({
        errors: []
      }),
      resetState: assign({
        ...initialState
      })
    },
    services: {
      sendMessage: (context, event) => {
        const { guaranteeId, messageContent, recipients } = context;

        return API.graphql(
          graphqlOperation(SendMessage, {
            input: {
              guaranteeId,
              messageContent,
              recipients
            }
          })
        );
      },
      removeMessage: (context, event) => {
        return API.graphql(
          graphqlOperation(RemoveMessage, {
            input: {
              guaranteeId: context.guaranteeId,
              messageId: event.messageId
            }
          })
        );
      }
    }
  }
);
