<template>
  <div class="flex min-h-screen bg-white">
    <div
      class="flex flex-col justify-center flex-1 px-4 py-12 sm:px-6 lg:flex-none md:w-gr-small lg:px-20 xl:px-24"
    >
      <slot></slot>
    </div>

    <div class="relative flex-1 hidden w-0 lg:block">
      <img
        class="absolute inset-0 object-cover w-full h-full"
        src="@/assets/images/misc/splash.jpg"
        alt=""
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "AuthLayout"
};
</script>

<style scoped></style>
