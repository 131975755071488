<template>
  <UiBaseInput
    :name="$attrs.name"
    :label="label"
    :size="labelSize"
    :error="error"
    :loading="loading"
    :class="{ loading }"
  >
    <AutoResize>
      <template v-slot:default="{ resize }">
        <textarea
          class="form-textarea ui-textarea"
          :class="inputClass"
          data-testid="ui-textarea"
          :value="value"
          v-bind="$attrs"
          rows="3"
          placeholder="Enter your message"
          :data-cy="$attrs.name"
          @input="onInput($event, resize)"
        />
      </template>
    </AutoResize>

    <slot slot="label" name="label" />
    <slot v-if="error" slot="errors" name="errors" />
  </UiBaseInput>
</template>

<script>
import AutoResize from "@/components/UI/UiTextarea/AutoResize";
import UiBaseInput from "@/components/UI/UiBaseInput/UiBaseInput";

export default {
  name: "UiTextarea",
  components: { AutoResize, UiBaseInput },
  inheritAttrs: false,
  props: {
    error: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ""
    },
    inputStyle: {
      type: String,
      default: ""
    },
    labelSize: {
      type: String,
      default: ""
    }
  },
  computed: {
    inputClass() {
      return {
        error: this.error,
        [this.inputStyle]: this.inputStyle
      };
    }
  },
  methods: {
    onInput(event, resize) {
      this.$emit("input", event.target.value);
      resize(event);
    }
  }
};
</script>

<style scoped>
/* Base Styles */
.ui-textarea {
  @apply block w-full text-xs whitespace-pre-wrap;
  min-height: 89px;

  &[disabled] {
    @apply text-gray-500;
  }

  &.error {
    @apply border-red-300 text-red-900 pr-10;

    &::placeholder {
      @apply text-red-900;
    }

    &:focus {
      @apply border-red-300 shadow-outline-red;
    }
  }

  @screen md {
    @apply text-sm;
  }
}
</style>
