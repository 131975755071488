<template>
  <div class="unverified-org space-y-2">
    <p>
      To register please follow the link in the invite email you received.
    </p>

    <p>Or reach out to one of our team members and we'll be happy to help.</p>
  </div>
</template>

<script>
export default {
  name: "Unverified"
};
</script>

<style scoped>
.unverified-org {
  @apply text-sm text-gray-600;
}
</style>
