<template>
  <div>
    <template v-if="role !== 'tenant'">
      <AbnSearchApplicationInput
        v-model.trim="form.organization"
        :role="name"
        :error="parentFormDirtied && $v.form.organization.abn.$error"
        :show-contact-details="$v.form.organization.$error || $v.form.organization.$dirty"
        :contact-email-error="parentFormDirtied && $v.form.organization.contactEmail.$error"
        :contact-firstname-error="parentFormDirtied && $v.form.organization.contactFirstname.$error"
        :contact-lastname-error="parentFormDirtied && $v.form.organization.contactLastname.$error"
      >
        <!-- ABN ERRORS -->
        <template v-if="parentFormDirtied && $v.form.organization.$error" slot="abnErrors">
          <p v-if="!$v.form.organization.abn.required">
            ABN is required.
          </p>
          <p v-else-if="!$v.form.organization.abn.minLength">
            ABN must have at least
            {{ $v.form.organization.abn.$params.minLength.min }}
            numbers.
          </p>
          <p v-else-if="!$v.form.organization.abn.maxLength">
            ABN must not be over
            {{ $v.form.organization.abn.$params.maxLength.max }}
            numbers.
          </p>
        </template>

        <template
          v-if="parentFormDirtied && $v.form.organization.name.$error"
          slot="companyNameErrors"
        >
          <p v-if="!$v.form.organization.name.required">
            Compnay name is required.
          </p>
        </template>

        <!-- CONTACT EMAIL ERRORS -->
        <template
          v-if="parentFormDirtied && $v.form.organization.contactEmail.$error"
          slot="contactEmailErrors"
        >
          <p v-if="!$v.form.organization.contactEmail.required">
            Contact email is required.
          </p>
          <p v-if="!$v.form.organization.contactEmail.email">
            Please enter a valid email address.
          </p>
        </template>

        <!-- CONTACT FIRST NAME ERRORS -->
        <template
          v-if="parentFormDirtied && $v.form.organization.contactFirstname.$error"
          slot="contactFirstnameErrors"
        >
          <p v-if="!$v.form.organization.contactFirstname.required">
            Contact first name is required.
          </p>
        </template>

        <!-- CONTACT LAST NAME ERRORS -->
        <template
          v-if="parentFormDirtied && $v.form.organization.contactLastname.$error"
          slot="contactLastnameErrors"
        >
          <p v-if="!$v.form.organization.contactLastname.required">
            Contact last name is required.
          </p>
        </template>
      </AbnSearchApplicationInput>
    </template>
  </div>
</template>

<script>
import formMixin from "@/mixins/form";
import AbnSearchApplicationInput from "@/components/AbnSearch/AbnSearchApplicationInput";
import { defaultAbnValidations } from "@/utils/validations";

export default {
  name: "UiOrganizationInput",
  components: {
    AbnSearchApplicationInput
  },
  mixins: [formMixin],
  props: {
    name: {
      type: String,
      default: ""
    },
    role: {
      type: String,
      default: ""
    },
    value: {
      type: Object,
      default: () => {}
    },
    formDirty: {
      type: Boolean,
      default: false
    },
    reportStatus: {
      type: Function,
      default: () => {}
    }
  },

  data() {
    return {
      form: {
        organization: this.value
      },
      parentFormDirtied: this.formDirty
    };
  },
  watch: {
    "form.organization": function(val) {
      this.$emit("input", this.form.organization);
    },
    formDirty: function(val) {
      this.parentFormDirtied = val;
    },
    "$v.$error": function(val) {
      this.reportStatus({ inputId: this.name, valid: !val });
    }
  },
  created() {
    this.validateForm();
  },
  methods: {
    abnValidations(role) {
      return {
        ...defaultAbnValidations("organization", this.form)
      };
    },
    validateForm() {
      this.$v.$touch();
      setTimeout(() => {
        document.querySelectorAll(".error")[0]?.focus();
      }, 100);
    }
  },
  validations() {
    let organization = {};
    if (this.role !== "tenant") {
      organization = this.abnValidations("tenant");
    }
    const validationRules = {
      organization
    };

    return {
      form: {
        ...validationRules
      }
    };
  }
};
</script>
