<template>
  <UiCard theme="primary">
    <div class="flex ">
      <div class="w-1/2 flex justify-start">
        <div v-if="modifiedUserGroups.length && !creatingNewGroup && !creating" class="mt-1">
          <p class="text-gray-700 font-semibold text-sm mb-1">
            Viewing Group:
          </p>
          <UiSelect
            v-model="currentUserGroupId"
            class="w-full"
            :options="userGroupSelect"
            :disabled="editing"
          />
        </div>
      </div>
      <div class="w-1/2 min-h-64 flex flex-col items-end justify-between">
        <GenerateReport report-type="userGroup" />
        <UiButton
          v-if="buttonReady && !creating"
          theme="outline"
          size="sm"
          title="New User Group"
          class="mt-2"
          :disabled="editing"
          @click="creatingNewGroup = !creatingNewGroup"
        >
          Create New User Group
        </UiButton>
      </div>
    </div>
    <div v-if="currentUserGroup && !creatingNewGroup && !creating">
      <hr class="my-4" />
      <div class="mb-4">
        <h3 class="text-sm text-gray-500 font-semibold mb-2">Group Name:</h3>
        <div class="ml-4 my-2">
          <UiInput
            v-model="currentUserGroup.editingName"
            name="newUserGroupName"
            placeholder="Group name"
            class="mb-2"
            :disabled="!editing"
          />
        </div>
      </div>
      <div class="mb-4">
        <h3 class="text-sm text-gray-500 font-semibold mb-2">Addresses:</h3>
        <div class="mx-4">
          <p
            v-if="!currentUserGroup.addresses.length && !addingNewAddress"
            class="text-sm text-gray-500"
          >
            No addresses have been assigned to this group.
          </p>
          <div v-for="(address, idx) in currentUserGroup.addresses" :key="`address-${idx}`">
            <div class="mt-2 w-full flex items-center justify-between bg-gray-50 py-1 px-2 rounded">
              <p class="font-medium text-sm text-gray-500">
                {{ address.unit }} {{ address.street }} {{ address.suburb }} {{ address.state }}
                {{ address.postcode }} {{ address.country }}
              </p>
              <div class="flex gap-2">
                <UiButton
                  v-if="editing"
                  theme="outline"
                  title="Edit Address"
                  size="sm"
                  @click="onEditAddressClick(address)"
                >
                  Edit
                </UiButton>
                <UiButton
                  v-if="editing"
                  theme="warning"
                  title="Remove Address"
                  size="sm"
                  @click="onRemoveAddressClick(address)"
                >
                  Remove
                </UiButton>
              </div>
            </div>
          </div>
          <UiButton
            v-if="buttonReady && editing"
            theme="outline"
            size="sm"
            class="mt-2"
            title="Add new Address"
            @click="addNewAddress"
          >
            + Add Address
          </UiButton>
        </div>
      </div>

      <div v-if="addingNewAddress" class="bg-gray-50 rounded m-1 p-4">
        <h4 class="text-gray-700 font-bold">Add a new address to this group</h4>
        <p class="text-gray-700 mb-4 text-sm">
          Users within this group will automatically be granted access and receive notifications to
          guarantees with a matching address.
          <span class="font-semibold">Country is required, all other fields are optional.</span>
        </p>
        <div class="flex flex-wrap justify-between mb-3">
          <div class="w-full px-2 mb-2 sm:w-1/3">
            <UiInput v-model.trim="newAddress.unit" name="unit" label="Unit" class="w-full" />
            <p class="m-1 text-xs font-medium text-gray-400">e.g. Unit 5 or Level 2</p>
          </div>
          <div class="w-full px-2 mb-2 sm:w-1/3">
            <UiInput v-model.trim="newAddress.street" name="street" label="Street" class="w-full" />
            <p class="m-1 text-xs font-medium text-gray-400">e.g. 30 George St</p>
          </div>
          <div class="w-full px-2 mb-2 sm:w-1/3">
            <UiInput v-model.trim="newAddress.suburb" name="suburb" label="Suburb" class="w-full" />
            <p class="m-1 text-xs font-medium text-gray-400">e.g. Sydney or Auckland CBD</p>
          </div>
        </div>

        <div class="flex flex-wrap justify-between mb-3">
          <UiInput
            v-model.trim="newAddress.postcode"
            name="postcode"
            label="Postcode"
            class="w-full px-2 sm:w-1/3"
          />
          <div class="w-full px-2 sm:w-1/3">
            <UiInput
              v-model.trim="newAddress.state"
              name="state"
              label="State or Region"
              class="w-full"
            />
            <p class="m-1 text-xs font-medium text-gray-400">e.g. NSW or Auckland</p>
          </div>
          <UiSelect
            v-model="newAddress.country"
            :options="countryOptions"
            label="Country"
            class="w-full px-2 sm:w-1/3"
          />
        </div>

        <div class="flex flex-wrap justify-end gap-3">
          <UiButton theme="outline" @click="resetNewAddress">Cancel</UiButton>
          <UiButton theme="primary" @click="onSaveNewAddress">
            Save
          </UiButton>
        </div>
      </div>

      <div class="mb-4">
        <h3 class="text-sm text-gray-500 font-semibold mb-2">Users:</h3>
        <div class="mx-4">
          <p v-if="!usersInGroup.length && !addingNewUser" class="text-sm text-gray-500">
            No users have been assigned to this group.
          </p>
          <div v-for="(user, idx) in usersInGroup" :key="`users-${idx}`">
            <div
              v-if="user"
              class="mt-2 text-sm text-gray-500 w-full flex items-center justify-between bg-gray-50 py-1 px-2 rounded"
            >
              <div class="w-1/2 flex flex-col items-start">
                <p class="font-medium">{{ user.firstname }} {{ user.lastname }}</p>
                <p class="text-xs">{{ user.email }}</p>
              </div>

              <div>
                {{ user.userRoles.map(role => role.name).join(", ") }}
              </div>

              <UiButton
                v-if="editing"
                theme="warning"
                title="Remove User"
                size="sm"
                @click="onRemoveUserClick(user)"
                >Remove</UiButton
              >
            </div>
          </div>
          <UiButton
            v-if="buttonReady && editing && usersOptions.length > 0"
            theme="outline"
            size="sm"
            title="Add new User"
            class="mt-2"
            @click="addingNewUser = !addingNewUser"
          >
            + Add User
          </UiButton>
        </div>
      </div>
      <div v-if="addingNewUser" class="bg-gray-50 rounded m-1 p-4">
        <h4 class="text-gray-700 font-bold">Add User</h4>
        <UiSelect v-model="newUserEmail" :options="usersOptions" class="my-3" />
        <div class="flex justify-end gap-3">
          <UiButton theme="outline" title="Cancel Add User" @click="resetNewUser">
            Cancel
          </UiButton>
          <UiButton theme="primary" title="Add New User to Group" @click="onSaveNewUser">
            Add
          </UiButton>
        </div>
      </div>
    </div>

    <div v-if="!!currentUserGroupId && !creating" class="mt-3 flex gap-3 justify-end">
      <UiButton
        v-if="buttonReady && !editing"
        theme="warning"
        :disabled="removing || updating"
        :loading="removing"
        @click="onDeleteUserGroup"
      >
        Delete
      </UiButton>
      <UiButton
        v-if="buttonReady && editing"
        theme="outline"
        title="Cancel"
        :disabled="removing || updating"
        @click="onCancel"
      >
        Cancel
      </UiButton>
      <UiButton
        v-if="buttonReady && editing"
        theme="primary"
        title="Save"
        :loading="updating"
        :disabled="removing || updating"
        @click="onSave"
      >
        Save Changes
      </UiButton>
      <UiButton
        v-if="buttonReady && !editing"
        theme="primary"
        :disabled="removing || updating"
        @click="onEditUserGroup"
      >
        Edit
      </UiButton>
    </div>

    <div v-if="creatingNewGroup || creating">
      <h4 class="font-semibold text-gray-700 mb-4">Create New User Group</h4>
      <UiInput
        v-model.trim="newUserGroupName"
        name="newUserGroupName"
        label="Name"
        placeholder="NSW Property Portfolio"
      />
      <div class="mt-3 flex gap-3 justify-end">
        <UiButton
          theme="outline"
          title="Cancel New User Group"
          @click="creatingNewGroup = !creatingNewGroup"
        >
          Cancel
        </UiButton>
        <UiButton
          theme="primary"
          title="Create New User Group"
          :loading="creating"
          @click="onCreate"
        >
          Create
        </UiButton>
      </div>
    </div>
  </UiCard>
</template>

<script>
import formMixin from "@/mixins/form";
import UiButton from "@/components/UI/UiButton/UiButton";
import UiCard from "@/components/UI/UiCard/UiCard";
import UiSelect from "@/components/UI/UiSelect/UiSelect";
import UiInput from "@/components/UI/UiInput/UiInput";
import GenerateReport from "@/components/Reports/GenerateReport";

export default {
  name: "UserGroups",
  components: { UiButton, UiCard, UiInput, UiSelect, GenerateReport },
  mixins: [formMixin],
  props: {
    organization: {
      type: Object,
      required: true,
      default: () => ({})
    },
    userGroups: {
      type: Array,
      default: () => []
    },
    users: {
      type: Array,
      default: () => []
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    editOnCreate: {
      type: Boolean,
      default: false
    },
    creating: {
      type: Boolean,
      default: false
    },
    updating: {
      type: Boolean,
      default: false
    },
    removing: {
      type: Boolean,
      default: false
    }
  },
  data(props) {
    return {
      modifiedUserGroups: props.userGroups,
      currentUserGroupId: props.userGroups[0] ? props.userGroups[0].id : "",
      fallbackGroup: props.userGroups[0] ? JSON.parse(JSON.stringify(props.userGroups[0])) : {},
      newAddress: {},
      fallbackAddress: {},
      addingNewAddress: false,
      creatingNewGroup: false,
      addingNewUser: false,
      editing: props.editOnCreate,
      newUserGroupName: "",
      newUserEmail: "",
      countryOptions: [
        { label: "AUS", value: "AUS" },
        { label: "NZL", value: "NZL" }
      ]
    };
  },
  computed: {
    currentUserGroup() {
      let group = this.modifiedUserGroups.find(g => g.id === this.currentUserGroupId);
      if (group) {
        group.editingName = group.name;
      }
      return group;
    },
    usersInGroup() {
      const response = [];
      for (const email of this.currentUserGroup.userEmails) {
        const user = this.users.find(u => u.email === email);
        if (user) {
          response.push(user);
        }
      }
      return response;
    },
    usersOptions() {
      return this.users
        .filter(u => u.email && !this.currentUserGroup.userEmails?.includes(u.email))
        .map(u => ({ label: `${u.firstname} ${u.lastname} (${u.email})`, value: u.email }));
    },
    userGroupSelect() {
      return this.modifiedUserGroups.map(group => ({
        label: group.name,
        value: group.id
      }));
    },
    buttonReady() {
      return (
        !this.creatingNewGroup && !this.addingNewAddress && !this.addingNewUser && !this.readOnly
      );
    }
  },
  watch: {
    updating(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.editing = false;
      }
      this.updating = newVal;
    },
    users: {
      handler(newVal, oldVal) {
        const newUser = newVal.find(
          newUser => !oldVal.some(oldUser => oldUser.email === newUser.email)
        );

        if (newUser && newUser.userGroupIds?.includes(this.currentUserGroupId)) {
          this.currentUserGroup.userEmails.push(newUser.email);
        }

        this.users = newVal;
      },
      deep: true
    }
  },
  methods: {
    onEditAddressClick(address) {
      this.addingNewAddress = true;
      this.fallbackAddress = { ...address };
      this.newAddress = { ...address };
    },
    onRemoveAddressClick(address) {
      const index = this.currentUserGroup.addresses.findIndex(
        a => JSON.stringify(a) === JSON.stringify(address)
      );
      if (index !== -1) {
        this.currentUserGroup.addresses.splice(index, 1);
      }
    },
    addNewAddress() {
      this.addingNewAddress = true;
      this.newAddress = {
        unit: "",
        street: "",
        suburb: "",
        postcode: "",
        state: "",
        country: "AUS"
      };
    },
    resetNewAddress() {
      this.addingNewAddress = false;
      this.newAddress = {};
      this.fallbackAddress = {};
    },
    onSaveNewAddress() {
      if (this.newAddress.country.length) {
        this.currentUserGroup.addresses = this.currentUserGroup.addresses.filter(
          a => JSON.stringify(a) !== JSON.stringify(this.fallbackAddress)
        );
        this.currentUserGroup.addresses.push(this.newAddress);
        this.resetNewAddress();
      }
    },
    onRemoveUserClick(user) {
      this.currentUserGroup.userEmails = this.currentUserGroup.userEmails.filter(
        email => email !== user.email
      );
    },
    resetNewUser() {
      this.addingNewUser = false;
      this.newUserEmail = "";
    },
    onSaveNewUser() {
      this.currentUserGroup.userEmails.push(this.newUserEmail);
      this.resetNewUser();
    },
    onSave() {
      this.$emit("save", { ...this.currentUserGroup, name: this.currentUserGroup.editingName });
    },
    onDeleteUserGroup() {
      if (confirm("Are you sure to delete this group?")) {
        this.$emit("delete", this.currentUserGroup.id);
      }
    },
    onEditUserGroup() {
      this.editing = true;
    },
    onCancel() {
      this.modifiedUserGroups = this.modifiedUserGroups.filter(
        g => g.id !== this.currentUserGroupId
      );
      this.modifiedUserGroups.push(JSON.parse(JSON.stringify({ ...this.fallbackGroup })));
      this.fallbackGroup = JSON.parse(JSON.stringify({ ...this.fallbackGroup }));
      this.editing = false;
    },
    onCreate() {
      if (this.newUserGroupName.length) {
        this.$emit("create", { name: this.newUserGroupName, addresses: [] });
        this.newUserGroupName = "";
        this.creatingNewGroup = false;
      }
    }
  }
};
</script>
