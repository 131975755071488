<template>
  <UiCard theme="secondary">
    <div v-if="loading" class="text-sm animate__animated animate__pulse animate__infinite">
      <UiLoader label="Attaching..." />
    </div>

    <div v-else>
      <h3 class="text-left mb-1 text-md leading-6 font-medium text-gray-900">
        Name
      </h3>
      <UiInput
        v-model="customName"
        name="customName"
        placeholder="(Optional) Document name"
        class="mb-4"
        size="sm"
        :error="$v.customName.$error"
      >
      </UiInput>

      <h3 class="text-left mb-2 text-md leading-6 font-medium text-gray-900">
        Access
      </h3>

      <template v-if="recipientOptions.includes('landlord')">
        <UiCheckbox
          v-model="allowLandlord"
          class="mb-3 text-left"
          name="allowLandlord"
          label="Landlord"
          required
        />
      </template>

      <template v-if="recipientOptions.includes('tenant')">
        <UiCheckbox
          v-model="allowTenant"
          class="mb-3 text-left"
          name="allowTenant"
          label="Tenant (and Broker if applicable)"
          required
        />
      </template>

      <template v-if="recipientOptions.includes('guarantor')">
        <UiCheckbox
          v-model="allowGuarantor"
          class="mb-3 text-left"
          name="allowGuarantor"
          label="Guarantor"
          required
        />
      </template>

      <template v-if="!disablePrivate && role !== 'admin'">
        <div class="flex justify-start gap-1">
          <UiCheckbox
            v-model="allowUserOrg"
            class="mb-3 text-left"
            name="allowUserOrg"
            label="All other members of my organisation"
            required
          />
          <v-popover slot="meta" offset="16">
            <UiButton theme="link" size="sm" button-class="p-0">
              <InformationCircleIcon size="16" />
            </UiButton>
            <template slot="popover">
              <p class="p-2">
                This control gives access to everyone else in your organisation. You will have
                access regardless of this option.
              </p>
            </template>
          </v-popover>
        </div>
      </template>

      <UiFileInput
        v-model="attachments"
        class="my-4"
        :error="$v.attachments.$error"
        mime-types="application/pdf,image/jpeg,image/png,application/msword,text/csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
        @uploading="uploading = $event"
        @input="uploading = false"
      />

      <div class="flex">
        <UiButton :disabled="uploading" theme="primary" size="md" class="ml-auto" @click="onSubmit">
          {{ attachButtonName }}
        </UiButton>
      </div>
    </div>
  </UiCard>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { InformationCircleIcon } from "@vue-hero-icons/outline";
import formMixin from "@/mixins/form";
import UiButton from "@/components/UI/UiButton/UiButton";
import UiCheckbox from "@/components/UI/UiCheckbox/UiCheckbox";
import UiCard from "@/components/UI/UiCard/UiCard";
import UiInput from "@/components/UI/UiInput/UiInput";
import UiLoader from "@/components/UI/UiLoader/UiLoader";
import UiFileInput from "@/components/UI/UiFileInput/UiFileInput";

export default {
  name: "AttachmentsUploader",
  components: {
    UiButton,
    UiCard,
    UiFileInput,
    UiInput,
    UiCheckbox,
    UiLoader,
    InformationCircleIcon
  },
  mixins: [formMixin],
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    recipientOptions: {
      type: Array,
      default: () => []
    },
    attachButtonName: {
      type: String,
      default: "Attach Document"
    },
    disablePrivate: {
      type: Boolean,
      default: false
    },
    role: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      allowGuarantor: false,
      allowTenant: false,
      allowLandlord: false,
      allowUserOrg: true,
      customName: "",
      attachments: [],
      uploading: false
    };
  },
  methods: {
    onSubmit() {
      this.validate();

      if (!this.$v.$invalid) {
        const {
          customName,
          role,
          attachments,
          allowUserOrg,
          allowLandlord,
          allowGuarantor,
          allowTenant
        } = this;

        const isPrivate = !allowUserOrg;
        const recipients = [];

        switch (role) {
          case "admin":
            allowTenant && recipients.push("tenant", "agent");
            allowGuarantor && recipients.push("guarantor");
            allowLandlord && recipients.push("landlord");
            break;
          case "tenant":
            recipients.push("guarantor", "agent");
            break;
          case "agent":
            recipients.push("guarantor", "tenant");
            break;
          case "guarantor":
            allowTenant && recipients.push("tenant", "agent");
            allowLandlord && recipients.push("landlord");
            break;
        }

        this.$emit("attach-document", customName, attachments[0], recipients, isPrivate);
      }
    }
  },
  validations: {
    customName: {},
    attachments: {
      required
    }
  }
};
</script>
