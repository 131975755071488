import { Organization as OrganizationQuery } from "@/graphql/queries";
import { getGraphQlData, helperActions } from "@/utils/machine-helpers";
import { API, graphqlOperation } from "aws-amplify";
import { assign, Machine } from "xstate";

const initialState = {
  errors: [],
  organization: {}
};

export default Machine(
  {
    id: "organizationMachine",

    initial: "idle",

    context: {
      ...initialState
    },

    states: {
      error: {},
      idle: {
        on: {
          LOAD_ORGANIZATION: "loading"
        }
      },
      loading: {
        invoke: {
          id: "organization",
          src: "fetchOrganization",
          onDone: {
            target: "loaded",
            actions: ["assignOrganization"]
          },
          onError: {
            target: "error",
            actions: ["assignErrors", "trackErrors"]
          }
        }
      },
      loaded: {}
    }
  },
  {
    actions: {
      ...helperActions,
      assignOrganization: assign({
        organization: (context, event) => {
          return getGraphQlData(event, "organization");
        }
      }),
      resetOrganization: assign({
        organization: {}
      })
    },
    services: {
      fetchOrganization: (context, event) => {
        const input = {
          organizationId: event.organizationId
        };
        return API.graphql(graphqlOperation(OrganizationQuery, { input }));
      }
    }
  }
);
