<template>
  <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
    <thead class="text-sm text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
      <tr>
        <th v-for="head in headers" :key="head" class="py-2 px-1">{{ head }}</th>
      </tr>
    </thead>
    <tbody>
      <tr
        v-for="(row, rowIdx) in body"
        :key="rowKey(rowIdx)"
        class="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
      >
        <td v-for="(item, colIdx) in row" :key="colKey(rowIdx, colIdx)" class="py-2 px-2">
          {{ item }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "UiTable",
  components: {},
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: ""
    },
    headers: {
      type: Array,
      default: () => []
    },
    body: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    colKey(row, col) {
      return `${this.id}-${row}-${col}`;
    },
    rowKey(row) {
      return `${this.id}--${row}`;
    }
  }
};
</script>

<style scoped></style>
