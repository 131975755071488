<template>
  <div class="flex items-center justify-center sm:justify-start w-full h-full py-10 gap-10">
    <div class="flex-auto sm:flex-1/2 pl-0 sm:pl-10 text-center sm:text-left">
      <h1 :size="2" class="text-newsecondary font-bold text-4xl sm:text-5xl md:text-6xl">
        Thank you
      </h1>
      <p class="text-newsecondary text-2xl sm:text-3xl md:text-4xl">{{ subheader }}</p>
      <p class="text-newwhite mt-10 text-lg">{{ description }}</p>
      <div class="mt-16">
        <UiButton button-class="px-10" type="button" theme="primary" @click="redirectToAbout()">
          <font-awesome-icon class="pr-5" icon="fa-solid fa-house" inverse />
          <span class="font-normal text-lg py-3">Return to homepage</span>
        </UiButton>
      </div>
    </div>
    <div class="flex-1/2 hidden sm:block">
      <img
        class="m-auto"
        width="220px"
        height="220px"
        src="@/assets/images/logos/eguarantee-tick.svg"
        alt="eGuarantee Thank you"
      />
    </div>
  </div>
</template>

<script>
import UiButton from "@/components/UI/UiButton/UiButton";

export default {
  name: "ThankYou",
  components: {
    UiButton
  },
  props: {
    subheader: {
      type: String,
      default: ""
    },
    description: {
      type: String,
      default: ""
    }
  },
  methods: {
    redirectToAbout() {
      window.location.href = "https://www.eguarantee.com.au";
    }
  }
};
</script>

<style scoped>
/* purgecss start ignore */

/* purgecss end ignore */
</style>
