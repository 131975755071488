<template>
  <div class="w-full">
    <div class="flex" :class="{ 'items-baseline': toggleActive }">
      <div class="rich-text-content">
        <slot v-if="$slots.excerpt && !toggleActive" name="excerpt" />

        <slot v-if="!$slots.excerpt || toggleActive" name="value" />
      </div>

      <button v-if="$slots.excerpt" class="field-toggle" @click="onToggleClick">
        <ChevronUpIcon v-if="toggleActive" size="0.8x" class="relative top-1" />
        <ChevronDownIcon v-else size="0.8x" />
      </button>
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon, ChevronUpIcon } from "vue-feather-icons";

export default {
  name: "RichTextFieldValue",
  components: { ChevronDownIcon, ChevronUpIcon },
  data() {
    return {
      toggleActive: false
    };
  },
  methods: {
    onToggleClick() {
      this.toggleActive = !this.toggleActive;
    }
  }
};
</script>

<style scoped>
.field-toggle {
  @apply text-cool-gray-500 ml-1 outline-none;
}

.rich-text-content {
  >>> p {
    @apply mb-2;

    @screen md {
      @apply mb-3;
    }

    &:last-child {
      @apply mb-0;
    }
  }

  >>> li {
    @apply mb-2 ml-6 list-disc;
  }
}
</style>
