<template>
  <nav class="ui-nav-wrap">
    <div class="container ui-nav-layout">
      <div class="flex justify-between h-16">
        <div class="flex">
          <div class="flex items-center flex-shrink-0">
            <router-link to="/">
              <UiBrandLogo class="ui-nav-logo" />
            </router-link>
          </div>

          <!-- Main Nav Items -->
          <div class="hidden sm:ml-2 sm:flex">
            <router-link to="/guarantees" class="ui-nav-item active">
              Guarantees
            </router-link>
            <router-link to="/settings" class="ui-nav-item">
              Settings
            </router-link>
            <router-link v-if="canManageUsers" to="/manage-users" class="ui-nav-item">
              Manage Users
            </router-link>
            <router-link v-if="isLandlord" to="/organisation/documents" class="ui-nav-item">
              Documents
            </router-link>
          </div>
        </div>

        <div class="flex flex-grow justify-end self-center" :class="{ hidden: hideOrgName() }">
          <span
            class="ui-nav-item-meta text-xxs border-none md:border lg:text-xs md:border-solid md:border-gray-200 md:p-1 lg:p-2 rounded-md"
            title="Switch organisation"
          >
            {{ organizationName }}
          </span>
        </div>

        <!-- Nav Meta Data -->
        <div class="hidden sm:ml-6 sm:flex sm:items-center">
          <button
            class="ui-nav-item-meta ui-nav-item-meta-notifiction"
            :class="{ hidden: !organizationState.matches('ready') }"
            @click="onToggleNotifications"
          >
            <span class="relative">
              <div v-if="unreadNotifications" class="ui-nav-notifiction-dot" />
              <svg class="w-5 h-5" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
            </span>

            <span class="ml-1">Notifications</span>
          </button>

          <button
            class="ui-nav-item-meta ui-nav-item-meta-signOut"
            title="Are you sure?"
            data-cy="signOut"
            @click="signOutHandler"
          >
            {{ signingOut ? "Signing Out" : "Sign Out" }}
          </button>
        </div>

        <!-- Mobile menu button -->
        <div class="flex items-center -mr-2 sm:hidden">
          <button
            class="ui-nav-mobile-control"
            data-testid="ui-nav-mobile-control"
            @click="navActive = !navActive"
          >
            <!-- Icon when menu is closed. -->
            <svg
              class="w-6 h-6"
              :class="!navActive ? 'block' : 'hidden'"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <!-- Icon when menu is open. -->
            <svg
              class="w-6 h-6"
              :class="navActive ? 'block' : 'hidden'"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>

    <!-- Mobile menu -->
    <div class="sm:hidden" :class="navActive ? 'block' : 'hidden'" data-testid="ui-nav-mobile">
      <!-- Nav Items -->
      <div class="pt-2 pb-3">
        <router-link to="/guarantees" class="ui-nav-item-mobile active">Guarantees</router-link>
        <router-link to="/settings" class="ui-nav-item-mobile">Settings</router-link>
      </div>

      <!-- Meta Nav -->
      <div class="py-3 border-t border-gray-200">
        <!-- Meta Nav Items -->
        <div>
          <button
            class="ui-nav-item-mobile-meta"
            :class="{ hidden: !organizationState.matches('ready') }"
            @click="onToggleNotifications"
          >
            <span class="relative">
              <div v-if="unreadNotifications" class="ui-nav-notifiction-dot" />
              Notifications
            </span>
          </button>

          <button
            class="mt-1 ui-nav-item-mobile-meta"
            title="Are you sure?"
            @click="signOutHandler"
          >
            {{ signingOut ? "Signing Out" : "Sign Out" }}
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { signout } from "@/lib/auth";
import { useService } from "@xstate/vue";
import { computed } from "@vue/composition-api";
import { organizationService } from "@/main";
import UiBrandLogo from "@/components/UI/UiBrandLogo/UiBrandLogo";
import { isProduction } from "@/utils/constants";

export default {
  name: "UiNav",
  components: { UiBrandLogo },
  mixins: [],
  setup() {
    // Setup our state machine...
    const { state: organizationState, send: sendToOrganizationService } = useService(
      organizationService
    );

    const unreadNotifications = computed(() => {
      const notifications = organizationState?.value?.context?.organization?.notifications ?? [];
      return notifications.filter(n => n.isUnread).length;
    });

    const organizationName = computed(() => organizationState?.value?.context?.organization?.name);
    const canManageUsers = computed(
      () => organizationState?.value?.context?.organization?.permissions?.manageUsers
    );
    const isAdmin = computed(
      () => organizationState?.value?.context?.organization?.role === "admin"
    );

    const isAgent = computed(
      () => organizationState?.value?.context?.organization?.role === "agent"
    );

    const isLandlord = computed(
      () => organizationState?.value?.context?.organization.role === "landlord"
    );

    // Return what we want to expose to the template...
    return {
      organizationState,
      sendToOrganizationService,
      unreadNotifications,
      organizationName,
      canManageUsers,
      isAdmin,
      isAgent,
      isLandlord
    };
  },
  data() {
    return {
      signingOut: false,
      navActive: false
    };
  },
  watch: {
    $route(to, from) {
      this.navActive = false;
    }
  },
  methods: {
    signOutHandler() {
      this.signingOut = true;
      signout();
    },
    onToggleNotifications() {
      const ready = this.organizationState.matches("ready");
      const notificationsActive = this.organizationState.matches("ready.notificationsActive");

      if (ready) this.sendToOrganizationService("SHOW_NOTIFICATIONS");

      if (notificationsActive) this.sendToOrganizationService("HIDE_NOTIFICATIONS");
    },
    hideOrgName() {
      return !this.organizationState.matches("ready") || isProduction;
    }
  }
};
</script>

<style scoped>
/* Base Styles */
.ui-nav-wrap {
  @apply bg-white shadow;
}

.ui-nav-mobile-control {
  @apply inline-flex items-center justify-center p-2 text-gray-400 transition duration-150 ease-in-out rounded-md;

  &:hover {
    @apply text-gray-500 bg-gray-100;
  }

  &:focus {
    @apply outline-none bg-gray-100 text-gray-500;
  }
}

/* Nav Items */
.ui-nav-item-mobile {
  @apply block py-2 pl-3 pr-4 mt-1 text-base font-medium text-gray-600 transition duration-150 ease-in-out border-l-4 border-transparent;

  &:hover {
    @apply text-gray-800 bg-gray-50 border-gray-300;
  }

  &:focus {
    @apply outline-none text-gray-800 bg-gray-50 border-gray-300;
  }

  &.active {
    @apply text-primary border-blue-400 bg-blue-50;

    &:focus {
      @apply text-blue-700 bg-blue-100 border-blue-700;
    }
  }
}

.ui-nav-item-mobile-meta {
  @apply block px-4 py-2 text-base font-medium text-gray-500 transition duration-150 ease-in-out w-full text-left;

  &:hover {
    @apply text-gray-800 bg-gray-100;
  }

  &:focus {
    @apply outline-none text-gray-800 bg-gray-100;
  }

  .ui-nav-notifiction-dot {
    right: -8px;
  }
}

.ui-nav-item {
  @apply inline-flex items-center px-1 pt-1 ml-4 text-sm font-medium leading-5 text-gray-500 transition duration-150 ease-in-out border-b border-transparent;

  &:hover {
    @apply text-primary border-primary;
  }

  &:focus {
    @apply outline-none text-gray-700 border-gray-300;
  }

  &.router-link-exact-active,
  &.router-link-active {
    @apply text-gray-900 border-primary;

    &:focus {
      @apply outline-none border-blue-700;
    }
  }
}

.ui-nav-item-meta {
  @apply block ml-2 py-2 text-sm font-medium leading-5 text-gray-500;

  &:hover {
    @apply text-primary;
  }

  &:focus {
    @apply outline-none;
  }
}

.ui-nav-item-meta-notifiction {
  @apply flex items-center text-gray-400;

  &:focus {
    @apply outline-none text-gray-500 bg-transparent;
  }
}

.ui-nav-item-meta-signOut {
  &:hover {
    @apply text-gray-700;
  }
}

.ui-nav-notifiction-dot {
  @apply absolute h-2 w-2 bg-red-500 rounded-full border border-white right-0 transform scale-100;
  animation: red-pulse 2s infinite;
  top: -3px;
}

.ui-nav-logo {
  @apply w-auto h-6;

  @screen sm {
    @apply mb-2;
  }
}
</style>
