<template>
  <div>
    <UiHeader class="mb-4">
      <UiHeadline :size="2">Documents</UiHeadline>
    </UiHeader>
    <UiMain v-if="organizationDataReady">
      <Attachments
        :attachments="fetchedOrganization.attachments"
        :allow-attaching="fetchedOrganization.permissions.attach"
        :reload-parent="reloadOrg"
        :parent-id="fetchedOrganization.organizationId"
        :hide-title="true"
        parent-type="organization"
      />
    </UiMain>
  </div>
</template>

<script>
import { useService } from "@xstate/vue";
import { computed } from "@vue/composition-api";
import { organizationService as profileService } from "@/main";
import UiHeader from "@/components/UI/UiHeader/UiHeader";
import UiHeadline from "@/components/UI/UiHeadline/UiHeadline";
import UiMain from "@/components/UI/UiMain/UiMain";
import Attachments from "@/components/Attachments/Attachments";

export default {
  name: "OrganizationDocuments",
  components: {
    UiHeader,
    UiHeadline,
    UiMain,
    Attachments
  },
  inject: ["organization"],
  mounted() {
    const accessibleRoles = ["admin", "landlord"];
    if (!accessibleRoles.includes(this.organization?.value?.role)) {
      this.$router.push("/");
    }
  },
  setup(props, context) {
    const { state: profileState, send: sendToProfileService } = useService(profileService);

    const fetchedOrganization = computed(() => {
      return profileState?.value?.context?.organization ?? {};
    });

    const organizationDataReady = computed(() => profileState?.value?.matches("ready"));

    const reloadOrg = () => sendToProfileService("FETCH_ORGANIZATION");

    return {
      fetchedOrganization,
      reloadOrg,
      organizationDataReady
    };
  },
  methods: {}
};
</script>

<style scoped>
.settings-subtitle {
  @apply mt-2 text-sm text-gray-600;
}
</style>
