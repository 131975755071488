import Vue from "vue";

const calculateSelectWidth = (target, arrowWidth = 8) => {
  // create test element
  var testSpan = document.createElement("span");
  // Set element styles
  testSpan.style.fontSize = window.getComputedStyle(target, null).getPropertyValue("font-size"); // ensures same size text
  testSpan.style.visibility = "hidden"; // prevents FOUC
  // and give it some content
  var selectedInnterText = target.children[target.selectedIndex ?? 0].innerText ?? "";
  var testContent = document.createTextNode(selectedInnterText);
  // add the text node to the newly created span
  testSpan.appendChild(testContent);
  // add to body, get width, and get out
  document.body.appendChild(testSpan);
  const width = testSpan.offsetWidth;
  document.body.removeChild(testSpan);
  // set select width
  target.style.boxSizing = "content-box";
  target.style.width = width + parseInt(arrowWidth, 10) + "px";
};

// Register a global custom directive called `v-focus`
Vue.directive("autoResizeSelect", {
  bind(el, binding) {
    el.addEventListener("change", () => calculateSelectWidth(el, binding.value));
  },
  inserted(el, binding) {
    calculateSelectWidth(el, binding.value);
  },
  unbind(el) {
    el.removeEventListener("change", calculateSelectWidth);
  }
});
